import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  IonLabel,
  IonCard,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonButton,
  IonCardHeader,
  IonContent,
  IonPage,
  IonItem,
  IonHeader,
  IonToolbar,
  IonTitle,
  useIonToast,
  IonImg,
  useIonAlert,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonAvatar,
  IonSkeletonText,
  IonCardTitle,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonViewDidLeave,
  IonAlert,
  IonPopover,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  arrowBackOutline,
  bagHandleSharp,
  cameraSharp,
  heart,
  locationSharp,
  send,
  shieldCheckmarkSharp,
  starSharp,
  ellipsisVertical,
  close,
} from "ionicons/icons";
import {
  addToFavoriteList,
  interestAction,
  interestList,
  sendInterestRequest,
  getCurrentPackage,
  sendPhotoRequestApi,
  interestAcceptList,
  interestRejectList,
  interestPendingList,
  deleteInterestRequest,
} from "../../service";
import appContext from "../../context/AppContext";
import moment from "moment";
import Utils from "../utils";
import constant from "../../constant";
import report from "../../assets/images/report.png";
import weddingrings from "../../assets/images/weddingrings.png";
import blockuser from "../../assets/images/blockuser.png";
import Header from "../shared/Header";
import Details from "./Details";
import { useLocation } from "react-router-dom";
// import vector2 from '../../assets/images/Vector(2).png';
// import vector1 from '../../assets/images/Vector(1).png';

const styles: any = {
  disabledGrid: {
    filter: "blur(1px)",
    pointerEvents: "none",
  },
  blurOverlay: {
    position: "absolute",
    opacity: "1 !important",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "999",
    backdropFilter: "blur(1px)",
  },
  nowAvailableText: {
    position: "absolute",
    top: "36%",
    left: "50%",
    zIndex: "999",
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
  nowAvailableSubText: {
    position: "absolute",
    width: "100%",
    top: "58%",
    left: "50%",
    zIndex: "999",
    fontSize: "0.9em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
};

const Interest = () => {
  const [list, setList]: any = useState(null);
  const [userList, setUserList]: any = useState([]);
  const [userAcceptList, setUserAcceptList]: any = useState([]);
  const [userPendingList, setUserPendingList]: any = useState([]);
  const [userRejectList, setUserRejectList]: any = useState([]);
  const [selectState, setSelectState] = useState({
    type: "send",
    status: "accept",
  });
  const AppContext = useContext(appContext);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [userData, setUserData] = useState<any>({ user: "", i: "" });
  const location = useLocation();
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [showAccept, setShowAccept] = useState<any>(true);
  const [showPending, setShowPending] = useState<any>(false);
  const [showReject, setShowReject] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [event, setEvent] = useState<any>();
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [CurrentPackageSpecial, setCurrentPackageSpecial] = useState<any>(null);

  useEffect(() => {
    handleChange(selectState, 0);
  }, [selectState]);

  const history = useHistory();

  // const handleChange = async (selectValue: any, page: any) => {
  //     var response = await interestList(selectValue, page)
  //     if (response.data.status) {
  //         setPageNumber(page + 1);
  //         setList(response?.data?.data);
  //         let uList = page === 0 ? [] : userList;
  //         setUserList([...uList, ...response?.data?.data?.list]);
  //         setLoading(false)
  //     }
  // }

  useEffect(() => {
    CurrentPackageData();
  }, []);

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      setCurrentPackageSpecial(
        getPackagesByType("special") || getPackagesByType("Special")
          ? getPackagesByType("special") || getPackagesByType("Special")
          : null
      );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  const handleChange = async (selectValue: any, page: any) => {
    const response = await interestList(selectValue, page);
    if (response.data.status) {
      setPageNumber(page + 1);
      setLoading(false);
      setList(response?.data?.data);
      let uList = page === 0 ? [] : userList || [];
      uList = [...uList, ...response?.data?.data?.list];
      setUserList(uList);
    }
  };

  const addToFavorite = async (selectedUser: any, index: any) => {
    const usersListTemp = [...userList];
    usersListTemp[index].favorite = !usersListTemp[index].favorite;
    setUserList([...userList, ...usersListTemp]);
    await addToFavoriteList(selectedUser);
    handleChange(selectState, 0);
  };

  const sendInterest = async (selectedUser: any, index: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      handleChange(selectState, 0);
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  const rejectInterest = async (selectedUser: any, index: any) => {
    var response = await interestAction({
      id: selectedUser?.id,
      status: "reject",
    });
    handleChange(selectState, 0);
  };

  const acceptInterest = async (selectedUser: any, index: any) => {
    var response = await interestAction({
      id: selectedUser?.id,
      status: "accept",
    });
    handleChange(selectState, 0);
  };
  const sendPhotoRequest = async (selectedUser: any, index: any) => {
    var res: any = await sendPhotoRequestApi(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...list.list];
      usersListTemp[index].photoRequest = [
        {
          count_no: usersListTemp[index]?.photoRequest[0]?.count_no
            ? usersListTemp[index]?.photoRequest[0]?.count_no + 1
            : 1,
        },
      ];
      setList({ ...list, list: usersListTemp });
      present({
        message: "Photo request sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      // handleChange(selectState)
    }
  };

  const handleIconClick = (e: any) => {
    setShowPopover(true);
    setEvent(e);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
  };

  const handleRemoveInterest = async () => {
    const res = await deleteInterestRequest(userData.user);
    if (res?.data?.status) {
      userData.user.interest = false;
      present({
        message: "Sent Interest Cancelled",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      setShowAlert(false);
      setUserData({ user: "", i: "" });
    }
  };

  return (
    <>
      <div className="header-common-box">
        <Header />
      </div>
      <IonPage className="interestBody">
        <IonHeader>
          <IonToolbar color={"primary"}>
            <div
              onClick={() => {
                history.goBack();
              }}
              slot="start"
              className="backBtn"
            >
              <IonButton type="submit" className="px-1">
                <IonIcon icon={arrowBackOutline} color="light" />
              </IonButton>
            </div>
            <IonTitle className="fs-18 text-white">
              <b>Interest List</b>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="bg-none mb-0">
          <IonRow class="ion-align-items-center border-bottom   mb-3">
            <IonCol
              className={
                selectState.type === "send"
                  ? "dark text-center  pb-3 fs-14 font-weight-500"
                  : "text-center  pb-3 fs-14 font-weight-500"
              }
              onClick={() => {
                setLoading(true);
                setSelectState({ ...selectState, type: "send" });
              }}
            >
              Send Interest{" "}
              {selectState.type === "send" && <div className="line-div"></div>}
            </IonCol>
            <IonCol
              className={
                selectState.type === "recieved"
                  ? "dark text-center  pb-3 fs-14 font-weight-500"
                  : "text-center  pb-3 fs-14 font-weight-500"
              }
              color={selectState.type === "recieved" ? "dark" : ""}
              onClick={() => {
                setLoading(true);
                setSelectState({ ...selectState, type: "recieved" });
              }}
            >
              Recieved Interest{" "}
              {selectState.type === "recieved" && (
                <div className="line-div"></div>
              )}
            </IonCol>
          </IonRow>
        </IonCard>
        <IonCard className="bg-none mb-3 mt-0 ion-text-center">
          <IonButton
            shape="round"
            size="small"
            className="outline-btn fs-14 font-weight-500"
            fill={selectState.status === "accept" ? "solid" : "outline"}
            color={selectState.status === "accept" ? "primary" : "medium"}
            onClick={() => {
              setLoading(true);
              setSelectState({ ...selectState, status: "accept" });
              setShowAccept(true);
              setShowPending(false);
              setShowReject(false);
              setUserList([]);
            }}
          >
            Accept {list?.totalAccept || 0}
          </IonButton>
          <IonButton
            shape="round"
            size="small"
            className="outline-btn ml-10 fs-14 font-weight-500"
            fill={selectState.status === "pending" ? "solid" : "outline"}
            color={selectState.status === "pending" ? "primary" : "medium"}
            onClick={() => {
              setLoading(true);
              setSelectState({ ...selectState, status: "pending" });
              setShowPending(true);
              setShowReject(false);
              setShowAccept(false);
              setUserList([]);
            }}
          >
            Pending {list?.totalPending || 0}
          </IonButton>
          <IonButton
            shape="round"
            size="small"
            className="outline-btn ml-10 fs-14 font-weight-500"
            fill={selectState.status === "reject" ? "solid" : "outline"}
            color={selectState.status === "reject" ? "primary" : "medium"}
            onClick={() => {
              setLoading(true);
              setSelectState({ ...selectState, status: "reject" });
              setShowReject(true);
              setShowAccept(false);
              setShowPending(false);
              setUserList([]);
            }}
          >
            Rejected {list?.totalReject || 0}
          </IonButton>
        </IonCard>
        <IonContent>
          <Details
            isOpenDetail={isOpenDetail}
            setOpenDetail={setOpenDetail}
            id={userID}
          />

          <IonList>
            {loading ? (
              <IonList>
                {[...Array(3)].map((i) => (
                  <IonCard className="box-new-profile ion-justify-content-center ion-align-items-center">
                    <IonGrid
                      className="ion-margin-0"
                      style={{ maxHeight: "130px", margin: 0 }}
                    >
                      <IonRow>
                        <IonCol size="4">
                          <div
                            style={{
                              width: "100%",
                              paddingTop: "100%",
                              position: "relative",
                              background: "#ccc",
                            }}
                          ></div>
                        </IonCol>
                        <IonCol size="8">
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "60%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
              </IonList>
            ) : (
              <>
                {showAccept && (
                  <div>
                    {userList?.length === 0 ? (
                      <IonCard className="card ">
                        {" "}
                        <p className="fs-16 ion-text-center ion-margin-top">
                          No Interest found here ,Please send Interest to see
                          users here!
                        </p>
                      </IonCard>
                    ) : (
                      userList?.map((u: any, i: any) => {
                        var user = JSON.parse(JSON.stringify(u));
                        let p = u?.hindu_profiles?.find((i: any) => {
                          return (
                            i?.type === "profile" && i?.order_number === 10
                          );
                        });
                        if (!p)
                          p = user?.hindu_profiles?.find((i: any) => {
                            return i?.type === "profile";
                          });
                        return (
                          <>
                            <IonCard
                              key={i}
                              onClick={() => {
                                if (!u?.blocked && !u?.shadiDone) {
                                  setOpenDetail(true);
                                  setUserID(u?.id);
                                }
                              }}
                              className="box-new-profile ion-justify-content-center ion-align-items-center"
                            >
                              {(u?.blocked || u?.shadiDone) && (
                                <div style={styles.blurOverlay}></div>
                              )}
                              <IonGrid
                                className="ion-margin-0"
                                style={{
                                  maxHeight: "11em",
                                  margin: 0,
                                  opacity: `${
                                    u?.blocked || u?.shadiDone ? "0.5" : "1"
                                  }`,
                                }}
                              >
                                <IonRow>
                                  <IonCol
                                    className="image-newCard"
                                    style={{
                                      backgroundImage:
                                        !p?.name &&
                                        Utils.getDefaultUserUrl(
                                          AppContext?.user
                                            ?.first_formGenderEnglish
                                        ),
                                      backgroundSize: p?.name
                                        ? "100% 100%"
                                        : "90px",
                                    }}
                                    sizeXs="5"
                                    sizeSm="5"
                                    sizeLg="5"
                                  >
                                    {/* {p?.name ? ( */}
                                    <IonImg
                                      src={
                                        p?.name
                                          ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                                          : "https://ionicframework.com/docs/img/demos/avatar.svg"
                                      }
                                      style={{
                                        height: "10.3em",
                                        objectFit: "cover",
                                      }}
                                    ></IonImg>
                                    {/* ) : null} */}
                                    {u?.verified_at ? (
                                      <div className="corner-ribbon-new">
                                        <span className="cr-inner-new">
                                          <span className="cr-text-new">
                                            {" "}
                                            <IonIcon
                                              style={{ fontSize: "2em" }}
                                              icon={shieldCheckmarkSharp}
                                            />
                                            <br />
                                            Verified
                                          </span>
                                        </span>
                                      </div>
                                    ) : null}
                                  </IonCol>
                                  <IonCol
                                    className="ps-2 pb-0 mb-0"
                                    sizeSm="7"
                                    sizeXs="7"
                                    sizeLg="7"
                                  >
                                    <IonRow className="ion-justify-content-between ps-1">
                                      <IonLabel style={{ margin: 0 }}>
                                        <p
                                          className="color-primary  font-weight-500 m-0"
                                          style={{
                                            fontSize: 12,
                                            lineHeight: "12px",
                                          }}
                                        >
                                          <b>HSC-{u?.id}</b>
                                        </p>
                                        <div className="my-1 userCardNameandAge">
                                          <b className="ellispses w-50">
                                            {u.nameEnglish}{" "}
                                          </b>
                                          <b>
                                            &nbsp;(
                                            {u?.dob
                                              ? moment().diff(u?.dob, "years")
                                              : u?._age}{" "}
                                            Yrs)
                                          </b>
                                        </div>
                                        <p className="text-muted subInfoUserCard">
                                          <b>
                                            {u.third_formSectEnglish}{" "}
                                            {u.third_formSectEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}{" "}
                                            {u.first_formMaritalStatusEnglish}{" "}
                                            {u.first_formMaritalStatusEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}
                                            <br />{" "}
                                            {u.fourth_formEmployedEnglish
                                              ? u.fourth_formEmployedEnglish
                                              : "-"}{" "}
                                          </b>
                                        </p>
                                      </IonLabel>
                                      {/* <span className='position-absolute' style={{ top: 1, right: 1 }} onClick={handleIconClick}>
                                                                        <IonIcon icon={ellipsisVertical} color='dark' style={{ verticalAlign: 'sub' }} />
                                                                    </span> */}
                                    </IonRow>
                                    <IonRow className="p-0 m-0">
                                      <div className="p-0 m-0 manglikAndAddressUserCard">
                                        <div style={{ flex: "100%" }}>
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 manglikUserCard">
                                              <IonIcon
                                                icon={starSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Manglikta
                                            </p>
                                            <p className="manglikValueUserCard">
                                              <b>
                                                {u.third_formDoshEnglish || "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "20px",
                                            padding: "0px 6px",
                                            flex: "5%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 1,
                                              height: 29,
                                              background: "#797f85",
                                            }}
                                          ></div>
                                        </div>
                                        <div
                                          style={{
                                            flex: "100%",
                                            maxWidth: "80px",
                                          }}
                                        >
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 addressUserCard">
                                              <IonIcon
                                                icon={locationSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Address
                                            </p>
                                            <p className="addressValueUserCard">
                                              <b>
                                                {u.second_formStateEnglish ||
                                                  "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                      </div>
                                    </IonRow>
                                    <IonRow className="mt-1">
                                      <IonCol
                                        size="6"
                                        className="ion-no-padding ion-no-margin sendInterestBtn"
                                      >
                                        <IonButton
                                          style={{ fontSize: "11px" }}
                                          color={"secondary"}
                                          size="small"
                                          onClick={(event: any) => {
                                            // event.stopPropagation();
                                            // if (!AppContext?.user?.second_formMobile) AppContext.setNoDelayPopup(constant.popups.isGoLive);
                                            // else if (!AppContext?.user?.payment_verified_at && (CurrentPackageBasic !== null || CurrentPackageVip !== null)) AppContext.setNoDelayPopup(constant.popups.isSubscribe);
                                            // else if (!u?.interest) sendInterest(u, i);
                                          }}
                                        >
                                          <IonIcon
                                            className="white-New"
                                            icon={send}
                                          />{" "}
                                          Accepted
                                        </IonButton>
                                      </IonCol>
                                      <IonCol
                                        size="6"
                                        className="favouriteBtn ion-no-padding ion-no-margin"
                                      >
                                        <IonButton
                                          style={{ fontSize: "11px" }}
                                          color={
                                            u?.favorite
                                              ? "secondary"
                                              : "warning"
                                          }
                                          size="small"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            addToFavorite(u, i);
                                          }}
                                        >
                                          <IonIcon
                                            className="favouriteBtnIcon"
                                            icon={heart}
                                          />{" "}
                                          Favorite
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                              {u?.blocked && !u?.shadiDone && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person has blocked you!
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && !u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list !
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list && blocked you !
                                  </p>
                                </>
                              )}
                            </IonCard>
                          </>
                        );
                      })
                    )}
                  </div>
                )}

                {showPending && (
                  <div>
                    {userList?.length === 0 ? (
                      <IonCard className="card ">
                        {" "}
                        <p className="fs-16 ion-text-center ion-margin-top">
                          No Interest found here ,Please send Interest to see
                          users here!
                        </p>
                      </IonCard>
                    ) : (
                      userList?.map((u: any, i: any) => {
                        var user = JSON.parse(JSON.stringify(u));
                        let p = u?.hindu_profiles?.find((i: any) => {
                          return (
                            i?.type === "profile" && i?.order_number === 10
                          );
                        });
                        if (!p)
                          p = user?.hindu_profiles?.find((i: any) => {
                            return i?.type === "profile";
                          });
                        return (
                          <>
                            <IonCard
                              key={i}
                              onClick={() => {
                                if (!u?.blocked && !u?.shadiDone) {
                                  setOpenDetail(true);
                                  setUserID(u?.id);
                                }
                              }}
                              className="box-new-profile ion-justify-content-center ion-align-items-center"
                            >
                              {(u?.blocked || u?.shadiDone) && (
                                <div style={styles.blurOverlay}></div>
                              )}
                              <IonGrid
                                className="ion-margin-0"
                                style={{
                                  maxHeight: "11em",
                                  margin: 0,
                                  opacity: `${
                                    u?.blocked || u?.shadiDone ? "0.5" : "1"
                                  }`,
                                }}
                              >
                                <IonRow>
                                  <IonCol
                                    className="image-newCard"
                                    style={{
                                      backgroundImage:
                                        !p?.name &&
                                        Utils.getDefaultUserUrl(
                                          AppContext?.user
                                            ?.first_formGenderEnglish
                                        ),
                                      backgroundSize: p?.name
                                        ? "100% 100%"
                                        : "90px",
                                    }}
                                    sizeXs="5"
                                    sizeSm="5"
                                    sizeLg="5"
                                  >
                                    {/* {p?.name ? ( */}
                                    <IonImg
                                      src={
                                        p?.name
                                          ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                                          : "https://ionicframework.com/docs/img/demos/avatar.svg"
                                      }
                                      style={{
                                        height: "10.3em",
                                        objectFit: "cover",
                                      }}
                                    ></IonImg>
                                    {/* ) : null} */}
                                    {u?.verified_at ? (
                                      <div className="corner-ribbon-new">
                                        <span className="cr-inner-new">
                                          <span className="cr-text-new">
                                            {" "}
                                            <IonIcon
                                              style={{ fontSize: "2em" }}
                                              icon={shieldCheckmarkSharp}
                                            />
                                            <br />
                                            Verified
                                          </span>
                                        </span>
                                      </div>
                                    ) : null}
                                  </IonCol>
                                  <IonCol
                                    className="ps-2 pb-0 mb-0"
                                    sizeSm="7"
                                    sizeXs="7"
                                    sizeLg="7"
                                  >
                                    <IonRow className="ion-justify-content-between ps-1">
                                      <IonLabel style={{ margin: 0 }}>
                                        <p
                                          className="color-primary  font-weight-500 m-0"
                                          style={{
                                            fontSize: 12,
                                            lineHeight: "12px",
                                          }}
                                        >
                                          <b>HSC-{u?.id}</b>
                                        </p>
                                        <div className="my-1 userCardNameandAge">
                                          <b className="ellispses w-50">
                                            {u.nameEnglish}{" "}
                                          </b>
                                          <b>
                                            &nbsp;(
                                            {u?.dob
                                              ? moment().diff(u?.dob, "years")
                                              : u?._age}{" "}
                                            Yrs)
                                          </b>
                                        </div>
                                        <p className="text-muted subInfoUserCard">
                                          <b>
                                            {u.third_formSectEnglish}{" "}
                                            {u.third_formSectEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}{" "}
                                            {u.first_formMaritalStatusEnglish}{" "}
                                            {u.first_formMaritalStatusEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}
                                            <br />{" "}
                                            {u.fourth_formEmployedEnglish
                                              ? u.fourth_formEmployedEnglish
                                              : "-"}{" "}
                                          </b>
                                        </p>
                                      </IonLabel>
                                      {/* <span className='position-absolute' style={{ top: 1, right: 1 }} onClick={handleIconClick}>
                                                                        <IonIcon icon={ellipsisVertical} color='dark' style={{ verticalAlign: 'sub' }} />
                                                                    </span> */}
                                    </IonRow>
                                    <IonRow className="p-0 m-0">
                                      <div
                                        className="p-0 m-0"
                                        style={{
                                          flexDirection: "row",
                                          flexWrap: "nowrap",
                                          display: "flex",
                                        }}
                                      >
                                        <div style={{ flex: "100%" }}>
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 manglikUserCard">
                                              <IonIcon
                                                icon={starSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Manglikta
                                            </p>
                                            <p className="manglikValueUserCard">
                                              <b>
                                                {u.third_formDoshEnglish || "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "20px",
                                            padding: "0px 6px",
                                            flex: "5%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 1,
                                              height: 29,
                                              background: "#797f85",
                                            }}
                                          ></div>
                                        </div>
                                        <div
                                          style={{
                                            flex: "100%",
                                            maxWidth: "80px",
                                          }}
                                        >
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 addressUserCard">
                                              <IonIcon
                                                icon={locationSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Address
                                            </p>
                                            <p className="addressValueUserCard">
                                              <b>
                                                {u.second_formStateEnglish ||
                                                  "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                      </div>
                                    </IonRow>
                                    <IonRow className="mt-1">
                                      <IonCol
                                        size="6"
                                        className="ion-no-padding ion-no-margin sendInterestBtn"
                                      >
                                        {selectState.type === "send" ? (
                                          <IonButton
                                            style={{
                                              fontSize: "11px",
                                              width: "100%",
                                            }}
                                            color={"primary"}
                                            size="small"
                                            onClick={(event: any) => {
                                              event.stopPropagation();
                                              setUserData({ user: u, i: i });
                                              if (
                                                !AppContext?.user
                                                  ?.second_formMobile
                                              )
                                                AppContext.setNoDelayPopup(
                                                  constant.popups.isGoLive
                                                );
                                              else if (
                                                CurrentPackageBasic === null &&
                                                CurrentPackageVip === null &&
                                                CurrentPackageSpecial !== null
                                              )
                                                AppContext.setNoDelayPopup(
                                                  constant.popups.isSubscribe
                                                );
                                              else if (
                                                !AppContext?.user
                                                  ?.payment_verified_at
                                              )
                                                AppContext.setNoDelayPopup(
                                                  constant.popups.isSubscribe
                                                );
                                              else setShowAlert(true);
                                            }}
                                          >
                                            <IonIcon
                                              className="white-New"
                                              icon={send}
                                            />{" "}
                                            Cancel
                                          </IonButton>
                                        ) : (
                                          <IonButton
                                            style={{
                                              fontSize: "11px",
                                              width: "100%",
                                            }}
                                            color={"primary"}
                                            size="small"
                                            onClick={(event: any) => {
                                              event.stopPropagation();
                                              acceptInterest(u, i);
                                            }}
                                          >
                                            <IonIcon
                                              className="white-New"
                                              icon={send}
                                            />{" "}
                                            Accept
                                          </IonButton>
                                        )}
                                      </IonCol>
                                      <IonCol
                                        size="6"
                                        className="favouriteBtn ion-no-padding ion-no-margin"
                                      >
                                        {selectState.type === "send" ? (
                                          <IonButton
                                            style={{
                                              fontSize: "11px",
                                              width: "100%",
                                            }}
                                            color={
                                              u?.favorite
                                                ? "secondary"
                                                : "warning"
                                            }
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              addToFavorite(u, i);
                                            }}
                                          >
                                            <IonIcon
                                              className="favouriteBtnIcon"
                                              icon={heart}
                                            />{" "}
                                            Favorite
                                          </IonButton>
                                        ) : (
                                          <IonButton
                                            style={{
                                              fontSize: "11px",
                                              widthL: "100%",
                                            }}
                                            color={"primary"}
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              rejectInterest(u, i);
                                            }}
                                          >
                                            <IonIcon
                                              className="favouriteBtnIcon"
                                              icon={close}
                                            />{" "}
                                            Cancel
                                          </IonButton>
                                        )}
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                              {u?.blocked && !u?.shadiDone && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person has blocked you!
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && !u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list !
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list && blocked you !
                                  </p>
                                </>
                              )}
                            </IonCard>
                          </>
                        );
                      })
                    )}
                  </div>
                )}

                {showReject && (
                  <div>
                    {userList?.length === 0 ? (
                      <IonCard className="card ">
                        {" "}
                        <p className="fs-16 ion-text-center ion-margin-top">
                          No Interest found here ,Please send Interest to see
                          users here!
                        </p>
                      </IonCard>
                    ) : (
                      userList?.map((u: any, i: any) => {
                        var user = JSON.parse(JSON.stringify(u));
                        let p = u?.hindu_profiles?.find((i: any) => {
                          return (
                            i?.type === "profile" && i?.order_number === 10
                          );
                        });
                        if (!p)
                          p = user?.hindu_profiles?.find((i: any) => {
                            return i?.type === "profile";
                          });
                        return (
                          <>
                            <IonCard
                              key={i}
                              onClick={() => {
                                if (!u?.blocked && !u?.shadiDone) {
                                  setOpenDetail(true);
                                  setUserID(u?.id);
                                }
                              }}
                              className="box-new-profile ion-justify-content-center ion-align-items-center"
                            >
                              {(u?.blocked || u?.shadiDone) && (
                                <div style={styles.blurOverlay}></div>
                              )}
                              <IonGrid
                                className="ion-margin-0"
                                style={{
                                  maxHeight: "11em",
                                  margin: 0,
                                  opacity: `${
                                    u?.blocked || u?.shadiDone ? "0.5" : "1"
                                  }`,
                                }}
                              >
                                <IonRow>
                                  <IonCol
                                    className="image-newCard"
                                    style={{
                                      backgroundImage:
                                        !p?.name &&
                                        Utils.getDefaultUserUrl(
                                          AppContext?.user
                                            ?.first_formGenderEnglish
                                        ),
                                      backgroundSize: p?.name
                                        ? "100% 100%"
                                        : "90px",
                                    }}
                                    sizeXs="5"
                                    sizeSm="5"
                                    sizeLg="5"
                                  >
                                    {/* {p?.name ? ( */}
                                    <IonImg
                                      src={
                                        p?.name
                                          ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                                          : "https://ionicframework.com/docs/img/demos/avatar.svg"
                                      }
                                      style={{
                                        height: "10.3em",
                                        objectFit: "cover",
                                      }}
                                    ></IonImg>
                                    {/* ) : null} */}
                                    {u?.verified_at ? (
                                      <div className="corner-ribbon-new">
                                        <span className="cr-inner-new">
                                          <span className="cr-text-new">
                                            {" "}
                                            <IonIcon
                                              style={{ fontSize: "2em" }}
                                              icon={shieldCheckmarkSharp}
                                            />
                                            <br />
                                            Verified
                                          </span>
                                        </span>
                                      </div>
                                    ) : null}
                                  </IonCol>
                                  <IonCol
                                    className="ps-2 pb-0 mb-0"
                                    sizeSm="7"
                                    sizeXs="7"
                                    sizeLg="7"
                                  >
                                    <IonRow className="ion-justify-content-between ps-1">
                                      <IonLabel style={{ margin: 0 }}>
                                        <p
                                          className="color-primary  font-weight-500 m-0"
                                          style={{
                                            fontSize: 12,
                                            lineHeight: "12px",
                                          }}
                                        >
                                          <b>HSC-{u?.id}</b>
                                        </p>
                                        <div className="my-1 userCardNameandAge">
                                          <b className="ellispses w-50">
                                            {u.nameEnglish}{" "}
                                          </b>
                                          <b>
                                            &nbsp;(
                                            {u?.dob
                                              ? moment().diff(u?.dob, "years")
                                              : u?._age}{" "}
                                            Yrs)
                                          </b>
                                        </div>
                                        <p className="text-muted subInfoUserCard">
                                          <b>
                                            {u.third_formSectEnglish}{" "}
                                            {u.third_formSectEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}{" "}
                                            {u.first_formMaritalStatusEnglish}{" "}
                                            {u.first_formMaritalStatusEnglish ? (
                                              <span>|</span>
                                            ) : (
                                              ""
                                            )}
                                            <br />{" "}
                                            {u.fourth_formEmployedEnglish
                                              ? u.fourth_formEmployedEnglish
                                              : "-"}{" "}
                                          </b>
                                        </p>
                                      </IonLabel>
                                      {/* <span className='position-absolute' style={{ top: 1, right: 1 }} onClick={handleIconClick}>
                                                                        <IonIcon icon={ellipsisVertical} color='dark' style={{ verticalAlign: 'sub' }} />
                                                                    </span> */}
                                    </IonRow>
                                    <IonRow className="p-0 m-0">
                                      <div className="p-0 m-0 manglikAndAddressUserCard">
                                        <div style={{ flex: "100%" }}>
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 manglikUserCard">
                                              <IonIcon
                                                icon={starSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Manglikta
                                            </p>
                                            <p className="manglikValueUserCard">
                                              <b>
                                                {u.third_formDoshEnglish || "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "20px",
                                            padding: "0px 6px",
                                            flex: "5%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 1,
                                              height: 29,
                                              background: "#797f85",
                                            }}
                                          ></div>
                                        </div>
                                        <div
                                          style={{
                                            flex: "100%",
                                            maxWidth: "80px",
                                          }}
                                        >
                                          <IonLabel style={{ margin: 0 }}>
                                            <p className="m-0 addressUserCard">
                                              <IonIcon
                                                icon={locationSharp}
                                                color="primary"
                                                style={{ verticalAlign: "sub" }}
                                              />{" "}
                                              Address
                                            </p>
                                            <p className="addressValueUserCard">
                                              <b>
                                                {u.second_formStateEnglish ||
                                                  "-"}
                                              </b>
                                            </p>
                                          </IonLabel>
                                        </div>
                                      </div>
                                    </IonRow>
                                    <IonRow className="mt-1">
                                      <IonCol
                                        size="6"
                                        className="ion-no-padding ion-no-margin sendInterestBtn"
                                      >
                                        <IonButton
                                          style={{ fontSize: "11px" }}
                                          color={
                                            !u?.interest
                                              ? "primary"
                                              : "secondary"
                                          }
                                          size="small"
                                          onClick={(event: any) => {
                                            event.stopPropagation();
                                            if (
                                              !AppContext?.user
                                                ?.second_formMobile
                                            )
                                              AppContext.setNoDelayPopup(
                                                constant.popups.isGoLive
                                              );
                                            else if (
                                              CurrentPackageBasic === null &&
                                              CurrentPackageVip === null &&
                                              CurrentPackageSpecial !== null
                                            )
                                              AppContext.setNoDelayPopup(
                                                constant.popups.isSubscribe
                                              );
                                            else if (
                                              !AppContext?.user
                                                ?.payment_verified_at
                                            )
                                              AppContext.setNoDelayPopup(
                                                constant.popups.isSubscribe
                                              );
                                            else if (!u?.interest)
                                              sendInterest(u, i);
                                          }}
                                        >
                                          <IonIcon
                                            className="white-New"
                                            icon={send}
                                          />{" "}
                                          Send Interest
                                        </IonButton>
                                      </IonCol>
                                      <IonCol
                                        size="6"
                                        className="favouriteBtn ion-no-padding ion-no-margin"
                                      >
                                        <IonButton
                                          style={{ fontSize: "11px" }}
                                          color={
                                            u?.favorite
                                              ? "secondary"
                                              : "warning"
                                          }
                                          size="small"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            addToFavorite(u, i);
                                          }}
                                        >
                                          <IonIcon
                                            className="favouriteBtnIcon"
                                            icon={heart}
                                          />{" "}
                                          Favorite
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                              {u?.blocked && !u?.shadiDone && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person has blocked you!
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && !u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list !
                                  </p>
                                </>
                              )}
                              {u?.shadiDone && u?.blocked && (
                                <>
                                  <p style={styles.nowAvailableText}>
                                    Not Available
                                  </p>
                                  <p style={styles.nowAvailableSubText}>
                                    Sorry this person moved you in shadi done
                                    list & blocked you !
                                  </p>
                                </>
                              )}
                            </IonCard>
                          </>
                        );
                      })
                    )}
                  </div>
                )}
              </>
            )}
            <IonPopover
              isOpen={showPopover}
              event={event}
              onDidDismiss={handlePopoverClose}
            >
              <div className="popover-content">
                <IonList className="m-0 p-0 optionParent">
                  <IonItem>
                    <img src={report} />
                    <span
                      style={{
                        fontWeight: "600",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      Report
                    </span>
                  </IonItem>
                  <IonItem>
                    <img src={weddingrings} />
                    <span
                      style={{
                        fontWeight: "600",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      Shadi Done
                    </span>
                  </IonItem>
                  <IonItem>
                    <img src={blockuser} />
                    <span
                      style={{
                        fontWeight: "600",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      Block User
                    </span>
                  </IonItem>
                </IonList>
              </div>
            </IonPopover>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass="actionAlert123"
              header="Are you sure you want to remove interest?"
              buttons={[
                {
                  text: "No",
                  role: "cancel",
                  cssClass: "alertButton",
                },
                {
                  text: "Yes",
                  handler: handleRemoveInterest,
                  cssClass: "alertButton",
                },
              ]}
            />
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                handleChange(selectState, pageNumber);
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
};
export default Interest;
