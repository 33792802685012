import {
    IonLabel, IonCol, IonGrid, IonRow,
    IonIcon, IonButton, IonCard, IonCardHeader, IonItem, IonImg, useIonAlert, IonThumbnail, IonPopover, IonContent, IonAlert, useIonToast, IonList, IonModal
} from '@ionic/react';
import { bagHandleSharp, cameraSharp, heart, locationSharp, nuclearSharp, send, shieldCheckmarkSharp, starHalfSharp, starSharp } from 'ionicons/icons';
import moment from 'moment';
import { useContext, useState } from 'react';
import appContext from "../../context/AppContext"
import constant from '../../constant';
import Utils from '../utils'
import PopUp from './PopUp';
import publish from '../../assets/images/publish.png';
import { deleteInterestRequest, unblockUser, unlockBlockedUser } from '../../service';



const BlockedUserCard = ({ addToFavorite, sendInterest, user, getFavourite, i, history, sendPhotoRequest, isOpenDetailModal, onAction, tableId }: any) => {
    const AppContext = useContext(appContext);
    const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
    const [showAlert, setShowAlert] = useState<any>(false);
    const [presentAlert] = useIonAlert();
    const [showPopover, setShowPopover] = useState(false);
    const [event, setEvent] = useState<any>();
    const [present] = useIonToast();

    let p = user?.profiles?.find((i: any) => { return i?.type === "profile" && i?.order_number === 10 });
    if (!p) p = user?.profiles?.find((i: any) => { return i?.type === "profile" });

    const handleRemoveInterest = async () => {
        const res = await deleteInterestRequest(user)
        if (res?.data?.status) {
            user.interest = false;
            present({
                message: "Sent Interest Cancelled",
                duration: 1500,
                position: 'top',
                color: "success",
                cssClass: "alertToast"
            });
            setShowAlert(false);
        }
    };

    const goTo = async () => {
        setOpenPopUp(false)
        AppContext.setPopups(constant.popups.isGoLive, true)
    }

    const handleUnblockUserClick = async () => {
        let obj: any = {
            "id": String(tableId)
        }
        const res = await unlockBlockedUser(obj);
        if (res?.data?.status) {
            present({
                message: "User Unblocked",
                duration: 1500,
                position: 'top',
                color: "success",
                cssClass: "alertToast"
            });
            getFavourite();
        }
    }

    return (
        <div id="NewUserCard">
            <IonCard key={i}
                onClick={() => {
                    isOpenDetailModal(true);
                    onAction(user?.id)
                }}
                className='box-new-profile ion-justify-content-center ion-align-items-center'>
                <IonGrid className='ion-margin-0' style={{ maxHeight: "11em", margin: 0 }}>
                    <IonRow >
                        <IonCol className='image-newCard' style={{
                            backgroundImage: !p?.name
                                && Utils.getDefaultUserUrl(AppContext?.user?.first_formGenderEnglish)
                            , backgroundSize: p?.name ? "100% 100%" : "90px",
                        }} sizeXs='5' sizeSm='5' sizeLg='5'>
                            <IonImg
                                src={p?.name ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}` : "https://ionicframework.com/docs/img/demos/avatar.svg"}
                                style={{
                                    height: '10.3em',
                                    objectFit: 'cover',
                                }}
                            ></IonImg>
                            {user?.verified_at ? <div className="corner-ribbon-new">
                                <span className="cr-inner-new">
                                    <span className="cr-text-new"> <IonIcon style={{ fontSize: "2em" }} icon={shieldCheckmarkSharp} /><br />Verified</span>
                                </span>
                            </div> : null
                            }
                        </IonCol>
                        <IonCol className='ps-2 pb-0 mb-0' sizeSm='7' sizeXs='7' sizeLg='7'>
                            <IonRow className="ps-1">
                                <IonLabel style={{ margin: 0, width: "100%" }}>
                                    <p className='color-primary font-weight-500 m-0' style={{ fontSize: 12, lineHeight: "12px" }}>
                                        <b>JSM-{user?.id}</b>
                                    </p>
                                    <div className="my-1" style={{ display: "flex", fontSize: "14px", lineHeight: "20px", color: "#000" }}>
                                        <b className="ellispses" style={{ width: "55%" }}>{user?.name?.english} </b><b>&nbsp;({user?.dob ? moment().diff(user?.dob, 'years') : user?._age} Yrs)</b>
                                    </div>
                                    <p className='text-muted' style={{ letterSpacing: "0.1px", fontSize: "12px", lineHeight: '15px' }}><b>{user?.third_form?.sect?.english} {user?.third_form?.sect?.english ? <span>|</span> : ""} {user?.first_form?.maritalStatus?.english} {user?.first_form?.maritalStatus?.english ? <span>|</span> : ""}<br /> {user?.fourth_form?.employed?.english ? user?.fourth_form?.employed?.english : "-"} </b></p>
                                </IonLabel>
                            </IonRow>
                            <IonRow className='p-0 m-0'>
                                <div className='p-0 m-0' style={{ flexDirection: "row", flexWrap: "nowrap", display: "flex" }}>
                                    <div style={{ flex: "100%" }}>
                                        <IonLabel style={{ margin: 0 }}>
                                            <p className='m-0' style={{ letterSpacing: "0.1px", fontSize: "12px", fontWeight: 600, color: "#eb1d25" }}>
                                                <IonIcon icon={starSharp} color='primary' style={{ verticalAlign: "sub" }} />    Manglikta
                                            </p>
                                            <p style={{
                                                letterSpacing: "0 !important",
                                                fontSize: "12px",
                                                color: "#000",
                                                lineHeight: "12px",
                                                textTransform: 'capitalize',
                                                paddingLeft: 16,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }} ><b>{user?.third_form?.dosh?.english || '-'}</b></p>
                                        </IonLabel>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", padding: "0px 6px", flex: "5%" }}>
                                        <div style={{ width: 1, height: 29, background: "#797f85" }}></div>
                                    </div>
                                    <div style={{ flex: "100%", maxWidth: "80px" }}>
                                        <IonLabel style={{ margin: 0 }} >
                                            <p className='m-0' style={{ letterSpacing: "0.1px", fontSize: "12px", fontWeight: 600, color: "#eb1d25" }}>
                                                <IonIcon icon={locationSharp} color='primary' style={{ verticalAlign: "sub" }} />  Address
                                            </p>
                                            <p style={{
                                                letterSpacing: "0.1px",
                                                fontSize: "12px",
                                                color: "#000",
                                                lineHeight: "12px",
                                                paddingLeft: 16,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                minWidth: "80px",
                                            }}><b>{user?.second_form?.state?.english || '-'}</b></p>
                                        </IonLabel>
                                    </div>
                                </div>
                            </IonRow>
                            <IonRow className="mt-1">
                                <IonCol size='12' className="ion-no-padding ion-no-margin sendInterestBtn">
                                    <IonButton
                                        style={{ fontSize: "small", width: "98%", height: "2rem" }}
                                        color={"primary"}
                                        size='small'
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            setShowAlert(true);
                                        }}
                                    >
                                        Unblock User
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid >
            </IonCard >
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='actionAlert123'
                header='Are you sure you want to Unblock User?'
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'alertButton',
                    },
                    {
                        text: 'Yes',
                        handler: handleUnblockUserClick,
                        cssClass: 'alertButton',
                    },
                ]}
            />

            <PopUp isOpenPopUp={isOpenPopUp} setOpenPopUp={setOpenPopUp} img={publish} goTo={goTo} componentName={'golive'} cancel={true} />

        </div>

    )
}
export default BlockedUserCard;
