import { useState, useContext } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonCol, IonGrid, IonLabel, IonList, IonIcon, useIonToast, IonTextarea, useIonAlert } from '@ionic/react';
import { getGoliveFormDetails, signUp } from '../../service';
import appContext from "../../context/AppContext"
import { closeOutline } from 'ionicons/icons';
import { useForm } from "react-hook-form";
import EducationModal from './EducationModal'
import CustomSelect from '../shared/CustomSelect';
import CustomMultiselect from '../shared/CustomMultiselect';
import constant from '../../constant';
import { toast } from 'react-toastify';

function SecondForm() {

    let initialValues = {
        fourth_formHobbiesInterest: []
    };

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors, touchedFields }
    }: any = useForm({ defaultValues: initialValues });
    const [formDetail, setFormDetail] = useState<any>(null);
    const [education, setEducation] = useState<any>();
    const [presentAlert] = useIonAlert();
    const AppContext = useContext(appContext);
    const [isOpenEducation, setIsOpenEducation] = useState(false);
    const [employedInOption, setEmployedInOption] = useState<any>([]);

    const afterMarriageArray = [{ label: "Will Continue the same", value: "Will Continue the same" }, { label: "Want to do business/Job", value: "Want to do business/Job" }, { label: "Want to do study?", value: "Want to do study?" }, { label: "Doesn`t matter", value: "Doesn`t matter" }];


    const getFormDetais = async () => {
        var res: any = await getGoliveFormDetails();
        if (res?.data?.status) {
            setFormDetail(res?.data?.data);
            var arr: any = [];
            res?.data?.data?.employedIn?.english.map((i: any) => {
                if (i?.value !== 'Passed away' || i?.value !== 'Retired') {
                    arr.push(i)
                    setEmployedInOption(arr)
                }
                return 0;
            })
        }
    };

    const onSubmit = async (values: any) => {
        AppContext.setLoading(true);
        Object.keys(values)?.forEach((i: any) => { if (values?.[i]?.[0]?.value) { values[i] = values[i]?.map((i: any) => { return i?.value }); } })
        if (values.fourth_formAfterMarriageEnglish.value) values.fourth_formAfterMarriageEnglish = values.fourth_formAfterMarriageEnglish.value;
        values.fourth_formEducationEnglish = education?.fourth_formEducationEnglish || AppContext?.user?.fourth_formEducationEnglish
        values.fourth_formSubEductionEnglish = education?.fourth_formSubEductionEnglish || AppContext?.user?.fourth_formSubEductionEnglish
        delete values.education;
        values.fourth_formHobbiesInterest.map((val: any, i: any) => {
            values.fourth_formHobbiesInterest[i] = { hindi: "", english: val }
            return 0;
        })
        values.fourth_formEmployedEnglish = values.employedIn.value
        var response = await signUp(values);
        if (response?.data?.status) {
            toast("Professional Detail Saved Successfully");
            AppContext.setLoading(false);
            AppContext.updateUser();
            AppContext.setPopups(constant.popups.isSecondForm, false)
        }
        else {
            toast(response?.data?.errors);
        }
    };
    const onReject = (modalState: Boolean) => {
        reset();
        AppContext.setPopups(constant.popups.isSecondForm, modalState)
        AppContext.setDoItLater();

    }

    const onDidDismiss = async () => {
        AppContext.setPopups(constant.popups.isSecondForm, false)
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    const onWillDismiss = async () => {
        AppContext.setPopups(constant.popups.isSecondForm, false)
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    const onDidPresent = () => {
        AppContext?.setPopupClose(false)
        if (AppContext?.user?.fourth_formEducationEnglish) {
            setValue("education", AppContext?.user?.fourth_formEducationEnglish + " - " + AppContext?.user?.fourth_formSubEductionEnglish);
            delete errors.education;
        }
        if (AppContext?.user?.fourth_formDescribeEducationEnglish) setValue("fourth_formDescribeEducationEnglish", AppContext?.user?.fourth_formDescribeEducationEnglish)
        if (AppContext?.user?.fourth_formHobbiesInterestWriteEnglish) setValue("fourth_formHobbiesInterestWriteEnglish", AppContext?.user?.fourth_formHobbiesInterestWriteEnglish)
        if (AppContext?.user?.fourth_formEmployedEnglish) setValue("employedIn", { label: AppContext?.user?.fourth_formEmployedEnglish || "", value: AppContext?.user?.fourth_formEmployedEnglish || "" })
        if (AppContext?.user?.fourth_formAfterMarriageEnglish) setValue("fourth_formAfterMarriageEnglish", AppContext?.user?.fourth_formAfterMarriageEnglish)
        if (AppContext?.user?.education) setValue("education", `${AppContext?.user?.fourth_formEducationEnglish} - ${AppContext?.user?.fourth_formSubEductionEnglish}`)
        if (AppContext?.user?.fourth_formHobbiesInterest?.length > 0) setValue("fourth_formHobbiesInterest", AppContext.user.fourth_formHobbiesInterest?.map((val: any, i: any) => { return { label: val?.english || "", value: val?.english || "" } }))
        setEducation(AppContext?.user)
        getFormDetais();
        localStorage.setItem('isModal', 'true')

    }

    return (
        <IonContent>
            <IonModal isOpen={AppContext?.popups[constant.popups.isSecondForm]} className='GoliveBody'
                onDidDismiss={onDidDismiss}
                onWillDismiss={onWillDismiss}
                onDidPresent={onDidPresent}
                onWillPresent={() => {
                    localStorage.setItem('isModal', 'true')
                }}
            >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle className='heading text-white'>Candidate Professional Details</IonTitle>
                        <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={(event: any) => {
                                // event.stopPropagation(); setIsOpen(false)
                                Object.keys(touchedFields).length
                                    ? presentAlert({
                                        header: 'Would you like to save changes?',
                                        cssClass: 'actionAlert',
                                        buttons: [
                                            {
                                                text: 'Yes', role: 'confirm',
                                                handler: handleSubmit(onSubmit),
                                            },
                                            {
                                                text: 'no', role: 'cancel', handler: () => {
                                                    onReject(false);
                                                }
                                            },
                                        ],
                                    })
                                    : onReject(false)

                            }}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <IonList className='golive'>
                    <form className='my-2' onSubmit={handleSubmit(onSubmit)}>

                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Education<span className='errorText m-0' >*</span></IonLabel>
                            <IonTextarea autoGrow={true} rows={1} readonly
                                value={education?.fourth_formSubEductionEnglish ? education?.fourth_formEducationEnglish + " - " + education?.fourth_formSubEductionEnglish : ""}
                                placeholder="Select Education"  {...register("education", { required: true })}
                                className='border-bottom' onClick={() => { setIsOpenEducation(true) }} >
                            </IonTextarea>
                            {formDetail && <EducationModal isOpenEducation={isOpenEducation} setIsOpenEducation={setIsOpenEducation} formDetail={formDetail} setEducation={setEducation} education={education} setValue={setValue} errors={errors} />}
                            {errors?.education?.type === "required" && <p className='errorText ms-1'>Select education.</p>}
                        </IonGrid>
                        {/* {education?.fourth_formSubEductionEnglish &&
                            <IonGrid >
                                <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Education</IonLabel>
                                <IonInput readonly
                                    value={education?.fourth_formEducationEnglish}
                                    placeholder="Select Education"
                                    className='border-bottom' >
                                </IonInput>
                            </IonGrid>
                        } */}
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Describe Education<span className='errorText m-0' >*</span></IonLabel>
                            <IonTextarea autoGrow={true} rows={1} className='border-bottom' placeholder='Add Here...'   {...register("fourth_formDescribeEducationEnglish", { required: true })}></IonTextarea>
                            {errors.fourth_formDescribeEducationEnglish && errors.fourth_formDescribeEducationEnglish.type === "required" && <p className='errorText ms-1'>Field  is required</p>}
                        </IonGrid>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Employed In<span className='errorText m-0' >*</span></IonLabel>
                            <CustomSelect
                                options={employedInOption}
                                control={control}
                                required={true}
                                name={"employedIn"}
                                placeholder={"Select Employed In"}
                                isSearchable={true}

                            />
                            {errors?.employedIn?.type === "required" && <p className='errorText ms-1'>Select Candidate Employed In.</p>}
                        </IonGrid>
                        {/* <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Employed In<span className='errorText m-0' >*</span></IonLabel>
                            <CustomSelect
                                options={formDetail?.employedIn?.english}
                                control={control}
                                required={true}
                                name={"fourth_formEmployedEnglish"}
                                placeholder={"Select Employed In"}
                                isSearchable={true}

                            />
                            {errors?.fourth_formEmployedEnglish?.type === "required" && <p className='errorText ms-1'>Select Candidate Employed In.</p>}
                        </IonGrid> */}
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>After Marriage<span className='errorText m-0' >*</span></IonLabel>
                            {afterMarriageArray && <CustomSelect
                                options={afterMarriageArray}
                                control={control}
                                required={true}
                                name={"fourth_formAfterMarriageEnglish"}
                                placeholder={"Select After Marriage Status"}
                                isSearchable={false}

                            />}
                            {errors?.fourth_formAfterMarriageEnglish?.type === "required" && <p className='errorText ms-1'>Select atleast one option</p>}
                        </IonGrid>

                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Hobbies/Interest<span className='errorText m-0' >*</span></IonLabel>

                            {formDetail?.hobbies?.english && <CustomMultiselect
                                setValue={setValue}
                                options={formDetail?.hobbies?.english}
                                control={control}
                                required={true}
                                name={"fourth_formHobbiesInterest"}
                                placeholder={"Select Hobbies/Interest "}
                                isSearchable={true}
                            />}

                            {/* <IonSelect className='border-bottom' multiple={true} interface="popover" placeholder="Select  "  {...register("fourth_formHobbiesInterest", { required: true })}>
                                {formDetail?.hobbies?.english?.map((val: any, i: any) => { return <IonSelectOption value={val?.value}>{val?.value}</IonSelectOption> })}
                            </IonSelect> */}
                            {errors?.fourth_formHobbiesInterest?.type === "required" && <p className='errorText ms-1'>Select  Hobbies/Interest.</p>}
                        </IonGrid>
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Write Hobbies/Interest<span className='errorText m-0' >*</span></IonLabel>
                            <IonTextarea autoGrow={true} rows={1} className='border-bottom' placeholder='Add Here...'   {...register("fourth_formHobbiesInterestWriteEnglish")}></IonTextarea>
                        </IonGrid>

                        <IonCol class='btnFill  ion-margin-bottom'>
                            <IonButton type="submit" expand="block" >Save</IonButton>
                        </IonCol>
                    </form>
                </IonList>
            </IonModal>
        </IonContent >
    );
}

export default SecondForm;