import { useHistory } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
  IonImg,
  IonCheckbox,
  IonIcon,
  IonCard,
} from "@ionic/react";
import {
  authorizeMe,
  getCurrentState,
  getHinduEducationsApi,
  getProfileById,
  getStateListAPI,
  signUp,
  verifyReferral,
} from "../../service";
import appContext from "../../context/AppContext";
import { useForm } from "react-hook-form";
import CustomSelect from "../shared/CustomSelect";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { isPlatform } from "@ionic/react";
import vector2 from "../../assets/images/Vector(2).png";
import vector1 from "../../assets/images/Vector(1).png";
import {
  addCircleSharp,
  checkmarkDoneOutline,
  closeCircleSharp,
} from "ionicons/icons";

function SignUpModal({ isSignUpModal, setSignUpModal }: any) {
  const history = useHistory();
  const AppContext = useContext(appContext);
  const usernameRef = useRef<any>(null);
  const [DeviceToken, setDeviceToken] = useState("");
  const [SelectGender, setSelectGender] = useState<any>(null);
  const [stateList, setStateList]: any = useState([]);
  const [SelectGenderError, setSelectGenderError] = useState<any>(false);
  const [noOfChild, setnoOfChild] = useState<any>(false);
  const [childLivingStatus, setChildLivingStatus] = useState<any>(false);
  const [privacy, setPrivacy] = useState<any>();
  const [referral, setReferral] = useState<any>(false);
  const [referralCode, setReferralCode] = useState<any>("");
  const [referralError, setReferralError] = useState<any>({
    error: "",
    show: false,
  });

  const [sectOption, setsectOption] = useState<any[]>([]);

  useEffect(() => {
    getHinduSect();
  }, []);

  const profileArray = [
    { label: "Self(स्वयं)", value: "Self" },
    { label: "Son(पुत्र)", value: "Son" },
    { label: "Daughter(पुत्री)", value: "Daughter" },
    { label: "Brother(भाई)", value: "Brother" },
    { label: "Sister(बहन)", value: "Sister" },
    { label: "Relative(परिवार)", value: "Relative" },
    { label: "Friend(मित्र)", value: "Friend" },
  ];
  const ageArray = [];
  for (let index = 18; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index + " yrs" });
  }

  const childrenArray = [];
  for (let index = 0; index < 6; index++) {
    childrenArray.push({ label: index.toString(), value: index.toString() });
  }

  let initialValues = {
    name: "",
    age: "",
    profile: "",
    sect: "",
    marritalStatus: "",
    gender: "",
    state: "",
    first_formNoOfChildren: "",
    childrenLivingStatus: "",
    referral_code: "",
  };
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({ defaultValues: initialValues });

  const watchStatus = watch("marritalStatus");
  const watchLivingStatus = watch("first_formNoOfChildren");
  useEffect(() => {
    var value: any = getValues("marritalStatus");
    var u: any = getValues("first_formNoOfChildren");
    if (value) {
      if (value?.value !== "Unmarried") {
        setnoOfChild(true);
      } else {
        setnoOfChild(false);
      }
    }
    if (u) {
      if (u?.value > 0) {
        setChildLivingStatus(true);
      } else {
        setChildLivingStatus(false);
      }
    }
  }, [watchStatus, watchLivingStatus, getValues]);

  const getStateList = async () => {
    authorizeMe(AppContext.user.token);
    let stateList: any = await getStateListAPI({ countryId: "India" });
    let test = stateList?.data?.data?.map((i: any) => {
      i.label = i?.english;
      i.value = i?.english;
      return i;
    });
    setStateList(test);
    let currentstate: any = await getCurrentState();
    setValue("state", currentstate?.data?.regionName);
  };

  const onSubmit = async (data: any) => {
    usernameRef.current?.focus();
    if (SelectGender === null) {
      usernameRef.current?.focus();
      setSelectGenderError(true);
      return;
    } else if (!privacy) {
      usernameRef.current?.focus();
      setPrivacy(false);
      return;
    } else {
      AppContext.setLoading(true);
      authorizeMe(AppContext.user.token);
      AppContext.setUser({ token: AppContext.user.token });
      var platform = isPlatform("desktop");
      var obj = {
        nameEnglish: data.name,
        first_formGenderEnglish: SelectGender,
        third_formSectEnglish: data.sect.value,
        first_formMaritalStatusEnglish: data.marritalStatus.value,
        _age: data?.age?.value?.split(" ", 1)[0],
        first_formProfileEnglish: data.profile.value,
        app_id: platform ? null : DeviceToken,
        second_formStateEnglish: data.state.english || data.state,
        first_formNoOfChildren: parseInt(data?.first_formNoOfChildren?.value),
        first_formChildrenLivingStatusEnglish:
          data?.childrenLivingStatus?.value,
        user_referral_id: referralCode,
      };
      var response = await signUp(obj);
      if (response?.data?.status) {
        authorizeMe(AppContext.user.token);
        AppContext.setUser({
          ...response.data.data.user,
          token: AppContext.user.token,
        });
        AppContext.setLoading(false);
        AppContext?.setIsLoggedIn(true);
        reset();
        history.replace("/");
        window.location.assign("/");
      }
    }
  };

  const onDidDismiss = async () => {
    setSignUpModal(true);
    AppContext?.setPopupClose(true);
  };

  const onWillDismiss = async () => {
    setSignUpModal(true);
    AppContext?.setPopupClose(true);
  };

  const getHinduSect = async () => {
    const res = await getHinduEducationsApi();
    let tempArr: any = [];
    res?.data?.data?.HinduSect?.map((i: any) => {
      tempArr.push({
        value: i?.name_english,
        label: i?.name_english,
        isDisabled: true,
        isGroupHeding: true,
      });
      i?.HinduSubSect?.map((u: any) => {
        tempArr.push({ value: u?.name_english, label: u?.name_english });
      });
    });
    setsectOption(tempArr);
  };

  const onDidPresent = () => {
    AppContext?.setPopupClose(false);
    getStateList();
    if (AppContext?.user?.nameEnglish)
      setValue("name", AppContext?.user?.nameEnglish);
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) =>
      setDeviceToken(token.value)
    );
  };

  return (
    <IonContent>
      <IonModal
        isOpen={isSignUpModal}
        className="GoliveBody"
        onDidDismiss={onDidDismiss}
        backdropDismiss={false}
        onWillDismiss={onWillDismiss}
        onDidPresent={onDidPresent}
      >
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonTitle className="heading text-white">
              Candidate Basic Details
            </IonTitle>
            {/* <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={() => setSignUpModal(false)}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div> */}
          </IonToolbar>
        </IonHeader>

        <IonList className="golive">
          <p className="errorText  ms-0 mt-2 mb-0">
            *Please Fill all the Basic Information to see new feature of our
            application.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList style={{ paddingBottom: "70px" }}>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Profile created for{" "}
                  <span className="fs-14">(प्रोफाइल किसके लिए)</span>
                  <span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={profileArray}
                  control={control}
                  required={true}
                  name={"profile"}
                  placeholder={"Select Profile Created For"}
                  isSearchable={false}
                />
                {errors.profile && errors.profile.type === "required" && (
                  <p className="errorText ms-0 mt-2">Profile is required</p>
                )}
              </IonGrid>

              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Candidate name{" "}
                  <span className="fs-14">(प्रत्याशी का नाम)</span>
                  <span className="errorText m-0">*</span>
                </IonLabel>
                <IonInput
                  placeholder={"Candidate Full Name"}
                  className="border-bottom"
                  {...register("name", { required: true })}
                ></IonInput>
              </IonGrid>
              {errors.name && errors.name.type === "required" && (
                <p className="errorText ms-1"> Name is required</p>
              )}
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Candidate Age{" "}
                  <span className="fs-14">(प्रत्याशी की उम्र)</span>
                  <span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={ageArray}
                  control={control}
                  required={true}
                  name={"age"}
                  placeholder={"Select Age"}
                  isSearchable={false}
                />
                {errors.age && errors.age.type === "required" && (
                  <p className="errorText ms-0 mt-2">Age is required</p>
                )}
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel
                      className="fs-16 labelItem pl-0"
                      position="stacked"
                    >
                      Gender<span className="errorText m-0">*</span>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                {/* <IonRadioGroup value="custom-checked"   > */}
                <IonRow>
                  <IonCol
                    size="6"
                    style={{ paddingRight: 20 }}
                    onClick={() => setSelectGender("Male")}
                  >
                    <div
                      className="genderImg"
                      style={{
                        border:
                          SelectGender === "Male" ? "1px solid #ee453d" : "",
                      }}
                    >
                      <img loading="lazy" src={vector1} />
                      <p className="dark fs-15 font-weight-500 ion-text-center m-0">
                        Male
                      </p>
                      <p className="dark fs-12 font-weight-500 ion-text-center m-0">
                        (युवक)
                      </p>
                    </div>
                    {/* <IonRadio slot="start" className='check-round m-0' value='Male'  {...register("gender")}></IonRadio >
                                            <IonLabel className='ml-10'>Male</IonLabel> */}
                  </IonCol>
                  <IonCol size="6" onClick={() => setSelectGender("Female")}>
                    <div
                      className="genderImg"
                      style={{
                        border:
                          SelectGender === "Female" ? "1px solid #ee453d" : "",
                      }}
                    >
                      <img loading="lazy" src={vector2} />
                      <p className="dark fs-15 font-weight-500 ion-text-center m-0">
                        Female
                      </p>
                      <p className="dark fs-12 font-weight-500 ion-text-center m-0">
                        (युवती)
                      </p>
                    </div>
                    {/* <IonRadio slot="start" className='check-round m-0' value='Female'  {...register("gender")}></IonRadio >
                                            <IonLabel className='ml-10'>Female</IonLabel> */}
                  </IonCol>
                  {SelectGenderError && (
                    <p className="errorText  ms-0 mt-2">Gender is required</p>
                  )}
                </IonRow>
                {/* </IonRadioGroup> */}
              </IonGrid>

              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Sect <span className="fs-12">(पंथ)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      options={sectOption}
                      control={control}
                      required={true}
                      name={"sect"}
                      placeholder={"Select Sect"}
                      isSearchable={false}
                    />
                    {errors.sect && errors.sect.type === "required" && (
                      <p className="errorText  ms-0 mt-2">Sector is required</p>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Marital Status{" "}
                      <span className="fs-12">(वैवाहिक स्थिति)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      options={[
                        { label: "Unmarried", value: "Unmarried" },
                        { label: "Widow/Widower", value: "Widow/Widower" },
                        { label: "Divorced", value: "Divorced" },
                        {
                          label: "Awaiting divorce",
                          value: "Awaiting divorce",
                        },
                      ]}
                      control={control}
                      required={true}
                      name={"marritalStatus"}
                      placeholder={"Select Marital status"}
                      isSearchable={false}
                    />
                    {errors.marritalStatus &&
                      errors.marritalStatus.type === "required" && (
                        <p className="errorText  ms-0 mt-2">
                          Marital Status is required
                        </p>
                      )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              {noOfChild && (
                <>
                  <IonGrid>
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      No. of childrens<span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      options={childrenArray}
                      control={control}
                      required={false}
                      name={"first_formNoOfChildren"}
                      placeholder={"Select No. of childrens"}
                      isSearchable={false}
                    />
                  </IonGrid>
                  {errors.first_formNoOfChildren &&
                    errors.first_formNoOfChildren.type === "required" && (
                      <p className="errorText ms-1">
                        {" "}
                        No. of childrens is required
                      </p>
                    )}
                </>
              )}
              {noOfChild && childLivingStatus && (
                <IonRow>
                  <IonCol size="12">
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      Living Status<span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      options={[
                        { label: "Live with me", value: "Live with me" },
                        {
                          label: "Not live with me",
                          value: "Not live with me",
                        },
                      ]}
                      control={control}
                      required={false}
                      name={"childrenLivingStatus"}
                      placeholder={"Select Liiving status"}
                      isSearchable={false}
                    />
                    {errors.childrenLivingStatus &&
                      errors.childrenLivingStatus.type === "required" && (
                        <p className="errorText  ms-0 mt-2">
                          Living Status is required
                        </p>
                      )}
                  </IonCol>
                </IonRow>
              )}
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel position="stacked" className="fs-16 labelItem">
                      State <span className="fs-12">(राज्य)</span>
                      <span className="errorText m-0">*</span>
                    </IonLabel>
                    <CustomSelect
                      options={stateList}
                      control={control}
                      required={true}
                      name={"state"}
                      placeholder={"Select State"}
                      isSearchable={true}
                    />
                    {errors?.state && (
                      <p className="errorText  ms-0 mt-2">State is required</p>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              {referral && (
                <IonGrid>
                  <div>
                    <IonCard
                      class="card my-2 mx-1 px-2"
                      style={{
                        background: " rgba(239, 66, 59, 0.2)",
                        boxShadow: "none",
                      }}
                    >
                      <div className="displayProp mx-1 mt-2">
                        <div style={{ width: "70%", marginBottom: "8px" }}>
                          <IonLabel
                            style={{
                              lineHeight: "20px",
                              fontSize: 11,
                              marginBottom: 0,
                              marginLeft: 3,
                              color: "#000",
                            }}
                          >
                            <b> Referral Code </b>
                          </IonLabel>
                          <IonInput
                            placeholder={"Enter your Referral Code here..."}
                            className="referral_input me-3 "
                            {...register("referral_code")}
                          ></IonInput>
                        </div>
                        <div
                          slot="end"
                          className="backBtn"
                          onClick={async (event) => {
                            event.stopPropagation();
                            const watchReferral = watch("referral_code");
                            var res = await verifyReferral({
                              referralId: watchReferral,
                            });
                            if (res?.data?.status) {
                              setReferralCode(res?.data?.data?.id);
                              setReferralError({
                                show: true,
                                error: `Referral Code added Successfully of  user  ${res?.data?.data?.nameEnglish}.`,
                              });
                            } else {
                              setReferralError({
                                show: true,
                                error: "Referral Code not Matched.",
                              });
                            }
                          }}
                        >
                          <IonButton style={{ marginBottom: "14px" }}>
                            Apply
                          </IonButton>
                        </div>
                      </div>
                    </IonCard>
                    <IonIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        setReferral(false);
                      }}
                      className="fs-20"
                      icon={closeCircleSharp}
                      style={{ position: "absolute", top: -4, right: 0 }}
                    />
                  </div>

                  {referralError.show && (
                    <p
                      className="fs-14 ms-1 mt-2"
                      style={
                        referralError.error.includes("Successfully")
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {referralError.error}
                    </p>
                  )}
                </IonGrid>
              )}
              <IonList style={{ display: "flex" }}>
                <IonCheckbox
                  slot="start"
                  style={{ marginRight: "12px", width: 23 }}
                  onIonChange={(e: any) => {
                    setPrivacy(e.target.checked);
                  }}
                ></IonCheckbox>
                <IonLabel
                  style={{ lineHeight: "20px", fontSize: 11, marginBottom: 0 }}
                >
                  By Signning up, you agree to our{" "}
                  <span
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      window.open(
                        "https://mobile.hindushadiconnect.com/terms",
                        "_blank"
                      );
                    }}
                    className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                  >
                    Terms & Condition{" "}
                  </span>
                  and read our{" "}
                  <span
                    onClick={(event) => {
                      event.stopPropagation();
                      event.stopPropagation();
                      window.open(
                        "https://mobile.hindushadiconnect.com/privacy-policy",
                        "_blank"
                      );
                    }}
                    className="ion-text-wrap text-right color-primary f-13 font-weight-500"
                  >
                    Policy
                  </span>
                </IonLabel>
              </IonList>
              {privacy === false && (
                <p className="errorText   mt-2">
                  Please Read Accept Our Privacy Policy and Terms & Conditions.
                </p>
              )}
              <IonGrid>
                <IonRow>
                  <IonCol class="btnFill ion-margin-top ion-margin-bottom">
                    <IonButton type="submit" expand="block">
                      Submit
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {!referral && (
                <div className="m-2 displayProp ion-margin-bottom">
                  <IonLabel
                    style={{
                      lineHeight: "20px",
                      fontSize: 12,
                      marginBottom: 0,
                      marginLeft: 3,
                    }}
                  >
                    <b>Do you have Referral Code ?</b>
                  </IonLabel>
                  <div
                    slot="end"
                    onClick={(event) => {
                      event.stopPropagation();
                      setReferral(true);
                    }}
                  >
                    <IonLabel color="primary" style={{ fontSize: 14 }}>
                      <b>
                        <IonIcon
                          icon={addCircleSharp}
                          style={{ verticalAlign: "sub" }}
                        />{" "}
                        Add Now
                      </b>
                    </IonLabel>
                  </div>
                </div>
              )}
            </IonList>
          </form>
        </IonList>
      </IonModal>
    </IonContent>
  );
}

export default SignUpModal;
