import {
    IonLabel, IonCol, IonGrid, IonRow,
    IonIcon, IonButton, IonCard, IonCardHeader, IonItem, IonImg, useIonAlert
} from '@ionic/react';
import { bagHandleSharp, cameraSharp, heart, locationSharp, nuclearSharp, send, shieldCheckmarkSharp, starHalfSharp, starSharp } from 'ionicons/icons';
import moment from 'moment';
import { useContext, useState } from 'react';
import appContext from "../../context/AppContext"
import constant from '../../constant';
import Utils from '../utils'
import PopUp from './PopUp';
import publish from '../../assets/images/publish.png'

const UserCard = ({ addToFavorite, sendInterest, user, i, history, sendPhotoRequest, isOpenDetailModal, onAction }: any) => {
    const AppContext = useContext(appContext);
    const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
    const [presentAlert] = useIonAlert();

    let p = user?.profiles?.find((i: any) => { return i?.type === "profile" && i?.order_number === 10 });
    if (!p) p = user?.profiles?.find((i: any) => { return i?.type === "profile" });

    const goTo = async () => {
        setOpenPopUp(false)
        AppContext.setPopups(constant.popups.isGoLive, true)
    }

    return (
        <>
            <IonCard className='box-profile b-radius-10 userCard' key={i} onClick={() => {
                isOpenDetailModal(true);
                onAction(user?.id)
            }} style={{
                backgroundImage: !p?.name
                    && Utils.getDefaultUserUrl(AppContext?.user?.first_formGenderEnglish)
                , backgroundSize: p?.name ? "100% 100%" : "90px",

            }}>
                {p?.name ? <img loading='lazy'
                    src={`${constant.ImageS3URl}/${p?.folder}/${p?.name}`}
                    style={{ maxHeight: 375, objectFit: "cover", width: "100%" }} />
                    : null
                }

                {/* <img loading="lazy"
                src={p?.name
                    ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                    : Utils.getDefaultUserImg(AppContext?.user?.first_formGenderEnglish)}
                style={{ maxHeight: 375, objectFit: "cover", width: p?.name ? "100%" : 'none !important' }}>
            </img> */}
                <IonLabel className='heart-icon' onClick={(event) => { event.stopPropagation(); addToFavorite(user, i) }} >
                    <IonIcon icon={heart} color={user?.favorite ? "primary" : "secondary"} />
                </IonLabel>
                {user?.verified_at ? <div className="corner-ribbon">
                    <span className="cr-inner">
                        <span className="cr-text"> <IonIcon className='fs-14' icon={shieldCheckmarkSharp} /><br />Verified</span>
                    </span>
                </div> : null
                }
                <div className="in-middle">
                    {
                        !p?.name && <IonButton color={"primary"} size='small' onClick={(event: any) => {
                            event.stopPropagation();
                            if (!AppContext?.user?.second_formMobile) AppContext.setNoDelayPopup(constant.popups.isGoLive)
                            else if (!AppContext?.user?.payment_verified_at) AppContext.setNoDelayPopup(constant.popups.isSubscribe)
                            // else if (!AppContext?.user?.live) {
                            //     presentAlert({
                            //         header: 'Please wait for admin to publish your profile.',
                            //         cssClass: 'actionAlert',
                            //         buttons: [{ text: 'OK', role: 'confirm' }],
                            //     })
                            // }
                            else sendPhotoRequest(user, i)
                        }}>
                            <IonIcon className='f-16 me-1' icon={cameraSharp} /> {user?.photoRequest?.length > 0 ? `Request Sent (${user?.photoRequest[0]?.count_no})` : "Request Photo"}
                        </IonButton>
                    }
                </div>

                <IonCardHeader className='details-box'>
                    <div className='detailUp'>
                        <IonItem style={{ marginRight: "-15px" }} >
                            <IonLabel style={{ margin: 0 }} >
                                <p className='color-primary  font-weight-500 m-0' style={{ fontSize: 11, lineHeight: "12px" }}>
                                    <b>JSM-{user?.id}</b></p>
                                <div style={{ display: "flex", fontSize: "15px", lineHeight: "20px" }}>
                                    <b className="ellispses">{user.nameEnglish} </b>&nbsp;|&nbsp;<b> {user?.dob ? moment().diff(user?.dob, 'years') : user?._age} Yrs</b>
                                </div>
                                <p className='text-muted' style={{ letterSpacing: "0.1px", fontSize: "12px", lineHeight: '15px' }}><b>{user.third_formSectEnglish} {user.third_formSectEnglish ? <span>|</span> : ""} {user.first_formMaritalStatusEnglish}</b></p>
                            </IonLabel>
                            <div slot='end' className='m-0'>

                                <IonButton style={{ marginTop: "-15px" }} color={!user?.interest ? "primary" : "secondary"} size='small' onClick={(event: any) => {
                                    event.stopPropagation();
                                    if (!AppContext?.user?.second_formMobile) AppContext.setNoDelayPopup(constant.popups.isGoLive)
                                    else if (!AppContext?.user?.payment_verified_at) AppContext.setNoDelayPopup(constant.popups.isSubscribe)
                                    // else if (!AppContext?.user?.live) {
                                    //     presentAlert({
                                    //         header: 'Please wait for admin to publish your profile.',
                                    //         cssClass: 'actionAlert',
                                    //         buttons: [{ text: 'OK', role: 'confirm' }],
                                    //     })
                                    // }
                                    else if (!user?.interest) sendInterest(user, i)
                                }}>
                                    <IonIcon className='f-12 white' icon={send} />  Send Interest
                                </IonButton>
                            </div>
                        </IonItem>
                    </div>
                    <IonGrid className='p-0 detailDown' >
                        {/* <div className='displayPropAround'>
                        <div className='ellispses'>
                            <IonLabel className='fs-12 text-muted' style={{ letterSpacing: "0.1px" }}>Profession</IonLabel><br />
                            <IonLabel className='fs-12 ' style={{ letterSpacing: "0.1px" }} color='dark'>{user.fourth_formEmployedEnglish}</IonLabel>
                        </div>
                        <div className='border-right mx-3'></div>
                        <div className='ellispses'>
                            <IonLabel className='fs-12 text-muted' style={{ letterSpacing: "0.1px" }}>Manglik</IonLabel><br />
                            <IonLabel className='fs-12 ' style={{ letterSpacing: "0.1px" }} color='dark'>{user.third_formDoshEnglish}</IonLabel>
                        </div>
                        <div className='border-right mx-3'></div>
                        <div className='ellispses'>
                            <IonLabel className='fs-12 text-muted' style={{ letterSpacing: "0.1px" }}>Address</IonLabel><br />
                            <IonLabel className='fs-12 ' style={{ letterSpacing: "0.1px" }} color='dark'>{user.second_formCityEnglish}</IonLabel>
                        </div>
    
                    </div> */}
                        <div className='p-0 m-0' style={{ flexDirection: "row", flexWrap: "nowrap", display: "flex" }}>
                            <div style={{ flex: "34%" }} className='pb-0 pt-1 ps-1 pe-0 '>
                                <IonLabel style={{ margin: 0 }}>
                                    <p className='m-0 text-muted' style={{ letterSpacing: "0.1px", fontSize: "12px", fontWeight: 600, }}>
                                        <IonIcon icon={bagHandleSharp} color='primary' />    Profession</p>
                                    <p style={{ letterSpacing: "0.1px", fontSize: "12px", color: "#000", lineHeight: "17px", paddingLeft: 14 }} ><b>{user.fourth_formEmployedEnglish || '-'}</b></p>
                                </IonLabel>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", padding: "0px 8px", flex: "5%" }}><div style={{ width: 1, height: 42, background: "#797f85", }}></div></div>
                            <div style={{ flex: "33%" }} className='pb-0 pt-1 p-0'>
                                <IonLabel style={{ margin: 0 }} >
                                    <p className='m-0 text-muted' style={{ letterSpacing: "0.1px", fontSize: "12px", fontWeight: 600, }}>
                                        <IonIcon icon={starSharp} color='primary' style={{ verticalAlign: "sub" }} />    Manglik</p>
                                    <p style={{ letterSpacing: "0.1px", fontSize: "12px", color: "#000", lineHeight: "17px", paddingLeft: 15 }} ><b>{user.third_formDoshEnglish || '-'}</b></p>
                                </IonLabel>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", padding: "0px 8px", flex: "5%" }}><div style={{ width: 1, height: 42, background: "#797f85", }}></div></div>
                            <div style={{ flex: "33%" }} className='pb-0 pt-1 ps-0 pe-1'>
                                <IonLabel style={{ margin: 0 }} >
                                    <p className='m-0 text-muted' style={{ letterSpacing: "0.1px", fontSize: "12px", fontWeight: 600, }}>
                                        <IonIcon icon={locationSharp} color='primary' style={{ verticalAlign: "sub" }} />  Address</p>
                                    <p style={{ letterSpacing: "0.1px", fontSize: "12px", color: "#000", lineHeight: "17px", paddingLeft: 14 }}><b>{user.second_formStateEnglish || '-'}</b></p>
                                </IonLabel>
                            </div>
                        </div>
                    </IonGrid>
                </IonCardHeader>
            </IonCard >
            <PopUp isOpenPopUp={isOpenPopUp} setOpenPopUp={setOpenPopUp} img={publish} goTo={goTo} componentName={'golive'} cancel={true} />

        </>

    )
}
export default UserCard;
