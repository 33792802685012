import { SET_USER_STATUS, SET_USER, SET_IS_LOGGED_In, SET_NO_DELAY_POPUP, SET_BACK_COUNT, SET_DO_IT_LATER, SET_POPUPS, SET_IS_APP_UPDATING, SET_FILTER, SET_IS_UPDATE_AVAILABLE, SET_NOTIFICATION, SET_PACKAGES, SET_POPUP_CLOSE, SET_POPUP_OPEN, SET_INSTANT_POPUP, SET_UPLOAD } from "./AppType";
const Reducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_USER_STATUS:
            return {
                ...state,
                userStatus: payload
            };

        case SET_USER:
            return {
                ...state,
                user: payload
            };

        case SET_IS_LOGGED_In:
            return {
                ...state,
                isLoggedIn: payload
            };

        case SET_FILTER:
            return {
                ...state,
                filter: payload
            };

        case SET_NOTIFICATION:
            return {
                ...state,
                notification: payload
            };

        case SET_PACKAGES:
            return {
                ...state,
                package: payload
            };
        case SET_IS_UPDATE_AVAILABLE:
            return {
                ...state,
                isUpdateAvailable: payload
            };

        case SET_IS_APP_UPDATING:
            return {
                ...state,
                isAppUpdating: payload
            };

        case SET_POPUPS:
            return {
                ...state,
                popups: payload
            };

        case SET_DO_IT_LATER:
            return {
                ...state,
                doitlater: payload
            };

        case SET_NO_DELAY_POPUP:
            return {
                ...state,
                noDelayPopup: payload
            };

        case SET_INSTANT_POPUP:
            return {
                ...state,
                instantPopup: payload
            };
        case SET_UPLOAD:
            return {
                ...state,
                upload: payload
            };
        case SET_BACK_COUNT:
            return {
                ...state,
                backCount: payload
            };

        default:
            return state;
    }
};

export default Reducer;