import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonIcon, IonSearchbar, IonCheckbox, IonFooter, IonChip } from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import appContext from "../../context/AppContext"


function EducationFilterModal({ filterStates, setServerEducation, serverEducation, isOpenEducation, setIsOpenEducation, educationList, setEducationList, setSelectedEducation }: any) {


    const local_filters = localStorage.getItem('filters')
    const filters = local_filters ? JSON.parse(local_filters) : {}

    const AppContext = useContext(appContext);
    const [data, setData] = useState<any>(serverEducation.length > 0 ? serverEducation : filters.education)
    const [filteredResults, setFilteredResults] = useState<any>(educationList?.map((val: any) => {
        return {
            ...val, check: AppContext?.filter?.education?.sub_educations?.find((v: any) => {
                if (v === val?.name_english) return true
                else return false
            })
        }
    }));

    const set = () => {
        setFilteredResults(educationList);
    }

    const onSubmit = () => {
        setServerEducation(data)
        // setSelectedEducation(data)
    }


    const handleChange = (emp: any) => {
        const updatedSelectedItems = data.includes(emp?.name_english)
            ? data.filter((item: any) => item !== emp?.name_english)
            : [...data, emp?.name_english];
        setData(updatedSelectedItems)
    };

    const handleChangeSerach = (event: any) => {
        let query = "";
        const target = event?.target as HTMLIonSearchbarElement;
        if (target) query = target.value!.toLowerCase();
        setFilteredResults([]);
        const educationsArr = JSON.stringify(educationList);
        let newArr: any = [];
        if (query) {
            JSON.parse(educationsArr).forEach((res: any) => {
                const childArray = res.sub_educations.filter((sub: any) => sub?.name_english?.toLowerCase().indexOf(query) > -1);
                if (childArray.length > 0) {
                    res['sub_educations'] = childArray;
                    newArr.push(res);
                }
            })

            setFilteredResults(newArr);
        } else {
            newArr = [...educationList];
            setFilteredResults([...newArr]);
        }
    };

    const onDidDismiss = async () => {
        setIsOpenEducation(false)
    }

    const onWillDismiss = async () => {
        setIsOpenEducation(false)
    }

    return (
        <IonPage>
            <IonContent className="ion-padding filterBody">
                <IonModal isOpen={isOpenEducation} className='filterBody' onIonModalWillPresent={set}
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss}
                >
                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>Select Education</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => { setIsOpenEducation(false); setData([]) }}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonSearchbar debounce={1000} onIonChange={(ev) => handleChangeSerach(ev)}></IonSearchbar>
                        <div className='d-flex' style={{ overflowX: 'scroll', overflowY: "hidden" }}>{data?.map((label: any, i: any) => {
                            return <IonChip key={i} color="medium">
                                <IonLabel>{label}</IonLabel>
                                <IonIcon icon={close} onClick={(ind: any) => {
                                    const newEdu = data?.filter((item: any) => item !== label)
                                    setData(newEdu)
                                }}>
                                </IonIcon>
                            </IonChip>
                        })}
                        </div>
                        <div className='educationModal'>
                            <IonList>
                                <IonRadioGroup allowEmptySelection={true}  >
                                    {filteredResults?.map((edu: any, index: any) => (
                                        <div key={index}>
                                            {edu?.sub_educations.length !== 0
                                                && <IonList>
                                                    <h5 className='fs-16 px-3' style={{ textDecoration: 'underline' }}><b>{edu?.name_english}</b></h5>
                                                    <IonRadioGroup allowEmptySelection={true}  >

                                                        {(edu?.sub_educations)?.map((edu: any, i: any) => {
                                                            return <IonItem key={i}>
                                                                <IonLabel style={{ fontSize: 15 }} >{edu?.name_english}</IonLabel>
                                                                <IonCheckbox slot="start" checked={data?.includes(edu?.name_english)} color='primary' class='mx-2' onClick={(e) => {
                                                                    handleChange(edu)
                                                                }} />
                                                            </IonItem>
                                                        })
                                                        }
                                                    </IonRadioGroup>
                                                </IonList>
                                            }
                                        </div>
                                    ))}
                                </IonRadioGroup>
                            </IonList>
                        </div>
                        <IonFooter style={{ display: "grid", position: "sticky", bottom: '0' }}>
                            <IonButton onClick={() => { setIsOpenEducation(false); onSubmit() }}>Submit</IonButton>
                        </IonFooter>

                    </IonContent>
                </IonModal >
            </IonContent >
        </IonPage >
    );
};
export default EducationFilterModal;