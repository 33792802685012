import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router";

const PrivacyPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <div
            onClick={() => {
              history.goBack();
            }}
            slot="start"
            className="backBtn"
          >
            <IonButton className="px-1">
              <IonIcon icon={arrowBackOutline} color="light" />
            </IonButton>
          </div>
          <IonTitle className="fs-18 text-white">
            <b>Privacy Policy</b>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Container fluid>
          <Row>
            {/* <Header /> */}
            <div className="topDivider">
              <p
                className={`PageTitleText light p-2 m-0 ${
                  window.innerWidth <= 768 ? "fs-24" : "fs-30"
                }`}
              >
                Privacy Policy
              </p>
            </div>
          </Row>
          <Row>
            <div
              className={`py-3 fs-16 ${
                window.innerWidth <= 768 ? "px-3 text-center" : "px-5"
              }`}
              style={{ borderBottom: "1px solid #d1d1d1" }}
            >
              <h5 className="py-3">
                Welcome to the privacy policy of Shadi Connect!
              </h5>
              <h6>1. Introduction</h6>
              <p>
                At Shadi Connect, we hold your privacy in the highest regard.
                This privacy policy is intended to provide you with a clear
                understanding of how we collect, use, and protect the
                information you share with us while using our services. We
                believe in transparency and empower you to make informed
                decisions as you navigate our platform.
              </p>
              <h6>2. Definitions and Scope</h6>
              <p>
                When we mention the "site," we're referring to both our website
                and app, which you can access at Hindu Shadi Connect. in or
                through our mobile application. "Service" encompasses all the
                offerings provided by our company via the site or app, allowing
                users to access matrimonial services. Throughout this policy,
                "we," "us," or "our" refers to the company, and "you" refers to
                you as a user of our site, app, or service. By using our site,
                app, or service, you are indicating your acceptance of our
                privacy policy and terms of use, as well as consenting to the
                collection, storage, use, and disclosure of your personal
                information as detailed herein.
              </p>
              <h6>3. Information Collection</h6>
              <p>
                Our data collection comprises two categories: non-personal
                information" and "personal information." "Non-Personal
                Information" includes data that cannot personally identify you,
                such as anonymous usage data, general demographic information,
                referring and exit pages and URLs, platform types, submitted
                preferences, and preferences derived from the data you provide.
                "Personal Information" collected from our website and app users
                includes details like email address, name, birth date,
                educational background, user-selected password, mailing address,
                zip/pin code, and telephone/mobile number or fax number.
              </p>
              <h6>4. Security and Cookies</h6>
              <p>
                We take your security seriously, especially for credit card
                transactions. We use a secure server for this purpose.
                Additionally, we employ cookies, which are small files stored on
                your hard drive, to store login information and enhance our
                service delivery. It's worth noting that certain pages on our
                website or apps may contain cookies or similar tools placed by
                third parties. However, we do not have control over the use of
                third-party cookies.
              </p>
              <h6>5. Additional Information</h6>
              <p>
                For users who create credit accounts to pay applicable fees, we
                collect additional information, including billing address,
                credit or debit card number, credit or debit card expiration
                date, and tracking information from checks or demand drafts. The
                information collected from users depends on their interactions,
                choices, and the products or services they use. This data is
                used for purposes such as authentication and account access.
                Users who register through social networking platforms like
                Facebook, Google, or LinkedIn may have their personal data
                accessed via their respective APIs. When users access our
                platform, data such as device ID, log files, geographic
                location, and device specifications are collected automatically.
              </p>
              <h6>6. Use of Information</h6>
              <p>
                Personal information is used for various purposes, including
                data analysis, identifying usage trends, improving our site or
                app, conducting market research, and preventing fraud. We also
                collect and analyze demographic and profile data to enhance our
                product and service offerings and use your IP address for
                diagnosing server issues and obtaining demographic insights.
              </p>

              <h6>7. Permissions for Mobile Applications</h6>
              <p>
                Our mobile application is designed to connect users based on
                their preferences. To provide you with the best experience, the
                app may request permissions for specific features, including
                access to the camera, contacts, location, phone, SMS, and
                storage.
              </p>

              <h6>
                How We Utilize the Information We Gather from Websites and Apps
              </h6>
              <p>
                Our primary goal is to use the information we collect to enhance
                your experience, tailor our services to your needs, and provide
                you with valuable insights. Here's how we utilize the data:
              </p>
              <h6>1. Insights through Technological Data Collection</h6>
              <p>
                We analyze the data you share with us through your browser or
                our software application to improve our services. This includes
                data such as the referring website, browser type, device
                details, access date and time, and other non-personalized
                details. Cookies play a key role in helping us understand user
                preferences and trends.
              </p>

              <h6>2. Information from Account Registration</h6>
              <p>
                Creating a personal profile is a fundamental step in becoming a
                subscriber. When you sign up, you give us permission to collect
                and use your email address as outlined in our privacy policy.
                This involves providing your email address, a chosen username,
                and a password.
              </p>
              <h6>3. Safeguarding Children's Privacy</h6>
              <p>
                Our platform is intended for individuals aged 18 and above,
                aligning with our commitment against child marriage and forged
                accounts. Any discrepancies will lead to the cessation of the
                user's account, possibly followed by legal action.
              </p>
              <h6>How We Use and Share Information</h6>
              <h6>Personal Information:</h6>
              <p>
                We do not share, sell, rent, or trade your personal data for
                marketing purposes without your explicit consent, except as
                specified in this Privacy Policy. We may collaborate with
                vendors to provide specific services, such as email
                communication servers that use your email address to send
                messages as per our directives. Your personal information
                primarily aids in communication, whether it's responding to
                queries, collecting user feedback, providing technical
                assistance, or sharing promotional offers.
              </p>

              <p>
                We may disclose personal data to external entities when
                necessary for legal processes, government requests,
                investigations into potential policy violations, addressing
                fraud or security concerns, or protecting user and public
                rights, property, or safety.
              </p>

              <h6>Non-Personal Information:</h6>

              <p>
                To improve the user experience and enhance our service, we
                analyze non-personal data. This helps us understand usage
                patterns and trends and allows us to customize the platform.
                This policy does not restrict the use or sharing of non-personal
                data, allowing us to share such data with partners, advertisers,
                and third parties as needed.
              </p>
              <p>
                In the event of business changes, such as mergers or
                acquisitions, your personal data may be transferred as part of
                the assets. Such transfers will adhere to this privacy policy,
                and the acquiring entity will continue to process data as
                described here.
              </p>

              <h6>Securing your information</h6>
              <p>
                We prioritize the security of your data by implementing measures
                to prevent unauthorized access. Your account is protected by
                your password, and it's essential to keep it confidential and
                log out after each session. We employ technological security
                measures, including encryption, firewalls, and secure socket
                layer technology, to minimize potential breaches. However, it's
                important to recognize that these measures do not offer absolute
                protection against unauthorized access or alterations.
              </p>

              <h6>Your rights and policy changes</h6>
              <p>
                You have the right to opt out of marketing communications, and
                each promotional email contains unsubscribe instructions. We
                reserve the right to modify this policy and our terms of
                service, with significant changes being notified via email or
                prominent notices on our site.
              </p>
              <h6>Get in touch.</h6>
              <p>
                If you have any questions about this privacy policy or our
                practices, please feel free to reach out to us by emailing us at
                iinfo@hindushadiconnect.com.
              </p>
              <p>
                <b>Last Updated: </b>This privacy policy was last updated on
                20/05/2024. We encourage you to check this page regularly for
                updates.
              </p>

              <p>
                We are dedicated to ensuring your experience is safe,
                personalized, and aligned with your preferences. Your trust is
                of utmost importance to us, and we're here to address any
                concerns you might have.
              </p>
            </div>
            <Footer />
          </Row>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
