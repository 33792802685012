import {
  IonCard,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useContext, useState } from "react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonInput, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { cameraOutline, closeOutline, imagesOutline } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  getProfiles,
  IdUpload,
  profileAction,
  profileUpload,
} from "../../service";
import { decode } from "base64-arraybuffer";
import moment from "moment";
import { useIonActionSheet } from "@ionic/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import CustomSelect from "../shared/CustomSelect";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function MultipleProfileUpload() {
  const [presentAction] = useIonActionSheet();
  const AppContext = useContext(appContext);
  const [image, setImage] = useState<any>(null);
  const [ImageResponse, setImageResponse] = useState<any>([]);
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");
  const documentArray = [
    { label: "Aadhar Card", value: "Aadhar Card" },
    { label: "Driving License", value: "Driving License" },
    { label: "Pan Card", value: "Pan Card" },
    { label: "Voter ID", value: "Voter ID" },
    {
      label: "Mandir Authority Application",
      value: "Mandir Authority Application",
    },
  ];

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getProfilesList = async () => {
    var res: any = await getProfiles(
      { type: AppContext?.upload?.key },
      AppContext?.user?.id
    );
    if (res?.data?.status) {
      setImageResponse(res?.data?.data[0]);
    }
  };

  const pickImage = async () => {
    await presentAction({
      cssClass: "myMenu",
      buttons: [
        {
          text: "Add From Album",
          icon: imagesOutline,
          data: {
            action: "Add From Album",
          },
          handler: () => {
            uploadProfile("Gallery");
          },
        },
        {
          text: " Use Camera",
          icon: cameraOutline,
          data: {
            action: "Use Camera",
          },
          handler: () => {
            uploadProfile("Camera");
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          icon: closeOutline,
          data: {
            action: "cancel",
          },
        },
      ],
      onDidDismiss: () => {},
    });
  };

  const uploadProfile = async (source: any) => {
    const image: any = await Camera.getPhoto({
      quality: 50,
      resultType: CameraResultType.Base64,
      source: source === "Gallery" ? CameraSource.Photos : CameraSource.Camera,
      correctOrientation: true,
    });
    setImage(image);
  };

  const submitPhoto = async () => {
    const blob = new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,
    });
    const file = new File([blob], "Name", {
      lastModified: moment().unix(),
      type: blob.type,
    });
    var bodyFormData: any = new FormData();
    bodyFormData.append("images", file);
    AppContext.setLoading(true);
    var res = await profileUpload(AppContext?.upload?.key, bodyFormData);
    if (res?.data?.status) {
      AppContext.setLoading(false);
      setImage(null);
      present({
        message: `${AppContext?.upload?.name} upload successfully`,
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      getProfilesList();
      AppContext.updateUser();
    } else {
      present({
        message: res?.data?.errors,
        duration: 1500,
        position: "top",
        color: "primary",
        cssClass: "alertToast",
      });
    }
  };

  const ActionProfile = async (id: any, action: any) => {
    presentAlert({
      header:
        action === "delete"
          ? "Are you sure want to delete?"
          : `Are you sure want to make ${AppContext?.upload?.name}?`,
      cssClass: "actionAlert",
      buttons: [
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            var obj = { id: id, action: action };
            var res: any = await profileAction(obj);
            if (res?.data?.status) {
              setImage(null);
              present({
                message:
                  action === "delete"
                    ? "Delete Image Successfully"
                    : `Make ${AppContext?.upload?.name} Successfully`,
                duration: 1500,
                position: "top",
                color: "success",
                cssClass: "alertToast",
              });
              getProfilesList();
              AppContext.updateUser();
            }
          },
        },
        {
          text: "no",
          role: "cancel",
        },
      ],
    });
  };

  const imagePreview = (img: any) => {
    setImagePreviewSrc(img);
    setIsOpen(true);
  };

  const onDidDismiss = async () => {
    setImageResponse(null);
    AppContext.setPopups(constant.popups.isOpenProfile, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    setIsOpen(false);
    localStorage.setItem("isModal", "false");
  };

  const onWillDismiss = async () => {
    setImageResponse(null);
    AppContext.setPopups(constant.popups.isOpenProfile, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    setIsOpen(false);
    localStorage.setItem("isModal", "false");
  };

  const onSubmit = async (values: any) => {
    const blob = new Blob([new Uint8Array(decode(image?.base64String))], {
      type: `image/${image?.format}`,
    });
    const file = new File([blob], "Name", {
      lastModified: moment().unix(),
      type: blob.type,
    });
    var bodyFormData: any = new FormData();
    bodyFormData.append("images", file);
    console.log("values.idNumber, values?.selectedIds", values);
    AppContext.setLoading(true);
    var res = await IdUpload(
      values.idNumber,
      values?.selectedIds?.value,
      bodyFormData
    );
    if (res?.data?.status) {
      AppContext.setLoading(false);
      setImage(null);
      toast("Id Upload Successfully");
      getProfilesList();
      AppContext.updateUser();
      reset();
    } else {
      present({
        message: res?.data?.errors,
        duration: 1500,
        position: "top",
        color: "primary",
        cssClass: "alertToast",
      });
    }
  };

  return (
    <IonModal
      isOpen={AppContext?.popups[constant.popups.isOpenProfile]}
      className="GoliveBody"
      onDidDismiss={onDidDismiss}
      onWillDismiss={onWillDismiss}
      onWillPresent={() => {
        localStorage.setItem("isModal", "true");
      }}
      onDidPresent={() => {
        localStorage.setItem("isModal", "true");
        getProfilesList();
        AppContext?.setPopupClose(false);
      }}
    >
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonTitle
            className="heading text-white"
            style={{ textTransform: "capitalize" }}
          >
            {AppContext?.upload?.name} Upload
          </IonTitle>
          <div slot="end" className="backBtn">
            <IonButton
              type="submit"
              className="px-1"
              onClick={() => {
                AppContext.setPopups(constant.popups.isOpenProfile);
                AppContext.setDoItLater();
              }}
            >
              <IonIcon icon={closeOutline} color="light" />
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="bio-data-upload-card" style={{ marginBottom: "20%" }}>
          <IonCard className="card">
            <IonRow class="p-2">
              <IonCol size="6">
                <span className="dragBox" onClick={pickImage}>
                  <IonIcon
                    icon={cameraOutline}
                    color="primary"
                    className="fs-24"
                  />
                  <br />
                  Add {AppContext?.upload?.name}
                  <IonInput
                    className="drop-zone__input"
                    accept="image/*"
                    readonly
                  ></IonInput>
                </span>
              </IonCol>
              <IonCol size="6">
                {image && (
                  <div className="imagePreview">
                    <div>
                      <img
                        loading="lazy"
                        src={"data:image/jpeg;base64," + image?.base64String}
                      ></img>
                      {AppContext?.upload?.key !== "id" && (
                        <div className="ion-text-center">
                          <IonButton
                            color="primary"
                            type="submit"
                            onClick={submitPhoto}
                          >
                            Upload
                          </IonButton>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>
            {image && AppContext?.upload?.key === "id" && (
              <form className="my-0" onSubmit={handleSubmit(onSubmit)}>
                <IonList class="pt-0 px-2 pb-5">
                  <IonRow class="pb-2 pt-0 px-2">
                    <IonCol size="12">
                      <IonLabel position="stacked" className="fs-16 labelItem">
                        Documents
                      </IonLabel>
                      <CustomSelect
                        options={documentArray}
                        control={control}
                        required={true}
                        name={"selectedIds"}
                        placeholder={"Select Documents"}
                        isSearchable={false}
                      />
                      {errors?.selectedIds?.type === "required" && (
                        <p className="errorText ms-1">
                          Select Atleast One Document.
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel position="stacked" className="fs-16 labelItem">
                        ID Proof Number
                      </IonLabel>
                      <IonInput
                        {...register("idNumber", { required: true })}
                        placeholder="Document ID Number"
                        className="border-bottom"
                      ></IonInput>
                      {errors?.idNumber?.type === "required" && (
                        <p className="errorText ms-1">Enter Document ID.</p>
                      )}
                    </IonCol>
                  </IonRow>
                  <div className="btnFill  ion-margin-bottom p-2 pb-3">
                    <IonButton type="submit" expand="block">
                      Upload
                    </IonButton>
                  </div>
                </IonList>
              </form>
            )}
          </IonCard>
          {ImageResponse?.length > 0 && (
            <IonRow>
              {ImageResponse?.map((img: any) => {
                return (
                  <IonCol size="6" className="p-3">
                    <div className="imagePreview">
                      <div>
                        <img
                          loading="lazy"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            imagePreview(
                              `${constant.ImageS3URl}/${img?.folder}/${img?.name}`
                            );
                          }}
                          src={`${constant.ImageS3URl}/${img?.folder}/${img?.name}`}
                        ></img>
                        {isOpen && (
                          <Lightbox
                            onImageLoad={() => {
                              window.dispatchEvent(new Event("resize"));
                            }}
                            imageTitle={`User ${AppContext?.upload?.name}`}
                            mainSrc={imagePreviewSrc}
                            onCloseRequest={() => setIsOpen(false)}
                            imageLoadErrorMessage="This image failed to load"
                          />
                        )}
                        {img?.order_number !== 10 && (
                          <>
                            <IonIcon
                              icon={closeOutline}
                              className="closeIcon"
                              onClick={() => ActionProfile(img?.id, "delete")}
                            />
                          </>
                        )}
                        {img?.order_number !== 10 &&
                          AppContext?.upload?.key !== "id" && (
                            <div className="otpSection">
                              <IonButton
                                fill="clear"
                                className="transparentBtn"
                                onClick={() =>
                                  ActionProfile(
                                    img?.id,
                                    AppContext?.upload?.key
                                  )
                                }
                              >
                                Make {AppContext?.upload?.name}
                              </IonButton>
                            </div>
                          )}
                        {AppContext?.upload?.key === "id" && (
                          <div className="otpSection">
                            <IonLabel className="transparentBtnId">
                              {img?.detail?.id_type?.english}
                              <br />
                              {img?.detail?.id}
                            </IonLabel>
                          </div>
                        )}
                      </div>
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
}
export default MultipleProfileUpload;
