import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { arrowBackOutline, callSharp } from "ionicons/icons";
import { requestToDelete, signUp } from "../../service";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const languages = [
  { label: "Hindi", value: "hi" },
  { label: "English", value: "en" },
];

const Settings: React.FC = () => {
  const AppContext = useContext(appContext);
  const [ShadiDoneReasonreason, setShadiDoneReason] = useState<any>("");
  const { i18n } = useTranslation();
  const [reason, setReason] = useState<string>("");
  const [present] = useIonToast();

  const { register }: any = useForm();

  const [presentAlert] = useIonAlert();

  const alert = async () => {
    if (ShadiDoneReasonreason === "") {
      setShadiDoneReason(null);
    } else {
      presentAlert({
        header: "Married now? Are you sure, you want to close account",
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Permanently Closed",
            role: "confirm",
            handler: async () => {
              var res: any = await signUp({
                shadi_done: 1,
                shaadi_done_reason: ShadiDoneReasonreason,
              });
              if (res?.data?.status) {
                AppContext.resetUser(true);
              }
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
    }
  };

  const handleSubmit = async () => {
    // Handle submission logic here using the 'reason' state
    let data = {
      reason: reason,
    };
    const res = await requestToDelete(data);
    if (res?.data?.status) {
      present({
        message: "Request sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
    setReason("");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <Link to="/app/profile" slot="start" className="backBtn">
            <IonButton type="submit" className="px-1">
              <IonIcon icon={arrowBackOutline} color="light" />
            </IonButton>
          </Link>
          <IonTitle className="fs-18 text-white">
            <b>Settings</b>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="settingBody">
        <div style={{ marginBottom: "20%" }}>
          <IonCard className="card">
            <IonAccordionGroup expand="inset">
              <IonAccordion value="first">
                <IonItem slot="header">
                  <IonLabel className="accordionHeading">
                    Deactivate Account
                  </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <p>
                    <b>
                      You can deactivate your account , Please contact{" "}
                      <span style={{ color: "#EF423B" }}>
                        Hindu Shadi Connect
                      </span>{" "}
                      team for deactivate your account.
                    </b>{" "}
                  </p>
                  {/* <button type="button" className="btn btn-primary mt-2" onClick={async () => {
                                        await CallNumber.callNumber(AppContext?.user?.payment_verified_at ? "7771003018" : "7610447511", true);
                                    }}><IonIcon icon={callSharp} color="light" style={{ verticalAlign: "middle", marginRight: 8 }} />Call Now</button> */}
                  <div>
                    <IonTextarea
                      placeholder="Enter reason..."
                      value={reason}
                      onIonChange={(e) => setReason(e.detail.value!)}
                    />
                    <IonButton expand="block" onClick={handleSubmit}>
                      Request to deactivate
                    </IonButton>
                  </div>
                </div>
              </IonAccordion>
              {/* <IonAccordion value="third">
                                <IonItem slot="header" >
                                    <IonLabel className='accordionHeading'>Language</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ paddingBottom: '100px' }}>
                                    <Select maxMenuHeight={280}
                                        blurInputOnSelect={false}
                                        className='border-bottom'
                                        theme={(theme: any) => ({
                                            ...theme, spacing: {
                                                ...theme.spacing, controlHeight: 30,
                                            }, borderRadius: 5, colors: {
                                                ...theme.colors, primary25: 'white', primary: 'rgb(238, 69, 61)', neutral50: 'lightgrey'
                                            },
                                        })}
                                        styles={{
                                            control: (baseStyles: any, state: any) => ({
                                                ...baseStyles,
                                                border: state.isFocused ? 'rgb(238, 69, 61)' : 'white', fontWeight: 600, fontSize: 15, height: 40,
                                            }),
                                        }}
                                        options={languages}
                                        onChange={(e: any) => {
                                            i18n.changeLanguage(e.value);
                                            AppContext.setUserStatus({ ...AppContext?.userStatus, language: e?.value });
                                        }}
                                    />
                                </div>
                            </IonAccordion> */}
              <IonAccordion value="second">
                <IonItem slot="header">
                  <IonLabel className="accordionHeading">Shadi Done</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <p>
                    <b>
                      From which platform you got married , please add comment
                      for it ?
                    </b>
                  </p>
                  <div className="my-2">
                    <IonTextarea
                      autoGrow={true}
                      rows={1}
                      className="border-bottom"
                      placeholder="Add Comments Here"
                      {...register("shaadi_done_reason", { required: true })}
                      onIonChange={(e: any) => {
                        setShadiDoneReason(e.target.value);
                      }}
                    ></IonTextarea>
                    <p className="mb-0">
                      {" "}
                      <small>
                        Eg. : (Patrika, Hindu Shadi Connect OR Other App)
                      </small>
                    </p>
                    {ShadiDoneReasonreason === null && (
                      <p className="errorText mb-0 ms-0">Comment is required</p>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={alert}
                    >
                      Shadi Done
                    </button>
                  </div>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Settings;
