import { useEffect, useState, useContext } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonIcon, IonCheckbox, useIonAlert, useIonToast } from '@ionic/react';
import { createCitytAPI, getCityListAPI, getCountryListAPI, getStateListAPI, signUp } from '../../service';
import appContext from "../../context/AppContext"
import { useForm } from "react-hook-form";
import { closeOutline } from 'ionicons/icons';
import CustomSelect from '../shared/CustomSelect';
import constant from '../../constant';
import { toast } from 'react-toastify';


const defaultCountry = "India"
function Firstform() {
    const [countryList, setCountryList] = useState<any>([]);
    const [stateList, setStateList] = useState<any>([]);
    const [cityList, setCityList] = useState<any>([]);
    const [showCheck, setShowCheck] = useState<any>(false);
    const [stateList2, setState2List] = useState<any>([]);
    const [city2List, setCity2List] = useState<any>([]);
    const [showWriteCity, setShowWriteCity] = useState(false)
    const [showWriteCity2, setShowWriteCity2] = useState(false)
    const [presentAlert] = useIonAlert();
    const [cityValue, setCityValue] = useState('');
    const [cityValue2, setCityValue2] = useState('');
    const [stateId1, setStateId] = useState('');
    const [stateId2, setStateId2] = useState('');


    const [same, setSame] = useState<any>(false);
    const AppContext = useContext(appContext);
    const residentialArray = [{ label: "Permanent Resident", value: "Permanent Resident" }, { label: "On Rent", value: "On Rent" }, { label: "Work permit", value: "Work permit" }, { label: "Student visa", value: "Student visa" }, { label: "Temporary visa", value: "Temporary visa" }, { label: "Non-Resident", value: "Non-Resident" }];
    const { handleSubmit, register, control, setValue, reset, getValues, watch, formState: { errors, touchedFields }, }: any = useForm({ defaultValues: {} });


    const getCountryList = async () => {
        var res: any = await getCountryListAPI();
        if (res?.data?.status) {
            let cList = res?.data?.data?.map((i: any) => { return { label: i.name_english, value: i.name_english } });
            setCountryList(cList);
        }
    }

    // repeate0
    const watchCountry = watch("second_formCountryEnglish");
    useEffect(() => {
        let v = getValues("second_formCountryEnglish")
        if (v) { getStatesByCountryName(v?.label); }
    }, [watchCountry, getValues]);

    //watch state
    const getStatesByCountryName = async (id: Number) => {
        var res1: any = await getStateListAPI({ countryId: id })
        if (res1?.data?.status) {
            let sList = res1?.data?.data?.map((i: any) => { return { label: i.english, value: i.english, id: i.id } });
            setStateList(sList);
        }
    }
    const watchState = watch("second_formStateEnglish");
    useEffect(() => {
        let v = getValues("second_formStateEnglish")
        if (v) getCityByStateName(v?.label)
    }, [watchState, getValues]);
    const getCityByStateName = async (id: Number) => {
        if (id) {
            let res1 = await getCityListAPI({ stateid: id })
            if (res1?.data?.status) {
                let cList = res1?.data?.data?.map((i: any) => { return { label: i.name_english, value: i.name_english, id: i.id } });
                setCityList(cList);
            }
        }
    }


    //repeate1
    const watchCountry2 = watch("second_formLivingCountryEnglish");
    useEffect(() => {
        let v = getValues("second_formLivingCountryEnglish")
        if (v) { getStatesByCountryName2(v?.label); }
    }, [watchCountry2, getValues]);

    //watch state
    const getStatesByCountryName2 = async (id: Number) => {
        var res1: any = await getStateListAPI({ countryId: id })
        if (res1?.data?.status) {
            let sList = res1?.data?.data?.map((i: any) => { return { label: i.english, value: i.english, id: i.id } });
            setState2List(sList);
        }
    }
    const watchState2 = watch("second_formLivingStateEnglish");
    useEffect(() => {
        let v = getValues("second_formLivingStateEnglish")
        if (v) getCityByStateName2(v?.label)
    }, [watchState2, getValues]);

    const getCityByStateName2 = async (id: Number) => {
        if (id) {
            let res1 = await getCityListAPI({ stateid: id })
            if (res1?.data?.status) {
                let cList = res1?.data?.data?.map((i: any) => { return { label: i.name_english, value: i.name_english, id: i.id } });
                setCity2List(cList);
            }
        }
    }
    //repeate1


    const onSubmit = async (values: any) => {
        Object.keys(values)?.forEach((i: any) => { if (values[i]?.value) { values[i] = values[i].value; } })
        var obj = {
            second_formCountryEnglish: values.second_formCountryEnglish,
            second_formStateEnglish: values.second_formStateEnglish,
            second_formCityEnglish: cityValue,
            second_formAddressEnglish: values.second_formAddressEnglish,
            fifth_formResidentalStatusEnglish: values.fifth_formResidentalStatusEnglish,
            second_formSameAddress: same,
            second_formLivingCountryEnglish: values.second_formLivingCountryEnglish,
            second_formLivingStateEnglish: values.second_formLivingStateEnglish,
            second_formLivingCityEnglish: cityValue2,
            second_formLivingAddressEnglish: values.second_formLivingAddressEnglish,
        }
        var res = await signUp(obj);
        if (res?.data?.status) {
            toast("Residential Detail Saved Successfully");
            AppContext.updateUser();
            reset();
            AppContext.setPopups(constant.popups.isFirstForm, false);
        } else {
            toast(res?.data?.errors);
        }

    }


    const saveValue = (e: any) => {
        setSame(!same);
        if (!same) {
            setValue("second_formLivingCountryEnglish", { label: getValues("second_formCountryEnglish").value || defaultCountry, value: getValues("second_formCountryEnglish").value || defaultCountry })
            setValue("second_formLivingStateEnglish", { label: getValues("second_formStateEnglish").value, value: getValues("second_formStateEnglish").value })
            setCityValue2(cityValue)
            setValue("second_formLivingAddressEnglish", getValues("second_formAddressEnglish"));
        } else {
            setValue("second_formLivingCountryEnglish", { label: defaultCountry, value: '' })
            setValue("second_formLivingStateEnglish", { label: '', value: '' })
            setCityValue2('')
            setValue("second_formLivingAddressEnglish", '');
        }
    }


    const onReject = (modalState: Boolean) => {
        reset();
        AppContext.setPopups(constant.popups.isFirstForm, modalState);
        AppContext.setDoItLater();
    }

    const onDidDismiss = async () => {
        AppContext.setPopups(constant.popups.isFirstForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }


    const onWillDismiss = async () => {
        AppContext.setPopups(constant.popups.isFirstForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    // console.log("(AppContext?.user", AppContext?.user.second_formSameAddress);


    const onDidPresent = () => {
        AppContext?.setPopupClose(false)
        if (AppContext?.user?.second_formCountryEnglish) setValue("second_formCountryEnglish", { label: AppContext?.user?.second_formCountryEnglish || defaultCountry, value: AppContext?.user?.second_formCountryEnglish || defaultCountry })
        else setValue("second_formCountryEnglish", { label: defaultCountry, value: defaultCountry })
        if (AppContext?.user?.second_formStateEnglish) setValue("second_formStateEnglish", { label: AppContext?.user?.second_formStateEnglish, value: AppContext?.user?.second_formStateEnglish })
        if (AppContext?.user?.second_formCityEnglish) setValue("second_formCityEnglish", { label: AppContext?.user?.second_formCityEnglish, value: AppContext?.user?.second_formCityEnglish })
        if (AppContext?.user?.fifth_formResidentalStatusEnglish) setValue("fifth_formResidentalStatusEnglish", { label: AppContext?.user?.fifth_formResidentalStatusEnglish, value: AppContext?.user?.fifth_formResidentalStatusEnglish })
        if (AppContext?.user?.second_formAddressEnglish) setValue("second_formAddressEnglish", AppContext?.user?.second_formAddressEnglish);
        setSame(AppContext?.user?.second_formSameAddress ? true : false)
        if (AppContext?.user?.second_formLivingCountryEnglish) setValue("second_formLivingCountryEnglish", { label: AppContext?.user?.second_formLivingCountryEnglish || defaultCountry, value: AppContext?.user?.second_formLivingCountryEnglish || defaultCountry })
        if (AppContext?.user?.second_formLivingStateEnglish) setValue("second_formLivingStateEnglish", { label: AppContext?.user?.second_formLivingStateEnglish, value: AppContext?.user?.second_formLivingStateEnglish })
        if (AppContext?.user?.second_formLivingCityEnglish) setCityValue2(AppContext?.user?.second_formLivingCityEnglish)
        if (AppContext?.user?.second_formLivingAddressEnglish) setValue("second_formLivingAddressEnglish", AppContext?.user?.second_formLivingAddressEnglish);
        getCountryList();
        localStorage.setItem('isModal', 'true')
    }
    // for home add

    const watchStateId = watch("second_formStateEnglish");
    useEffect(() => {
        let v = getValues("second_formStateEnglish")
        setStateId(v?.id)
    }, [watchStateId, getValues]);

    const watchStateId2 = watch("second_formLivingStateEnglish");
    useEffect(() => {
        let v = getValues("second_formLivingStateEnglish")
        setStateId2(v?.id)
    }, [watchStateId2, getValues]);


    const watchCityValue = watch("second_formCityEnglish");
    useEffect(() => {
        let v = getValues("second_formCityEnglish")
        setCityValue(v?.value)
        if (AppContext?.user?.second_formSameAddress) setCityValue2(v?.value)
    }, [watchCityValue, getValues]);

    const createCity = async () => {
        let obj = {
            "state_id": stateId1 ? stateId1 : 21,
            "name_english": cityValue,
        }
        let res: any = await createCitytAPI(obj)
    }

    const watchCityValue2 = watch("second_formLivingCityEnglish");
    useEffect(() => {
        let v = getValues("second_formLivingCityEnglish")
        setCityValue2(v?.value)
    }, [watchCityValue2, getValues]);

    const createCity2 = async () => {
        let obj = {
            "state_id": stateId2,
            "name_english": cityValue2,
        }
        let res: any = await createCitytAPI(obj)
    }

    return (
        <>
            <IonModal isOpen={AppContext?.popups[constant.popups.isFirstForm]} className='GoliveBody'
                onDidDismiss={onDidDismiss}
                onWillDismiss={onWillDismiss}
                onDidPresent={onDidPresent}
                onWillPresent={() => {
                    localStorage.setItem('isModal', 'true')
                }}
            >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle className='heading text-white'>Address</IonTitle>
                        <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={(event: any) => {
                                // event.stopPropagation(); setFirstForm(false)
                                Object.keys(touchedFields).length
                                    ? presentAlert({
                                        header: 'Would you like to save changes?',
                                        cssClass: 'actionAlert',
                                        buttons: [
                                            {
                                                text: 'Yes', role: 'confirm',
                                                handler: handleSubmit(onSubmit),
                                            },
                                            {
                                                text: 'no', role: 'cancel', handler: () => {
                                                    onReject(false);
                                                }
                                            },
                                        ],
                                    })
                                    : onReject(false)
                            }}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <IonContent className='settingBody'>
                    <div style={{ padding: "10px" }}>
                        <form className='my-2' onSubmit={handleSubmit(onSubmit)}>

                            {/* Home Address */}
                            <div className='card-family imagePreview' >
                                <div className='card-family-name Color-Theme'>Home Address</div>
                                <div className='p-1'>
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>Country<span className='errorText m-0' >*</span></IonLabel>
                                        {countryList
                                            && <CustomSelect
                                                options={countryList}
                                                control={control}
                                                required={true}
                                                name={"second_formCountryEnglish"}
                                                placeholder={"Select Country"}
                                                isSearchable={true}

                                            />}
                                        {errors?.second_formCountryEnglish?.type === "required" && <p className='errorText ms-1'>Select country.</p>}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>State<span className='errorText m-0' >*</span></IonLabel>
                                        {stateList
                                            && <CustomSelect
                                                options={stateList}
                                                control={control}
                                                required={true}
                                                name={"second_formStateEnglish"}
                                                placeholder={"Select State"}
                                                isSearchable={true}
                                            />}
                                        {errors?.second_formStateEnglish?.type === "required" && <p className='errorText ms-1'>Select state.</p>}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>City<span className='errorText m-0' >*</span></IonLabel>
                                        {cityList
                                            && <CustomSelect
                                                options={cityList}
                                                control={control}
                                                // required={true}
                                                name={"second_formCityEnglish"}
                                                placeholder={"Select City"}
                                                isSearchable={true}
                                            />}
                                        {errors?.second_formCityEnglish?.type === "required" && <p className='errorText ms-1'>Select City.</p>}
                                    </IonGrid>
                                    {!showWriteCity && < IonGrid >
                                        <IonButton size='small' onClick={() => setShowWriteCity(true)}>Write City</IonButton>
                                    </IonGrid>}
                                    {showWriteCity && <><IonGrid >
                                        <IonInput onIonChange={(e: any) => { setCityValue(e.target.value) }} placeholder={"Write City"} className='border-bottom' ></IonInput>
                                    </IonGrid>
                                        <IonGrid >
                                            <IonButton size='small' onClick={() => { createCity() }}>Add City</IonButton>
                                        </IonGrid></>}
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>Address<span className='errorText m-0' >*</span></IonLabel>
                                        <IonInput
                                            {...register("second_formAddressEnglish", { required: true })}
                                            type="text"
                                            placeholder="Enter Address"
                                            className='border-bottom' >
                                        </IonInput>
                                        {errors?.second_formAddressEnglish?.type === "required" && <p className='errorText ms-1'>Please write address.</p>}
                                    </IonGrid>
                                </div>
                            </div>

                            {showCheck && <div className='d-flex justify-content-end'>
                                <IonItem>
                                    <IonCheckbox onClick={(e) => { setShowCheck(false); saveValue(e) }} class='mx-2' checked={same}></IonCheckbox>
                                    <IonLabel className="Color-Theme">Same as Home Address</IonLabel>
                                </IonItem>
                            </div>
                            }

                            {/* Current Address */}
                            {!same && <div className='card-family imagePreview mx-1 my-4' >
                                <div className='card-family-name Color-Theme'>Current Address</div>
                                <div className='p-1'>
                                    {!showCheck && <div className='my-3 mx-0'>
                                        <IonCheckbox onClick={(e) => { setShowCheck(true); saveValue(e) }} class='mx-2' checked={same}></IonCheckbox>
                                        <IonLabel className="Color-Theme">Same as Home Address</IonLabel>
                                    </div>}
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>Country<span className='errorText m-0' >*</span></IonLabel>
                                        {countryList
                                            && <CustomSelect
                                                options={countryList}
                                                control={control}
                                                required={true}
                                                name={"second_formLivingCountryEnglish"}
                                                placeholder={"Select Country"}
                                                isSearchable={true}

                                            />}
                                        {errors?.second_formLivingCountryEnglish?.type === "required" && <p className='errorText ms-1'>Select country.</p>}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>State<span className='errorText m-0' >*</span></IonLabel>
                                        {stateList2
                                            && <CustomSelect
                                                options={stateList2}
                                                control={control}
                                                required={true}
                                                name={"second_formLivingStateEnglish"}
                                                placeholder={"Select State"}
                                                isSearchable={true}

                                            />}
                                        {errors?.second_formLivingStateEnglish?.type === "required" && <p className='errorText ms-1'>Select state.</p>}
                                    </IonGrid>
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>City<span className='errorText m-0' >*</span></IonLabel>
                                        {city2List
                                            && <CustomSelect
                                                options={city2List}
                                                control={control}
                                                required={true}
                                                name={"second_formLivingCityEnglish"}
                                                placeholder={"Select City"}
                                                isSearchable={true}

                                            />}
                                        {errors?.second_formLivingCityEnglish?.type === "required" && <p className='errorText ms-1'>Select City.</p>}
                                    </IonGrid>
                                    {!showWriteCity2 && < IonGrid >
                                        <IonButton size='small' onClick={() => setShowWriteCity2(true)}>Write City</IonButton>
                                    </IonGrid>}
                                    {showWriteCity2 && <><IonGrid >
                                        <IonInput onIonChange={(e: any) => { setCityValue2(e.target.value) }} placeholder={"Write City"} className='border-bottom' ></IonInput>
                                    </IonGrid>
                                        <IonGrid >
                                            <IonButton size='small' onClick={() => { createCity2() }}>Add City</IonButton>
                                        </IonGrid></>}
                                    <IonGrid>
                                        <IonLabel position="stacked" className='fs-16 labelItem'>Address<span className='errorText m-0' >*</span></IonLabel>
                                        <IonInput
                                            {...register("second_formLivingAddressEnglish", { required: true })}
                                            type="text"
                                            placeholder="Enter Address"
                                            className='border-bottom' >
                                        </IonInput>
                                        {errors?.second_formLivingAddressEnglish?.type === "required" && <p className='errorText ms-1'>Please write address.</p>}
                                    </IonGrid>
                                </div>
                            </div>}

                            <IonGrid>
                                <IonLabel position="stacked" className='fs-16 labelItem'>Residential Status<span className='errorText m-0' >*</span></IonLabel>
                                <CustomSelect
                                    options={residentialArray}
                                    control={control}
                                    required={true}
                                    name={"fifth_formResidentalStatusEnglish"}
                                    placeholder={"Select Residential Status"}
                                    isSearchable={false}

                                />
                                {errors?.fifth_formResidentalStatusEnglish?.type === "required" && <p className='errorText ms-1'>Select residential status.</p>}
                            </IonGrid>

                            <div className='m-3'>
                                <IonCol class='btnFill'>
                                    <IonButton type="submit" expand="block" >Save</IonButton>
                                </IonCol>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonModal >
        </>
    );
}

export default Firstform;