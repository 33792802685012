import {
  IonAlert,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonPopover,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { useHistory, useLocation } from "react-router-dom";
import {
  arrowBackOutline,
  bagHandleSharp,
  cameraSharp,
  heart,
  locationSharp,
  send,
  shieldCheckmarkSharp,
  starSharp,
  ellipsisVertical,
} from "ionicons/icons";
import {
  addToFavoriteList,
  interestList,
  sendInterestRequest,
  sendPhotoRequestApi,
  getCurrentPackage,
  interestFavouriteList,
  deleteInterestRequest,
} from "../../service";
import moment from "moment";
import vector2 from "../../assets/images/Vector(2).png";
import vector1 from "../../assets/images/Vector(1).png";
import Utils from "../utils";
import Details from "./Details";
import report from "../../assets/images/report.png";
import weddingrings from "../../assets/images/weddingrings.png";
import blockuser from "../../assets/images/blockuser.png";
import Header from "../shared/Header";

const styles: any = {
  disabledGrid: {
    filter: "blur(1px)",
    pointerEvents: "none",
  },
  blurOverlay: {
    position: "absolute",
    opacity: "1 !important",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "999",
    backdropFilter: "blur(1px)",
  },
  nowAvailableText: {
    position: "absolute",
    top: "36%",
    left: "50%",
    zIndex: "999",
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
  nowAvailableSubText: {
    position: "absolute",
    width: "100%",
    top: "58%",
    left: "50%",
    zIndex: "999",
    fontSize: "0.9em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
};

const Favourite: React.FC = () => {
  const AppContext = useContext(appContext);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [present] = useIonToast();
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [presentAlert] = useIonAlert();
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [list, setList] = useState<any>();
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [event, setEvent] = useState<any>();
  const [showAlert, setShowAlert] = useState<any>(false);
  const [userData, setUserData] = useState<any>({ user: "", i: "" });
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [CurrentPackageSpecial, setCurrentPackageSpecial] = useState<any>(null);

  useIonViewWillEnter(() => {
    getFavourite(0);
  });

  useEffect(() => {
    getFavourite(0);
  }, []);

  useEffect(() => {
    CurrentPackageData();
  }, []);

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      setCurrentPackageSpecial(
        getPackagesByType("special") || getPackagesByType("Special")
          ? getPackagesByType("special") || getPackagesByType("Special")
          : null
      );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  // const getFavourite = async (page: any) => {
  //     var obj = {
  //         status: "favourite",
  //         type: "send"
  //     }
  //     // var res: any = await interestFavouriteList(obj, page);
  //     var res: any = await interestList(obj, page);
  //     if (res?.data?.status) {
  //         setPageNumber(page + 1);
  //         setLoading(false);
  //         let uList = page === 0 ? [] : list;
  //         setList([...uList, ...res?.data?.data?.list]);
  //     }
  // }

  const getFavourite = async (page: any) => {
    const obj = {
      status: "favourite",
      type: "send",
    };
    const res: any = await interestList(obj, page);
    if (res?.data?.status) {
      setPageNumber(page + 1);
      setLoading(false);
      const newList = res?.data?.data?.list || [];
      const updatedList = page === 0 ? [] : list || [];
      const filteredNewList = newList.filter(
        (item: any) => item.favorite !== false
      );
      const mergedList = [...updatedList, ...filteredNewList];
      setList(mergedList);
    }
  };

  const addToFavorite = async (selectedUser: any, index: any) => {
    const usersListTemp = [...list];
    usersListTemp[index].favorite = !usersListTemp[index].favorite;
    await addToFavoriteList(selectedUser);
    getFavourite(0);
  };
  const sendInterest = async (selectedUser: any, index: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...list];
      usersListTemp[index].interest = true;
      present({
        message: "Sent Interest Successfully",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      getFavourite(0);
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };
  const sendPhotoRequest = async (selectedUser: any, index: any) => {
    var res: any = await sendPhotoRequestApi(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...list];
      usersListTemp[index].photoRequest = [
        {
          count_no: usersListTemp[index]?.photoRequest[0]?.count_no
            ? usersListTemp[index]?.photoRequest[0]?.count_no + 1
            : 1,
        },
      ];
      setList(usersListTemp);
      present({
        message: "Photo request sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };

  const goTo = async () => {
    setOpenPopUp(false);
    AppContext.setPopups(constant.popups.isGoLive, true);
  };

  const handleRemoveInterest = async () => {
    const res = await deleteInterestRequest(userData.user);
    if (res?.data?.status) {
      userData.user.interest = false;
      getFavourite(0);
      present({
        message: "Sent Interest Cancelled",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      setShowAlert(false);
      setUserData({ user: "", i: "" });
    }
  };

  const handleIconClick = (e: any) => {
    setShowPopover(true);
    setEvent(e);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
  };
  return (
    <>
      <div className="header-common-box">
        <Header />
      </div>
      <IonPage className="favorite-body">
        <IonHeader>
          <IonToolbar color={"primary"}>
            <div
              onClick={() => {
                history.goBack();
              }}
              slot="start"
              className="backBtn"
            >
              <IonButton type="submit" className="px-1">
                <IonIcon icon={arrowBackOutline} color="light" />
              </IonButton>
            </div>
            <IonTitle className="fs-18 text-white">
              <b>Favourite List</b>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent style={{ marginBottom: "50px !important " }}>
          <div>
            <Details
              isOpenDetail={isOpenDetail}
              setOpenDetail={setOpenDetail}
              id={userID}
            />

            {loading ? (
              <IonList>
                {[...Array(3)].map((i) => (
                  <IonCard className="box-new-profile ion-justify-content-center ion-align-items-center">
                    <IonGrid
                      className="ion-margin-0"
                      style={{ maxHeight: "11em", margin: 0 }}
                    >
                      <IonRow>
                        <IonCol size="4">
                          <div
                            style={{
                              width: "100%",
                              paddingTop: "100%",
                              position: "relative",
                              background: "#ccc",
                            }}
                          ></div>
                        </IonCol>
                        <IonCol size="8">
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "60%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%", height: "12px" }}
                              ></IonSkeletonText>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
              </IonList>
            ) : (
              <div>
                <div className="favourite-body-box">
                  {list?.length < 1 ? (
                    <IonCard className="card ">
                      {" "}
                      <p className="fs-16 ion-text-center ion-margin-top">
                        You don't have any user in favourite!
                      </p>
                    </IonCard>
                  ) : (
                    list?.map((o: any, i: any) => {
                      let p = o?.hindu_profiles?.find((i: any) => {
                        return i?.type === "profile" && i?.order_number === 10;
                      });
                      if (!p)
                        p = o?.hindu_profiles?.find((i: any) => {
                          return i?.type === "profile";
                        });
                      return (
                        <>
                          <IonCard
                            key={i}
                            onClick={() => {
                              if (!o?.blocked && !o?.shadiDone) {
                                setOpenDetail(true);
                                setUserID(o?.id);
                              }
                            }}
                            className="box-new-profile ion-justify-content-center ion-align-items-center"
                          >
                            {(o?.blocked || o?.shadiDone) && (
                              <div style={styles.blurOverlay}></div>
                            )}
                            <IonGrid
                              className="ion-margin-0"
                              style={{
                                maxHeight: "11em",
                                margin: 0,
                                opacity: `${
                                  o?.blocked || o?.shadiDone ? "0.5" : "1"
                                }`,
                              }}
                            >
                              <IonRow>
                                <IonCol
                                  className="image-newCard"
                                  style={{
                                    backgroundImage:
                                      !p?.name &&
                                      Utils.getDefaultUserUrl(
                                        AppContext?.user
                                          ?.first_formGenderEnglish
                                      ),
                                    backgroundSize: p?.name
                                      ? "100% 100%"
                                      : "90px",
                                  }}
                                  sizeXs="5"
                                  sizeSm="5"
                                  sizeLg="5"
                                >
                                  {/* {p?.name ? ( */}
                                  <IonImg
                                    src={
                                      p?.name
                                        ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                                        : "https://ionicframework.com/docs/img/demos/avatar.svg"
                                    }
                                    style={{
                                      height: "10.3em",
                                      objectFit: "cover",
                                    }}
                                  ></IonImg>
                                  {/* ) : null} */}
                                  {o?.verified_at ? (
                                    <div className="corner-ribbon-new">
                                      <span className="cr-inner-new">
                                        <span className="cr-text-new">
                                          {" "}
                                          <IonIcon
                                            style={{ fontSize: "2em" }}
                                            icon={shieldCheckmarkSharp}
                                          />
                                          <br />
                                          Verified
                                        </span>
                                      </span>
                                    </div>
                                  ) : null}
                                </IonCol>
                                <IonCol
                                  className="ps-2 pb-0 mb-0"
                                  sizeSm="7"
                                  sizeXs="7"
                                  sizeLg="7"
                                >
                                  <IonRow className="ion-justify-content-between ps-1">
                                    <IonLabel style={{ margin: 0 }}>
                                      <p
                                        className="color-primary  font-weight-500 m-0"
                                        style={{
                                          fontSize: 12,
                                          lineHeight: "12px",
                                        }}
                                      >
                                        <b>HSC-{o?.id}</b>
                                      </p>
                                      <div className="my-1 userCardNameandAge">
                                        <b className="ellispses w-50">
                                          {o.nameEnglish}{" "}
                                        </b>
                                        <b>
                                          &nbsp;(
                                          {o?.dob
                                            ? moment().diff(o?.dob, "years")
                                            : o?._age}{" "}
                                          Yrs)
                                        </b>
                                      </div>
                                      <p className="text-muted subInfoUserCard">
                                        <b>
                                          {o.third_formSectEnglish}{" "}
                                          {o.third_formSectEnglish ? (
                                            <span>|</span>
                                          ) : (
                                            ""
                                          )}{" "}
                                          {o.first_formMaritalStatusEnglish}{" "}
                                          {o.first_formMaritalStatusEnglish ? (
                                            <span>|</span>
                                          ) : (
                                            ""
                                          )}
                                          <br />{" "}
                                          {o.fourth_formEmployedEnglish
                                            ? o.fourth_formEmployedEnglish
                                            : "-"}{" "}
                                        </b>
                                      </p>
                                    </IonLabel>
                                    {/* <span className='position-absolute' style={{ top: 1, right: 1 }} onClick={handleIconClick}>
                                                                        <IonIcon icon={ellipsisVertical} color='dark' style={{ verticalAlign: 'sub' }} />
                                                                    </span> */}
                                  </IonRow>
                                  <IonRow className="p-0 m-0">
                                    <div className="p-0 m-0 manglikAndAddressUserCard">
                                      <div style={{ flex: "100%" }}>
                                        <IonLabel style={{ margin: 0 }}>
                                          <p className="m-0 manglikUserCard">
                                            <IonIcon
                                              icon={starSharp}
                                              color="primary"
                                              style={{ verticalAlign: "sub" }}
                                            />{" "}
                                            Manglikta
                                          </p>
                                          <p className="manglikValueUserCard">
                                            <b>
                                              {o.third_formDoshEnglish || "-"}
                                            </b>
                                          </p>
                                        </IonLabel>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "20px",
                                          padding: "0px 6px",
                                          flex: "5%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 1,
                                            height: 29,
                                            background: "#797f85",
                                          }}
                                        ></div>
                                      </div>
                                      <div
                                        style={{
                                          flex: "100%",
                                          maxWidth: "80px",
                                        }}
                                      >
                                        <IonLabel style={{ margin: 0 }}>
                                          <p className="m-0 addressUserCard">
                                            <IonIcon
                                              icon={locationSharp}
                                              color="primary"
                                              style={{ verticalAlign: "sub" }}
                                            />{" "}
                                            Address
                                          </p>
                                          <p className="addressValueUserCard">
                                            <b>
                                              {o.second_formStateEnglish || "-"}
                                            </b>
                                          </p>
                                        </IonLabel>
                                      </div>
                                    </div>
                                  </IonRow>
                                  <IonRow className="mt-1">
                                    <IonCol
                                      size="6"
                                      className="ion-no-padding ion-no-margin sendInterestBtn"
                                    >
                                      <IonButton
                                        style={{
                                          fontSize: "11px",
                                          width: "100%",
                                        }}
                                        color={"primary"}
                                        size="small"
                                        onClick={(event: any) => {
                                          event.stopPropagation();
                                          setUserData({ user: o, i: i });
                                          if (
                                            !AppContext?.user?.second_formMobile
                                          )
                                            AppContext.setNoDelayPopup(
                                              constant.popups.isGoLive
                                            );
                                          else if (
                                            CurrentPackageBasic === null &&
                                            CurrentPackageVip === null &&
                                            CurrentPackageSpecial !== null
                                          )
                                            AppContext.setNoDelayPopup(
                                              constant.popups.isSubscribe
                                            );
                                          else if (
                                            !AppContext?.user
                                              ?.payment_verified_at
                                          )
                                            AppContext.setNoDelayPopup(
                                              constant.popups.isSubscribe
                                            );
                                          if (!o?.interest) sendInterest(o, i);
                                          else if (o?.interest) {
                                            setShowAlert(true);
                                          }
                                        }}
                                      >
                                        <IonIcon
                                          className="white-New"
                                          icon={send}
                                        />{" "}
                                        {!o?.interest
                                          ? "Send Interest"
                                          : "Cancel"}
                                      </IonButton>
                                    </IonCol>
                                    <IonCol
                                      size="6"
                                      className="favouriteBtn ion-no-padding ion-no-margin"
                                    >
                                      <IonButton
                                        style={{
                                          fontSize: "11px",
                                          width: "100%",
                                        }}
                                        color={
                                          o?.favorite ? "secondary" : "warning"
                                        }
                                        size="small"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          addToFavorite(o, i);
                                        }}
                                      >
                                        <IonIcon
                                          className="favouriteBtnIcon"
                                          icon={heart}
                                        />{" "}
                                        Favorite
                                      </IonButton>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                            {o?.blocked && !o?.shadiDone && (
                              <>
                                <p style={styles.nowAvailableText}>
                                  Not Available
                                </p>
                                <p style={styles.nowAvailableSubText}>
                                  Sorry this person has blocked you!
                                </p>
                              </>
                            )}
                            {o?.shadiDone && !o?.blocked && (
                              <>
                                <p style={styles.nowAvailableText}>
                                  Not Available
                                </p>
                                <p style={styles.nowAvailableSubText}>
                                  Sorry this person moved you in shadi done list
                                  !
                                </p>
                              </>
                            )}
                            {o?.shadiDone && o?.blocked && (
                              <>
                                <p style={styles.nowAvailableText}>
                                  Not Available
                                </p>
                                <p style={styles.nowAvailableSubText}>
                                  Sorry this person moved you in shadi done list
                                  & blocked you !
                                </p>
                              </>
                            )}
                          </IonCard>
                        </>
                      );
                    })
                  )}
                  <IonPopover
                    isOpen={showPopover}
                    event={event}
                    onDidDismiss={handlePopoverClose}
                  >
                    <div className="popover-content">
                      <IonList className="m-0 p-0 optionParent">
                        <IonItem>
                          <img src={report} />
                          <span
                            style={{
                              fontWeight: "600",
                              marginLeft: "8px",
                              fontSize: "15px",
                            }}
                          >
                            Report
                          </span>
                        </IonItem>
                        <IonItem>
                          <img src={weddingrings} />
                          <span
                            style={{
                              fontWeight: "600",
                              marginLeft: "8px",
                              fontSize: "15px",
                            }}
                          >
                            Shadi Done
                          </span>
                        </IonItem>
                        <IonItem>
                          <img src={blockuser} />
                          <span
                            style={{
                              fontWeight: "600",
                              marginLeft: "8px",
                              fontSize: "15px",
                            }}
                          >
                            Block User
                          </span>
                        </IonItem>
                      </IonList>
                    </div>
                  </IonPopover>
                  <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass="actionAlert123"
                    header="Are you sure you want to remove interest?"
                    buttons={[
                      {
                        text: "No",
                        role: "cancel",
                        cssClass: "alertButton",
                      },
                      {
                        text: "Yes",
                        handler: handleRemoveInterest,
                        cssClass: "alertButton",
                      },
                    ]}
                  />
                  <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                      getFavourite(pageNumber);
                      setTimeout(() => ev.target.complete(), 500);
                    }}
                  >
                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </div>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
export default Favourite;
