import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  IonList,
  IonAvatar,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  useIonToast,
  IonButton,
  IonImg,
  IonIcon,
  useIonAlert,
  IonItem,
} from "@ionic/react";
import {
  addToFavoriteList,
  dashboard,
  home,
  sendInterestRequest,
  sendPhotoRequestApi,
  getCurrentPackage,
  getContact,
} from "../../service";
import appContext from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { IonSkeletonText } from "@ionic/react";
import UserCard from "./UserCard";
import vector2 from "../../assets/images/Vector(2).png";
import vector1 from "../../assets/images/Vector(1).png";
import support from "../../assets/images/support.png";
import {
  callSharp,
  ellipsisVertical,
  heart,
  locationSharp,
  send,
  starSharp,
  logoWhatsapp,
} from "ionicons/icons";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import Details from "./Details";
import NewUserCard from "./NewUserCard";

function DashboardUserList() {
  const [usersList, setUsersList] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const AppContext = useContext(appContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [present] = useIonToast();
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [CurrentPackageSpecial, setCurrentPackageSpecial] = useState<any>(null);
  const [presentAlert] = useIonAlert();
  const [before, setBefore] = useState<any>(null);
  const [after, setAfter] = useState<any>(null);

  useEffect(() => {
    CurrentPackageData();
  }, []);

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      setCurrentPackageSpecial(
        getPackagesByType("special") || getPackagesByType("Special")
          ? getPackagesByType("special") || getPackagesByType("Special")
          : null
      );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  const getContactList = useCallback(async () => {
    const res = await getContact();
    setBefore(res?.data?.data?.conatct[0]?.mobile_number);
    setAfter(res?.data?.data?.conatct[1]?.mobile_number);
  }, [AppContext?.user?.payment_verified_at]);

  useEffect(() => {
    getContactList();
    getUserlist();
  }, []);

  const getUserlist = useCallback(async () => {
    try {
      // var response = await home(0);
      var response = await dashboard({}, 0);
      // var response = await dashboard({ home: "home" }, 0);
      setUsersList(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error in getUserlist:", error);
    }
  }, []);

  const handleProfileNavigation = (type: string) => {
    if (usersList && usersList.length > 0) {
      if (type === "previous" && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
        setUserID(usersList[currentIndex - 1].id);
      } else if (type === "next" && currentIndex < usersList.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setUserID(usersList[currentIndex + 1].id);
      }
    }
  };

  const addToFavorite = async (selectedUser: any, index: any) => {
    const usersListTemp = [...usersList];
    usersListTemp[index].favorite = !usersListTemp[index].favorite;
    setUsersList(usersListTemp);
    await addToFavoriteList(selectedUser);
  };

  const sendInterest = async (selectedUser: any, index: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...usersList];
      usersListTemp[index].interest = true;
      setUsersList(usersListTemp);
      present({
        message: "Sent Interest Successfully",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  const sendPhotoRequest = async (selectedUser: any, index: any) => {
    var res: any = await sendPhotoRequestApi(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...usersList];
      usersListTemp[index].photoRequest = [
        {
          count_no: usersListTemp[index]?.photoRequest[0]?.count_no
            ? usersListTemp[index]?.photoRequest[0]?.count_no + 1
            : 1,
        },
      ];
      setUsersList(usersListTemp);
      present({
        message: "Photo request sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    }
  };

  const handleWhatsAppClick = () => {
    const whatsappLink = `https://wa.me/${
      AppContext?.user?.payment_verified_at ? after : before
    }`;
    window.open(whatsappLink, "_blank");
  };

  if (loading) {
    return (
      <IonList>
        {[...Array(10)].map((i, index) => (
          <IonCard className="box-new-profile ion-justify-content-center ion-align-items-center">
            <IonGrid
              className="ion-margin-0"
              style={{ maxHeight: "11em", margin: 0 }}
            >
              <IonRow>
                <IonCol sizeXs="5" sizeSm="5" sizeLg="5">
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "100%",
                      position: "relative",
                      background: "#ccc",
                    }}
                  ></div>
                </IonCol>
                <IonCol
                  className="ps-2 pb-0 mb-0"
                  sizeSm="7"
                  sizeXs="7"
                  sizeLg="7"
                >
                  <IonRow className="ion-justify-content-between ps-1">
                    <IonLabel style={{ margin: 0 }}>
                      <p
                        className="color-primary mt-1 font-weight-500 m-0"
                        style={{ fontSize: 12, lineHeight: "12px" }}
                      >
                        <b>
                          JSM-
                          <IonSkeletonText
                            animated
                            style={{
                              display: "inline",
                              width: "80% !important",
                              height: "8px",
                              padding: "2px 3em",
                            }}
                          ></IonSkeletonText>
                        </b>
                      </p>
                      {/* <div className="my-1" style={{ display: "flex", fontSize: "14px", lineHeight: "20px", color: "#000" }}>
                                                <b className="ellispses w-50"><IonSkeletonText animated style={{ display: "inline", width: "80%", height: "12px" }}></IonSkeletonText> </b><b>&nbsp;(<IonSkeletonText animated style={{ display: "inline", width: "80%", height: "12px" }}></IonSkeletonText>Yrs)</b>
                                            </div> */}
                      <p
                        className="text-muted mt-3"
                        style={{
                          letterSpacing: "0.1px",
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        <b>
                          <IonSkeletonText
                            animated
                            style={{
                              display: "inline",
                              width: "80%",
                              height: "6px !important",
                              padding: "1px 3em",
                            }}
                          ></IonSkeletonText>
                          <span>|</span>{" "}
                          <IonSkeletonText
                            animated
                            style={{
                              display: "inline",
                              width: "80%",
                              height: "6px !important",
                              padding: "1px 3em",
                              marginTop: "3px",
                            }}
                          ></IonSkeletonText>
                        </b>
                      </p>
                    </IonLabel>
                    <span
                      className="position-absolute"
                      style={{ top: 1, right: "-33px" }}
                    >
                      <IonIcon
                        icon={ellipsisVertical}
                        color="dark"
                        style={{ verticalAlign: "sub" }}
                      />
                    </span>
                  </IonRow>
                  <IonRow className="p-0 m-0">
                    <div
                      className="p-0 m-0"
                      style={{
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        display: "flex",
                      }}
                    >
                      <div style={{ flex: "100%" }}>
                        <IonLabel style={{ margin: 0 }}>
                          <p
                            className="m-0"
                            style={{
                              letterSpacing: "0.1px",
                              fontSize: "12px",
                              fontWeight: 600,
                              color: "#f66e06",
                            }}
                          >
                            <IonIcon
                              icon={starSharp}
                              color="primary"
                              style={{ verticalAlign: "sub" }}
                            />{" "}
                            Manglikta
                          </p>
                          <p
                            style={{
                              letterSpacing: "0.1px",
                              fontSize: "12px",
                              color: "#000",
                              lineHeight: "12px",
                              paddingLeft: 16,
                              overflow: "hidden",
                              // textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              minWidth: "80px",
                            }}
                          >
                            <b>
                              <IonSkeletonText
                                animated
                                style={{
                                  display: "inline",
                                  width: "80%",
                                  height: "12px",
                                  padding: "1px 3em",
                                }}
                              ></IonSkeletonText>
                            </b>
                          </p>
                        </IonLabel>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "20px",
                          padding: "0px 6px",
                          flex: "5%",
                        }}
                      >
                        <div
                          style={{
                            width: 1,
                            height: 29,
                            background: "#797f85",
                          }}
                        ></div>
                      </div>
                      <div style={{ flex: "100%", maxWidth: "80px" }}>
                        <IonLabel style={{ margin: 0 }}>
                          <p
                            className="m-0"
                            style={{
                              letterSpacing: "0.1px",
                              fontSize: "12px",
                              fontWeight: 600,
                              color: "#f66e06",
                            }}
                          >
                            <IonIcon
                              icon={locationSharp}
                              color="primary"
                              style={{ verticalAlign: "sub" }}
                            />{" "}
                            Address
                          </p>
                          <p
                            style={{
                              letterSpacing: "0.1px",
                              fontSize: "12px",
                              color: "#000",
                              lineHeight: "12px",
                              paddingLeft: 16,
                              overflow: "hidden",
                              // textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              minWidth: "80px",
                            }}
                          >
                            <b>
                              <IonSkeletonText
                                animated
                                style={{
                                  display: "inline",
                                  width: "80%",
                                  height: "12px",
                                  padding: "1px 3em",
                                }}
                              ></IonSkeletonText>
                            </b>
                          </p>
                        </IonLabel>
                      </div>
                    </div>
                  </IonRow>
                  <IonRow className="mt-1">
                    <IonCol
                      size="7"
                      className="ion-no-padding ion-no-margin sendInterestBtn"
                    >
                      <IonButton
                        style={{ fontSize: "x-small", width: "98%" }}
                        color={"primary"}
                        size="small"
                      >
                        <IonIcon className="white-New" icon={send} /> Send
                        Interest
                      </IonButton>
                    </IonCol>
                    <IonCol
                      size="5"
                      className="favouriteBtn ion-no-padding ion-no-margin"
                    >
                      <IonButton
                        style={{ fontSize: "x-small", width: "94%" }}
                        color={"warning"}
                        size="small"
                      >
                        <IonIcon className="favouriteBtnIcon" icon={heart} />{" "}
                        Favorite
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        ))}
      </IonList>
    );
  }
  return usersList.length < 1 ? (
    <IonCard className="card ">
      {" "}
      <p className="fs-16 ion-text-center ion-margin-top">No Data Found !</p>
    </IonCard>
  ) : (
    <>
      <IonList className="pt-0">
        {usersList?.map((user, i) => {
          return (
            <NewUserCard
              key={i}
              addToFavorite={addToFavorite}
              sendInterest={sendInterest}
              user={user}
              i={i}
              CurrentPackageBasic={CurrentPackageBasic}
              CurrentPackageVip={CurrentPackageVip}
              CurrentPackageSpecial={CurrentPackageSpecial}
              history={history}
              sendPhotoRequest={sendPhotoRequest}
              onAction={() => {
                setUserID(user?.id);
              }}
              isOpenDetailModal={() => {
                setOpenDetail(true);
              }}
            />
          );
        })}
      </IonList>
      <Details
        isOpenDetail={isOpenDetail}
        CurrentPackageBasic={CurrentPackageBasic}
        CurrentPackageSpecial={CurrentPackageSpecial}
        CurrentPackageVip={CurrentPackageVip}
        handleProfileNavigation={handleProfileNavigation}
        setOpenDetail={setOpenDetail}
        id={userID}
      />

      <IonRow style={{ margin: "0px 0px 20px 0px" }}>
        <IonCol>
          <div className="hr-border-red" style={{ height: 1.5 }}></div>
          <div className="hr-border-yellow" style={{ height: 1.5 }}></div>
          <div className="hr-border-green" style={{ height: 1.5 }}></div>
          <div className="hr-border-blue" style={{ height: 1.5 }}></div>
          <div className="hr-text" style={{ marginTop: -27, width: "90px" }}>
            <IonButton
              size="small"
              style={{ fontSize: 13, fontWeight: 600 }}
              onClick={() => {
                history.push("/app/search");
              }}
            >
              View All
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="p-2 d-flex">
        <IonCol>
          <IonItem
            onClick={() => {
              history.push("/app/terms");
            }}
            className="text-center"
            style={{ fontSize: "0.8em", color: "#f35c17" }}
          >
            Terms & Conditions
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem
            onClick={() => {
              history.push("/app/privacy-policy");
            }}
            className="text-center"
            style={{ fontSize: "0.8em", color: "#f35c17" }}
          >
            Privacy Policy
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem
            onClick={() => {
              history.push("/app/refund-policy");
            }}
            className="text-center"
            style={{ fontSize: "0.8em", color: "#f35c17" }}
          >
            Refund & Cancellation Policy
          </IonItem>
        </IonCol>
      </IonRow>
      <div className="temp">
        <div className="otpSection">
          <div className="ion-text-center">
            <div className="d-flex justify-content-center mt-4">
              <h3 style={{ color: "#fff" }} className="typewriter">
                <b>
                  <img
                    loading="lazy"
                    src={support}
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "5px",
                    }}
                  ></img>
                  Need help ?
                </b>
              </h3>
            </div>
            <h3 style={{ color: "#fff", margin: "15px 0px 15px 10px" }}>
              <IonIcon icon={callSharp} color="light" />
              <span style={{ margin: "0px 8px" }}>+91</span>-
              <span
                onClick={async () => {
                  await CallNumber.callNumber(
                    AppContext?.user?.payment_verified_at ? after : before,
                    true
                  );
                }}
              >
                {AppContext?.user?.payment_verified_at ? after : before}
              </span>
            </h3>
            <IonRow className="d-flex m-0 p-0 justify-content-center">
              <p className="text-white m-0">
                {AppContext?.user?.payment_verified_at
                  ? "(11:00 Am to 7:00 Pm)"
                  : ""}
              </p>
            </IonRow>
            <IonRow>
              <IonCol className="footerbtn-1" size="6">
                <IonButton
                  onClick={async () => {
                    await CallNumber.callNumber(
                      AppContext?.user?.payment_verified_at ? after : before,
                      true
                    );
                  }}
                >
                  <IonIcon className="me-1" icon={callSharp} color="light" />
                  Call Now
                </IonButton>
              </IonCol>
              <IonCol className="footerbtn-2" size="6">
                <IonButton onClick={handleWhatsAppClick}>
                  <IonIcon className="me-1" icon={logoWhatsapp} color="light" />
                  Whatsapp Now
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
          {/* <div className='ion-text-center'>
                            <img loading="lazy" src={support} style={{ width: "50px", margin: "20px auto 10px auto" }}></img>
                            <h3 style={{ color: "#fff" }} className='typewriter'><b>How can we help you?</b></h3>
                            <h3 style={{ color: "#fff", margin: "30px 0px 0px 10px" }}>
                                <IonIcon icon={callSharp} color="light" />
                                <span style={{ margin: "0px 8px" }}>+91</span>-<span
                                    onClick={async () => {
                                        await CallNumber.callNumber(AppContext?.user?.payment_verified_at ? after : before, true);
                                    }}>{AppContext?.user?.payment_verified_at ? after : before}</span></h3>
                        </div> */}
        </div>
      </div>
      {/* <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                        getUserlist(pageNumber);
                        setTimeout(() => ev.target.complete(), 500);
                    }}
                >
                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll> */}
    </>
  );
}
export default DashboardUserList;
