import React from "react";
import insta from "../../assets/images/insta.png";
import fb from "../../assets/images/fb.png";
import snap from "../../assets/images/snap.png";
import ytube from "../../assets/images/Ytube.png";
import ws from "../../assets/images/Wp.png";
import icon from "../../assets/icon.png";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import play from "../../assets/images/google-play 1.png";
import { Button, Col, Container, Row } from "reactstrap";
const Footer = () => {
  return (
    <div className="row py-3 justify-content-center">
      <div
        className={`col-lg-3 col-md-5 col-sm-12 p-0 footercenter ${
          window.innerWidth <= 1025 ? "ps-3" : "ps-5"
        }`}
      >
        <a href="">
          {" "}
          <img src={icon} height="50" alt="HSM" className="pe-2" />
          <span className="fs-20 primaryColor fw-500 my-2">
            Hindu Shadi Connect
          </span>
        </a>
        <p className="m-0 fs-16">
          <a href="tel:+9009992462"> +91-9009992462</a>
        </p>
        <p className="m-0 fs-16">
          <a href="tel:+9009992464"> +91-9009992464</a>
        </p>
        <p className="m-0 fs-16">
          <a href="mailto:info@hindushadiconnect.com">
            iinfo@hindushadiconnect.com
          </a>
        </p>
        <p className="m-0 fs-16">
          Vishishta Building, Infront of Agrawal School,
        </p>
        <p className="mb-3 fs-16">Damoh, Madhya Pradesh - 470661</p>
        <div>
          <a href="https://www.instagram.com/hindu.shadi.connect/">
            {" "}
            <img src={insta} alt="" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61557317483628">
            {" "}
            <img src={fb} alt="" />
          </a>
          <a href=" https://www.youtube.com/channel/UCcY96r5QO676gtqbowb4XHw">
            <img src={ytube} alt="" />
          </a>
          <a href="https://wa.me/9009992464">
            {" "}
            <img src={ws} alt="" />
          </a>
        </div>
      </div>
      <div
        className={`col-lg-3 col-md-4 col-sm-12 ${
          window.innerWidth <= 767 ? "text-center" : ""
        }`}
      >
        <p className="fs-20 primaryColor fw-500 my-2">Privacy & Help</p>
        <Link to="/terms">
          <p className="fs-16">Terms & Conditions</p>
        </Link>
        <p className="fs-16">Registration Fees</p>
        <Link to="/refund-policy">
          <p className="fs-16">Refund & Cancellation Policy</p>
        </Link>
      </div>
      <div
        className={`col-lg-2 col-md-6 col-sm-12 ${
          window.innerWidth <= 767 ? "text-center" : ""
        }`}
      >
        <p className="fs-20 primaryColor fw-500 my-2">Help</p>
        <Link to="/privacy-policy">
          <p className="fs-16">Privacy Policy</p>
        </Link>
      </div>
      <hr />
      <p
        className={`ps-5 m-0 ${window.innerWidth <= 767 ? "text-center" : ""}`}
        style={{ fontSize: "12px" }}
      >
        All rights reserved©2024{" "}
        <a href="https://hindushadiconnect.com/">hindushadiconnect.com</a> |
        This app is strictly for matrimonial purpose only.
      </p>
    </div>
  );
};

export default Footer;
