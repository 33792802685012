import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonContent,
  IonImg,
  IonModal,
  IonPage,
  IonSkeletonText,
  IonSlides,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon, IonSlide } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import {
  arrowBackOutline,
  callSharp,
  checkmarkCircleSharp,
  documentSharp,
  download,
  downloadOutline,
  downloadSharp,
  heart,
  locationOutline,
  lockClosedSharp,
  lockOpenSharp,
  send,
} from "ionicons/icons";
import {
  addToFavoriteList,
  getProfileById,
  getProfileByIdPublic,
  sendInterestRequest,
  unlock,
} from "../../service";
import blank_user from "../../assets/images/blank_user.png";
import Packages from "./Packages";
import Lightbox from "react-image-lightbox";
import subscribe from "../../assets/images/subscribe.png";
import publish from "../../assets/images/publish.png";
import moment from "moment";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import PopUp from "./PopUp";
import GoLive from "./GoLive";
import "react-image-lightbox/style.css";
import { useHistory, useParams } from "react-router";
import { AppAvailability } from "@awesome-cordova-plugins/app-availability";

function LessDetail() {
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const AppContext = useContext(appContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [open, setOpen] = useState<any>(false);
  const [openImage, setOpenImage] = useState<any>(false);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [showDownload, setShowDownload] = useState<any>(false);
  const [photoIndex, setPhotoIndex] = useState<any>(0);
  const [images, setImages] = useState<any>([]);
  const [accordionOpen, setAccordionOpen] = useState<any>([]);
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const { id } = useParams<any>();
  const history = useHistory();

  console.log(id);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
  };

  const slideOpts2 = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
  };

  useEffect(() => {
    checkAppAvailability();
    if (id) getProfileByIDTemp(id);
    setAccordionOpen(["first", "second", "third", "four", "five", "six"]);
    setOpenImage(false);
    setOpen(false);
  }, []);

  const checkAppAvailability = async () => {
    var res: any = await AppAvailability.check("com.jsm.digital");
    if (res === "OK") {
      setShowDownload(true);
    }
  };

  const getProfileByIDTemp = async (id: any) => {
    setLoading(true);
    var res = await getProfileByIdPublic({ id: id });
    if (res?.data?.status) {
      setLoading(false);
      setData(res?.data?.data);
    }
    // res?.data?.data?.profiles?.forEach((profile: any) => {
    //     if (profile?.type === "birth") {
    //         var arr = [];
    //         arr.push(`${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`);
    //         setImages(arr);
    //     }
    // });
    // res?.data?.data?.profiles?.forEach((profile: any) => {
    //     if (profile?.type === "birth") {
    //         var arr = [];
    //         arr.push(`${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`);
    //         setImages(arr);
    //     }
    // })
  };

  const addToFavorite = async (selectedUser: any) => {
    setData({ ...data, favorite: !data?.favorite });
    await addToFavoriteList(selectedUser);
  };

  const sendInterest = async (selectedUser: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      setData({ ...data, interest: !data?.interest });
      present({
        message: "The interest has been sent successfully.",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  const goTo = async () => {
    AppContext.setPopups(constant.popups.isSubscribe, true, true);
    setOpenPopUp(false);
    localStorage.setItem("activeUser", id);
  };

  const handleClick = async () => {
    presentAlert({
      header:
        "For view complete user details and using our more feature , Login or Register to our JSM.",
      cssClass: "actionAlert",
      buttons: [
        {
          text: "Sign Up",
          role: "confirm",
          handler: async () => {
            AppContext.setUserStatus({
              ...AppContext?.userStatus,
              form: constant.SignUp,
            });
            history.push("/signin");
          },
        },
        {
          text: "Sign In",
          role: "confirm",
          handler: async () => {
            AppContext.setUserStatus({
              ...AppContext?.userStatus,
              form: constant.SignIn,
            });
            history.push("/signin");
          },
        },
      ],
    });
  };

  const LockUI = () => {
    return (
      <>
        <div
          onClick={(event) => {
            handleClick();
          }}
        >
          <IonIcon className="fs-22 " color="primary" icon={lockClosedSharp} />
        </div>
      </>
    );
  };

  return (
    <IonPage>
      {/* <Packages /> */}
      <IonContent>
        <div className="detailBody">
          {!showDownload && (
            <div className="downloadBtn">
              <button
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.jsm.digital",
                    "_blank"
                  );
                }}
                className="blinkDownloadBtn mt-3"
              >
                <IonIcon
                  icon={downloadOutline}
                  color="light"
                  style={{ verticalAlign: "sub", fontSize: "22px" }}
                  className="me-1"
                />
                Download App Now
              </button>
            </div>
          )}

          {/* Slide images */}
          <div>
            {loading && (
              <IonSlides
                pager={true}
                options={slideOpts2}
                className="detailSlidesMain"
              >
                {[...Array(3)].map((i) => {
                  return (
                    <>
                      {" "}
                      <IonSlide>
                        <img
                          loading="lazy"
                          src={blank_user}
                          style={{
                            minHeight: 350,
                            maxHeight: 350,
                            objectFit: "cover",
                            width: "100%",
                          }}
                        ></img>
                      </IonSlide>
                    </>
                  );
                })}
              </IonSlides>
            )}
          </div>
          <div>
            {!loading && (
              <IonSlides options={slideOpts} className="detailSlidesMain">
                {(data?.profiles?.length === 0 ||
                  (data?.profiles?.length === 1 &&
                    data?.profiles[0]?.type !== "profile")) && (
                  <div className="detailSlides">
                    <img
                      loading="lazy"
                      src={blank_user}
                      style={{
                        minHeight: 350,
                        maxHeight: 350,
                        objectFit: "cover",
                        width: "100%",
                      }}
                    ></img>
                    <IonLabel
                      style={{
                        margin: 0,
                        position: "absolute",
                        bottom: 15,
                        left: 18,
                        fontWeight: "bolder",
                        color: "#fff",
                        textAlign: "left",
                      }}
                    >
                      <p
                        className="text-light font-weight-500 m-0"
                        style={{ fontSize: 11, lineHeight: "12px" }}
                      >
                        <b>JSM-{data?.id}</b>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "17px",
                          lineHeight: "28px",
                          textTransform: "capitalize",
                        }}
                      >
                        <b className="ellispses">{data.nameEnglish} </b>
                        &nbsp;|&nbsp;
                        <b>
                          {" "}
                          {data._age !== null
                            ? data._age
                            : moment().diff(data?.dob, "years")}{" "}
                          Yrs
                        </b>
                      </div>
                      <p
                        className="text-light dataText mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {data?.third_formSectEnglish}{" "}
                        {data?.first_formMaritalStatusEnglish &&
                          data?.third_formSectEnglish && <span>| </span>}
                        {data?.first_formMaritalStatusEnglish}
                      </p>
                      <p
                        className="text-light dataText mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        <IonIcon
                          icon={locationOutline}
                          color="light"
                          style={{ verticalAlign: "sub" }}
                          className="me-1"
                        />
                        {data?.second_formCityEnglish}{" "}
                        {data?.second_formStateEnglish &&
                          data?.second_formStateEnglish && <span>| </span>}
                        {data?.second_formStateEnglish}
                      </p>
                    </IonLabel>
                  </div>
                )}
                {data?.profiles?.length === 1 &&
                  data?.profiles[0]?.type === "profile" && (
                    <div
                      className="detailSlides"
                      onClick={() => {
                        var arr: any = [];
                        data?.profiles?.map((profile: any) => {
                          if (profile?.type === "profile") {
                            arr.push(
                              `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                            );
                            setImages(arr);
                          }
                        });
                        setOpenImage(true);
                      }}
                    >
                      <img
                        loading="lazy"
                        src={`${constant.ImageS3URl}/${data?.profiles[0]?.folder}/${data?.profiles[0]?.name}`}
                        style={{
                          minHeight: 350,
                          maxHeight: 350,
                          objectFit: "cover",
                          width: "100%",
                        }}
                      ></img>
                      <IonLabel
                        style={{
                          margin: 0,
                          position: "absolute",
                          bottom: 15,
                          left: 18,
                          fontWeight: "bolder",
                          color: "#fff",
                          textAlign: "left",
                        }}
                      >
                        <p
                          className="text-light font-weight-500 m-0"
                          style={{ fontSize: 11, lineHeight: "12px" }}
                        >
                          <b>JSM-{data?.id}</b>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            fontSize: "17px",
                            lineHeight: "28px",
                            textTransform: "capitalize",
                          }}
                        >
                          <b className="ellispses">{data.nameEnglish} </b>
                          &nbsp;|&nbsp;
                          <b>
                            {" "}
                            {data._age !== null
                              ? data._age
                              : moment().diff(data?.dob, "years")}{" "}
                            Yrs
                          </b>
                        </div>
                        <p
                          className="text-light dataText mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.third_formSectEnglish}{" "}
                          {data?.first_formMaritalStatusEnglish &&
                            data?.third_formSectEnglish && <span>| </span>}
                          {data?.first_formMaritalStatusEnglish}
                        </p>
                        <p
                          className="text-light dataText mb-0"
                          style={{ fontSize: "12px" }}
                        >
                          <IonIcon
                            icon={locationOutline}
                            color="light"
                            style={{ verticalAlign: "sub" }}
                            className="me-1"
                          />
                          {data?.second_formCityEnglish}{" "}
                          {data?.second_formStateEnglish &&
                            data?.second_formStateEnglish && <span>| </span>}
                          {data?.second_formStateEnglish}
                        </p>
                      </IonLabel>
                    </div>
                  )}
                {data?.profiles?.length > 1 &&
                  data?.profiles?.map((profile: any) => {
                    return (
                      profile?.type === "profile" && (
                        <>
                          <IonSlide
                            className="detailSlides"
                            onClick={() => {
                              var arr: any = [];
                              data?.profiles?.map((profile: any) => {
                                if (profile?.type === "profile") {
                                  arr.push(
                                    `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                                  );
                                  setImages(arr);
                                }
                              });
                              setOpenImage(true);
                            }}
                          >
                            <img
                              loading="lazy"
                              src={`${constant.ImageS3URl}/${profile.folder}/${profile.name}`}
                              style={{
                                minHeight: 350,
                                maxHeight: 350,
                                objectFit: "cover",
                                width: "100%",
                              }}
                            ></img>
                            <IonLabel
                              style={{
                                margin: 0,
                                position: "absolute",
                                bottom: 13,
                                left: 18,
                                fontWeight: "bolder",
                                color: "#fff",
                                textAlign: "left",
                              }}
                            >
                              <p
                                className="text-light font-weight-500 m-0"
                                style={{ fontSize: 11, lineHeight: "12px" }}
                              >
                                <b>JSM-{data?.id}</b>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "17px",
                                  lineHeight: "24px",
                                  textTransform: "capitalize",
                                }}
                              >
                                <b className="ellispses">{data.nameEnglish} </b>
                                &nbsp;|&nbsp;
                                <b>
                                  {" "}
                                  {data._age !== null
                                    ? data._age
                                    : moment().diff(data?.dob, "years")}{" "}
                                  Yrs
                                </b>
                              </div>
                              <p
                                className="text-light dataText mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                {data?.third_formSectEnglish}{" "}
                                {data?.first_formMaritalStatusEnglish &&
                                  data?.third_formSectEnglish && (
                                    <span>| </span>
                                  )}
                                {data?.first_formMaritalStatusEnglish}
                              </p>
                              <p
                                className="text-light dataText mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                <IonIcon
                                  icon={locationOutline}
                                  color="light"
                                  style={{ verticalAlign: "sub" }}
                                  className="me-1"
                                />
                                {data?.second_formCityEnglish}{" "}
                                {data?.second_formStateEnglish &&
                                  data?.second_formStateEnglish && (
                                    <span>| </span>
                                  )}
                                {data?.second_formStateEnglish}
                              </p>
                            </IonLabel>
                          </IonSlide>
                        </>
                      )
                    );
                  })}
                {openImage && (
                  <Lightbox
                    clickOutsideToClose={false}
                    imageTitle="Profiles"
                    imageLoadErrorMessage="This image failed to load"
                    onImageLoad={() => {
                      window.dispatchEvent(new Event("resize"));
                    }}
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setOpenImage(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
              </IonSlides>
            )}
          </div>

          {/* 3 button on top */}
          {loading ? (
            <IonCard className="bg-none mb-0 mt-1">
              {AppContext?.user?.id !== id && (
                <IonRow
                  style={{ alignItems: "center" }}
                  className="detailBtnGrp mt-2"
                >
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#f2f2f2" }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={documentSharp}
                          style={{ color: "#f2f2f2" }}
                        ></IonIcon>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#f2f2f2 " }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={documentSharp}
                          style={{ color: "#f2f2f2" }}
                        ></IonIcon>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#f2f2f2" }}
                      >
                        <IonIcon
                          slot="icon-only"
                          className="white m-0"
                          icon={send}
                          style={{ color: "#f2f2f2 " }}
                        ></IonIcon>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              )}
            </IonCard>
          ) : (
            <IonCard className="bg-none mb-0 mt-1">
              {AppContext?.user?.id !== id && (
                <IonRow
                  style={{ alignItems: "center" }}
                  className="detailBtnGrp mt-2"
                >
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#03852c66" }}
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={callSharp}
                          style={{ color: "#03852c", verticalAlign: "middle" }}
                        ></IonIcon>
                      </div>
                      <p style={{ color: "#03852c" }}>Call Now</p>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#3655e452 " }}
                        onClick={(event) => {
                          handleClick();
                        }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={documentSharp}
                          style={{ color: "#3655e4", verticalAlign: "middle" }}
                        ></IonIcon>
                      </div>
                      <p style={{ color: "#3655e4" }}>View Biodata</p>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="ion-text-center">
                      <div
                        className="btnICon"
                        style={{ background: "#e405014f" }}
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        <IonIcon
                          slot="icon-only"
                          className="white m-0"
                          icon={send}
                          style={{ color: "#e40501 ", verticalAlign: "middle" }}
                        ></IonIcon>
                      </div>
                      <p style={{ color: "#e40501" }}>Send Interest</p>
                    </div>
                  </IonCol>
                </IonRow>
              )}
            </IonCard>
          )}

          {/* surbhi jain test */}
          <IonAccordionGroup
            expand="inset"
            ref={accordionGroup}
            value={accordionOpen}
            multiple={true}
          >
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Basic Details
                </IonLabel>
              </IonItem>
              <div className="ion-padding datas" slot="content">
                <IonRow className="ion-align-items-center">
                  <IonCol>
                    <h6 className="headText mb-0">Created For</h6>
                    <p className="dataText mb-0">
                      {data?.first_formProfileEnglish || "-"}
                    </p>
                  </IonCol>
                  <IonCol>
                    <h6 className="headText mb-0">Height</h6>
                    <p className="dataText mb-0">
                      {data?.second_formHeightFeet ||
                        "-" +
                          " Ft. (" +
                          (data?.second_formHeightCm || "-") +
                          " CM)"}
                    </p>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-center">
                  <IonCol>
                    <h6 className="headText mb-0">Physical Status</h6>
                    <p className="dataText mb-0">
                      {data?.second_formPhysicalStatusEnglish || "-"}
                    </p>
                  </IonCol>
                  <IonCol>
                    <h6 className="headText mb-0">Mother tongue</h6>
                    <p className="dataText mb-0">
                      {data?.second_formMotherTongueEnglish || "-"}
                    </p>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-center">
                  <IonCol>
                    <h6 className="headText mb-0">Eating habits</h6>
                    <p className="dataText mb-0">
                      {data?.second_formEatingHabitsEnglish || "-"}
                    </p>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="second">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Professional Information
                </IonLabel>
              </IonItem>
              <div
                className="ion-padding datas"
                slot="content"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IonRow className="ion-align-items-center p-0">
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Education</h6>
                      <p className="dataText mb-0">
                        {data?.fourth_formEducationEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Describe Education</h6>
                      <p className="dataText mb-0">
                        {data?.fourth_formDescribeEducationEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Employed In</h6>
                      <p className="dataText mb-0">
                        {data?.fourth_formEmployedEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">
                          Income of Applicant (Per Annum)
                        </h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.fourth_formIncomeOfApplicantEnglish || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.fourth_formIncomeOfApplicantEnglish || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Hobbies/Interest</h6>
                      <p className="dataText mb-0">
                        {data?.fourth_formHobbiesInterestWriteEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="third">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Contact Details
                </IonLabel>
              </IonItem>
              <div
                className="ion-padding datas"
                slot="content"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IonRow className="ion-align-items-center">
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">
                          Registered Mobile Number
                        </h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.mobile || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.mobile || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">
                          Alternate mobile numbers
                        </h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.second_formMobile || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.second_formMobile || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Home Town Address</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.second_formAddressEnglish || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.second_formAddressEnglish || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">
                          Candidate Current Address
                        </h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.second_formLivingAddressEnglish || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.second_formLivingAddressEnglish || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="four">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Religious Information
                </IonLabel>
              </IonItem>
              <div
                className="ion-padding datas"
                slot="content"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IonRow className="ion-align-items-center">
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Self Sect</h6>
                      <p className="dataText mb-0">
                        {data?.third_formSectEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0"> Sub Sect</h6>
                      <p className="dataText mb-0">
                        {data?.third_formSubSectEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0"> Gotra</h6>
                      <p className="dataText mb-0">
                        {data?.third_formGotraEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="five">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Horoscope Details
                </IonLabel>
              </IonItem>
              <div
                className="ion-padding datas"
                slot="content"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IonRow className="ion-align-items-center">
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Raashi</h6>
                      <p className="dataText mb-0">
                        {data?.third_formRaashiEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Star/ Nakshatra</h6>
                      <p className="dataText mb-0">
                        {data?.third_formSubRaashiEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0">Manglik</h6>
                      <p className="dataText mb-0">
                        {data?.third_formDoshEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Birth Date</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.dob || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.dob || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Birth Time</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.third_formBirthdetailsDate || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.third_formBirthdetailsDate || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Birth Place</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.third_formBirthdetailsDescriptionEnglish ||
                              "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.third_formBirthdetailsDescriptionEnglish ||
                              "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Bio Data Image</h6>
                      </div>
                    </div>
                    {AppContext?.user?.id !== id ? (
                      data?.unlock && AppContext?.user?.payment_verified_at ? (
                        <div>
                          <IonButton
                            slot="end"
                            type="button"
                            color="primary"
                            fill="outline"
                            onClick={() => {
                              var arr: any = [];
                              data?.profiles?.map((profile: any) => {
                                if (profile?.type === "birth") {
                                  arr.push(
                                    `${constant.ImageS3URl}/${profile?.folder}/${profile?.name}`
                                  );
                                  setImages(arr);
                                }
                              });
                              setOpen(true);
                            }}
                          >
                            View
                          </IonButton>
                        </div>
                      ) : (
                        LockUI()
                      )
                    ) : null}
                    {open && (
                      <Lightbox
                        imageTitle="Biodata"
                        imageLoadErrorMessage="This image failed to load"
                        onImageLoad={() => {
                          window.dispatchEvent(new Event("resize"));
                        }}
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                          images[
                            (photoIndex + images.length - 1) % images.length
                          ]
                        }
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % images.length)
                        }
                      />
                    )}
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="six">
              <IonItem slot="header">
                <IonLabel className="accordion-expanding">
                  Family Information
                </IonLabel>
              </IonItem>
              <div
                className="ion-padding datas"
                slot="content"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <IonRow className="ion-align-items-center">
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">
                          Total no of family members
                        </h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.fifth_formFamilyMember || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.fifth_formFamilyMember || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Father's Detail</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? `${data?.fifth_formFatherNameEnglish || "-"} / ${
                                data?.fifth_formFatherContactNumberMobile || "-"
                              } / ${
                                data?.fifth_formFatherEmployedInEnglish || "-"
                              }`
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? `${data?.fifth_formFatherNameEnglish || "-"} / ${
                                data?.fifth_formFatherContactNumberMobile || "-"
                              } / ${
                                data?.fifth_formFatherEmployedInEnglish || "-"
                              }`
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Mother's Detail</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? `${data?.fifth_formMotherNameEnglish || "-"} / ${
                                data?.fifth_formMotherContactNumberMobile || "-"
                              } / ${
                                data?.fifth_formMotherEmployedInEnglish || "-"
                              }`
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? `${
                                data?.fifth_formMotherNameInEnglish || "-"
                              } / ${
                                data?.fifth_formMotherContactNumberMobile || "-"
                              } / ${
                                data?.fifth_formMotherEmployedInEnglish || "-"
                              }`
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0">Family type</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.fifth_formFamilyTypeEnglish || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.fifth_formFamilyTypeEnglish || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol size="12" className="displayProp my-1 ">
                    <div className="displayPropAround ion-align-items-start">
                      <div>
                        <IonIcon
                          className="fs-22 me-3"
                          color="primary"
                          icon={checkmarkCircleSharp}
                        />
                      </div>
                      <div>
                        <h6 className="headText mb-0"> Residential status</h6>
                        <p className="dataText mb-0">
                          {AppContext?.user?.id === id
                            ? data?.fifth_formResidentalStatusEnglish || "-"
                            : AppContext?.user?.payment_verified_at &&
                              data?.unlock
                            ? data?.fifth_formResidentalStatusEnglish || "-"
                            : "****** ****** ****"}
                        </p>
                      </div>
                    </div>
                    {LockUI()}
                  </IonCol>
                  <IonCol
                    size="12"
                    className="displayPropAround my-1 ion-align-items-start"
                  >
                    <div>
                      <IonIcon
                        className="fs-22 me-3"
                        color="primary"
                        icon={checkmarkCircleSharp}
                      />
                    </div>
                    <div>
                      <h6 className="headText mb-0"> Family Status</h6>
                      <p className="dataText mb-0">
                        {data?.fifth_formFamilyStatusEnglish || "-"}
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
      </IonContent>
    </IonPage>
  );
}
export default LessDetail;
