import { useState, useContext, useEffect } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonCol, IonGrid, IonLabel, IonList, IonIcon, useIonAlert, IonRange, useIonToast, IonRow, } from '@ionic/react';
import { getGoliveFormDetails, getStateListAPI, signUp, } from '../../service';
import appContext from "../../context/AppContext"
import { closeOutline, } from 'ionicons/icons';
import { useForm } from "react-hook-form";
import CustomMultiselect from '../shared/CustomMultiselect';
import constant from '../../constant';
import CustomSelect from '../shared/CustomSelect';
import { toast } from 'react-toastify';

function SixthForm() {

    const AppContext = useContext(appContext);
    const [stateList, setStateList]: any = useState(null);
    const [formDetail, setFormDetail] = useState<any>(null);
    const [employedInOption, setEmployedInOption] = useState<any>([]);
    const [presentAlert] = useIonAlert();
    const [filter, setFilter]: any = useState({});

    const {
        handleSubmit,
        setValue,
        formState: { touchedFields },
        control,
        watch,
        getValues,
        reset
    }: any = useForm({
        defaultValues: {
            partner_preferenceStateEnglish: [],
            partner_preferenceSectEnglish: [],
            partner_preferenceMaritalStatusEnglish: [],
            partner_preferenceEmployedInEnglish: [],
            partner_preferenceManglikStatusEnglish: [],
            partner_preferenceCandidateIncomeEnglish: []
        }
    });

    const getStateList = async () => {
        let stateList: any = await getStateListAPI({ countryId: "India" });
        const allOption = { english: "All" };
        const modifiedStateList = [allOption, ...stateList.data.data];

        setStateList(modifiedStateList);
    }

    const onSubmit = async (data: any) => {
        Object.keys(data)?.forEach((i: any) => {
            if (data?.[i]?.[0]?.value) {
                var find = 0;
                data[i] = data[i]?.map((j: any) => { return j?.value });
                // data[i]?.map((j: any) => {
                //     if (j === "All") find++;
                //     return 0;
                // });
                if (find > 0) data[i] = []
                return 0;
            }
        });
        if (data?.partner_preferenceStateEnglish?.includes("All")) {
            const allStates = stateList
                .filter((state: any) => state.english !== "All")
                .map((state: any) => state.english);
            data.partner_preferenceStateEnglish = allStates;
        }
        if (data?.partner_preferenceSectEnglish?.includes("All")) {
            const allOption = [{ label: "All", value: "All" }, { label: "Digambar", value: "Digambar" }, { label: "Shwetambar", value: "Shwetambar" }]
                .filter((val: any) => val.value !== "All")
                .map((val: any) => val.value);
            data.partner_preferenceSectEnglish = allOption;
        }
        if (data?.partner_preferenceMaritalStatusEnglish?.includes("All")) {
            const allOption = [{ label: "All", value: "All" }, { label: "Unmarried", value: "Unmarried" }, { label: "Widow/Widower", value: "Widow/Widower" }, { label: "Divorced", value: "Divorced" }, { label: "Awaiting divorce", value: "Awaiting divorce" }]
                .filter((val: any) => val.value !== "All")
                .map((val: any) => val.value);
            data.partner_preferenceMaritalStatusEnglish = allOption;
        }
        if (data?.partner_preferenceEmployedInEnglish?.includes("All")) {
            const allOption = employedInOption
                .filter((val: any) => val.value !== "All")
                .map((val: any) => val.value);
            data.partner_preferenceEmployedInEnglish = allOption;
        }
        if (data?.partner_preferenceManglikStatusEnglish?.includes("All")) {
            const allOption = [{ label: "All", value: "All" }, { label: "YES", value: "Yes" }, { label: "No", value: "NO" }, { label: "Don't Know", value: "DONT KNOW" }, { label: "Anshik Mangal", value: "ANSHIK" }]
                .filter((val: any) => val.value !== "All")
                .map((val: any) => val.value);
            data.partner_preferenceManglikStatusEnglish = allOption;
        }
        if (data?.partner_preferenceCandidateIncomeEnglish?.includes("All")) {
            const allOption = [{ label: "All", value: "All" }].concat(formDetail?.incomeList?.english)
                .filter((val: any) => val.value !== "All")
                .map((val: any) => val.value);
            data.partner_preferenceCandidateIncomeEnglish = allOption;
        }
        AppContext.setLoading(true);
        data.partner_preferenceAgeStart = data?.partner_preferenceAgeStart?.value;
        data.partner_preferenceAgeEnd = data?.partner_preferenceAgeEnd?.value;
        var response = await signUp(data);
        if (response?.data?.status) {
            toast("Partner Preference Saved Successfully");
            AppContext.setLoading(false);
            AppContext.updateUser();
            AppContext.setPopups(constant.popups.isPartnerPreference, false)
        }
        else {
            toast(response?.data?.errors);
        }
    };

    const onReject = (modalState: Boolean) => {
        reset();
        AppContext.setPopups(constant.popups.isPartnerPreference, modalState)
        AppContext.setDoItLater();
    }

    const getFormDetais = async () => {
        var res: any = await getGoliveFormDetails();
        if (res?.data?.status) {
            setFormDetail(res?.data?.data);
        }
        var arr: any = [];
        const employedInData = res?.data?.data?.employedIn;

        if (employedInData && employedInData.english) {
            const filteredEmployedIn = employedInData.english.filter((i: any) => {
                return i.value !== 'Passed away' && i.value !== 'Retired';
            });

            const allOption = { label: "All", value: "All" };
            const modifiedEmployedInList = [allOption, ...filteredEmployedIn];

            setEmployedInOption(modifiedEmployedInList);
        }
    }

    const ageArray = [];
    for (let index = 18; index < 81; index++) {
        ageArray.push({ label: index, value: index });
    }

    const onDidDismiss = async () => {
        AppContext.setPopups(constant.popups.isPartnerPreference, false)
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }
    const onWillDismiss = async () => {
        AppContext.setPopups(constant.popups.isPartnerPreference, false)
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    const onDidPresent = () => {
        AppContext?.setPopupClose(false)
        localStorage.setItem('isModal', 'true');
        if (AppContext?.user?.partner_preferenceAgeStart > 0) setValue("partner_preferenceAgeStart", AppContext.user.partner_preferenceAgeStart)
        if (AppContext?.user?.partner_preferenceAgeEnd > 0) setValue("partner_preferenceAgeEnd", AppContext.user.partner_preferenceAgeEnd)
        if (AppContext?.user?.partner_preferenceStateEnglish?.length > 0) setValue("partner_preferenceStateEnglish", AppContext.user.partner_preferenceStateEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        if (AppContext?.user?.partner_preferenceSectEnglish?.length > 0) setValue("partner_preferenceSectEnglish", AppContext.user.partner_preferenceSectEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        if (AppContext?.user?.partner_preferenceMaritalStatusEnglish?.length > 0) setValue("partner_preferenceMaritalStatusEnglish", AppContext.user.partner_preferenceMaritalStatusEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        if (AppContext?.user?.partner_preferenceEmployedInEnglish?.length > 0) setValue("partner_preferenceEmployedInEnglish", AppContext.user.partner_preferenceEmployedInEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        if (AppContext?.user?.partner_preferenceManglikStatusEnglish?.length > 0) setValue("partner_preferenceManglikStatusEnglish", AppContext.user.partner_preferenceManglikStatusEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        if (AppContext?.user?.partner_preferenceCandidateIncomeEnglish?.length > 0) setValue("partner_preferenceCandidateIncomeEnglish", AppContext.user.partner_preferenceCandidateIncomeEnglish?.map((val: any, i: any) => { return { label: val, value: val } }))
        getStateList();
        getFormDetais();
    }

    return (
        <IonContent>
            <IonModal isOpen={AppContext.popups[constant.popups.isPartnerPreference]} className='GoliveBody'
                onDidDismiss={onDidDismiss}
                onWillDismiss={onWillDismiss}
                onDidPresent={onDidPresent}
                onWillPresent={() => { localStorage.setItem('isModal', 'true'); }}
            >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle className='heading text-white'>Partner Preference </IonTitle>
                        <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={(event: any) => {
                                // event.stopPropagation(); setFourthForm(false)
                                Object.keys(touchedFields).length
                                    ? presentAlert({
                                        header: 'Would you like to save changes?',
                                        cssClass: 'actionAlert',
                                        buttons: [
                                            {
                                                text: 'Yes', role: 'confirm',
                                                handler: handleSubmit(onSubmit),
                                            },
                                            {
                                                text: 'no', role: 'cancel', handler: () => {
                                                    onReject(false);
                                                }
                                            },
                                        ],
                                    })
                                    : onReject(false)
                            }}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <IonList className='golive '>
                    {formDetail && <form className='mt-2 ' onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: "30%" }}>
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem' >Age (In Year)</IonLabel>
                            <IonRow>
                                <IonCol>
                                    <CustomSelect isSearchable={true}
                                        options={ageArray}
                                        control={control}
                                        required={true}
                                        name={"partner_preferenceAgeStart"}
                                        placeholder={"Age From"}
                                    />
                                </IonCol>
                                <IonCol>
                                    <CustomSelect isSearchable={true}
                                        options={ageArray}
                                        control={control}
                                        required={true}
                                        name={"partner_preferenceAgeEnd"}
                                        placeholder={"Age To"}
                                    /></IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid className=' ion-margin-bottom'>
                            <IonLabel position="stacked" className='fs-16 labelItem' >State</IonLabel>
                            {stateList && <CustomMultiselect
                                setValue={setValue}
                                options={stateList?.map((i: any) => {
                                    return { label: i?.english, value: i?.english };
                                })}
                                control={control}
                                required={false}
                                name={"partner_preferenceStateEnglish"}
                                placeholder={"All States"}
                                isSearchable={true}
                            />}
                        </IonGrid>
                        <IonGrid className='ion-margin-bottom'>
                            <IonLabel position="stacked" className='fs-16 labelItem' >Sect</IonLabel>
                            <CustomMultiselect
                                setValue={setValue}
                                options={[{ label: "All", value: "All" }, { label: "Digambar", value: "Digambar" }, { label: "Shwetambar", value: "Shwetambar" }]}
                                control={control}
                                required={false}
                                name={"partner_preferenceSectEnglish"}
                                placeholder={"All Sect"}
                                isSearchable={false}
                            // isMulti={true}
                            />
                        </IonGrid>
                        <IonGrid className='ion-margin-bottom'>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Marital status</IonLabel>
                            <CustomMultiselect
                                setValue={setValue}
                                options={[{ label: "All", value: "All" }, { label: "Unmarried", value: "Unmarried" }, { label: "Widow/Widower", value: "Widow/Widower" }, { label: "Divorced", value: "Divorced" }, { label: "Awaiting divorce", value: "Awaiting divorce" }]}
                                control={control}
                                required={false}
                                name={"partner_preferenceMaritalStatusEnglish"}
                                placeholder={"All Marital status"}
                                isSearchable={false}
                            />
                        </IonGrid>
                        <IonGrid className=' ion-margin-bottom'>
                            <IonLabel position="stacked" className='fs-16 labelItem' >Employed In</IonLabel>
                            <CustomMultiselect
                                setValue={setValue}
                                options={employedInOption}
                                control={control}
                                required={true}
                                name={"partner_preferenceEmployedInEnglish"}
                                placeholder={"Select Employed In"}
                                isSearchable={true}
                            />
                        </IonGrid>
                        <IonGrid className='ion-margin-bottom'>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Manglik Status</IonLabel>
                            <CustomMultiselect
                                setValue={setValue}
                                options={[{ label: "All", value: "All" }, { label: "YES", value: "Yes" }, { label: "No", value: "NO" }, { label: "Don't Know", value: "DONT KNOW" }, { label: "Anshik Mangal", value: "ANSHIK" }]}
                                control={control}
                                required={false}
                                name={"partner_preferenceManglikStatusEnglish"}
                                placeholder={"All manglik status"}
                                isSearchable={false}
                            />
                        </IonGrid>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Income<span className='errorText m-0' >*</span></IonLabel>
                            <CustomMultiselect
                                setValue={setValue}
                                options={[{ label: "All", value: "All" }].concat(formDetail?.incomeList?.english)}
                                control={control}
                                required={true}
                                name={"partner_preferenceCandidateIncomeEnglish"}
                                placeholder={"Select Income"}
                                isSearchable={false}
                            />
                        </IonGrid>
                        <IonCol class='btnFill  ion-margin-bottom'>
                            <IonButton type="submit" expand="block" >Save</IonButton>
                        </IonCol>
                    </form>
                    }
                </IonList>
            </IonModal>
        </IonContent >

    );
}

export default SixthForm;