import { useEffect, useState, useContext } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonIcon,
  useIonToast,
  useIonAlert,
  IonInput,
} from "@ionic/react";
import {
  addGotra,
  addSubSect,
  getGotraList,
  getHinduEducationsApi,
  getSubsect,
  signUp,
} from "../../service";
import appContext from "../../context/AppContext";
import { closeOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import CustomSelect from "../shared/CustomSelect";
import "react-multi-date-picker/styles/layouts/mobile.css";
import constant from "../../constant";
import { toast } from "react-toastify";

function FifthForm() {
  const [subsect, setSubsect] = useState<any>([]);
  const [gotraList, setGotraList] = useState<any>([]);
  const [mamaSubsect, setMamaSubsect] = useState<any>([]);
  const [mamGotraList, setMamaGotraList] = useState<any>([]);
  const [releteiveSubsect, setReleteiveSubsect] = useState<any>([]);
  const [releteiveSubsects, setReleteiveSubsects] = useState<any>([]);
  const [releteiveGotraList, setReleteiveGotraList] = useState<any>([]);
  const [sectOption, setSectOption] = useState<any>([]);
  const [releteiveGotraLists, setReleteiveGotraLists] = useState<any>([]);
  const [showWriteSubSect, setShowWriteSubSect] = useState<any>({
    show1: false,
    show2: false,
    show3: false,
    show4: false,
  });
  const [showWriteGotra, setShowWriteGotra] = useState<any>({
    show1: false,
    show2: false,
    show3: false,
    show4: false,
  });
  const [sectValue, setSectValue] = useState({
    candidate: "",
    mama: "",
    relative: "",
    other: "",
  });
  const [subSectId, setSubSectId] = useState({
    candidate: "",
    mama: "",
    relative: "",
    other: "",
  });
  const [writeSubSectValue, setWriteSubSectValue] = useState({
    candidate: "",
    mama: "",
    relative: "",
    other: "",
  });
  const [gotraValue, setGotraValue] = useState<any>({
    candidate: "",
    mama: "",
    relative: "",
    other: "",
  });
  const [createdSubsectId, setCreatedSubsectId] = useState("");
  const [subsectError, SectSubSectError] = useState<any>({
    candidate: false,
    mama: false,
    relative: false,
    other: false,
  });
  const AppContext = useContext(appContext);
  const [presentAlert] = useIonAlert();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    control,
    watch,
    reset,
    getValues,
  }: any = useForm();

  const onSubmit = async (values: any) => {
    var obj = {
      third_formSectEnglish: values?.third_formSectEnglish?.value,
      third_formSubSectEnglish: values?.third_formSubSectEnglish?.value
        ? values?.third_formSubSectEnglish?.value
        : writeSubSectValue?.candidate,
      third_formGotraEnglish: values?.third_formGotraEnglish?.value
        ? values?.third_formGotraEnglish?.value
        : gotraValue?.candidate,

      third_formMamaSectEnglish: values?.third_formMamaSectEnglish?.value || "",
      third_formMamaSubSectEnglish: values?.third_formMamaSubSectEnglish?.value
        ? values?.third_formMamaSubSectEnglish?.value
        : writeSubSectValue?.mama || "",
      third_formMamaGotraEnglish: values?.third_formMamaGotraEnglish?.value
        ? values?.third_formMamaGotraEnglish?.value
        : gotraValue?.mama || "",

      third_formReletiveSectStatus:
        values?.third_formReletiveSectStatus?.value || "",
      third_formReleteivesSubSectEnglish: values
        ?.third_formReleteivesSubSectEnglish?.value
        ? values?.third_formReleteivesSubSectEnglish?.value
        : writeSubSectValue?.relative || "",
      third_formReletivesGotraEnglish: values?.third_formReletivesGotraEnglish
        ?.value
        ? values?.third_formReletivesGotraEnglish?.value
        : gotraValue?.relative || "",

      third_formSecondReleteivesSect:
        values?.third_formSecondReleteivesSect?.value || "",
      third_formSecondReleteivesSubSectEnglish: values
        ?.third_formSecondReleteivesSubSectEnglish?.value
        ? values?.third_formSecondReleteivesSubSectEnglish?.value
        : writeSubSectValue?.other || "",
      third_formSecondReletivesGotraEnglish: values
        ?.third_formSecondReletivesGotraEnglish?.value
        ? values?.third_formSecondReletivesGotraEnglish?.value
        : gotraValue?.other || "",
    };
    AppContext.setLoading(true);
    var response = await signUp(obj);
    if (response?.data?.status) {
      toast("religious information Saved Successfully");
      AppContext.setLoading(false);
      AppContext.updateUser();
      AppContext.setPopups(constant.popups.isFifthForm, false);
    } else {
      toast(response?.data?.errors);
    }
  };

  const onReject = (modalState: Boolean) => {
    reset();
    AppContext.setPopups(constant.popups.isFifthForm, modalState);
    AppContext.setDoItLater();
  };

  // candidate
  const watchSect = watch(`third_formSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formSectEnglish`);
    setSectValue({ ...sectValue, candidate: w?.value });
    if (w) {
      getSubsecBysectName(w?.label);
    }
  }, [watchSect, getValues]);

  const getSubsecBysectName = async (name: any) => {
    let obj = {
      sect: name,
    };
    var res: any = await getSubsect(obj);
    if (res?.data?.status) {
      let sList = res?.data?.data?.subSect?.map((i: any) => {
        return { label: i.name_english, value: i.name_english, id: i.id };
      });
      setMamaSubsect(sList);
    }
  };
  const watchSubSect = watch(`third_formSubSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formSubSectEnglish`);
    setSubSectId({ ...subSectId, candidate: w?.id });
    if (w) {
      getGotraBySubSectName(w?.label);
      SectSubSectError({ candidate: false });
    }
  }, [watchSubSect, getValues]);

  const getGotraBySubSectName = async (name: any) => {
    var res: any = await getGotraList(name);
    if (res?.data?.status) {
      let list = res?.data?.data?.map((i: any) => {
        return { label: i.english, value: i.english };
      });
      setGotraList(list);
    }
  };

  // mama
  const watchMamaSect = watch(`third_formMamaSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formMamaSectEnglish`);
    setSectValue({ ...sectValue, mama: w?.value });
    if (w) {
      getmSubsecBysectName(w?.label);
    }
  }, [watchMamaSect, getValues]);

  const getmSubsecBysectName = async (name: any) => {
    let obj = {
      sect: name,
    };
    var res: any = await getSubsect(obj);
    if (res?.data?.status) {
      let sList = res?.data?.data?.subSect?.map((i: any) => {
        return { label: i.name_english, value: i.name_english, id: i.id };
      });
      setMamaSubsect(sList);
    }
  };

  useEffect(() => {
    getHinduSect();
  }, []);

  const watchSubSectm = watch(`third_formMamaSubSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formMamaSubSectEnglish`);
    setSubSectId({ ...subSectId, mama: w?.id });
    if (w) {
      getGotraBySubSectNamem(w?.label);
      SectSubSectError({ mama: false });
    }
  }, [watchSubSectm, getValues]);

  const getGotraBySubSectNamem = async (name: any) => {
    var res: any = await getGotraList(name);
    if (res?.data?.status) {
      let list = res?.data?.data?.map((i: any) => {
        return { label: i.english, value: i.english };
      });
      setMamaGotraList(list);
    }
  };

  // relative
  const watchSects = watch(`third_formReletiveSectStatus`);
  useEffect(() => {
    let w: any = getValues(`third_formReletiveSectStatus`);
    setSectValue({ ...sectValue, relative: w?.value });
    if (w) {
      getmSubsecBysectNamer(w?.label);
    }
  }, [watchSects, getValues]);

  const getmSubsecBysectNamer = async (name: any) => {
    let obj = {
      sect: name,
    };
    var res: any = await getSubsect(obj);
    if (res?.data?.status) {
      let sList = res?.data?.data?.subSect?.map((i: any) => {
        return { label: i.name_english, value: i.name_english, id: i.id };
      });
      setMamaSubsect(sList);
    }
  };

  const watchSubSectr = watch(`third_formReleteivesSubSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formReleteivesSubSectEnglish`);
    setSubSectId({ ...subSectId, relative: w?.id });
    if (w) {
      getGotraBySubSectNamer(w?.label);
      SectSubSectError({ relative: false });
    }
  }, [watchSubSectr, getValues]);

  const getGotraBySubSectNamer = async (name: any) => {
    var res: any = await getGotraList(name);
    if (res?.data?.status) {
      let list = res?.data?.data?.map((i: any) => {
        return { label: i.english, value: i.english };
      });
      setReleteiveGotraList(list);
    }
  };

  const getHinduSect = async () => {
    const res = await getHinduEducationsApi();
    let tempArr: any = [];
    res?.data?.data?.HinduSect?.map((i: any) => {
      tempArr.push({
        value: i?.name_english,
        label: i?.name_english,
      });
    });
    setSectOption(tempArr);
  };

  // second Relative
  const watchSectss = watch(`third_formSecondReleteivesSect`);
  useEffect(() => {
    let w: any = getValues(`third_formSecondReleteivesSect`);
    setSectValue({ ...sectValue, other: w?.value });
    if (w) {
      getmSubsecBysectNamers(w?.label);
    }
  }, [watchSectss, getValues]);

  const getmSubsecBysectNamers = async (name: any) => {
    let obj = {
      sect: name,
    };
    var res: any = await getSubsect(obj);
    if (res?.data?.status) {
      let sList = res?.data?.data?.subSect?.map((i: any) => {
        return { label: i.name_english, value: i.name_english, id: i.id };
      });
      setMamaSubsect(sList);
    }
  };
  const watchSubSects = watch(`third_formSecondReleteivesSubSectEnglish`);
  useEffect(() => {
    let w: any = getValues(`third_formSecondReleteivesSubSectEnglish`);
    setSubSectId({ ...subSectId, other: w?.id });
    if (w) {
      getGotraBySubSectNames(w?.label);
      SectSubSectError({ other: false });
    }
  }, [watchSubSects, getValues]);

  const getGotraBySubSectNames = async (name: any) => {
    var res: any = await getGotraList(name);
    if (res?.data?.status) {
      let list = res?.data?.data?.map((i: any) => {
        return { label: i.english, value: i.english };
      });
      setReleteiveGotraLists(list);
    }
  };

  const onWriteSubSect = async (type: string) => {
    let obj = {
      sect:
        type === "candi"
          ? sectValue?.candidate
          : type === "mama"
          ? sectValue?.mama
          : type === "relative"
          ? sectValue?.relative
          : type === "other"
          ? sectValue?.other
          : "",
      name_english:
        type === "candi"
          ? writeSubSectValue?.candidate
          : type === "mama"
          ? writeSubSectValue?.mama
          : type === "relative"
          ? writeSubSectValue?.relative
          : type === "other" && writeSubSectValue?.other,
      user_id: AppContext?.user?.id,
    };

    if (obj.name_english) var res: any = await addSubSect(obj);
    setCreatedSubsectId(res?.data?.data?.id);
    if (res?.data?.status) {
      toast("SubSect added Successfully");
    } else {
      toast("Please Write Sub-Sect");
    }
  };

  const onWriteGotra = async (type: string) => {
    let obj: any = {
      sub_sect_id:
        type === "candi"
          ? createdSubsectId
            ? createdSubsectId
            : subSectId?.candidate
          : type === "mama"
          ? createdSubsectId
            ? createdSubsectId
            : subSectId?.mama
          : type === "relative"
          ? createdSubsectId
            ? createdSubsectId
            : subSectId?.relative
          : type === "other"
          ? createdSubsectId
            ? createdSubsectId
            : subSectId?.other
          : "",
      name_english:
        type === "candi"
          ? gotraValue?.candidate
          : type === "mama"
          ? gotraValue?.mama
          : type === "relative"
          ? gotraValue?.relative
          : type === "other"
          ? gotraValue?.other
          : "",
      user_id: AppContext?.user?.id,
    };
    var response: any = await addGotra(obj);
    if (response?.data?.status) {
      toast("Gotra added Successfully");
    } else {
      SectSubSectError({
        candidate: true,
        mama: true,
        relative: true,
        other: true,
      });
    }
  };

  const onDidDismiss = async () => {
    AppContext.setPopups(constant.popups.isFifthForm, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    localStorage.setItem("isModal", "false");
  };
  const onWillDismiss = async () => {
    AppContext.setPopups(constant.popups.isFifthForm, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    localStorage.setItem("isModal", "false");
  };

  const onDidPresent = () => {
    AppContext?.setPopupClose(false);
    if (AppContext?.user?.third_formSectEnglish)
      setValue("third_formSectEnglish", {
        label: AppContext?.user?.third_formSectEnglish,
        value: AppContext?.user?.third_formSectEnglish,
      });
    if (AppContext?.user?.third_formSubSectEnglish)
      setValue("third_formSubSectEnglish", {
        label: AppContext?.user?.third_formSubSectEnglish,
        value: AppContext?.user?.third_formSubSectEnglish,
      });
    if (AppContext?.user?.third_formGotraEnglish)
      setValue("third_formGotraEnglish", {
        label: AppContext?.user?.third_formGotraEnglish,
        value: AppContext?.user?.third_formGotraEnglish,
      });

    if (AppContext?.user?.third_formMamaSectEnglish)
      setValue("third_formMamaSectEnglish", {
        label: AppContext?.user?.third_formMamaSectEnglish,
        value: AppContext?.user?.third_formMamaSectEnglish,
      });
    if (AppContext?.user?.third_formMamaSubSectEnglish)
      setValue("third_formMamaSubSectEnglish", {
        label: AppContext?.user?.third_formMamaSubSectEnglish,
        value: AppContext?.user?.third_formMamaSubSectEnglish,
      });
    if (AppContext?.user?.third_formMamaGotraEnglish)
      setValue("third_formMamaGotraEnglish", {
        label: AppContext?.user?.third_formMamaGotraEnglish,
        value: AppContext?.user?.third_formMamaGotraEnglish,
      });

    if (AppContext?.user?.third_formReletiveSectStatus)
      setValue("third_formReletiveSectStatus", {
        label: AppContext?.user?.third_formReletiveSectStatus,
        value: AppContext?.user?.third_formReletiveSectStatus,
      });
    if (AppContext?.user?.third_formReleteivesSubSectEnglish)
      setValue("third_formReleteivesSubSectEnglish", {
        label: AppContext?.user?.third_formReleteivesSubSectEnglish,
        value: AppContext?.user?.third_formReleteivesSubSectEnglish,
      });
    if (AppContext?.user?.third_formReletivesGotraEnglish)
      setValue("third_formReletivesGotraEnglish", {
        label: AppContext?.user?.third_formReletivesGotraEnglish,
        value: AppContext?.user?.third_formReletivesGotraEnglish,
      });

    if (AppContext?.user?.third_formSecondReleteivesSect)
      setValue("third_formSecondReleteivesSect", {
        label: AppContext?.user?.third_formSecondReleteivesSect,
        value: AppContext?.user?.third_formSecondReleteivesSect,
      });
    if (AppContext?.user?.third_formSecondReleteivesSubSectEnglish)
      setValue("third_formSecondReleteivesSubSectEnglish", {
        label: AppContext?.user?.third_formSecondReleteivesSubSectEnglish,
        value: AppContext?.user?.third_formSecondReleteivesSubSectEnglish,
      });
    if (AppContext?.user?.third_formSecondReletivesGotraEnglish)
      setValue("third_formSecondReletivesGotraEnglish", {
        label: AppContext?.user?.third_formSecondReletivesGotraEnglish,
        value: AppContext?.user?.third_formSecondReletivesGotraEnglish,
      });
    localStorage.setItem("isModal", "true");
  };

  return (
    <IonContent>
      <IonModal
        isOpen={AppContext?.popups[constant.popups.isFifthForm]}
        className="GoliveBody"
        onDidDismiss={onDidDismiss}
        onWillDismiss={onWillDismiss}
        onDidPresent={onDidPresent}
        onWillPresent={() => {
          localStorage.setItem("isModal", "true");
        }}
      >
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonTitle className="heading text-white">
              Candidate Religious Information
            </IonTitle>
            <div slot="end" className="backBtn">
              <IonButton
                type="submit"
                className="px-1"
                onClick={(event: any) => {
                  // event.stopPropagation(); setFifthForm(false)
                  Object.keys(touchedFields).length
                    ? presentAlert({
                        header: "Would you like to save changes?",
                        cssClass: "actionAlert",
                        buttons: [
                          {
                            text: "Yes",
                            role: "confirm",
                            handler: handleSubmit(onSubmit),
                          },
                          {
                            text: "no",
                            role: "cancel",
                            handler: () => {
                              onReject(false);
                            },
                          },
                        ],
                      })
                    : onReject(false);
                }}
              >
                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
              </IonButton>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="golive ">
          <form
            className="mt-2 "
            onSubmit={handleSubmit(onSubmit)}
            style={{ marginBottom: "30%" }}
          >
            <div className="card-family imagePreview">
              <div className="card-family-name">Candidate</div>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sect<span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={sectOption}
                  control={control}
                  required={true}
                  name={`third_formSectEnglish`}
                  placeholder={"Select Sect"}
                  isSearchable={false}
                />
                {errors?.[`third_formSectEnglish`]?.type === "required" && (
                  <p className="errorText ms-1">Select Sect</p>
                )}
              </IonGrid>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sub Sect <span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={subsect}
                  control={control}
                  // required={true}
                  name={`third_formSubSectEnglish`}
                  placeholder={"Select Sub Sect"}
                  isSearchable={true}
                />
                {(errors?.[`third_formSubSectEnglish`]?.type === "required" ||
                  subsectError?.candidate) && (
                  <p className="errorText ms-1">Select Sub Sect</p>
                )}
              </IonGrid>
              {!showWriteSubSect?.show1 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteSubSect({ ...showWriteSubSect, show1: true })
                    }
                  >
                    Write Sub-Sect
                  </IonButton>
                </IonGrid>
              )}
              {showWriteSubSect?.show1 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setWriteSubSectValue({
                          ...writeSubSectValue,
                          candidate: e.target.value,
                        });
                      }}
                      placeholder={"Write SubSect"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteSubSect("candi")}
                    >
                      Add Sub-Sect
                    </IonButton>
                  </IonGrid>
                </>
              )}
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Gotra <span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={gotraList}
                  control={control}
                  // required={true}
                  name={`third_formGotraEnglish`}
                  placeholder={"Select Gotra Sect"}
                  isSearchable={true}
                />
                {errors?.[`third_formGotraEnglish`]?.type === "required" && (
                  <p className="errorText ms-1">Select Gotra</p>
                )}
              </IonGrid>
              {!showWriteGotra?.show1 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteGotra({ ...showWriteGotra, show1: true })
                    }
                  >
                    Write Gotra
                  </IonButton>
                </IonGrid>
              )}
              {showWriteGotra?.show1 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setGotraValue({
                          ...gotraValue,
                          candidate: e.target.value,
                        });
                      }}
                      placeholder={"Write Gotra"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteGotra("candi")}
                    >
                      Add Gotra
                    </IonButton>
                  </IonGrid>
                </>
              )}
            </div>

            <div className="card-family imagePreview">
              <div className="card-family-name">Mama</div>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sect<span className="errorText m-0">*</span>
                </IonLabel>
                <CustomSelect
                  options={sectOption}
                  control={control}
                  required={false}
                  name={`third_formMamaSectEnglish`}
                  placeholder={"Select Sect"}
                  isSearchable={false}
                />
                {/* {errors?.[`third_formMamaSectEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Sect</p>} */}
              </IonGrid>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sub Sect
                </IonLabel>
                <CustomSelect
                  options={mamaSubsect}
                  control={control}
                  required={false}
                  name={`third_formMamaSubSectEnglish`}
                  placeholder={"Select Sub Sect"}
                  isSearchable={true}
                />
                {subsectError?.mama && (
                  <p className="errorText ms-1">Select Sub Sect</p>
                )}
              </IonGrid>
              {!showWriteSubSect?.show2 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteSubSect({ ...showWriteSubSect, show2: true })
                    }
                  >
                    Write Sub-Sect
                  </IonButton>
                </IonGrid>
              )}
              {showWriteSubSect?.show2 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setWriteSubSectValue({
                          ...writeSubSectValue,
                          mama: e.target.value,
                        });
                      }}
                      placeholder={"Write SubSect"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteSubSect("mama")}
                    >
                      Add Sub-Sect
                    </IonButton>
                  </IonGrid>
                </>
              )}
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Gotra
                </IonLabel>
                <CustomSelect
                  options={mamGotraList}
                  control={control}
                  required={false}
                  name={`third_formMamaGotraEnglish`}
                  placeholder={"Select Gotra Sect"}
                  isSearchable={true}
                />
                {/* {errors?.[`third_formMamaGotraEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Gotra</p>} */}
              </IonGrid>
              {!showWriteGotra?.show2 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteGotra({ ...showWriteGotra, show2: true })
                    }
                  >
                    Write Gotra
                  </IonButton>
                </IonGrid>
              )}
              {showWriteGotra?.show2 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setGotraValue({ ...gotraValue, mama: e.target.value });
                      }}
                      placeholder={"Write Gotra"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteGotra("mama")}
                    >
                      Add Gotra
                    </IonButton>
                  </IonGrid>
                </>
              )}
            </div>

            <div className="card-family imagePreview">
              <div className="card-family-name">Relative</div>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sect
                </IonLabel>
                <CustomSelect
                  options={sectOption}
                  control={control}
                  required={false}
                  name={`third_formReletiveSectStatus`}
                  placeholder={"Select Sect"}
                  isSearchable={false}
                />
                {/* {errors?.[`third_formReletiveSectStatus`]?.type === "required" && <p className='errorText ms-1'>Select Sect</p>} */}
              </IonGrid>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sub Sect
                </IonLabel>
                <CustomSelect
                  options={releteiveSubsect}
                  control={control}
                  required={false}
                  name={`third_formReleteivesSubSectEnglish`}
                  placeholder={"Select Sub Sect"}
                  isSearchable={true}
                />
                {subsectError?.relative && (
                  <p className="errorText ms-1">Select Sub Sect</p>
                )}
              </IonGrid>
              {!showWriteSubSect?.show3 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteSubSect({ ...showWriteSubSect, show3: true })
                    }
                  >
                    Write Sub-Sect
                  </IonButton>
                </IonGrid>
              )}
              {showWriteSubSect?.show3 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setWriteSubSectValue({
                          ...writeSubSectValue,
                          relative: e.target.value,
                        });
                      }}
                      placeholder={"Write SubSect"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteSubSect("relative")}
                    >
                      Add Sub-Sect
                    </IonButton>
                  </IonGrid>
                </>
              )}
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Gotra
                </IonLabel>
                <CustomSelect
                  options={releteiveGotraList}
                  control={control}
                  required={false}
                  name={`third_formReletivesGotraEnglish`}
                  placeholder={"Select Gotra Sect"}
                  isSearchable={true}
                />
                {/* {errors?.[`third_formReletivesGotraEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Gotra</p>} */}
              </IonGrid>
              {!showWriteGotra?.show3 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteGotra({ ...showWriteGotra, show3: true })
                    }
                  >
                    Write Gotra
                  </IonButton>
                </IonGrid>
              )}
              {showWriteGotra?.show3 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setGotraValue({
                          ...gotraValue,
                          relative: e.target.value,
                        });
                      }}
                      placeholder={"Write Gotra"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteGotra("relative")}
                    >
                      Add Gotra
                    </IonButton>
                  </IonGrid>
                </>
              )}
            </div>

            <div className="card-family imagePreview">
              <div className="card-family-name">Other Relative</div>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sect
                </IonLabel>
                <CustomSelect
                  options={sectOption}
                  control={control}
                  required={false}
                  name={`third_formSecondReleteivesSect`}
                  placeholder={"Select Sect"}
                  isSearchable={false}
                />
                {/* {errors?.[`third_formSecondReleteivesSect`]?.type === "required" && <p className='errorText ms-1'>Select Sect</p>} */}
              </IonGrid>
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sub Sect
                </IonLabel>
                <CustomSelect
                  options={releteiveSubsects}
                  control={control}
                  required={false}
                  name={`third_formSecondReleteivesSubSectEnglish`}
                  placeholder={"Select Sub Sect"}
                  isSearchable={true}
                />
                {subsectError?.other && (
                  <p className="errorText ms-1">Select Sub Sect</p>
                )}
              </IonGrid>
              {!showWriteSubSect?.show4 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteSubSect({ ...showWriteSubSect, show4: true })
                    }
                  >
                    Write Sub-Sect
                  </IonButton>
                </IonGrid>
              )}
              {showWriteSubSect?.show4 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setWriteSubSectValue({
                          ...writeSubSectValue,
                          other: e.target.value,
                        });
                      }}
                      placeholder={"Write SubSect"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteSubSect("other")}
                    >
                      Add Sub-Sect
                    </IonButton>
                  </IonGrid>
                </>
              )}
              <IonGrid>
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Gotra
                </IonLabel>
                <CustomSelect
                  options={releteiveGotraLists}
                  control={control}
                  required={false}
                  name={`third_formSecondReletivesGotraEnglish`}
                  placeholder={"Select Gotra Sect"}
                  isSearchable={true}
                />
                {/* {errors?.[`third_formSecondReletivesGotraEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Gotra</p>} */}
              </IonGrid>
              {!showWriteGotra?.show4 && (
                <IonGrid>
                  <IonButton
                    size="small"
                    onClick={() =>
                      setShowWriteGotra({ ...showWriteGotra, show4: true })
                    }
                  >
                    Write Gotra
                  </IonButton>
                </IonGrid>
              )}
              {showWriteGotra?.show4 && (
                <>
                  <IonGrid>
                    <IonInput
                      onIonChange={(e: any) => {
                        setGotraValue({ ...gotraValue, other: e.target.value });
                      }}
                      placeholder={"Write Gotra"}
                      className="border-bottom"
                    ></IonInput>
                  </IonGrid>
                  <IonGrid>
                    <IonButton
                      size="small"
                      onClick={() => onWriteGotra("other")}
                    >
                      Add Gotra
                    </IonButton>
                  </IonGrid>
                </>
              )}
            </div>

            <IonCol class="btnFill  ion-margin-bottom">
              <IonButton type="submit" expand="block">
                Save
              </IonButton>
            </IonCol>
          </form>
        </div>
      </IonModal>
    </IonContent>
  );
}

export default FifthForm;
