import { useEffect, useState, useContext, useRef } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonCol, IonGrid, IonInput, IonLabel, IonIcon, useIonToast, IonTextarea, IonDatetime, IonPopover, useIonAlert, IonRow } from '@ionic/react';
import { getnakshtra, getRashiList, signUp, } from '../../service';
import appContext from "../../context/AppContext"
import { closeOutline, } from 'ionicons/icons';
import { Controller, useForm } from "react-hook-form";
import moment from 'moment';
import CustomSelect from '../shared/CustomSelect';
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/mobile.css"
import "../../theme/web.css"
import constant from '../../constant';
import { toast } from 'react-toastify';

const monthOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11 ", value: "11 " },
    { label: "12 ", value: "12 " },
]

function FourthForm() {

    const [rashi, setRashi] = useState<any>(null);
    const [nakshtra, setNakshtra] = useState<any>(null);
    const AppContext = useContext(appContext);
    const [dateErr, setDateErr] = useState(false)
    const [timeErr, setTimeErr] = useState(false)
    const [placeErr, setPlaceErr] = useState(false)
    const [dateTime, setDateTime] = useState<any>({ Date: "", Time: "" });
    const [dobValue, setDobValue] = useState<any>({});


    const [presentAlert] = useIonAlert();
    // let initialValues = {
    //     dob: AppContext?.user?.dob,
    //     third_formBirthdetailsTime: AppContext?.user?.third_formBirthdetailsTime,
    //     third_formBirthdetailsDescriptionEnglish: AppContext?.user?.third_formBirthdetailsDescriptionEnglish,
    //     third_formRaashiEnglish: AppContext?.user?.third_formRaashiEnglish?.name_english,
    //     third_formSubRaashiEnglish: AppContext?.user?.third_formSubRaashiEnglish,
    // };
    const {
        register,
        handleSubmit,
        formState: { errors, touchedFields },
        setValue,
        control,
        watch,
        reset,
        getValues
    }: any = useForm();

    const getRashi = async () => {
        var res: any = await getRashiList();
        if (res?.data?.status) {
            let cList = res?.data?.data?.map((i: any) => { return { label: i.name_english, value: i.name_english } });
            setRashi(cList);
        }
    }

    const currentYear = (new Date()).getFullYear();
    const year = [];
    for (let index = 18; index < 81; index++) {
        year.push({ label: (currentYear - index), value: (currentYear - index) });
    }

    const day = [];
    for (let index = 1; index < 32; index++) {
        day.push({ label: index, value: index });
    }

    const watchyears = watch("years");
    const watchmonths = watch("months");
    const watchdays = watch("days");
    useEffect(() => {
        let year: any = getValues("years")
        let month: any = getValues("months")
        let day: any = getValues("days")
        if (year && month && day) {
            const newDate = new Date(year?.value, month?.value - 1, day?.value + 1);
            setDateTime({ ...dateTime, Date: newDate })
        }
    }, [watchyears, watchmonths, watchdays, getValues]);


    const watchCountry = watch("third_formRaashiEnglish");
    useEffect(() => {
        let v: any = getValues("third_formRaashiEnglish")
        if (v) { getStatesByCountryName(v?.label); }
    }, [watchCountry, getValues]);

    const getStatesByCountryName = async (name: any) => {
        var res: any = await getnakshtra({ id: name })
        if (res?.data?.status) {
            let sList = res?.data?.data?.map((i: any) => { return { label: i.name_english, value: i.name_english } });
            setNakshtra(sList);
        }
    }
    const onSubmit = async (values: any) => {

        if (dateTime.Date === "") { setDateErr(true) }
        else if (dateTime.Time === "") { setTimeErr(true); setDateErr(false); }
        else if (!values?.third_formBirthdetailsDescriptionEnglish) { setPlaceErr(true); setTimeErr(true); setDateErr(false); }
        else {
            setDateErr(false);
            setTimeErr(false);
            setPlaceErr(false)
            var obj = {
                dob: dateTime.Date,
                third_formBirthdetailsTime: dateTime.Time,
                third_formBirthdetailsDescriptionEnglish: values?.third_formBirthdetailsDescriptionEnglish,
                third_formRaashiEnglish: values?.third_formRaashiEnglish?.value,
                third_formSubRaashiEnglish: values?.third_formSubRaashiEnglish?.value,
            }
            AppContext.setLoading(true);
            var response = await signUp(obj);
            if (response?.data?.status) {
                toast("Birth Detail Saved Successfully.");
                AppContext.setLoading(false);
                AppContext.updateUser();
                AppContext.setPopups(constant.popups.isFourthForm, false);
            }
            else {
                toast(response?.data?.errors);
            }
        }
    };

    var minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 80))
    var maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18))

    const onReject = (modalState: Boolean) => {
        reset();
        AppContext.setPopups(constant.popups.isFourthForm, modalState);
        AppContext.setDoItLater();
    }

    const onDidDismiss = async () => {
        AppContext.setPopups(constant.popups.isFourthForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }
    const onWillDismiss = async () => {
        AppContext.setPopups(constant.popups.isFourthForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }



    const onDidPresent = () => {
        AppContext?.setPopupClose(false)
        if (AppContext?.user?.third_formBirthdetailsDescriptionEnglish) setValue("third_formBirthdetailsDescriptionEnglish", AppContext?.user?.third_formBirthdetailsDescriptionEnglish)
        if (AppContext?.user?.third_formRaashiEnglish) setValue("third_formRaashiEnglish", { label: AppContext?.user?.third_formRaashiEnglish, value: AppContext?.user?.third_formRaashiEnglish })
        if (AppContext?.user?.third_formSubRaashiEnglish) setValue("third_formSubRaashiEnglish", { label: AppContext?.user?.third_formSubRaashiEnglish, value: AppContext?.user?.third_formSubRaashiEnglish })
        var obj: any = {}
        if (AppContext?.user?.third_formBirthdetailsTime) obj.Time = AppContext?.user?.third_formBirthdetailsTime
        else obj.Time = ""
        if (AppContext?.user?.dob) {
            obj.Date = new Date(AppContext?.user?.dob)
            const dateParts = AppContext?.user?.dob.split("-");
            const dobYear = parseInt(dateParts[0]);
            const dobMonth = parseInt(dateParts[1]);
            const dobDay = parseInt(dateParts[2]);
            setValue("years", { label: dobYear, value: dobYear })
            setValue("days", { label: dobDay, value: dobDay })
            const selectedMonthOption = monthOptions.find(option => option.value === String(dobMonth));
            setValue("months", selectedMonthOption)
            // setDobValue(selectedMonthOption)
        }
        else obj.Date = ""
        setDateTime(obj)
        getRashi()
        localStorage.setItem('isModal', 'true')

    }
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const openPopover = (e: any) => {
        popover.current!.event = e;
        setPopoverOpen(true);

    };

    return (
        <IonContent>

            <IonModal isOpen={AppContext?.popups[constant.popups.isFourthForm]} className='GoliveBody'
                onDidDismiss={onDidDismiss}
                onWillDismiss={onWillDismiss}
                onDidPresent={onDidPresent}
                onWillPresent={() => {
                    localStorage.setItem('isModal', 'true')
                }}
            >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle className='heading text-white'>Candidate Birth Details</IonTitle>
                        <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={(event: any) => {
                                // event.stopPropagation(); setFourthForm(false)
                                Object.keys(touchedFields).length
                                    ? presentAlert({
                                        header: 'Would you like to save changes?',
                                        cssClass: 'actionAlert',
                                        buttons: [
                                            {
                                                text: 'Yes', role: 'confirm',
                                                handler: handleSubmit(onSubmit),
                                            },
                                            {
                                                text: 'no', role: 'cancel', handler: () => {
                                                    onReject(false);
                                                }
                                            },
                                        ],
                                    })
                                    : onReject(false)
                            }}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <div className='golive '>
                    <form className='mt-2 fourth-form-box' onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: "30%" }}>
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'> Birth Date<span className='errorText m-0' >*</span></IonLabel>
                            <IonRow>
                                <div style={{ flex: "35%" }}><IonCol>
                                    <CustomSelect isSearchable={false}
                                        options={year}
                                        control={control}
                                        name={"years"}
                                        placeholder={"Year"}
                                    />
                                </IonCol>
                                </div>
                                <div style={{ flex: "30%" }}>
                                    <IonCol>
                                        <CustomSelect isSearchable={false}
                                            options={monthOptions}
                                            control={control}
                                            name={"months"}
                                            placeholder={"Month"}
                                        />
                                    </IonCol>
                                </div>
                                <div style={{ flex: "22%" }}>
                                    <IonCol>
                                        <CustomSelect isSearchable={false}
                                            options={day}
                                            control={control}
                                            name={"days"}
                                            placeholder={"Day"}
                                        />
                                    </IonCol>
                                </div>
                            </IonRow>
                            {/* <div>
                                <Controller
                                    control={control}
                                    name="date"
                                    render={({
                                        field: { onChange, name, value },
                                    }) => (
                                        <>
                                            <DatePicker
                                                style={{
                                                    width: "100%",
                                                    boxSizing: "border-box",
                                                    height: "26px"
                                                }}
                                                containerStyle={{
                                                    width: "100%"
                                                }}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                                placeholder="Select Birth Date"
                                                className='border-bottom rmdp-mobile'
                                                value={value || dateTime.Date}
                                                onChange={(date: any) => {
                                                    setDateTime({ ...dateTime, Date: date?.isValid ? date : "" })
                                                    onChange(date?.isValid ? date : "");
                                                }}
                                                format={"YYYY-MM-DD"}
                                            />
                                        </>
                                    )}
                                />
                            </div> */}
                            {dateErr && <p className='errorText ms-1'>Birth Date is required</p>}
                        </IonGrid>

                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'> Birth Time<span className='errorText m-0' >*</span></IonLabel>
                            <IonInput
                                onClick={openPopover}
                                value={dateTime.Time && moment(dateTime.Time).format("hh:mm a")}
                                placeholder="Select Time "
                                // {...register("third_formBirthdetailsTime", { required: true })}
                                className='border-bottom BirthDatePicker' >
                            </IonInput>
                            {timeErr && <p className='errorText ms-1'>Birth Time is required</p>}
                            <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                                <IonContent class="ion-padding">
                                    <IonDatetime showDefaultButtons={true} presentation="time" value={dateTime.Time} onIonChange={(e: any) => { e.stopPropagation(); setDateTime({ ...dateTime, Time: e.target.value }) }}></IonDatetime>
                                </IonContent>

                            </IonPopover>
                        </IonGrid>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Birth Place<span className='errorText m-0' >*</span></IonLabel>
                            <IonTextarea autoGrow={true} rows={1} className='border-bottom' placeholder='Add Here...'
                                {...register("third_formBirthdetailsDescriptionEnglish")}
                            ></IonTextarea>
                            {placeErr && <p className='errorText ms-1'>Birth Place is required</p>}
                        </IonGrid>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Rashi</IonLabel>
                            {rashi
                                && <CustomSelect
                                    options={rashi}
                                    control={control}
                                    required={false}
                                    name={"third_formRaashiEnglish"}
                                    placeholder={"Select Rashi"}
                                    isSearchable={true}

                                />}
                        </IonGrid>
                        {nakshtra
                            && <IonGrid >
                                <IonLabel position="stacked" className='fs-16 labelItem' >Nakshtra/Star</IonLabel>
                                <CustomSelect
                                    options={nakshtra}
                                    control={control}
                                    required={false}
                                    name={"third_formSubRaashiEnglish"}
                                    placeholder={"Select Nakshtra"}
                                    isSearchable={true}
                                />
                            </IonGrid>
                        }
                        {/* <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Sect<span className='errorText m-0' >*</span></IonLabel>
                            <CustomSelect
                                options={[{ label: "Digambar", value: "Digambar" }, { label: "Shwetambar", value: "Shwetambar" }]}
                                control={control}
                                required={true}
                                name={"third_formSectEnglish"}
                                placeholder={"Select Sect"}
                                isSearchable={false}
                            />
                            {errors.sect && errors.sect.type === "required" && <p className='errorText  ms-0 mt-2'>Sector is required</p>}
                        </IonGrid>
                        {subsect
                            && <IonGrid >
                                <IonLabel position="stacked" className='fs-16 labelItem' >Sub Sect</IonLabel>
                                <CustomSelect
                                    options={subsect}
                                    control={control}
                                    required={false}
                                    name={"third_formSubSectEnglish"}
                                    placeholder={"Select Sub Sect"}
                                    isSearchable={true}
                                />
                            </IonGrid>
                        } */}
                        <IonCol class='btnFill  ion-margin-bottom'>
                            <IonButton type="submit" expand="block" >Save</IonButton>
                        </IonCol>
                    </form>
                </div>
            </IonModal>
        </IonContent >

    );
}

export default FourthForm;