import {
  IonSlides,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonProgressBar,
  IonSlide,
  IonThumbnail,
  IonGrid,
  useIonRouter,
  useIonAlert,
  IonButton,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonImg,
  IonCardSubtitle,
  IonCardContent,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon, IonBadge } from "@ionic/react";
import { IonInput, IonText } from "@ionic/react";
import appContext from "../../context/AppContext";
import {
  arrowUpOutline,
  cameraOutline,
  cashOutline,
  eyeOutline,
  heartCircleOutline,
} from "ionicons/icons";
import Firstform from "./firstform";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";
import payment from "../../assets/images/payment.png";
import FourthForm from "./FourthForm";
import SignUpModal from "./SignUpModal";
import {
  dashboard,
  getBanner,
  home,
  autoPackage,
  unlockUserList,
  getMyOffers,
  getSecurityKey,
  PaymentVerification,
  createOrder,
  SplPkgStatus,
  getUniversalOffers,
  referralApi,
  phonePePaymentVerification,
  isPhonePeOn,
} from "../../service";
import SixthForm from "./SixthForm";
import FifthForm from "./FifthForm";
import utills from "../utils";
import PopUp from "./PopUp";
import birth from "../../assets/images/birth icon.png";
import Family from "../../assets/images/Family icon.png";
import preference from "../../assets/images/prefrence icon.png";
import Profession from "../../assets/images/Profession icon.png";
import Religious from "../../assets/images/Religious icon.png";
import residental from "../../assets/images/residental icon.png";
import friend from "../../assets/images/referfreind.png";
import { Checkout } from "capacitor-razorpay";
import MultipleProfileUpload from "./MultipleProfileUpload";
import constant from "../../constant";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router";
import Details from "./Details";
import { InstallReferrer } from "@togetherprice/capacitor-plugin-install-referrer";
import { PhonePePaymentPlugin } from "phonepe-payment-capacitor";
import { v4 as uuidv4 } from "uuid";
import sha256 from "crypto-js/sha256";
import axios from "axios";
import { Capacitor } from "@capacitor/core";

PhonePePaymentPlugin.init({
  environment: "PRODUCTION",
  merchantId: "HINDUSHADIONLINE",
  appId: "b3afc119-8031-4cda-8430-8ea5052fd5ca",
  enableLogging: false,
})
  .then((result: any) => {
    console.log("VS: " + JSON.stringify(result["status"]));
  })
  .catch((error: any) => {
    console.log("VS: error:" + error.message);
  });

export const CardTimerAutoOffer = ({
  userCreatedAt,
  end_time,
  start_time,
}: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const startTimeInMillis = new Date(start_time).getTime();
    const endTimeInMillis = new Date(end_time * 1000).getTime();

    if (endTimeInMillis <= startTimeInMillis) return;

    const offerEnd = endTimeInMillis;
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = offerEnd - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
        setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
      } else {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeRemaining({ hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [start_time, end_time, userCreatedAt]);

  return (
    <div>
      <div className="row d-flex justify-content-center mt-1">
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.hours}</span>
          HRS
        </div>
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.minutes}</span>
          MIN
        </div>
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.seconds}</span>
          SEC
        </div>
      </div>
    </div>
  );
};

export const CardTimerTest = ({ end_time }: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const expirationDate = new Date(end_time).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = expirationDate - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [end_time]);
  return (
    <div>
      <div className="row d-flex justify-content-center mt-1">
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.days}</span>
          DAYS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.hours}</span>
          HRS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.minutes}</span>
          MIN
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.seconds}</span>
          SEC
        </div>
      </div>
    </div>
  );
};
export const CardTimer = ({ data }: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const expirationDate = new Date(data?.expire).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = expirationDate - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data?.expire]);
  return (
    <div>
      <div className="row d-flex justify-content-center mt-1">
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.days}</span>
          DAYS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.hours}</span>
          HRS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.minutes}</span>
          MIN
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.seconds}</span>
          SEC
        </div>
      </div>
    </div>
  );
};

export const CardTimerUniversal = ({ data }: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const isTimerZeroOrExpired = useCallback(() => {
    const expirationDate = new Date(data?.offer_end_date).getTime();
    const now = new Date().getTime();
    const timeDiff = expirationDate - now;

    return timeDiff <= 0;
  }, [data?.offer_end_date]);

  useEffect(() => {
    const expirationDate = new Date(data?.offer_end_date).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = expirationDate - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [data?.offer_end_date]);

  return isTimerZeroOrExpired() ? null : (
    <div>
      <div className="row d-flex justify-content-center mt-1">
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.days}</span>
          DAYS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.hours}</span>
          HRS
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.minutes}</span>
          MIN
        </div>{" "}
        :
        <div className="col-2 d-flex flex-column align-items-center timerContainer">
          <span className="timeDisplay">{timeRemaining.seconds}</span>
          SEC
        </div>
      </div>
    </div>
  );
};

const UserForms = () => {
  const AppContext = useContext(appContext);
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [formPercentage, setFormPercentage] = useState<any>();
  const [bannerShow, setBannerShow] = useState<any>(false);
  const [banners, setBanners] = useState([]);
  const [presentAlert] = useIonAlert();
  const [usersList, setUsersList] = useState<any[]>([]);
  const [universalPkgList, setUniversalPkgList] = useState<any[]>([]);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [splPkg, setSplPkg] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [showTimer, setShowTimer] = useState<any>(true);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [SecurityKey, setSecurityKey] = useState<any>("");
  const [userID, setUserID] = useState<any>("");
  const [autoPackageData, setAutoPackageData] = useState<any>([]);
  const [live, setLive] = useState<any>(true);
  const [unlockUsersList, setUnlockUsersList] = useState<any[]>([]);
  const [unlockLoading, setUnlockLoading] = useState(true);
  const [section, setSection] = useState<any>(null);
  const [isPhonePe, setIsPhonePe] = useState<any>(true);

  useEffect(() => {
    getIsPhonePeOn();
  }, []);

  const getIsPhonePeOn = async () => {
    const res = await isPhonePeOn();
    setIsPhonePe(res?.data?.data?.is_payment_method);
  };

  const startPhonePeTransaction = async (paymentAmount: number) => {
    const transactionId = "JSM-" + uuidv4().toString().slice(-6);
    if (Capacitor.getPlatform() === "android") {
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: "JAINSHADIONLINE",
          merchantTransactionId: transactionId,
          merchantUserId: "JSM-USERAPP",
          amount: paymentAmount,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: "9999999999",
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody +
          "/pg/v1/pay" +
          "a895e5f3-339d-4a2e-8904-e604235c3d65"
      );
      const checksum = checksumUrl + "###" + 1;

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          console.log(
            "Transaction Result: " + JSON.stringify(transactionResult)
          );
          if (transactionResult.status === "SUCCESS") {
            console.log("Payment Successful");
            let obj = {
              package_id: 71,
              amount: paymentAmount,
              payment_id: "phonePe",
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            history.push(`/app/transaction/${transactionId}`);
          } else if (transactionResult.status === "FAILURE") {
            console.log("Payment Failed");
          } else {
            console.log("Transaction Interrupted");
          }
        })
        .catch((transactionError: any) => {
          console.log("Error in Transaction: " + transactionError.message);
        });
    }
    // else {
    //   const base64RequestBody = btoa(
    //     JSON.stringify({
    //       merchantId: "JAINSHADIONLINE",
    //       merchantTransactionId: transactionId,
    //       merchantUserId: "JSM-USERAPP",
    //       amount: paymentAmount,
    //       callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
    //       mobileNumber: "9999999999",
    //       paymentInstrument: {
    //         type: "PAY_PAGE",
    //       },
    //     })
    //   );

    //   const checksumUrl = sha256(
    //     base64RequestBody + "/pg/v1/pay" + "a895e5f3-339d-4a2e-8904-e604235c3d65"
    //   );
    //   const checksum = checksumUrl + "###" + 1;

    // const res = await axios.post(
    //   "https://api.phonepe.com/apis/hermes/pg/v1/pay",
    //   {
    //     request: base64RequestBody,
    //   },
    //   {
    //     headers: {
    //       accept: "application/json",
    //       "Content-Type": "application/json",
    //       "X-VERIFY": checksum,
    //     },
    //   }
    // );
    // console.log("res ::::", res);
    // }
  };

  const formCalculation = useCallback(async () => {
    var calculation = await utills?.formCalculation(AppContext.user);
    setFormPercentage(calculation);
  }, [AppContext.user]);

  useEffect(() => {
    getBanners();
    setIsPhonePe(localStorage.getItem("IsPhonePeOnData"));
  }, []);

  const getBanners = async () => {
    var response = await getBanner();
    // return response?.data?.data || []
    setBanners(response?.data?.data || []);
  };

  const getAutoOffer = async () => {
    try {
      const res = await autoPackage();
      // console.log("RESPONSE ::::", res);
      if (res?.data?.status) {
        setAutoPackageData([]);
        // setAutoPackageData(
        //   !AppContext?.user?.payment_verified_at
        //     ? res?.data?.data !== null
        //       ? res?.data?.data
        //       : []
        //     : []
        // );
        // var resss = await getSecurityKey();
        // setSecurityKey(resss?.data?.data?.key);
      } else {
        setAutoPackageData([]);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getUserlist = useCallback(async () => {
    var response = await dashboard({ order: "latest" }, 0);
    if (response?.data?.status) {
      setLoading(false);
      setUsersList(response?.data?.data);
    }
  }, []);

  const getUnlockUserlist = useCallback(async () => {
    var response = await unlockUserList({}, 0);
    if (response?.data?.status) {
      setUnlockLoading(false);
      setUnlockUsersList(response?.data?.data);
    }
  }, []);

  useEffect(() => {
    if (AppContext?.user?.second_formMobile) {
      setLive(true);
    } else {
      setLive(false);
    }
  }, [AppContext?.user?.second_formMobile]);

  useEffect(() => {
    if (AppContext.user) {
      paymentValidate();
      // getBanners()
      formCalculation();
      getUserlist();
      getUnlockUserlist();
      getSplPkg();
      getUniversalPkgList();
      getAutoOffer();
    }
  }, [AppContext.user, formCalculation]);

  useEffect(() => {
    if (AppContext.user) {
      const checkSignUpPage = () => {
        if (
          AppContext.user.nameEnglish &&
          AppContext.user.first_formGenderEnglish &&
          AppContext.user.third_formSectEnglish &&
          AppContext.user.first_formMaritalStatusEnglish &&
          AppContext.user.first_formProfileEnglish &&
          AppContext.user.second_formStateEnglish &&
          AppContext.user._age
        ) {
          return false;
        } else return true;
      };
      if (checkSignUpPage()) {
        setTimeout(() => {
          setSignUpModal(true);
        }, 180000);
      }
    }
  }, [AppContext.user]);

  const paymentValidate = () => {
    if (!AppContext?.user?.payment_verified_at) {
      let RferBannerShow = moment(AppContext?.user?.created_at)
        .add(15, "days")
        .format("YYYY-MM-DD");
      if (RferBannerShow <= moment(new Date()).format("YYYY-MM-DD")) {
        setBannerShow(true);
        // console.log("hello");
      } else {
        setBannerShow(false);
      }
    }
  };

  const getUniversalPkgList = async () => {
    let res: any = await getUniversalOffers();
    setUniversalPkgList(res?.data?.data);
    var resss = await getSecurityKey();
    setSecurityKey(resss?.data?.data?.key);
  };

  const getSplPkg: any = useCallback(async () => {
    // let res: any = await getMyOffers(AppContext?.user?.id);
    // setSplPkg(res?.data?.data?.length > 0 ? res?.data?.data : null);
    // var resss = await getSecurityKey();
    // setSecurityKey(resss?.data?.data?.key);
  }, [AppContext?.user?.id]);

  useEffect(() => {
    getSplPkg();
  }, [getSplPkg]);

  useEffect(() => {
    if (AppContext?.user?.payment_verified_at) {
      getSplPkg();
    }
  }, [AppContext?.user?.payment_verified_at, getSplPkg]);

  const verifyPayment = async (data: any, i: any, z: any) => {
    let obj = {
      amount: z,
      package_id: i?.package_id,
      razorpay_payment_id: data?.response?.razorpay_payment_id,
      razorpay_order_id: data?.response?.razorpay_order_id,
      razorpay_signature: data?.response?.razorpay_signature,
    };
    let res: any = await PaymentVerification(obj);
    if (res?.data?.status) {
      toast("Paid Amount Succcessfully");
      getSplPkg();
      AppContext.setPopups(constant.popups.isSubscribe, true, true);
      setOpenPopUp(true);
    }
  };
  const verifyPaymentAutoOffer = async (data: any, i: any, z: any) => {
    let obj = {
      amount: z,
      package_id: i?.package?.id,
      razorpay_payment_id: data?.response?.razorpay_payment_id,
      razorpay_order_id: data?.response?.razorpay_order_id,
      razorpay_signature: data?.response?.razorpay_signature,
    };
    let res: any = await PaymentVerification(obj);
    if (res?.data?.status) {
      toast("Paid Amount Succcessfully");
      getSplPkg();
      AppContext.setPopups(constant.popups.isSubscribe, true, true);
      setOpenPopUp(true);
    }
  };

  const goTo = async () => {
    setOpenPopUp(false);
    AppContext.updateUser();
    if (localStorage.getItem("activeUser")) {
      setOpenDetail(true);
    }
  };

  const payNowUniversal = async (i: any, profile: any) => {
    if (isPhonePe && Capacitor.getPlatform() === "android") {
      const transactionId =
        `HSM-${profile?.id}-${i}-` + uuidv4().toString().slice(-6);
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: AppContext?.phonePe?.merchantId,
          merchantTransactionId: transactionId,
          merchantUserId: AppContext?.phonePe?.merchantUserId,
          amount: i * 100,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: AppContext?.user?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody + "/pg/v1/pay" + String(AppContext?.phonePe?.appId)
      );
      const checksum = checksumUrl + "###" + 1;

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          if (transactionResult.status === "SUCCESS") {
            let obj = {
              package_id: profile?.id,
              amount: i,
              payment_id: transactionId,
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            console.log("Payment Successful");
            if (res?.data?.status) {
              history.push(`/app/transaction/${transactionId}`);
            }
          } else if (transactionResult.status === "FAILURE") {
            console.log("Payment Failed");
          } else {
            console.log("Transaction Interrupted");
          }
        })
        .catch((transactionError: any) => {
          console.log("Error in Transaction: " + transactionError.message);
        });
    } else {
      console.log(SecurityKey);
      let res = await createOrder({ amount: i });
      if (res?.data?.status) {
        const options = {
          key: SecurityKey,
          amount: i,
          order_id: res?.data?.data?.id,
          currency: "INR",
          name: AppContext?.user?.nameEnglish,
          prefill: {
            email: "iinfo@hindushadiconnect.com",
            contact: AppContext?.user?.mobile,
          },
          theme: {
            color: "#3399cc",
          },
        };
        try {
          let data = await Checkout.open(options);
          verifyPayment(data, profile, i);
        } catch (error: any) {
          let errorObj = JSON.parse(error["code"]);
          alert(errorObj.description);
        }
      }
    }
  };
  const payNowSpecialVip = async (i: any, profile: any) => {
    if (isPhonePe && Capacitor.getPlatform() === "android") {
      const transactionId =
        `JSM-${profile?.package_id}-${i}-` + uuidv4().toString().slice(-6);
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: AppContext?.phonePe?.merchantId,
          merchantTransactionId: transactionId,
          merchantUserId: AppContext?.phonePe?.merchantUserId,
          amount: i * 100,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: AppContext?.user?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody + "/pg/v1/pay" + String(AppContext?.phonePe?.appId)
      );
      const checksum = checksumUrl + "###" + 1;

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          console.log(
            "Transaction Result: " + JSON.stringify(transactionResult)
          );
          if (transactionResult.status === "SUCCESS") {
            let obj = {
              package_id: profile?.package_id,
              amount: i,
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            console.log("Payment Successful");
            if (res?.data?.status) {
              history.push(`/app/transaction/${transactionId}`);
            }
          } else if (transactionResult.status === "FAILURE") {
            console.log("Payment Failed");
          } else {
            console.log("Transaction Interrupted");
          }
        })
        .catch((transactionError: any) => {
          console.log("Error in Transaction: " + transactionError.message);
        });
    } else {
      console.log(SecurityKey);
      let res = await createOrder({ amount: i });
      if (res?.data?.status) {
        const options = {
          key: SecurityKey,
          amount: i,
          order_id: res?.data?.data?.id,
          currency: "INR",
          name: AppContext?.user?.nameEnglish,
          prefill: {
            email: "iinfo@hindushadiconnect.com",
            contact: AppContext?.user?.mobile,
          },
          theme: {
            color: "#3399cc",
          },
        };
        try {
          let data = await Checkout.open(options);
          verifyPayment(data, profile, i);
        } catch (error: any) {
          let errorObj = JSON.parse(error["code"]);
          alert(errorObj.description);
        }
      }
    }
  };
  const payNowAutoOffer = async (i: any, profile: any) => {
    if (isPhonePe && Capacitor.getPlatform() === "android") {
      const transactionId =
        `JSM-${profile?.id}-${i}-` + uuidv4().toString().slice(-6);
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: AppContext?.phonePe?.merchantId,
          merchantTransactionId: transactionId,
          merchantUserId: AppContext?.phonePe?.merchantUserId,
          amount: i * 100,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: AppContext?.user?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody + "/pg/v1/pay" + String(AppContext?.phonePe?.appId)
      );
      const checksum = checksumUrl + "###" + 1;

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          console.log(
            "Transaction Result: " + JSON.stringify(transactionResult)
          );
          if (transactionResult.status === "SUCCESS") {
            let obj = {
              package_id: profile?.id,
              amount: i,
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            console.log("Payment Successful");
            if (res?.data?.status) {
              history.push(`/app/transaction/${transactionId}`);
            }
          } else if (transactionResult.status === "FAILURE") {
            console.log("Payment Failed");
          } else {
            console.log("Transaction Interrupted");
          }
        })
        .catch((transactionError: any) => {
          console.log("Error in Transaction: " + transactionError.message);
        });
    } else {
      let res = await createOrder({ amount: i });
      if (res?.data?.status) {
        const options = {
          key: SecurityKey,
          amount: i,
          order_id: res?.data?.data?.id,
          currency: "INR",
          name: AppContext?.user?.nameEnglish,
          prefill: {
            email: "iinfo@hindushadiconnect.com",
            contact: AppContext?.user?.mobile,
          },
          theme: {
            color: "#3399cc",
          },
        };
        try {
          let data = await Checkout.open(options);
          verifyPaymentAutoOffer(data, profile, i);
        } catch (error: any) {
          let errorObj = JSON.parse(error["code"]);
          alert(errorObj.description);
        }
      }
    }
  };

  // const aleartFunction = useCallback(() => {
  //     if (formPercentage?.bioDataScore === 0 && localStorage.getItem("BioData") !== "no" && isClosePopUp) {
  //         presentAlert({
  //             header: 'Do you have Bio Data',
  //             cssClass: 'actionAlert',
  //             buttons: [
  //                 {
  //                     text: 'Yes', role: 'confirm',
  //                     handler: () => {
  //                         localStorage.setItem("BioData", "no")
  //                         setOpenPopUp(true)
  //                     },
  //                 },
  //                 {
  //                     text: 'no', role: 'cancel', handler: () => {
  //                         localStorage.setItem("BioData", "no")
  //                         AppContext.setPopupClose(true)
  //                     }
  //                 },
  //             ],
  //         })
  //     }
  // }, [formPercentage?.bioDataScore, presentAlert, isClosePopUp, AppContext])

  // useEffect(() => {
  //     if (formPercentage?.profilePictureScore === 10 && formPercentage?.sixthForm === 8)
  //         setTimeout(() => {
  //             aleartFunction()
  //         }, 180000);
  // }, [formPercentage, aleartFunction]);

  const slidesRef = useRef<null | HTMLIonSlidesElement>(null);
  const slidesRef2 = useRef<null | HTMLIonSlidesElement>(null);
  const slidesRef3 = useRef<null | HTMLIonSlidesElement>(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    delay: 1000,
    zoom: false,
  };

  const slideOpts2 = {
    initialSlide: 0,
    speed: 400,
    delay: 1000,
    zoom: false,
  };
  const slideOpts3 = {
    initialSlide: 0,
    speed: 400,
    delay: 1000,
    zoom: false,
  };

  const handleImageClick = (name: any) => {
    // console.log(name);
    const abc =
      name === "MEDIATOR SERVICE" || name === "HIDE SERVICE"
        ? "Special"
        : "VIP";
    localStorage.setItem("pkgSection", abc);
    AppContext.setPopups(constant.popups.isSubscribe, true);
  };

  const AutoPlay = () => {
    slidesRef.current?.startAutoplay();
  };
  const AutoPlay1 = () => {
    slidesRef2.current?.startAutoplay();
  };
  const AutoPlay2 = () => {
    slidesRef3.current?.startAutoplay();
  };

  useEffect(() => {
    const refferelUpdate = async () => {
      const { referrerUrl } = await InstallReferrer.getReferrerDetails();
      let obj = {
        user_id: AppContext?.user?.id,
        referral_code: JSON.stringify(referrerUrl),
      };
      try {
        // if (referrerUrl) await referralApi(obj);
      } catch (error) {
        console.error(error);
      }
    };
    refferelUpdate();
  }, []);

  const profileProgressScore = formPercentage?.profileScore / 100;
  return (
    <>
      {/* <button onClick={() => startPhonePeTransaction(100)}>
        Click Phone Teste
      </button> */}
      <div className="temp2">
        <IonSlides
          pager={true}
          options={slideOpts}
          ref={slidesRef}
          onIonSlidesDidLoad={AutoPlay}
        >
          {banners?.map((val: any) => {
            return (
              <IonSlide key={val?.id}>
                {val.clickable === "1" ? (
                  <>
                    <img
                      src={val.path}
                      loading="lazy"
                      alt=""
                      onClick={() => {
                        localStorage.setItem("pkgSection", "basic");
                        handleImageClick(val.name);
                      }}
                    />
                  </>
                ) : (
                  <img src={val.path} loading="lazy" alt="" />
                )}
              </IonSlide>
            );
          })}
        </IonSlides>
      </div>
      <IonCard className="card mb-0 ">
        <IonCardHeader
          className="py-3 pb-1"
          style={{ paddingLeft: 10, paddingRight: 12 }}
        >
          <IonCardTitle className="displayProp">
            <h4 className="f-15 progress-bar-text" style={{ color: "#000" }}>
              Your Trust Scale&nbsp;
              <IonText color="primary">
                {" "}
                {(formPercentage?.profileScore || 0).toFixed()}%
              </IonText>
            </h4>
            <p
              onClick={(event) => {
                event.stopPropagation();
                AppContext?.setInstantPopup(true);
              }}
              className="primaryFont "
            >
              <IonIcon
                icon={arrowUpOutline}
                style={{ verticalAlign: "sub", marginRight: 2, fontSize: 16 }}
              />
              Increase trust scale
            </p>
          </IonCardTitle>
          <IonProgressBar
            value={profileProgressScore}
            className="progress-height my-1"
            color="primary"
          ></IonProgressBar>
        </IonCardHeader>
      </IonCard>
      {/* autoOffer offers */}
      {splPkg === null && autoPackageData?.length > 0 && (
        <div className="temp2">
          <IonSlides
            className="custom-slides"
            pager={true}
            options={slideOpts3}
            ref={slidesRef3}
            onIonSlidesDidLoad={AutoPlay2}
          >
            {autoPackageData?.map((val: any) => {
              const isSavedPrice =
                val?.package?.package?.mrp - val?.package?.ammount_one;
              return (
                <>
                  <IonSlide
                    className="ionSlidesCustom"
                    onClick={() => {
                      payNowAutoOffer(
                        AppContext.user.no_offer_for_user
                          ? val?.package?.package?.mrp
                          : val?.package?.ammount_one,
                        val?.package?.package
                      );
                    }}
                  >
                    <IonCard className={"custom-card"}>
                      <IonGrid style={{ margin: "0", padding: "0" }}>
                        <IonRow style={{ margin: "0", padding: "0" }}>
                          <IonCol className="SplImage" size="6">
                            <IonImg
                              src={
                                val?.package?.package?.image
                                  ? `${constant.ImageS3URl}/users/packages/${val?.package?.package?.image}`
                                  : "https://mobile.hindushadiconnect.com/static/media/logo.16c6a0c2903eab924d6b.png"
                              }
                              style={{ width: "100%", height: "100%" }}
                            />
                          </IonCol>
                          <IonCol size="6">
                            <IonRow>
                              <IonCardHeader className={`CardBox`}>
                                <IonCardTitle
                                  className={`text-white CardBox-title-1`}
                                >
                                  {/* {val?.package?.package?.name?.english} */}
                                  {val?.package?.package?.month === 120
                                    ? "Till Marriage"
                                    : val?.package?.package?.month === 12
                                    ? "12 Months"
                                    : val?.package?.package?.month === 6
                                    ? "6 Months"
                                    : val?.package?.package?.name?.english}
                                </IonCardTitle>
                                <IonCardSubtitle
                                  className={`${
                                    AppContext.user.no_offer_for_user && "fs-3"
                                  } text-white CardBox-title-2`}
                                >
                                  {AppContext.user.no_offer_for_user ? (
                                    `₹${val?.package?.package?.mrp}`
                                  ) : (
                                    <>
                                      ₹{val?.package?.ammount_one}
                                      {isSavedPrice ? (
                                        <span className={`CardBox-title-3`}>
                                          <del>
                                            ₹{val?.package?.package?.mrp}
                                          </del>
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </IonCardSubtitle>
                                {!AppContext.user.no_offer_for_user &&
                                isSavedPrice ? (
                                  <IonCardSubtitle
                                    className={`text-white CardBox-title-4`}
                                  >
                                    You Have Saved ₹
                                    {val?.package?.package?.mrp -
                                      val?.package?.ammount_one}
                                  </IonCardSubtitle>
                                ) : null}
                              </IonCardHeader>
                            </IonRow>
                            <IonRow>
                              <IonCardContent
                                style={{ width: "100%", padding: "1px" }}
                              >
                                <CardTimerTest end_time={val?.endTime} />
                                {/* <CardTimerAutoOffer userCreatedAt={AppContext?.user?.created_at} end_time={val?.end_time} start_time={val?.displayTime} /> */}
                                <div className="row d-flex w-100 justify-content-center btnPay">
                                  <div
                                    style={{
                                      width: "90%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    className="col-8"
                                  >
                                    <div className="PayButtonSplPkg mx-auto">
                                      PAY NOW
                                    </div>
                                  </div>
                                </div>
                              </IonCardContent>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonSlide>
                </>
              );
            })}
          </IonSlides>
        </div>
      )}
      {/* special offers */}
      <div className="temp2">
        <IonSlides
          className="custom-slides"
          pager={true}
          options={slideOpts2}
          ref={slidesRef2}
          onIonSlidesDidLoad={AutoPlay1}
        >
          {splPkg?.map((val: any) => {
            const isSavedPrice =
              JSON.parse(val?.special_packages)?.offer_mrp -
              JSON.parse(val?.special_packages)?.amount;
            return (
              val?.status !== 0 && (
                <IonSlide
                  className="ionSlidesCustom"
                  onClick={() => {
                    payNowSpecialVip(
                      JSON.parse(val?.special_packages)?.amount,
                      val
                    );
                  }}
                >
                  <IonCard className="custom-card">
                    <IonGrid style={{ margin: "0", padding: "0" }}>
                      <IonRow style={{ margin: "0", padding: "0" }}>
                        <IonCol className="SplImage" size="6">
                          <IonImg
                            src={
                              JSON.parse(val?.special_packages)?.image
                                ? `${constant.ImageS3URl}/users/packages/${
                                    JSON.parse(val?.special_packages)?.image
                                  }`
                                : "https://mobile.hindushadiconnect.com/static/media/logo.16c6a0c2903eab924d6b.png"
                            }
                            style={{ width: "100%", height: "100%" }}
                          />
                        </IonCol>
                        <IonCol size="6">
                          <IonRow>
                            <IonCardHeader className="CardBox">
                              <IonCardTitle className="text-white CardBox-title-1">
                                {/* {JSON.parse(val?.special_packages)?.title} */}
                                {JSON.parse(
                                  val?.special_packages
                                )?.title?.trim() === "Platinum"
                                  ? "Till Marriage"
                                  : JSON.parse(val?.special_packages)?.title ===
                                    "Gold"
                                  ? "12 Months"
                                  : JSON.parse(val?.special_packages)?.title ===
                                    "Silver"
                                  ? "6 Months"
                                  : JSON.parse(val?.special_packages)?.title}
                              </IonCardTitle>
                              <IonCardSubtitle className="text-white CardBox-title-2">
                                ₹{JSON.parse(val?.special_packages)?.amount}
                                <span className="CardBox-title-3">
                                  <del>
                                    ₹
                                    {
                                      JSON.parse(val?.special_packages)
                                        ?.offer_mrp
                                    }
                                  </del>
                                </span>
                              </IonCardSubtitle>
                              {isSavedPrice ? (
                                <IonCardSubtitle className="text-white CardBox-title-4">
                                  You Have Saved ₹
                                  {JSON.parse(val?.special_packages)
                                    ?.offer_mrp -
                                    JSON.parse(val?.special_packages)?.amount}
                                </IonCardSubtitle>
                              ) : null}
                            </IonCardHeader>
                          </IonRow>
                          <IonRow>
                            <IonCardContent
                              style={{ width: "100%", padding: "1px" }}
                            >
                              <CardTimer data={val} />
                              <div className="row d-flex w-100 justify-content-center btnPay">
                                <div
                                  style={{
                                    width: "90%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  className="col-8"
                                >
                                  <div
                                    className="PayButtonSplPkg mx-auto"
                                    // onClick={() => {
                                    //     payNowSpecialVip(JSON.parse(val?.special_packages)?.amount, val)
                                    // }}
                                  >
                                    PAY NOW
                                  </div>
                                </div>
                              </div>
                            </IonCardContent>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonSlide>
              )
            );
          })}
        </IonSlides>
      </div>
      {/* universal offer */}
      {splPkg === null && autoPackageData.length === 0 && (
        <div className="temp2">
          <IonSlides
            className="custom-slides"
            pager={true}
            options={slideOpts3}
            ref={slidesRef3}
            onIonSlidesDidLoad={AutoPlay2}
          >
            {universalPkgList?.map((val: any) => {
              const isTimerZeroOrExpired = AppContext.user.no_offer_for_user
                ? true
                : new Date(val?.offer_end_date).getTime() <=
                  new Date().getTime();
              const isSavedPrice = val?.mrp - val?.price;
              return (
                <IonSlide
                  className="ionSlidesCustom"
                  onClick={() => {
                    let amount = AppContext?.user?.no_offer_for_user
                      ? val?.mrp
                      : val?.price;
                    payNowUniversal(amount, val);
                  }}
                >
                  <IonCard
                    className={`${
                      !isTimerZeroOrExpired
                        ? "custom-card"
                        : "custom-card-timeroff"
                    }`}
                  >
                    <IonGrid style={{ margin: "0", padding: "0" }}>
                      <IonRow style={{ margin: "0", padding: "0" }}>
                        <IonCol
                          className={`${
                            !isTimerZeroOrExpired
                              ? "SplImage"
                              : "SplImage-timeroff"
                          }`}
                          size="6"
                        >
                          <IonImg
                            src={
                              val?.image
                                ? `${constant.ImageS3URl}/users/packages/${val?.image}`
                                : "https://mobile.hindushadiconnect.com/static/media/logo.16c6a0c2903eab924d6b.png"
                            }
                          />
                        </IonCol>
                        <IonCol size="6">
                          <IonRow>
                            <IonCardHeader
                              className={`${
                                !isTimerZeroOrExpired
                                  ? "CardBox"
                                  : "CardBox-timeroff"
                              }`}
                            >
                              <IonCardTitle
                                className={`text-white ${
                                  !isTimerZeroOrExpired
                                    ? "CardBox-title-1"
                                    : "CardBox-title-1-timeroff"
                                }`}
                              >
                                {val?.month === 120
                                  ? "Till Marriage"
                                  : val?.month === 12
                                  ? "12 Months"
                                  : val?.month === 6
                                  ? "6 Months"
                                  : val?.name?.english}
                              </IonCardTitle>
                              <IonCardSubtitle
                                className={`${
                                  AppContext.user.no_offer_for_user && "fs-3"
                                } text-white ${
                                  !isTimerZeroOrExpired
                                    ? "CardBox-title-2"
                                    : "CardBox-title-2-timeroff"
                                }`}
                              >
                                {AppContext.user.no_offer_for_user ? (
                                  `₹${val?.mrp}`
                                ) : (
                                  <>
                                    ₹{val?.price}
                                    <span
                                      className={`${
                                        !isTimerZeroOrExpired
                                          ? "CardBox-title-3"
                                          : "CardBox-title-3-timeroff"
                                      }`}
                                    >
                                      {isSavedPrice ? (
                                        <del>₹{val?.mrp}</del>
                                      ) : null}
                                    </span>
                                  </>
                                )}
                              </IonCardSubtitle>
                              {!AppContext.user.no_offer_for_user &&
                                (isSavedPrice ? (
                                  <IonCardSubtitle
                                    className={`text-white ${
                                      !isTimerZeroOrExpired
                                        ? "CardBox-title-4"
                                        : "CardBox-title-4-timeroff"
                                    }`}
                                  >
                                    You Have Saved ₹{val?.mrp - val?.price}
                                  </IonCardSubtitle>
                                ) : null)}
                            </IonCardHeader>
                          </IonRow>
                          <IonRow>
                            <IonCardContent
                              style={{ width: "100%", padding: "1px" }}
                            >
                              {!isTimerZeroOrExpired && (
                                <CardTimerUniversal
                                  id={val?.id}
                                  setShowTimer={setShowTimer}
                                  data={val}
                                />
                              )}
                              <div
                                className={`row d-flex justify-content-center mx-auto ${
                                  !isTimerZeroOrExpired
                                    ? "btnPay"
                                    : "btnPay-timeroff"
                                }`}
                              >
                                <p
                                  className={`${
                                    !isTimerZeroOrExpired
                                      ? "PayButtonSplPkg"
                                      : "PayButtonSplPkg-timeroff"
                                  }`}
                                >
                                  PAY NOW
                                </p>
                              </div>
                            </IonCardContent>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonSlide>
              );
            })}
          </IonSlides>
        </div>
      )}

      <IonGrid className="dashboardScreen" style={{ padding: 0 }}>
        <div
          className="d-flex"
          style={{ overflowX: "scroll", overflowY: "hidden" }}
        >
          {/* golive starts */}
          {!live && (
            <IonCard
              className="card"
              style={{ minWidth: 250 }}
              onClick={() => {
                AppContext.setPopups(constant.popups.isGoLive, true);
              }}
            >
              <IonItem>
                <IonThumbnail slot="start" className="people-icon">
                  <div>
                    <IonIcon
                      icon={eyeOutline}
                      style={{
                        fontSize: 20,
                        margin: 6,
                      }}
                    />
                  </div>
                  <IonLabel>
                    <IonBadge
                      slot="start"
                      className="ion-badge"
                      color="primary"
                    >
                      +
                    </IonBadge>
                  </IonLabel>
                </IonThumbnail>
                <IonLabel>
                  <h3 className="userName blinkText">
                    <b>Publish Now</b>
                  </h3>
                  <p
                    className="fs-12 text-muted"
                    style={{ letterSpacing: "0.1px" }}
                  >
                    <small>
                      Add Your Basic <br />
                      Detail from here
                    </small>
                  </p>
                </IonLabel>
                <IonLabel slot="end" className="add-text">
                  Add 10%
                </IonLabel>
              </IonItem>
            </IonCard>
          )}
          {/* golive ends */}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            !AppContext?.user?.hindu_profiles?.find((i: any) => {
              return i?.type === "profile";
            }) && (
              <>
                <span
                  className="dragBoxDashboard"
                  onClick={() => {
                    AppContext.setPopups(constant.popups.isOpenProfile, true);
                    AppContext.setUpload({ key: "profile", name: "Profile" });
                  }}
                >
                  <IonIcon
                    icon={cameraOutline}
                    color="primary"
                    className="fs-24"
                  />
                  <br />
                  Upload Profile
                  <IonInput
                    className="drop-zone__input"
                    accept="image/*"
                    readonly
                  ></IonInput>
                </span>
              </>
            )}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            !AppContext?.user?.hindu_profiles?.find((i: any) => {
              return i?.type === "birth";
            }) && (
              <span
                className="dragBoxDashboard"
                onClick={() => {
                  AppContext.setPopups(constant.popups.isOpenProfile, true);
                  AppContext.setUpload({ key: "birth", name: "Biodata" });
                }}
              >
                <IonIcon
                  icon={cameraOutline}
                  color="primary"
                  className="fs-24"
                />
                <br />
                Upload Biodata
                <IonInput
                  className="drop-zone__input"
                  accept="image/*"
                  readonly
                ></IonInput>
              </span>
            )}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.fistFormFillValue < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 270 }}
                onClick={() => {
                  AppContext.setPopups(constant.popups.isFirstForm, true);
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={residental}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    {/* <div className='user-icon-bg'></div> */}
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Residential Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Add Candidate <br />
                        Residential Details
                      </small>
                    </p>
                  </IonLabel>
                  <IonLabel slot="end" className="add-text">
                    Add 10%
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          {/* <Firstform /> */}

          <SignUpModal
            isSignUpModal={isSignUpModal}
            setSignUpModal={setSignUpModal}
          />
          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.secondFormFillValue < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 270 }}
                onClick={() => {
                  AppContext.setPopups(constant.popups.isSecondForm, true);
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={Profession}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Professional Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Add Professional <br />
                        Detail from here
                      </small>
                    </p>
                  </IonLabel>
                  <IonLabel slot="end" className="add-text">
                    Add 10%
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          {/* <SecondForm /> */}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.thirdFormFillValue < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 250 }}
                onClick={() => {
                  AppContext.setPopups(constant.popups.isThirdForm, true);
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={Family}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Family Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Add Family <br />
                        Detail from here
                      </small>
                    </p>
                  </IonLabel>
                  <IonLabel slot="end" className="add-text">
                    Add 10%
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          {/* <ThirdForm /> */}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.fourthFormFillValue < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 250 }}
                onClick={() => {
                  AppContext.setPopups(constant.popups.isFourthForm, true);
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={birth}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Birth Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Add Birth <br />
                        Detail from here
                      </small>
                    </p>
                  </IonLabel>
                  <IonLabel slot="end" className="add-text">
                    Add 10%
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          {/* <FourthForm /> */}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.fifthForm < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 200 }}
                onClick={() => {
                  AppContext.setPopups(constant.popups.isFifthForm, true);
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={Religious}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Religious Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Add Religious <br /> Information Form
                      </small>
                    </p>
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}

          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            formPercentage?.sixthForm < 8 && (
              <IonCard
                className="card"
                style={{ minWidth: 200 }}
                onClick={() => {
                  AppContext.setPopups(
                    constant.popups.isPartnerPreference,
                    true
                  );
                }}
              >
                <IonItem>
                  <IonThumbnail slot="start" className="people-icon">
                    <div>
                      <img
                        loading="lazy"
                        src={preference}
                        style={{
                          width: 21,
                          margin: 6,
                        }}
                      />
                    </div>
                    <IonLabel>
                      <IonBadge
                        slot="start"
                        className="ion-badge"
                        color="primary"
                      >
                        +
                      </IonBadge>
                    </IonLabel>
                  </IonThumbnail>
                  <IonLabel>
                    <h3 className="userName" style={{ fontWeight: 700 }}>
                      Preference Form
                    </h3>
                    <p
                      className="fs-12 text-muted"
                      style={{ letterSpacing: "0.1px" }}
                    >
                      <small>
                        Partner <br /> Preference Form
                      </small>
                    </p>
                  </IonLabel>
                </IonItem>
              </IonCard>
            )}
          {(AppContext?.user?.verified_at !== 1 ||
            !AppContext?.user?.oldUser) &&
            !AppContext?.user?.goverment_ids_verified_at &&
            !AppContext?.user?.hindu_profiles?.find((i: any) => {
              return i?.type === "id" && i?.order_number === 10;
            }) && (
              <span
                className="dragBoxDashboard"
                onClick={() => {
                  AppContext.setPopups(constant.popups.isOpenProfile, true);
                  AppContext.setUpload({ key: "id", name: "ID Proof" });
                }}
              >
                <IonIcon
                  icon={cameraOutline}
                  color="primary"
                  className="fs-24"
                />
                <br />
                Upload Id Proof
                <IonInput className="drop-zone__input" readonly></IonInput>
              </span>
            )}
        </div>
      </IonGrid>
      {AppContext?.user?.payment_verified_at === 0 &&
        AppContext?.user?.payment_verified_at !== null && (
          <IonCard className="card mb-0 ">
            <IonCardHeader
              className=" py-1"
              style={{ paddingLeft: 10, paddingRight: 12 }}
            >
              <div className="displayProp">
                <IonLabel className="fs-15" style={{ color: "#000" }}>
                  <b>
                    <IonIcon
                      icon={cashOutline}
                      className="me-2 fs-18"
                      color="primary"
                      style={{ verticalAlign: "middle" }}
                    />
                    Pay Registration Fee
                  </b>
                </IonLabel>
                <IonButton
                  size="small"
                  onClick={() => {
                    AppContext.setPopups(constant.popups.isSubscribe, true);
                  }}
                >
                  <b>Pay Now</b>
                </IonButton>
              </div>
            </IonCardHeader>
          </IonCard>
        )}
      {bannerShow && (
        <IonCard
          className="card mb-0 mt-1"
          onClick={() => history.push("/app/profile/refer-earn")}
        >
          <img
            loading="lazy"
            src={friend}
            style={{ maxHeight: 240, objectFit: "cover", width: "100%" }}
          />
        </IonCard>
      )}

      {/* {((AppContext?.user?.payment_verified_at === 0) || (AppContext?.user?.payment_verified_at === 1 ? false : AppContext?.user?.payment_verified_at !== null)) && (
                <IonCard className='card mb-0 '>
                    <IonCardHeader className=' py-1' style={{ paddingLeft: 10, paddingRight: 12 }}>
                        <div className='displayProp'>
                            <IonLabel className='fs-15' style={{ color: "#000" }}>
                                <b>
                                    <IonIcon icon={cashOutline} className='me-2 fs-18' color='primary' style={{ verticalAlign: "middle" }} />
                                    Pay Registration Fee
                                </b>
                            </IonLabel>
                            <IonButton size='small' onClick={() => {
                                AppContext.setPopups(constant.popups.isSubscribe, true);
                            }}>
                                <b>Pay Now</b>
                            </IonButton>
                        </div>
                    </IonCardHeader>
                </IonCard>
            )} */}

      {/* Latest profile */}

      <div style={{}}>
        <IonRow style={{ alignItems: "center", margin: "8px 12px" }}>
          <IonCol size="5" style={{ padding: 0 }}>
            <p style={{ fontSize: 14, fontWeight: 600, margin: 0 }}>
              <b>
                <IonIcon
                  icon={heartCircleOutline}
                  color="primary"
                  size="small"
                  style={{ marginRight: 4, verticalAlign: "middle" }}
                />
                Latest Profile
              </b>
            </p>
          </IonCol>
          <IonCol size="4" style={{ padding: 0 }}>
            <div style={{ margin: "0px 0px 0px 0px", width: "auto" }}>
              <div style={{ padding: 0 }}>
                <div className="hr-border-red" style={{ height: 1 }}></div>
                <div className="hr-border-yellow" style={{ height: 1 }}></div>
                <div className="hr-border-green" style={{ height: 1 }}></div>
                <div className="hr-border-blue" style={{ height: 1 }}></div>
              </div>
            </div>
          </IonCol>
          <IonCol size="3" style={{ textAlign: "right", padding: 0 }}>
            <p
              style={{
                fontSize: 12,
                fontWeight: 600,
                margin: 0,
                cursor: "pointer",
              }}
              className="color-primary"
              onClick={() => {
                history.push({
                  pathname: "/app/latest-profile",
                  state: { order: "latest", name: "Latest Profile" },
                });
              }}
            >
              View All
            </p>
          </IonCol>
        </IonRow>
      </div>

      <div
        className="d-flex"
        style={{ overflowX: "scroll", overflowY: "hidden" }}
      >
        {loading ? (
          <>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonCard
              className="card"
              style={{
                minWidth: 220,
                height: "max-content",
                padding: "4px 0px",
              }}
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText
                    animated={true}
                    style={{ borderRadius: 5 }}
                  ></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "40%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonCard>
          </>
        ) : (
          <>
            {usersList?.map((user: any, i: any) => {
              let p = user?.hindu_profiles?.find((i: any) => {
                return i?.type === "profile" && i?.order_number === 10;
              });
              if (!p)
                p = user?.hindu_profiles?.find((i: any) => {
                  return i?.type === "profile";
                });
              return (
                <IonCard
                  className="card"
                  style={{
                    minWidth: 220,
                    height: "max-content",
                    padding: "4px 0px",
                  }}
                  onClick={() => {
                    setOpenDetail(true);
                    setUserID(user?.id);
                  }}
                >
                  <IonItem>
                    <IonThumbnail slot="start">
                      <img
                        alt="No img"
                        src={`${constant.ImageS3URl}/${p?.folder}/${p?.name}`}
                        style={{ borderRadius: 5 }}
                      />
                    </IonThumbnail>
                    <IonLabel>
                      <p
                        className="color-primary  font-weight-500 m-0"
                        style={{ fontSize: 10, lineHeight: "12px" }}
                      >
                        <b>HSC-{user?.id}</b>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "12px",
                          lineHeight: "22px",
                          textTransform: "capitalize",
                        }}
                      >
                        <b className="ellispses">{user?.nameEnglish} </b>
                      </div>
                      <p
                        className="text-muted"
                        style={{
                          letterSpacing: "0.1px",
                          fontSize: 10,
                          lineHeight: "13px",
                        }}
                      >
                        <b>
                          {" "}
                          {user?._age !== null
                            ? user?._age
                            : moment().diff(user?.dob, "years")}
                          yrs {user?.third_formSectEnglish ? "|" : ""}{" "}
                          {user?.third_formSectEnglish}
                        </b>
                      </p>
                    </IonLabel>
                  </IonItem>
                </IonCard>
              );
            })}
          </>
        )}
        <Details
          isOpenDetail={isOpenDetail}
          setOpenDetail={setOpenDetail}
          id={userID}
        />
      </div>
      <PopUp
        isOpenPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        img={payment}
        goTo={goTo}
        cancel={false}
        componentName={"payment"}
      />
    </>
  );
};
export default UserForms;
