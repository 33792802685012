import { IonCard, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import { IonIcon } from '@ionic/react';
import { IonInput, IonList, IonButton } from '@ionic/react';
import appContext from "../../context/AppContext"
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { arrowBackOutline, checkmarkDoneOutline } from 'ionicons/icons';
import { signUp } from '../../service';
import moment from 'moment';
import Firstform from './firstform';
import SecondForm from './SecondForm';
import FourthForm from './FourthForm';
import ThirdForm from './ThirdForm';
import FifthForm from './FifthForm';
import SixthForm from './SixthForm';
import constant from '../../constant';
import { toast } from 'react-toastify';
import CustomSelect from '../shared/CustomSelect';


const monthOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11 ", value: "11 " },
    { label: "12 ", value: "12 " },
]

const ProfileEdit: React.FC = () => {
    const AppContext = useContext(appContext);
    const [dateErr, setDateErr] = useState(false)
    const [edit, setEdit] = useState<any>({
        firstEdit: false,
        secondEdit: false,
        thirdEdit: false,
        fourthEdit: false,
        fifthEdit: false,
    });
    const [dateTime, setDateTime] = useState<any>(null);
    const history = useHistory();

    let initialValues = {
        nameEnglish: AppContext?.user?.nameEnglish,
        dob: AppContext?.user?.dob !== null ? AppContext?.user?.dob : null,
        email: AppContext?.user?.email,
        first_formProfileEnglish: AppContext?.user?.first_formProfileEnglish,
        first_formMaritalStatusEnglish: AppContext?.user?.first_formMaritalStatusEnglish,
    };

    const {
        register,
        handleSubmit,
        control,
        getValues,
        watch,
        formState: { errors }
    }: any = useForm({ defaultValues: initialValues });

    // console.log("DOB :", AppContext?.user?.dob);

    const currentYear = (new Date()).getFullYear();
    const year = [];
    for (let index = 18; index < 81; index++) {
        year.push({ label: (currentYear - index), value: (currentYear - index) });
    }


    const day = [];
    for (let index = 1; index < 32; index++) {
        day.push({ label: index, value: index });
    }

    const watchyears = watch("years");
    const watchmonths = watch("months");
    const watchdays = watch("days");
    useEffect(() => {
        let year: any = getValues("years")
        let month: any = getValues("months")
        let day: any = getValues("days")
        if (year && month && day) {
            const newDate = new Date(year?.value, month?.value - 1, day?.value + 1);
            setDateTime({ ...dateTime, Date: newDate })
        }
    }, [watchyears, watchmonths, watchdays, getValues]);



    useEffect(() => {
        if (AppContext?.user?.dob) setDateTime(new Date(AppContext?.user?.dob))
        else setDateTime(null)
    }, [AppContext?.user?.dob]);

    const profileArray = ['Self', 'Son', 'Daughter', 'Brother', 'Sister', 'Relative', 'Friend'];
    const ageArray = [];
    for (let index = 16; index < 41; index++) {
        ageArray.push(index);
    }

    const onSubmit = async (values: any) => {
        if (dateTime.Date === "") { setDateErr(true) }
        values.dob = dateTime;
        AppContext.setLoading(true);
        var response = await signUp(values);
        if (response?.data?.status) {
            AppContext.setLoading(false);
            AppContext.updateUser();
            toast("Update data Successfully");
        }
        else {
            toast(response?.data?.errors);
        }
    }
    var minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 80))
    var maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
    return (
        <IonPage >
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <Link to='/app/profile' slot='start' className='backBtn'>
                        <IonButton type="submit" className="px-1" onClick={() => { history.goBack() }}>
                            <IonIcon icon={arrowBackOutline} color="light" />
                        </IonButton>
                    </Link>
                    <IonTitle color='dark' className='fs-18 text-white'><b>Profile Edit</b></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='settingBody'>
                <div style={{ marginBottom: "20%" }}>
                    <IonCard className='card'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <IonList class='p-20'>
                                <IonItem>
                                    <IonLabel position="stacked" className='fs-16 labelItem'>Candidate Name</IonLabel>
                                    <IonInput disabled placeholder="Candidate Name" className='border-bottom' {...register("nameEnglish", { required: true })} onIonChange={() => { setEdit({ ...edit, secondEdit: true }) }}></IonInput>
                                    {edit.secondEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                    {errors.nameEnglish && errors.nameEnglish.type === "required" && <p className='errorText ms-1'> Name is required</p>}

                                </IonItem>
                                <IonItem >
                                    <IonLabel position="stacked" className='fs-16 labelItem'> Birth Date<span className='errorText m-0' >*</span></IonLabel>
                                    <IonInput
                                        id="click-trigger"
                                        value={dateTime ? moment(dateTime).format('MM/DD/YYYY') : ''}
                                        placeholder="Select Date"
                                        {...register("dob", { required: true })}
                                        className='border-bottom'
                                    >
                                    </IonInput>
                                    <IonPopover trigger="click-trigger" triggerAction="click" >
                                        <IonDatetime showDefaultButtons={true} presentation="date" value={dateTime} onIonChange={(e: any) => { setDateTime(e.target.value); setEdit({ ...edit, fifthEdit: true }) }}></IonDatetime>
                                    </IonPopover>
                                    {edit.fifthEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                    {errors.dob && errors.dob.type === "required" && <p className='errorText ms-1'> dob is required</p>}

                                </IonItem>
                                {/* <IonItem style={{ position: "relative", zIndex: 999, overflow: "visible" }}>
                                    <IonLabel position="stacked" className='fs-16 labelItem'> Birth Date<span className='errorText m-0' >*</span></IonLabel>
                                    <IonRow style={{ marginTop: "0.5em", width: "100%" }}>
                                        <div style={{ flex: "35%" }}><IonCol>
                                            <CustomSelect isSearchable={false}
                                                options={year}
                                                control={control}
                                                name={"years"}
                                                placeholder={"Year"}
                                            />
                                        </IonCol>
                                        </div>
                                        <div style={{ flex: "30%" }}>
                                            <IonCol>
                                                <CustomSelect isSearchable={false}
                                                    options={monthOptions}
                                                    control={control}
                                                    name={"months"}
                                                    placeholder={"Month"}
                                                />
                                            </IonCol>
                                        </div>
                                        <div style={{ flex: "22%" }}>
                                            <IonCol>
                                                <CustomSelect isSearchable={false}
                                                    options={day}
                                                    control={control}
                                                    name={"days"}
                                                    placeholder={"Day"}
                                                />
                                            </IonCol>
                                        </div>
                                    </IonRow>
                                    {dateErr && <p className='errorText ms-1'>Birth Date is required</p>}

                                </IonItem> */}
                                <IonItem>
                                    <IonLabel position="stacked" className='fs-16 labelItem'>Email ID</IonLabel>
                                    <IonInput type='email' placeholder="Candidate Email ID" className='border-bottom' {...register("email")} onIonChange={() => { setEdit({ ...edit, firstEdit: true }) }}></IonInput>
                                    {edit.firstEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                </IonItem>
                                {/* <IonItem>
                                    <IonLabel position="stacked" className='fs-16 labelItem'>Date of Birth</IonLabel>
                                    <IonSelect className='border-bottom' interface="popover" placeholder="Select" {...register("profile", { required: true })} onIonChange={() => { setEdit({ secondEdit: true }) }}>
                                        {profileArray?.map(profile => {
                                            return <IonSelectOption value={profile}>{profile}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                    {edit.secondEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                </IonItem> */}
                                {errors.profile && errors.profile.type === "required" && <p className='errorText ms-0 mt-2'>Profile is required</p>}
                                <IonItem className='edit' >
                                    <IonLabel position="stacked" className='fs-16 labelItem'>Profile created for</IonLabel>
                                    <IonSelect className='border-bottom ' interface="popover" placeholder="Select Profile Created For" {...register("first_formProfileEnglish", { required: true })} onIonChange={() => { setEdit({ ...edit, thirdEdit: true }) }}>
                                        {profileArray?.map(profile => {
                                            return <IonSelectOption value={profile}>{profile}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                    {edit.thirdEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                    {errors.first_formProfileEnglish && errors.first_formProfileEnglish.type === "required" && <p className='errorText ms-0 mt-2'>Profile is required</p>}
                                </IonItem>

                                {/* <IonItem className='edit'>
                                    <IonLabel position="stacked" className='fs-16 labelItem'>Marital status</IonLabel>
                                    <IonSelect className='border-bottom ' interface="popover" placeholder="Select Marital Status"  {...register("first_formMaritalStatusEnglish")} onIonChange={() => { setEdit({ ...edit, fourthEdit: true }) }}>
                                        <IonSelectOption value="Unmarried">Unmarried</IonSelectOption>
                                        <IonSelectOption value="Widow/Widower">Widow/Widower</IonSelectOption>
                                        <IonSelectOption value="Divorce">Divorce</IonSelectOption>
                                        <IonSelectOption value="Awaiting divorce">Awaiting divorce</IonSelectOption>
                                    </IonSelect>
                                    {errors.first_formMaritalStatusEnglish && errors.first_formMaritalStatusEnglish.type === "required" && <p className='errorText ms-0 mt-2'>MaritalStatus is required</p>}
                                    {edit.fourthEdit &&
                                        <div slot='end' className='backBtn' style={{ margin: "24px 0px 0px 10px" }}>
                                            <IonButton type="submit">
                                                <IonIcon icon={checkmarkDoneOutline} color="light" />
                                            </IonButton>
                                        </div>}
                                </IonItem> */}
                            </IonList>
                        </form>
                        <IonItem className='mt-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isThirdForm, true); }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Family Detail
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <ThirdForm /> */}

                        <IonItem className='mt-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isFirstForm, true); }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Residential Detail
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <Firstform /> */}

                        <IonItem className='mt-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isFourthForm, true); }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Birth Detail
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <FourthForm /> */}

                        <IonItem className='my-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isSecondForm, true) }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Professional Detail
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <SecondForm /> */}

                        <IonItem className='my-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isFifthForm, true); }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Religious Information
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <FifthForm /> */}

                        <IonItem className='my-1'>
                            <div onClick={() => { AppContext.setPopups(constant.popups.isPartnerPreference, true) }} slot='start' className='backBtn'>
                                <IonButton type="submit" className="px-1" >
                                    <IonIcon icon={arrowBackOutline} color="light" /> Edit Partner Preference
                                </IonButton>
                            </div>
                        </IonItem>
                        {/* <SixthForm /> */}

                    </IonCard>
                </div>
            </IonContent>
        </IonPage >
    );
};
export default ProfileEdit;