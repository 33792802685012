import {
  IonAvatar,
  IonContent,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonSkeletonText,
  IonThumbnail,
  useIonActionSheet,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonButton, IonText } from "@ionic/react";
import appContext from "../../context/AppContext";
import { chevronForwardOutline } from "ionicons/icons";
import constant from "../../constant";
import { useHistory, useLocation } from "react-router-dom";
import {
  cameraOutline,
  documentsOutline,
  banOutline,
  checkmarkDoneCircleOutline,
  documentTextOutline,
  addCircleOutline,
  personAddOutline,
  personCircleOutline,
  logOutOutline,
  call,
  createOutline,
  locationOutline,
  notificationsOutline,
  cashOutline,
  flashOutline,
  peopleCircleOutline,
  flashOffOutline,
  cog,
  eyeOutline,
  bagCheckSharp,
  diamondOutline,
  bagCheckOutline,
} from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  getProfileStats,
  profileAction,
  profileUpload,
  profileList,
  authorizeMe,
  generateToken,
  chnageLoginStatus,
} from "../../service";
import { decode } from "base64-arraybuffer";
import moment from "moment";
import Packages from "./Packages";
import Header from "../shared/Header";
import Utils from "../utils";
import Details from "./Details";
import kalash from "../../assets/images/kalash.png";
import swastik from "../../assets/images/swastik.png";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import UserProfileView from "./UserProfileView";

interface UserProfile {
  id: number;
  name: string;
}

const Profile: React.FC = () => {
  const [presentAction] = useIonActionSheet();
  const history = useHistory();
  const [image, setImage] = useState<any>(null);
  const { state }: any = useLocation();
  const [stats, setStats] = useState<any>(null);
  const AppContext = useContext(appContext);
  const [DeviceToken, setDeviceToken] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [presentAlert, present] = useIonAlert();
  const [userList, setUserList] = useState([]);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [isAppUpdating, setIsAppUpdating] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<UserProfile | null>(
    null
  );
  const [showUserListModal, setShowUserListModal] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);

  var data: any = localStorage.getItem("responseData");
  var res = JSON.parse(data);
  localStorage.setItem("lastPage", window.location.pathname);

  useEffect(() => {
    const getUserlist = async () => {
      if (state) {
        var response = await profileList(
          { mobile: AppContext?.user?.mobile },
          AppContext?.user?.token
        );
        setUserList(response?.data?.data);
      } else {
        var response = await profileList(
          { mobile: AppContext?.user?.mobile },
          res?.accessToken
        );
        setUserList(response?.data?.data);
      }
    };
    if (state?.accessToken || res?.accessToken) {
      getUserlist();
    }
  }, [state?.mobile, state?.accessToken]);

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
      setDeviceToken(token.value);
    });
  }, [DeviceToken]);

  const loginNewUser = async (selectedUser: any) => {
    let p = selectedUser?.profiles?.find((i: any) => {
      return i?.type === "profile" && i?.order_number === 10;
    });
    if (!p)
      p = selectedUser?.profiles?.find((i: any) => {
        return i?.type === "profile";
      });
    let url = p
      ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
      : Utils.getDefaultUserImg(selectedUser?.first_formGenderEnglish);
    presentAlert({
      header: `Login with ${selectedUser?.nameEnglish}?`,
      message: `
            <IonItem>
                <IonThumbnail className='ion-margin-end'>
                <img  alt="Silhouette of mountains" src=${url} >
                </IonThumbnail>
            </IonItem>
            `,
      cssClass: "actionAlert",
      buttons: [
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            AppContext.setLoading(true);
            var response = await generateToken(
              { id: selectedUser.id, app_id: DeviceToken },
              localStorage.getItem("jsm_user")
            );
            if (response.data.status) {
              authorizeMe(response?.data?.data?.accessToken);
              AppContext.setUser({
                ...response?.data?.data?.user,
                token: response?.data?.data?.accessToken,
              });
              AppContext?.setIsLoggedIn(true);
              AppContext.setLoading(false);
              history.replace("/");
              window.location.assign("/");
              // console.log("TOKEN: ", response?.data?.data?.accessToken);
            } else {
              AppContext.setLoading(false);
              // console.log("LOGINERROR: ", response?.data?.errors);
            }
          },
        },
        {
          text: "Change User",
          role: "cancel",
        },
      ],
    });
  };

  const handleSwitchClick = () => {
    if (showUserListModal === true) {
      setShowUserListModal(false);
    }
    if (showUserListModal === false) {
      setShowUserListModal(true);
    }
  };

  useEffect(() => {
    if (AppContext.user && AppContext.user?.profiles?.length) {
      let p = AppContext?.user?.hindu_profiles?.find((i: any) => {
        return i?.type === "profile" && i?.order_number === 10;
      });
      if (!p)
        p = AppContext?.user?.hindu_profiles?.find((i: any) => {
          return i?.type === "profile";
        });

      if (p) setImage(`${constant.ImageS3URl}/${p?.folder}/${p?.name}`);
    }
    if (AppContext.user) getProfileStatsF();
    setUserID(AppContext?.user?.id);
  }, [AppContext.user]);

  const getProfileStatsF = async () => {
    let res = await getProfileStats();
    setStats(res?.data?.data);
  };

  const profiles = [
    {
      profile: " Edit Profile Detail ",
      icon: createOutline,
      link: "/#",
      click: () => {
        history.push("/app/profile/profile-edit");
      },
    },
    {
      profile: "Your Visited profile ",
      icon: personAddOutline,
      link: "/#",
      click: () => {
        history.push("/app/profile/profile-view");
      },
    },
    {
      profile: "Preview Own Profile",
      icon: eyeOutline,
      link: "/#",
      click: () => {
        setOpenDetail(true);
      },
    },
    {
      profile: "Profile Picture",
      icon: personCircleOutline,
      link: "/#",
      click: () => {
        AppContext.setPopups(constant.popups.isOpenProfile, true);
        AppContext.setUpload({ key: "profile", name: "Profile" });
      },
    },
    {
      profile: "Biodata",
      icon: documentTextOutline,
      link: "/#",
      click: () => {
        AppContext.setPopups(constant.popups.isOpenProfile, true);
        AppContext.setUpload({ key: "birth", name: "Biodata" });
      },
    },
    {
      profile: "Id Proof",
      icon: documentsOutline,
      link: "/#",
      click: () => {
        AppContext.setPopups(constant.popups.isOpenProfile, true);
        AppContext.setUpload({ key: "id", name: "ID Proof" });
      },
    },
    {
      profile: "View Packages",
      icon: cashOutline,
      link: "/#",
      click: () => {
        localStorage.removeItem("activeUser");
        AppContext.setPopups(constant.popups.isSubscribe, true, true);
      },
    },
    {
      profile: "Latest Profile",
      icon: flashOutline,
      link: "/#",
      click: () => {
        history.push({
          pathname: "/app/profile/second-marrige",
          state: { order: "latest", name: "Latest Profile" },
        });
      },
    },

    {
      profile: "35+ Profile",
      icon: peopleCircleOutline,
      link: "/#",
      click: () => {
        history.push({
          pathname: "/app/profile/second-marrige",
          state: { thirtyFive: true, name: "35+ Profile" },
        });
      },
    },
    {
      profile: "Second Marrige",
      icon: flashOffOutline,
      link: "/second-marrige",
      click: () => {
        history.push({
          pathname: "/app/profile/second-marrige",
          state: { secondMarriage: true, name: "Second Marrige Profile" },
        });
      },
    },
    {
      profile: "Blocked Users",
      icon: banOutline,
      link: "/blocked-user",
      click: () => {
        history.push("/app/profile/blocked-user");
      },
    },
    {
      profile: "Shadi Done Users",
      icon: checkmarkDoneCircleOutline,
      link: "/shadi-done-users",
      click: () => {
        history.push("/app/profile/shadi-done-users");
      },
    },
    {
      profile: "Refer And Earn",
      icon: diamondOutline,
      link: "/second-marrige",
      click: () => {
        history.push("/app/profile/refer-earn");
      },
    },
    // {
    //     "profile": "Apply for Part Time  Job",
    //     "icon": bagCheckOutline,
    //     "link": "/second-marrige",
    //     click: () => {
    //         // history.push({
    //         //     pathname: '/app/profile/second-marrige',
    //         //     state: { "secondMarriage": true, "name": "Second Marrige Profile" }
    //         // })
    //     }
    // },
    {
      profile: "Settings",
      icon: cog,
      link: "/#",
      click: () => {
        history.push("/app/profile/settings");
      },
    },
    {
      profile: "Update Available",
      icon: cog,
      link: "/#",
      click: () => {
        updateClick();
      },
    },
    {
      profile: "Logout",
      icon: logOutOutline,
      click: () => {
        presentAlert({
          subHeader: "Are you sure want to Logout?",
          cssClass: "actionAlert",
          buttons: [
            {
              text: "Yes",
              role: "confirm",
              handler: async () => {
                await chnageLoginStatus();
                AppContext.resetUser(true);
              },
            },
            {
              text: "no",
              role: "cancel",
            },
          ],
        });
      },
    },
  ];

  const uploadProfile = async (source: any) => {
    const image: any = await Camera.getPhoto({
      quality: 50,
      correctOrientation: true,
      resultType: CameraResultType.Base64,
      source: source === "Gallery" ? CameraSource.Photos : CameraSource.Camera,
    });
    setImage("data:image/jpeg;base64," + image.base64String);
    const blob = new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,
    });
    const file = new File([blob], "Name", {
      lastModified: moment().unix(),
      type: blob.type,
    });
    var bodyFormData: any = new FormData();
    bodyFormData.append("images", file);
    var res = await profileUpload("profile", bodyFormData);
    var obj1 = { id: res?.data?.data?.id, action: "profile" };
    await profileAction(obj1);
    AppContext.updateUser();
  };

  const pickImage = async () => {
    await presentAction({
      cssClass: "myMenu",
      buttons: [
        {
          text: "Add From Album",
          data: {
            action: "Add From Album",
          },
          handler: () => {
            uploadProfile("Gallery");
          },
        },
        {
          text: " Use Camera",
          data: {
            action: "Use Camera",
          },
          handler: () => {
            uploadProfile("Camera");
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          data: {
            action: "cancel",
          },
        },
      ],
      onDidDismiss: () => {},
    });
  };

  // console.log("state :", state);
  const test = () => {
    let obj = {
      accessToken: AppContext?.user?.token,
      mobile: AppContext?.user?.mobile,
    };
    obj = JSON.parse(JSON.stringify(obj));
    AppContext.setIsLoggedIn(false);
    history.replace({ pathname: "/user-list", state: obj });
    // history.push({ pathname: '/signup-form', state: obj });
    window.location.replace("/user-list");
    // window.location.replace('/signup-form');
  };

  const updateClick = async () => {
    setIsAppUpdating(true);
    let res = await AppContext.forceUpdate();
    setIsAppUpdating(false);
  };
  function calculateBreakpoint(userListLength: any) {
    const baseBreakpoint = 0.31;
    const increment = 0.1;
    const numberOfAdditionalItems = 0.23;
    const breakpoint =
      baseBreakpoint +
      increment * (userListLength - 4 === 0 ? 1 : userListLength - 3) +
      numberOfAdditionalItems;
    console.log("breakpoint:", breakpoint);
    return breakpoint;
  }

  // const bottom = userList.length === 2 ? "0.1em" : "0.7em";
  const marginTop = userList.length === 2 ? "0.4em" : "0.1em";

  return (
    <>
      <div className="header-common-box">
        <Header />
      </div>
      <IonPage className="profile-body">
        <UserProfileView
          isOpenDetail={isOpenDetail}
          setOpenDetail={setOpenDetail}
          id={userID}
        />
        {/* <Details isOpenDetail={isOpenDetail} setOpenDetail={setOpenDetail} id={userID} /> */}
        {/* <MultipleProfileUpload /> */}
        <div style={{ height: "100vh" }} className="profileBody">
          <IonContent>
            <div className="topCard ion-text-right">
              {/* <IonButton className='outlineBtn' onClick={() => { AppContext.setNotification(true) }} >
                                <IonIcon icon={notificationsOutline} />
                            </IonButton> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 212,
                    height: 217,
                    position: "absolute",
                    zIndex: 2,
                    top: "33px",
                  }}
                >
                  <img
                    loading="lazy"
                    src={swastik}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                className="text-center"
                style={{ zIndex: 2, position: "relative" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ height: "38px", width: "38px" }}>
                    <img
                      loading="lazy"
                      src={kalash}
                      style={{ maxHeight: 36, objectFit: "cover" }}
                    />
                  </div>
                  <h3
                    className="light mb-1 font-weight-600"
                    color="light"
                    style={{
                      textTransform: "capitalize",
                      alignSelf: "center",
                      whiteSpace: "nowrap",
                      marginRight: "2px",
                      position: "relative",
                      top: "3px",
                    }}
                  >
                    ।। श्री गणेशाय नमः ।।
                  </h3>
                  <div style={{ height: "38px", width: "38px" }}>
                    <img
                      loading="lazy"
                      src={kalash}
                      style={{ maxHeight: 36, objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div
                  className="profile-user position-relative d-inline-block mx-auto  mb-3"
                  style={{ marginTop: "2px" }}
                >
                  <div
                    onClick={() => {
                      AppContext.setPopups(constant.popups.isOpenProfile, true);
                      AppContext.setUpload({ key: "profile", name: "Profile" });
                    }}
                  >
                    <img
                      src={
                        image
                          ? image
                          : "https://ionicframework.com/docs/img/demos/avatar.svg"
                      }
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                      alt="abc"
                    />
                  </div>
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                      onClick={pickImage}
                    >
                      <span className="avatar-title rounded-circle bg-light text-body shadow">
                        <IonIcon
                          className="fs-18 font-weight-700"
                          icon={cameraOutline}
                        />
                      </span>
                    </label>
                  </div>
                </div>
                <p className="fs-12 mb-1" style={{ color: "#fff" }}>
                  HSC-{AppContext?.user?.id}
                </p>
                <h5
                  className="light mb-1 font-weight-600"
                  color="light"
                  style={{ textTransform: "capitalize" }}
                >
                  {AppContext?.user?.nameEnglish}{" "}
                </h5>
                <p className="mb-0 light profile-number" color="light">
                  <IonIcon icon={call} color="light" /> +91{" "}
                  {AppContext?.user?.mobile}
                </p>
                {AppContext?.user?.second_formCityEnglish ||
                AppContext?.user?.second_formStateEnglish ? (
                  <p className="light profile-number">
                    <IonIcon icon={locationOutline} color="light" />{" "}
                    {AppContext?.user?.second_formCityEnglish},{" "}
                    {AppContext?.user?.second_formStateEnglish}
                  </p>
                ) : null}
                {/* {(AppContext?.user?.second_formCityEnglish || AppContext?.user?.second_formStateEnglish)
                                ? <p className="light"><IonIcon icon={locationOutline} color="light" /> {Object.keys(AppContext?.user?.second_formCityEnglish).length ? '' : AppContext?.user?.second_formCityEnglish}, {Object.keys(AppContext?.user?.second_formStateEnglish).length ? '' : AppContext?.user?.second_formStateEnglish}</p>
                                : null
                            } */}
              </div>
              <div className="bottomCard">
                <IonRow className="ion-justify-content-center text-center p-2">
                  <IonCol size="4">
                    <div className="box p-2">
                      <h6 className="dark font-weight-700 fs-12 mt-2">View</h6>
                      <IonText color="primary">
                        <h3 className="fs-22 font-weight-700">
                          {stats?.publicView
                            ? Math.abs(stats?.publicView) > 999
                              ? Math.sign(stats?.publicView) *
                                  (Math.abs(stats?.publicView) / 1000) +
                                "K"
                              : Math.sign(stats?.publicView) *
                                Math.abs(stats?.publicView)
                            : 0}
                        </h3>
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="box p-2">
                      <h6 className="dark font-weight-700 fs-12 mt-2">
                        Accepted
                      </h6>
                      <IonText color="primary">
                        <h3 className="fs-22 font-weight-700">
                          {stats?.accept
                            ? Math.abs(stats?.accept) > 999
                              ? Math.sign(stats?.accept) *
                                  (Math.abs(stats?.accept) / 1000) +
                                "K"
                              : Math.sign(stats?.accept) *
                                Math.abs(stats?.accept)
                            : 0}
                        </h3>
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="4" onClick={test}>
                    <div className="box p-2">
                      <h6 className="dark font-weight-700 fs-12 mt-2">
                        Switch
                      </h6>
                      <IonText color="primary">
                        <h3 className="fs-22 font-weight-700">
                          {stats?.totalAccount
                            ? Math.abs(stats?.totalAccount) > 999
                              ? Math.sign(stats?.totalAccount) *
                                  (Math.abs(stats?.totalAccount) / 1000) +
                                "K"
                              : Math.sign(stats?.totalAccount) *
                                Math.abs(stats?.totalAccount)
                            : 0}
                        </h3>
                      </IonText>
                    </div>
                  </IonCol>
                  {/* <IonCol size="4" onClick={handleSwitchClick}>
                                        <div className='box p-2'>
                                            <h6 className='dark font-weight-700 fs-12 mt-2'>Switch</h6>
                                            <IonText color='primary'><h3 className='fs-22 font-weight-700'>{stats?.totalAccount ? Math.abs(stats?.totalAccount) > 999 ? Math.sign(stats?.totalAccount) * (Math.abs(stats?.totalAccount) / 1000) + 'K' : Math.sign(stats?.totalAccount) * Math.abs(stats?.totalAccount) : 0}</h3></IonText>
                                        </div>
                                    </IonCol> */}
                </IonRow>
                <IonRow className="ion-justify-content-center text-center p-2">
                  <IonCol size="12">
                    {profiles?.map((o, index) => {
                      return (
                        <>
                          {o.profile === "Update Available" &&
                            AppContext?.isUpdateAvailable && (
                              <IonItem
                                key={index}
                                detail={true}
                                onClick={() => {
                                  o.click();
                                }}
                              >
                                <IonIcon
                                  color="primary"
                                  className="fs-24 ms-2 me-3"
                                  icon={o?.icon}
                                />
                                <IonLabel
                                  className="text-muted  font-weight-700"
                                  style={{ fontSize: 14 }}
                                >
                                  {o?.profile}
                                </IonLabel>
                              </IonItem>
                            )}
                          {o.profile !== "Update Available" && (
                            <IonItem
                              key={index}
                              detail={true}
                              onClick={() => {
                                o.click();
                              }}
                            >
                              <IonIcon
                                color="primary"
                                className="fs-24 ms-2 me-3"
                                icon={o?.icon}
                              />
                              <IonLabel
                                className="text-muted  font-weight-700"
                                style={{ fontSize: 14 }}
                              >
                                {o?.profile}
                              </IonLabel>
                            </IonItem>
                          )}
                        </>
                      );
                    })}
                  </IonCol>
                </IonRow>
                <span style={{ fontSize: 10, paddingRight: 10, color: "grey" }}>
                  {" "}
                  version: 0.0.{AppContext?.currentVersion}
                </span>
              </div>
            </div>
            {/* {showUserListModal && (
                            <IonModal isOpen={showUserListModal} style={{ '--border-radius': '20px 20px 0 0', bottom: "0" }} initialBreakpoint={calculateBreakpoint(userList.length)} breakpoints={[0.54, 1]} backdropBreakpoint={0.2}>
                                <IonContent style={{ position: "fixed", bottom: "0" }}>
                                    <IonList className="ion-padding ion-margin-top" style={{ margin: "-1.1em", marginBottom: "-1.5em" }}>
                                        {userList?.map((user: any, i: any) => {
                                            let p = user?.profiles?.find((i: any) => { return i?.type === "profile" && i?.order_number === 10 });
                                            if (!p) p = user?.profiles?.find((i: any) => { return i?.type === "profile" });
                                            return (
                                                <IonItem key={i} onClick={() => { loginNewUser(user) }}>
                                                    <IonThumbnail style={{ height: "4em", width: "4em", borderRadius: "50%" }} className='ion-margin-end'>
                                                        <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} alt="Silhouette of mountains" src={p ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}` : Utils.getDefaultUserImg(user?.first_formGenderEnglish)} />
                                                    </IonThumbnail>
                                                    <IonLabel>
                                                        <h3 className='userName'><b>{user?.nameEnglish}</b></h3>
                                                        <p>{user?.first_formProfileEnglish}</p>
                                                    </IonLabel>
                                                    <IonRadio style={{ width: "1.8em", height: "1.8em", border: "2px solid primary", borderRadius: "50%" }}></IonRadio>
                                                </IonItem>
                                            )
                                        })}
                                    </IonList>
                                    <IonList className="ion-padding-start ion-padding-end" style={{ margin: "-1.1em", marginBottom: "-1.5em" }}>
                                        <IonItem
                                            onClick={() => {
                                                test();
                                            }}
                                            style={{ marginBottom: "1.5em" }}
                                        >
                                            <IonThumbnail style={{ height: "4.5em", width: "4.5em" }} className='ion-margin-end'>
                                                <IonIcon style={{ fontSize: "4.5em", color: "#000", '--ionicon-stroke-width': "5px" }} icon={addCircleOutline} />
                                            </IonThumbnail>
                                            <IonLabel>
                                                <h3 className='userName'><b>Add account</b></h3>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                    <IonItem style={{ color: "primary" }}>
                                        <IonButton onClick={() => {
                                            localStorage.setItem('lastPage', '/signin');
                                            AppContext.setIsLoggedIn(false);
                                            localStorage.clear();
                                            AppContext.setIsLoggedIn(false);
                                            window.location.assign('/');
                                            history.replace('/signin')
                                        }} style={{
                                            width: "100%",
                                            marginTop: `${marginTop}`,
                                            marginLeft: "-1rem",
                                            marginRight: "-1rem",
                                            height: "3.5em",
                                            position: "fixed",
                                            bottom: "39 %"
                                        }} size='large' color='primary' expand='full'>Log Out</IonButton>
                                    </IonItem>
                                </IonContent>
                            </IonModal>
                        )} */}
          </IonContent>
        </div>
        <IonModal isOpen={isAppUpdating}>
          <IonContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "28vh",
                position: "absolute",
                width: "100%",
                paddingLeft: "5vw",
                paddingRight: "5vw",
                textAlign: "center",
              }}
            >
              <h2>hold on!</h2>
              <h6>
                We are updating you
                <br />
                The latest app of{" "}
                <b style={{ color: "#EF423B" }}>Hindu Shadi Connect</b>
              </h6>
            </div>
            <h1 className="onloading">
              <div className="loading-container">
                <div className="loading"></div>
                <div id="loading-text">UPDATING</div>
              </div>
            </h1>
          </IonContent>
        </IonModal>
      </IonPage>
    </>
  );
};
export default Profile;
