import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonIcon,
  IonSearchbar,
  IonCheckbox,
  IonFooter,
  IonChip,
} from "@ionic/react";
import { close, closeOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import appContext from "../../context/AppContext";

const optionMarital = [
  {
    value: "All",
    label: "All",
    children: [
      { value: "Unmarried", label: "Unmarried" },
      { value: "Widow/Widower", label: "Widow/Widower" },
      { value: "Divorced", label: "Divorced" },
      { value: "Awaiting divorce", label: "Awaiting divorce" },
    ],
  },
];

const optionManglik = [
  {
    value: "All",
    label: "All",
    children: [
      { value: "YES", label: "YES" },
      { value: "NO", label: "NO" },
      { value: "Don't Know", label: "DON`T KNOW" },
      { value: "Anshik Mangal", label: "Anshik" },
    ],
  },
];

function Model({
  sectOption,
  serverDosh,
  setServerDosh,
  serverMaritalStatus,
  setServerMaritalStatus,
  serverSect,
  setServerSect,
  filterStates,
  isOpenModel,
  setIsOpenModel,
  show,
}: any) {
  const local_filters = localStorage.getItem("filters");
  const filters = local_filters ? JSON.parse(local_filters) : {};

  const AppContext = useContext(appContext);
  const [sect, setSect] = useState(
    serverSect?.length > 0 ? serverSect : filters.sect
  );
  const [marital, setMarital] = useState(
    serverMaritalStatus?.length > 0
      ? serverMaritalStatus
      : filters.marritalStatus
  );
  const [manglik, setManglik] = useState(
    serverDosh?.length > 0 ? serverDosh : filters.dosh
  );

  const onsubmitSect = () => {
    // onSelectionChangeSect(sect)
    setServerSect(sect);
  };

  const onsubmitMarital = () => {
    // onSelectionChangeMarital(marital)
    setServerMaritalStatus(marital);
  };

  const onsubmitManglik = () => {
    // onSelectionChangeManglik(manglik)
    setServerDosh(manglik);
  };

  const onDidDismiss = async () => {
    setIsOpenModel(false);
  };

  const onWillDismiss = async () => {
    setIsOpenModel(false);
  };

  return (
    <IonPage>
      <IonContent className="ion-padding filterBody">
        <IonModal
          isOpen={isOpenModel}
          className="filterBody"
          onDidDismiss={onDidDismiss}
          onWillDismiss={onWillDismiss}
        >
          <IonHeader>
            <IonToolbar color={"primary"}>
              <IonTitle className="heading text-white">
                {show.sect && "Select Sect"} {show.marital && "Select Marital"}{" "}
                {show.manglik && "Select Manglik"}
              </IonTitle>
              <div slot="end" className="backBtn">
                {show.sect && (
                  <IonButton
                    type="submit"
                    className="px-1"
                    onClick={() => {
                      setIsOpenModel(false);
                    }}
                  >
                    <IonIcon icon={closeOutline} color="light" />
                  </IonButton>
                )}

                {show.marital && (
                  <IonButton
                    type="submit"
                    className="px-1"
                    onClick={() => {
                      setIsOpenModel(false);
                    }}
                  >
                    <IonIcon icon={closeOutline} color="light" />
                  </IonButton>
                )}

                {show.manglik && (
                  <IonButton
                    type="submit"
                    className="px-1"
                    onClick={() => {
                      setIsOpenModel(false);
                    }}
                  >
                    <IonIcon icon={closeOutline} color="light" />
                  </IonButton>
                )}
              </div>
            </IonToolbar>
          </IonHeader>
          {/* sect  */}
          {show.sect && (
            <IonContent className="ion-padding">
              {sect?.map((item: any, i: any) => {
                return (
                  <IonChip key={i} color="medium">
                    <IonLabel>{item}</IonLabel>
                    <IonIcon
                      icon={close}
                      onClick={(ind: any) => {
                        const updatedSect = sect?.filter(
                          (i: any) => i !== item
                        );
                        setSect(updatedSect);
                      }}
                    ></IonIcon>
                  </IonChip>
                );
              })}
              <div className="checkbox-tree-container">
                <CheckboxTree
                  nodes={sectOption}
                  checked={sect}
                  expanded={["All"]}
                  onCheck={(checked: any) => setSect(checked)}
                />
              </div>
              <IonFooter
                style={{ display: "grid", position: "sticky", bottom: "0" }}
              >
                <IonButton
                  onClick={() => {
                    setIsOpenModel(false);
                    onsubmitSect();
                  }}
                >
                  Submit
                </IonButton>
              </IonFooter>
            </IonContent>
          )}

          {/* marital Status  */}
          {show.marital && (
            <IonContent className="ion-padding">
              {marital?.map((item: any, i: any) => {
                return (
                  <IonChip key={i} color="medium">
                    <IonLabel>{item}</IonLabel>
                    <IonIcon
                      icon={close}
                      onClick={(ind: any) => {
                        const updatedmarital = marital?.filter(
                          (i: any) => i !== item
                        );
                        setMarital(updatedmarital);
                      }}
                    ></IonIcon>
                  </IonChip>
                );
              })}
              <div className="checkbox-tree-container">
                <CheckboxTree
                  nodes={optionMarital}
                  checked={marital}
                  expanded={["All"]}
                  onCheck={(checked: any) => setMarital(checked)}
                />
              </div>
              <IonFooter
                style={{ display: "grid", position: "sticky", bottom: "0" }}
              >
                <IonButton
                  onClick={() => {
                    setIsOpenModel(false);
                    onsubmitMarital();
                  }}
                >
                  Submit
                </IonButton>
              </IonFooter>
            </IonContent>
          )}

          {/* manglik  */}
          {show.manglik && (
            <IonContent className="ion-padding">
              {manglik?.map((item: any, i: any) => {
                return (
                  <IonChip key={i} color="medium">
                    <IonLabel>{item}</IonLabel>
                    <IonIcon
                      icon={close}
                      onClick={(ind: any) => {
                        const updatedmanglik = manglik?.filter(
                          (i: any) => i !== item
                        );
                        setManglik(updatedmanglik);
                      }}
                    ></IonIcon>
                  </IonChip>
                );
              })}
              <div className="checkbox-tree-container">
                <CheckboxTree
                  nodes={optionManglik}
                  checked={manglik}
                  expanded={["All"]}
                  onCheck={(checked: any) => setManglik(checked)}
                />
              </div>
              <IonFooter
                style={{ display: "grid", position: "sticky", bottom: "0" }}
              >
                <IonButton
                  onClick={() => {
                    setIsOpenModel(false);
                    onsubmitManglik();
                  }}
                >
                  Submit
                </IonButton>
              </IonFooter>
            </IonContent>
          )}
        </IonModal>
      </IonContent>
    </IonPage>
  );
}
export default Model;
