import { IonAvatar, IonCard, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonSkeletonText, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IonLabel } from '@ionic/react';
import { IonIcon } from '@ionic/react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonList, IonButton } from '@ionic/react';
import appContext from "../../context/AppContext"
import { Link, useHistory, useLocation } from 'react-router-dom';
import { arrowBackOutline } from 'ionicons/icons';
import { addToFavoriteList, dashboard, profileVisitedUserList, sendInterestRequest, sendPhotoRequestApi } from '../../service';
import UserCard from './UserCard';
import GoLive from './GoLive';
import Details from './Details';
import { toast } from 'react-toastify';
import NewUserCard from './NewUserCard';

const SecondMarrige: React.FC = () => {

    const [usersList, setUsersList] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const AppContext = useContext(appContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { state }: any = useLocation();
    const [isOpenDetail, setOpenDetail] = useState<any>(false);
    const [userID, setUserID] = useState<any>("");
    const [presentAlert] = useIonAlert();

    const getUserlist = useCallback(async (pN: any) => {
        if (state?.type === "new_ten_visitor") {
            var response = await profileVisitedUserList(state, pN);
            setLoading(false);
            setPageNumber(pN + 1);
            setUsersList(response?.data?.data);
        } else {
            var response2 = await dashboard(state, pN);
            setLoading(false);
            setPageNumber(pN + 1);
            setUsersList(response2?.data?.data);
        }
    }, [state])

    useEffect(() => {
        if (AppContext?.user) getUserlist(0);
    }, [AppContext?.user, state, getUserlist]);

    const getNextUserlist = async (pN: any) => {
        if (pN === 0) setUsersList([]);
        if (state?.type === "new_ten_visitor") {
            var response = await profileVisitedUserList(state, pN);
            setLoading(false);
            setPageNumber(pN + 1);
            let uList = pN === 0 ? [] : usersList;
            setUsersList([...uList, ...response?.data?.data]);
        } else {
            var response2 = await dashboard(state, pN);
            setLoading(false);
            setPageNumber(pN + 1);
            let uList = pN === 0 ? [] : usersList;
            setUsersList([...uList, ...response2?.data?.data]);
        }
    }

    const addToFavorite = async (selectedUser: any, index: any) => {
        const usersListTemp = [...usersList];
        usersListTemp[index].favorite = !usersListTemp[index].favorite;
        setUsersList(usersListTemp);
        await addToFavoriteList(selectedUser);
    }

    const sendInterest = async (selectedUser: any, index: any) => {
        var res: any = await sendInterestRequest(selectedUser);
        if (res?.data?.status) {
            const usersListTemp = [...usersList];
            usersListTemp[index].interest = true;
            setUsersList(usersListTemp);
            toast("Sent Interest Successfully");
        } else {
            presentAlert({
                header: res?.data?.message,
                cssClass: 'actionAlert',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    }
                ],
            })
        }
    }

    const sendPhotoRequest = async (selectedUser: any, index: any) => {
        const usersListTemp = [...usersList];
        usersListTemp[index].photoRequest = [{
            count_no: usersListTemp[index]?.photoRequest[0]?.count_no ? usersListTemp[index]?.photoRequest[0]?.count_no + 1 : 1
        }];
        setUsersList(usersListTemp);
        var res: any = await sendPhotoRequestApi(selectedUser);
        if (res?.data?.status) {
            toast("Photo request sent successfully.");
        }
    }

    return (
        <IonPage >
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <Link to='/app/profile' slot='start' className='backBtn'>
                        <IonButton type="submit" className="px-1" >
                            <IonIcon icon={arrowBackOutline} color="light" />
                        </IonButton>
                    </Link>
                    <IonTitle className='fs-18 text-white'>
                        <b> {state?.name}</b>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ marginBottom: "50px !important " }}>
                {loading ?
                    <IonList >
                        {
                            [...Array(3)].map(i => (<IonCard className='box-profile b-radius-10'>
                                <IonLabel className='heart-icon'>
                                    <IonAvatar slot="end">
                                        <IonSkeletonText animated></IonSkeletonText>
                                    </IonAvatar>
                                </IonLabel>
                                <IonCardHeader className='details-box'>
                                    <IonGrid className='pb-0'>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonCardTitle>
                                                    <h3>
                                                        <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                    </h3>
                                                    <p>
                                                        <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                    </p>
                                                </IonCardTitle>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    <IonGrid className='pt-0'>
                                        <IonRow>
                                            <IonCol className='b-right'>
                                                <p>
                                                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                </p>
                                                <h6>
                                                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                </h6>
                                            </IonCol>
                                            <IonCol className='b-right'>
                                                <p>
                                                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                </p>
                                                <h6>
                                                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                </h6>
                                            </IonCol>
                                            <IonCol>
                                                <p>
                                                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                                </p>
                                                <h6>
                                                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                                </h6>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardHeader>

                            </IonCard>))
                        }
                    </IonList> :
                    <>
                        <IonList>
                            {usersList?.map((user, i) => {
                                return <NewUserCard
                                    addToFavorite={addToFavorite}
                                    sendInterest={sendInterest}
                                    user={user}
                                    i={i}
                                    history={history}
                                    sendPhotoRequest={sendPhotoRequest}
                                    onAction={() => {
                                        setUserID(user?.id)
                                    }}
                                    isOpenDetailModal={() => {
                                        setOpenDetail(true);
                                    }}
                                />
                            })}
                        </IonList>
                        <Details isOpenDetail={isOpenDetail} setOpenDetail={setOpenDetail} id={userID} />

                        <IonInfiniteScroll
                            onIonInfinite={(ev) => {
                                getNextUserlist(pageNumber);
                                setTimeout(() => ev.target.complete(), 500);
                            }}
                        >
                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </>
                }
            </IonContent>
            {/* <GoLive /> */}
        </IonPage>
    );
};
export default SecondMarrige;