import { useEffect, useState, useContext, useCallback } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonLabel,
  IonList,
  IonIcon,
  IonRow,
  IonCol,
  IonTextarea,
  useIonAlert,
  IonItem,
} from "@ionic/react";
import {
  getCurrentPackage,
  getGoliveFormDetails,
  getHinduEducationsApi,
  getStateListAPI,
  userFilters,
} from "../../service";
import appContext from "../../context/AppContext";
import { closeOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import PopUp from "./PopUp";
import advance from "../../assets/images/advance_filter.png";
import constant from "../../constant";
import StateModal from "./StateModal";
import EmployedInModel from "./EmployedInModel";
import EducationFilterModal from "./EducationFilterModal";
import Model from "./Model";
import AgeModal from "./AgeModal";
import FamilyStatusModel from "./FamilyStatusModel";

const optionManglik = [
  { id: 1, label: "All", value: "all", check: false },
  { id: 2, label: "YES", value: "YES", check: false },
  { id: 3, label: "NO", value: "NO", check: false },
  { id: 4, label: "Don't Know", value: "DON`T KNOW", check: false },
  { id: 5, label: "Anshik Mangal", value: "Anshik", check: false },
];

const optionFamilyStatus = [
  { id: 1, label: "Poor", value: "Poor", check: false },
  {
    id: 2,
    label: "Lower Middle Class",
    value: "Lower Middle Class",
    check: false,
  },
  { id: 3, label: "Middle Class", value: "Middle Class", check: false },
  {
    id: 4,
    label: "Upper Middle Class",
    value: "Upper Middle Class",
    check: false,
  },
  { id: 5, label: "Rich / Affluent", value: "Rich / Affluent", check: false },
];

const optionPhysicalStatus = [
  { id: 1, label: "Normal", value: "Normal", check: false },
  {
    id: 2,
    label: "Basic Physical Problem",
    value: "Basic Physical Problem",
    check: false,
  },
  {
    id: 3,
    label: "Some Medical Problem",
    value: "Some Medical Problem",
    check: false,
  },
  {
    id: 4,
    label: "Physically Challenged",
    value: "Physically Challenged",
    check: false,
  },
];

const optionIncomeOfApplicant = [
  { id: 1, label: "None", value: "", check: false },
  {
    id: 2,
    label: "Under 1 Lakh",
    value: "Under 1 Lakh",
    check: false,
  },
  {
    id: 3,
    label: "1-2 Lakhs",
    value: "1-2 Lakhs",
    check: false,
  },
  {
    id: 4,
    label: "2-3 Lakhs",
    value: "2-3 Lakhs",
    check: false,
  },
  {
    id: 5,
    label: "3-4 Lakhs",
    value: "3-4 Lakhs",
    check: false,
  },
  {
    id: 6,
    label: "4-6 Lakhs",
    value: "4-6 Lakhs",
    check: false,
  },
  {
    id: 7,
    label: "6-8 Lakhs",
    value: "6-8 Lakhs",
    check: false,
  },
  {
    id: 8,
    label: "15-20 Lakhs",
    value: "15-20 Lakhs",
    check: false,
  },
  {
    id: 9,
    label: "20-30 Lakhs",
    value: "20-30 Lakhs",
    check: false,
  },
  {
    id: 10,
    label: "30-40 Lakhs",
    value: "30-40 Lakhs",
    check: false,
  },
  {
    id: 11,
    label: "40-50 Lakhs",
    value: "40-50 Lakhs",
    check: false,
  },
  {
    id: 12,
    label: "50-70 Lakhs",
    value: "50-70 Lakhs",
    check: false,
  },
  {
    id: 13,
    label: "70-1 crore",
    value: "70-1 crore",
    check: false,
  },
  {
    id: 14,
    label: "Above 1 crore",
    value: "Above 1 crore",
    check: false,
  },
];

function DashboardFilter({
  serverStartAge,
  serverEndAge,
  serverDosh,
  serverMaritalStatus,
  serverEducation,
  serverEmployedIn,
  serverSect,
  serverStates,
  serverIncomeOfApplicant,
  serverFamilyStatus,
  serverPhysicalStatus,

  setServerEndAge,
  setServerStartAge,
  setServerDosh,
  setServerMaritalStatus,
  setServerEducation,
  setServerEmployedIn,
  setServerSect,
  setServerStates,
  setFilterStates,
  setServerIncomeOfApplicant,
  setServerFamilyStatus,
  setServerPhysicalStatus,
  sectOption,
  filterStates,
  isOpen,
  setIsOpen,

  getUserList,
}: any) {
  const AppContext = useContext(appContext);
  const [stateList, setStateList]: any = useState(null);
  const [advanced, setAdvanced]: any = useState(false);
  const [isProfile, setIsProfile]: any = useState(false);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [employedInList, setEmployedInOption]: any = useState([]);
  const [educationList, setEducationList]: any = useState([]);
  const [isOpenState, setIsOpenState] = useState(false);
  const [isOpenAge, setIsOpenAge] = useState(false);
  const [isOpenEmployed, setIsOpenEmployed] = useState(false);
  const [openPkgWarning, setOpenPkgWarning] = useState(false);
  const [isOpenEducation, setIsOpenEducation] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isOpenFamilyModel, setIsOpenFamilyModel] = useState(false);
  const [field, setField] = useState<any>({
    profile: false,
    state: false,
    sect: false,
    marital: false,
    age: false,
    employed: false,
    education: false,
    manglik: false,
    familyStatus: false,
    physicalStatus: false,
    incomeOfApplicant: false,
  });
  const [fieldDisabled, setFieldDisabled] = useState({
    age: false,
    state: false,
    sect: false,
    marital: false,
    employed: false,
    education: false,
    manglik: false,
    profile: false,
    familyStatus: false,
    physicalStatus: false,
    incomeOfApplicant: false,
  });
  const { handleSubmit, setValue } = useForm();
  const [presentAlert] = useIonAlert();
  const [selectedStates, setSelectedStates] = useState<any[]>([]);
  const [selectedEmployed, setSelectedEmployed] = useState<any[]>([]);
  const [selectedEducation, setSelectedEducation] = useState<any[]>([]);
  const [showUpgradeAlert, setShowUpgradeAlert] = useState<any>(false);
  const [startAge, setStartAge] = useState<any>([]);
  const [endAge, setEndAge] = useState<any>([]);
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [selectedfilters, setSelectedfilters] = useState<any>([]);
  const [show, setShow] = useState<any>({
    sect: false,
    marital: false,
    manglik: false,
  });
  const [activeField, setactiveField] = useState<any>(null);
  const [ActiveAgeField, setActiveAgeField] = useState<any>(null);

  const local_filters = localStorage.getItem("filters");
  const filters = local_filters ? JSON.parse(local_filters) : {};
  let l_startAge = filters?.age?.start ?? 18;
  let l_endAge = filters?.age?.end ?? 80;
  let l_dosh = filters?.dosh ?? [];
  let l_education = filters?.education ?? [];
  let l_employedIn = filters?.employedIn ?? [];
  let l_marritalStatus = filters?.marritalStatus ?? [];
  let l_sect = filters?.sect ?? [];
  let l_states = filters?.states ?? [];
  let l_familyStatus = filters?.familyStatus ?? [];
  let l_incomeOfApplicant = filters?.incomeOfApplicant ?? [];
  let l_physicalStatus = filters?.physicalStatus ?? [];

  useEffect(() => {
    CurrentPackageData();
  }, []);

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      // setCurrentPackageSpecial(
      //   getPackagesByType("special") || getPackagesByType("Special")
      //     ? getPackagesByType("special") || getPackagesByType("Special")
      //     : null
      // );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  const limit = CurrentPackageVip
    ? CurrentPackageVip?.filter_view_limit
    : CurrentPackageBasic
    ? CurrentPackageBasic?.filter_view_limit
    : 8;

  const getFormDetais = useCallback(async () => {
    let stateList: any = await getStateListAPI({ countryId: "India" });
    let res: any = await getGoliveFormDetails();
    setStateList(stateList.data.data);
    var arr: any = [];

    setEducationList(res?.data?.data?.education);

    res?.data?.data?.employedIn?.english?.map((i: any) => {
      if (i?.value !== "Passed away" || i?.value !== "Retired") {
        arr.push(i);
        setEmployedInOption(arr);
      }
      return 0;
    });
  }, []);

  useEffect(() => {
    setIsProfile(AppContext?.filter?.["isProfile"]);
  }, [AppContext?.filter?.["isProfile"]]);

  useEffect(() => {
    if (AppContext?.user && isOpen) getFormDetais();
  }, [AppContext?.user, isOpen, getFormDetais]);

  const onSubmit = async (data: any) => {
    if (AppContext?.user?.payment_verified_at === 0) {
      if (!field.state) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerStates(serverStates);
      }
      if (!field.marital) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerMaritalStatus(serverMaritalStatus);
        setServerEducation([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.sect) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerEmployedIn([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerSect(serverSect);
        setServerStates([]);
      }
      if (!field.employed) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerEmployedIn(serverEmployedIn);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.education) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerEducation(serverEducation);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.manglik) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh(serverDosh);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.incomeOfApplicant) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerIncomeOfApplicant(serverIncomeOfApplicant);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.familyStatus) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus(serverFamilyStatus);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.physicalStatus) {
        setServerEndAge(80);
        setServerStartAge(18);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus(serverPhysicalStatus);
        setServerFamilyStatus([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerStates([]);
      }
      if (!field.age) {
        setServerEndAge(serverEndAge);
        setServerStartAge(serverStartAge);
        setServerDosh([]);
        setServerMaritalStatus([]);
        setServerEducation([]);
        setServerEmployedIn([]);
        setServerSect([]);
        setServerIncomeOfApplicant([]);
        setServerPhysicalStatus([]);
        setServerFamilyStatus([]);
        setServerStates([]);
      }
      if (!field.profile) {
        setIsProfile(false);
      }
      filterAlert();
    }

    const updatedServerFilters = {
      age: {
        start: serverStartAge,
        end: serverEndAge,
      },
      dosh: serverDosh,
      education: serverEducation,
      employedIn: serverEmployedIn,
      physicalStatus: serverPhysicalStatus,
      familyStatus: serverFamilyStatus,
      incomeOfApplicant: serverIncomeOfApplicant,
      endAge: {
        start: serverStartAge,
        end: serverEndAge,
      },
      isProfile: false,
      marritalStatus: serverMaritalStatus,
      sect: serverSect,
      startAge: {
        start: serverStartAge,
        end: serverEndAge,
      },
      states: serverStates,
    };
    const filtersJSON = JSON.stringify(updatedServerFilters);
    localStorage.setItem("filters", filtersJSON);
    getUserList(0);

    setIsOpen(false);
    setAdvanced(false);
  };

  const filterAlert = () => {
    presentAlert({
      header: "ALERT",
      subHeader: "One Filter At Time",
      message:
        "You can use only one filter at a time in free service to use more filters",
      cssClass: "actionAlert2 PayButton",
      buttons: [
        {
          text: "PAY NOW",
          role: "confirm",
          handler: async () => {
            AppContext.setPopups(constant.popups.isSubscribe, true);
          },
        },
        {
          text: "Do It Later",
          role: "cancel",
        },
      ],
    });
  };

  const goTo = () => {
    AppContext.setPopups(constant.popups.isSubscribe, true, true);
    setOpenPopUp(false);
  };

  const onDidDismiss = async () => {
    setIsOpen(false);
    AppContext?.setPopupClose(true);
  };

  const onWillDismiss = async () => {
    setIsOpen(false);
    AppContext?.setPopupClose(true);
  };

  useEffect(() => {
    if (!CurrentPackageBasic || !selectedfilters) {
      return;
    }

    const allFields = [
      "age",
      "manglik",
      "marital",
      "education",
      "employed",
      "sect",
      "state",
      "familyStatus",
      "physicalStatus",
      "incomeOfApplicant",
    ];
    const updatedFieldDisabled: any = {};

    if (limit === selectedfilters.length) {
      allFields.forEach((field) => {
        if (!selectedfilters.includes(field)) {
          updatedFieldDisabled[field] = true;
          updatedFieldDisabled["profile"] = true;
        }
      });

      setFieldDisabled({ ...updatedFieldDisabled });
      setOpenPkgWarning(true);
    } else {
      allFields.forEach((field) => {
        if (selectedfilters.includes(field)) {
          updatedFieldDisabled[field] = false;
          updatedFieldDisabled["profile"] = false;
        }
      });

      setFieldDisabled({ ...updatedFieldDisabled });
      setOpenPkgWarning(false);
    }
  }, [selectedfilters?.length, CurrentPackageBasic]);

  useEffect(() => {
    const updatedFilters = [];

    if (serverStartAge !== 18 || serverEndAge !== 80) {
      updatedFilters.push("age");
    }

    if (serverDosh?.length > 0) {
      updatedFilters.push("manglik");
    }

    if (serverMaritalStatus?.length > 0) {
      updatedFilters.push("marital");
    }

    if (serverEducation?.length > 0) {
      updatedFilters.push("education");
    }

    if (serverEmployedIn?.length > 0) {
      updatedFilters.push("employed");
    }

    if (serverSect?.length > 0) {
      updatedFilters.push("sect");
    }

    if (serverStates?.length > 0) {
      updatedFilters.push("state");
    }
    if (serverFamilyStatus?.length > 0) {
      updatedFilters.push("familyStatus");
    }
    if (serverPhysicalStatus?.length > 0) {
      updatedFilters.push("physicalStatus");
    }
    if (serverIncomeOfApplicant?.length > 0) {
      updatedFilters.push("incomeOfApplicant");
    }

    setSelectedfilters(
      updatedFilters.filter((filter) => {
        return (
          (filter !== "age" || serverStartAge !== 18 || serverEndAge !== 80) &&
          (filter !== "manglik" || serverDosh?.length > 0) &&
          (filter !== "marital" || serverMaritalStatus?.length > 0) &&
          (filter !== "education" || serverEducation?.length > 0) &&
          (filter !== "employed" || serverEmployedIn?.length > 0) &&
          (filter !== "sect" || serverSect?.length > 0) &&
          (filter !== "incomeOfApplicant" ||
            serverIncomeOfApplicant?.length > 0) &&
          (filter !== "physicalStatus" || serverPhysicalStatus?.length > 0) &&
          (filter !== "familyStatus" || serverFamilyStatus?.length > 0) &&
          (filter !== "state" || serverStates?.length > 0)
        );
      })
    );
  }, [
    serverStartAge,
    serverEndAge,
    serverDosh?.length,
    serverMaritalStatus?.length,
    serverEducation?.length,
    serverEmployedIn?.length,
    serverSect?.length,
    serverFamilyStatus?.length,
    serverPhysicalStatus?.length,
    serverIncomeOfApplicant?.length,
    serverStates?.length,
    selectedfilters?.length,
  ]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        className="filterBody filterBackGround"
        onDidDismiss={onDidDismiss}
        onWillDismiss={onWillDismiss}
        onDidPresent={() => {
          AppContext?.setPopupClose(false);
        }}
      >
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonTitle className="heading text-white">Filter</IonTitle>
            <div slot="end" className="backBtn">
              <IonButton
                type="submit"
                className="px-1"
                onClick={() => {
                  setIsOpen(false);
                  setAdvanced(false);
                }}
              >
                <IonIcon icon={closeOutline} color="light" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="mt-0"
              >
                <p
                  className="labelItem "
                  style={{ margin: 0, fontWeight: 600 }}
                >
                  Profile View{" "}
                </p>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                  onClick={() => {
                    setField({
                      profile: false,
                      sect: true,
                      state: true,
                      marital: true,
                      employed: true,
                      age: true,
                      education: true,
                      manglik: true,
                      familyStatus: true,
                      physicalStatus: true,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <button
                    type="button"
                    className={isProfile ? "btn" : "btn btn-success"}
                    onClick={() => {
                      if (!fieldDisabled.profile) {
                        setIsProfile(false);
                      }
                    }}
                    disabled={fieldDisabled.profile}
                  >
                    All
                  </button>
                  <button
                    type="button"
                    className={isProfile ? "btn btn-success" : "btn "}
                    onClick={() => {
                      if (!fieldDisabled.profile) setIsProfile(true);
                    }}
                    disabled={fieldDisabled.profile}
                  >
                    Only with profile
                  </button>
                </div>
              </div>

              <IonGrid
                onClick={() => {
                  setField({
                    age: false,
                    employed: true,
                    sect: true,
                    profile: true,
                    marital: true,
                    state: true,
                    education: true,
                    manglik: true,
                    familyStatus: true,
                    physicalStatus: true,
                    incomeOfApplicant: true,
                  });
                }}
              >
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Age (In Year)
                </IonLabel>
                <IonRow>
                  <IonCol>
                    <IonTextarea
                      rows={1}
                      readonly
                      disabled={fieldDisabled.age}
                      value={serverStartAge ? serverStartAge : l_startAge}
                      // value={startAge?.value ? startAge?.value : filterStates?.age?.start}
                      placeholder="Select Age"
                      className="border-bottom single-line-label"
                      onClick={() => {
                        if (!fieldDisabled.age) {
                          setIsOpenAge(true);
                          setActiveAgeField("startAge");
                        }
                      }}
                    ></IonTextarea>
                  </IonCol>
                  {ActiveAgeField === "startAge" && (
                    <AgeModal
                      setServerStartAge={setServerStartAge}
                      setServerEndAge={setServerEndAge}
                      serverStartAge={serverStartAge}
                      serverEndAge={serverEndAge}
                      filterStates={filterStates}
                      isOpenAge={isOpenAge}
                      setIsOpenAge={setIsOpenAge}
                      startAge={startAge}
                      setStartAge={setStartAge}
                      ActiveAgeField={ActiveAgeField}
                    />
                  )}
                  <IonCol>
                    <IonTextarea
                      rows={1}
                      readonly
                      disabled={fieldDisabled.age}
                      value={serverEndAge ? serverEndAge : l_endAge}
                      placeholder="Select Age"
                      className="border-bottom single-line-label"
                      onClick={() => {
                        if (!fieldDisabled.age) {
                          setIsOpenAge(true);
                          setActiveAgeField("endAge");
                        }
                      }}
                    ></IonTextarea>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {ActiveAgeField === "endAge" && (
                <AgeModal
                  setServerStartAge={setServerStartAge}
                  setServerEndAge={setServerEndAge}
                  serverStartAge={serverStartAge}
                  serverEndAge={serverEndAge}
                  filterStates={filterStates}
                  isOpenAge={isOpenAge}
                  endAge={endAge}
                  setEndAge={setEndAge}
                  setIsOpenAge={setIsOpenAge}
                  ActiveAgeField={ActiveAgeField}
                />
              )}

              <IonGrid
                className=" ion-margin-bottom"
                onClick={() => {
                  setField({
                    state: false,
                    profile: true,
                    sect: true,
                    marital: true,
                    age: true,
                    employed: true,
                    education: true,
                    manglik: true,
                    familyStatus: true,
                    physicalStatus: true,
                    incomeOfApplicant: true,
                  });
                }}
              >
                <IonLabel position="stacked" className="fs-16 labelItem">
                  State
                </IonLabel>
                <IonTextarea
                  autoGrow={true}
                  disabled={fieldDisabled.state}
                  rows={1}
                  readonly
                  style={{ border: "0px", whiteSpace: "nowrap" }}
                  value={serverStates?.length > 0 ? serverStates : l_states}
                  placeholder="Select State"
                  className="border-bottom"
                  onClick={() => {
                    if (!fieldDisabled.state) {
                      setIsOpenState(true);
                    }
                  }}
                ></IonTextarea>
                {stateList && (
                  <StateModal
                    serverStates={serverStates}
                    setServerStates={setServerStates}
                    isOpenState={isOpenState}
                    setIsOpenState={setIsOpenState}
                    stateList={stateList}
                    selectedStates={selectedStates}
                    setSelectedStates={setSelectedStates}
                  />
                )}
              </IonGrid>
              <IonGrid
                className="ion-margin-bottom"
                onClick={() => {
                  setField({
                    sect: false,
                    state: true,
                    profile: true,
                    marital: true,
                    employed: true,
                    age: true,
                    education: true,
                    manglik: true,
                    familyStatus: true,
                    physicalStatus: true,
                    incomeOfApplicant: true,
                  });
                }}
              >
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Sect
                </IonLabel>
                <IonTextarea
                  autoGrow={true}
                  rows={1}
                  readonly
                  disabled={fieldDisabled.sect}
                  value={serverSect?.length > 0 ? serverSect : l_sect}
                  placeholder="Select Sect"
                  className="border-bottom"
                  onClick={() => {
                    if (!fieldDisabled.sect) {
                      setIsOpenModel(true);
                      setShow({ sect: true });
                      setactiveField("sect");
                    }
                  }}
                ></IonTextarea>
                {activeField === "sect" && (
                  <Model
                    sectOption={sectOption}
                    serverDosh={serverDosh}
                    setServerDosh={setServerDosh}
                    serverMaritalStatus={serverMaritalStatus}
                    setServerMaritalStatus={setServerMaritalStatus}
                    serverSect={serverSect}
                    setServerSect={setServerSect}
                    filterStates={filterStates}
                    isOpenModel={isOpenModel}
                    activeField={activeField}
                    show={show}
                    setIsOpenModel={setIsOpenModel}
                  />
                )}
              </IonGrid>

              <IonGrid
                className="ion-margin-bottom"
                onClick={() => {
                  setField({
                    marital: false,
                    sect: true,
                    profile: true,
                    state: true,
                    employed: true,
                    age: true,
                    education: true,
                    manglik: true,
                    familyStatus: true,
                    physicalStatus: true,
                    incomeOfApplicant: true,
                  });
                }}
              >
                <IonLabel position="stacked" className="fs-16 labelItem">
                  Marital status
                </IonLabel>
                <IonTextarea
                  autoGrow={true}
                  rows={1}
                  disabled={fieldDisabled.marital}
                  readonly
                  style={{ border: "0px", whiteSpace: "nowrap" }}
                  value={
                    serverMaritalStatus?.length > 0
                      ? serverMaritalStatus
                      : l_marritalStatus
                  }
                  placeholder="Select Marital"
                  className="border-bottom"
                  onClick={() => {
                    if (!fieldDisabled.marital) {
                      setIsOpenModel(true);
                      setShow({ marital: true });
                      setactiveField("marital");
                    }
                  }}
                ></IonTextarea>
                {activeField === "marital" && (
                  <Model
                    serverDosh={serverDosh}
                    setServerDosh={setServerDosh}
                    serverMaritalStatus={serverMaritalStatus}
                    setServerMaritalStatus={setServerMaritalStatus}
                    serverSect={serverSect}
                    setServerSect={setServerSect}
                    filterStates={filterStates}
                    isOpenModel={isOpenModel}
                    activeField={activeField}
                    show={show}
                    setIsOpenModel={setIsOpenModel}
                  />
                )}
              </IonGrid>
              <>
                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      employed: false,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      state: true,
                      education: true,
                      manglik: true,
                      familyStatus: true,
                      physicalStatus: true,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Employed In
                  </IonLabel>

                  <IonTextarea
                    autoGrow={true}
                    rows={1}
                    readonly
                    disabled={fieldDisabled.employed}
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={
                      serverEmployedIn?.length > 0
                        ? serverEmployedIn
                        : l_employedIn
                    }
                    placeholder="Select Employed"
                    className="border-bottom"
                    onClick={() => {
                      if (!fieldDisabled.employed) {
                        setIsOpenEmployed(true);
                      }
                    }}
                  ></IonTextarea>
                  {employedInList && (
                    <EmployedInModel
                      serverEmployedIn={serverEmployedIn}
                      setServerEmployedIn={setServerEmployedIn}
                      filterStates={filterStates}
                      isOpenEmployed={isOpenEmployed}
                      setIsOpenEmployed={setIsOpenEmployed}
                      setEmployedInOption={setEmployedInOption}
                      employedInList={employedInList}
                      selectedEmployed={selectedEmployed}
                      setSelectedEmployed={setSelectedEmployed}
                    />
                  )}
                </IonGrid>

                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      education: false,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      employed: true,
                      state: true,
                      manglik: true,
                      familyStatus: true,
                      physicalStatus: true,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Education
                  </IonLabel>

                  <IonTextarea
                    autoGrow={true}
                    disabled={fieldDisabled.education}
                    rows={1}
                    readonly
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={
                      serverEducation?.length > 0
                        ? serverEducation
                        : l_education
                    }
                    placeholder="Select Education"
                    className="border-bottom filter"
                    onClick={() => {
                      if (!fieldDisabled.education) {
                        setIsOpenEducation(true);
                      }
                    }}
                  ></IonTextarea>
                  {educationList && (
                    <EducationFilterModal
                      setServerEducation={setServerEducation}
                      serverEducation={serverEducation}
                      filterStates={filterStates}
                      isOpenEducation={isOpenEducation}
                      setIsOpenEducation={setIsOpenEducation}
                      educationList={educationList}
                      selectedEducation={selectedEducation}
                      setSelectedEducation={setSelectedEducation}
                    />
                  )}
                </IonGrid>
                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      manglik: false,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      employed: true,
                      education: true,
                      state: true,
                      familyStatus: true,
                      physicalStatus: true,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Manglik Status
                  </IonLabel>
                  <IonTextarea
                    disabled={fieldDisabled.manglik}
                    autoGrow={true}
                    rows={1}
                    readonly
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={serverDosh?.length > 0 ? serverDosh : l_dosh}
                    placeholder="Select Marital"
                    className="border-bottom"
                    onClick={() => {
                      if (!fieldDisabled.manglik) {
                        setIsOpenModel(true);
                        setShow({ manglik: true });
                        setactiveField("manglik");
                      }
                    }}
                  ></IonTextarea>

                  {activeField === "manglik" && (
                    <Model
                      serverDosh={serverDosh}
                      setServerDosh={setServerDosh}
                      serverMaritalStatus={serverMaritalStatus}
                      setServerMaritalStatus={setServerMaritalStatus}
                      serverSect={serverSect}
                      setServerSect={setServerSect}
                      filterStates={filterStates}
                      isOpenModel={isOpenModel}
                      activeField={activeField}
                      show={show}
                      setIsOpenModel={setIsOpenModel}
                      optionManglik={optionManglik}
                    />
                  )}
                </IonGrid>

                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      manglik: true,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      employed: true,
                      education: true,
                      state: true,
                      familyStatus: false,
                      physicalStatus: true,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Family Status
                  </IonLabel>
                  <IonTextarea
                    disabled={fieldDisabled.familyStatus}
                    autoGrow={true}
                    rows={1}
                    readonly
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={
                      serverFamilyStatus?.length > 0
                        ? serverFamilyStatus
                        : l_familyStatus
                    }
                    placeholder="Select Family Status"
                    className="border-bottom"
                    onClick={() => {
                      if (!fieldDisabled.familyStatus) {
                        setIsOpenFamilyModel(true);
                        setactiveField("familyStatus");
                      }
                    }}
                  ></IonTextarea>

                  {activeField === "familyStatus" && (
                    <FamilyStatusModel
                      serverFamilyStatus={serverFamilyStatus}
                      setServerFamilyStatus={setServerFamilyStatus}
                      filterStates={filterStates}
                      isOpenModel={isOpenFamilyModel}
                      setIsOpenEducation={setIsOpenFamilyModel}
                      activeField={activeField}
                      optionManglik={optionFamilyStatus}
                    />
                  )}
                </IonGrid>

                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      manglik: true,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      employed: true,
                      education: true,
                      state: true,
                      familyStatus: true,
                      physicalStatus: false,
                      incomeOfApplicant: true,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Physical Status
                  </IonLabel>
                  <IonTextarea
                    disabled={fieldDisabled.physicalStatus}
                    autoGrow={true}
                    rows={1}
                    readonly
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={
                      serverPhysicalStatus?.length > 0
                        ? serverPhysicalStatus
                        : l_physicalStatus
                    }
                    placeholder="Select Physical Status"
                    className="border-bottom"
                    onClick={() => {
                      if (!fieldDisabled.physicalStatus) {
                        setIsOpenFamilyModel(true);
                        setactiveField("physicalStatus");
                      }
                    }}
                  ></IonTextarea>

                  {activeField === "physicalStatus" && (
                    <FamilyStatusModel
                      serverFamilyStatus={serverPhysicalStatus}
                      setServerFamilyStatus={setServerPhysicalStatus}
                      filterStates={filterStates}
                      isOpenModel={isOpenFamilyModel}
                      setIsOpenEducation={setIsOpenFamilyModel}
                      activeField={activeField}
                      optionManglik={optionPhysicalStatus}
                    />
                  )}
                </IonGrid>

                <IonGrid
                  className=" ion-margin-bottom"
                  onClick={() => {
                    setField({
                      manglik: true,
                      sect: true,
                      profile: true,
                      marital: true,
                      age: true,
                      employed: true,
                      education: true,
                      state: true,
                      familyStatus: true,
                      physicalStatus: true,
                      incomeOfApplicant: false,
                    });
                  }}
                >
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Income Of Applicant
                  </IonLabel>
                  <IonTextarea
                    disabled={fieldDisabled.incomeOfApplicant}
                    autoGrow={true}
                    rows={1}
                    readonly
                    style={{ border: "0px", whiteSpace: "nowrap" }}
                    value={
                      serverIncomeOfApplicant?.length > 0
                        ? serverIncomeOfApplicant
                        : l_incomeOfApplicant
                    }
                    placeholder="Select Income of Applicant"
                    className="border-bottom"
                    onClick={() => {
                      if (!fieldDisabled.incomeOfApplicant) {
                        setIsOpenFamilyModel(true);
                        setactiveField("incomeOfApplicant");
                      }
                    }}
                  ></IonTextarea>

                  {activeField === "incomeOfApplicant" && (
                    <FamilyStatusModel
                      serverFamilyStatus={serverIncomeOfApplicant}
                      setServerFamilyStatus={setServerIncomeOfApplicant}
                      filterStates={filterStates}
                      isOpenModel={isOpenFamilyModel}
                      setIsOpenEducation={setIsOpenFamilyModel}
                      activeField={activeField}
                      optionManglik={optionIncomeOfApplicant}
                    />
                  )}
                </IonGrid>
              </>

              <div className="btnFill" style={{ margin: "30px 0px 25% 0px" }}>
                <IonButton type="submit" expand="block">
                  Apply
                </IonButton>
              </div>
              {openPkgWarning && (
                <div className="pkgWarning">
                  <p>{`You can use ${limit} filter at a time with your (${CurrentPackageBasic?.name?.english} plan)`}</p>
                  <div
                    className="upgradeBtn"
                    onClick={() => {
                      localStorage.setItem("pkgSection", "basic");
                      AppContext.setPopups(constant.popups.isSubscribe, true);
                    }}
                  >
                    Upgrade Now
                  </div>
                </div>
              )}
            </form>
          </IonList>
        </IonContent>
      </IonModal>
      <PopUp
        isOpenPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        img={advance}
        goTo={goTo}
        cancel={true}
        componentName={"filter"}
      />
    </>
  );
}

export default DashboardFilter;
