import { useState, useContext } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonCol, IonGrid, IonInput, IonLabel, IonList, IonIcon, useIonAlert, IonTextarea, useIonToast } from '@ionic/react';
import { getGoliveFormDetails, signUp } from '../../service';
import appContext from "../../context/AppContext"
import { addOutline, closeOutline } from 'ionicons/icons';
import { useForm } from "react-hook-form";
import CustomSelect from '../shared/CustomSelect';
import constant from '../../constant';
import { toast } from 'react-toastify';

function ThirdForm() {
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors, touchedFields }
    }: any = useForm();
    const [formDetail, setFormDetail] = useState<any>(null);
    const [presentAlert] = useIonAlert();
    const [relation, setRelation] = useState<any>([
        { key: "Father", name: "Father", show: true },
        { key: "Mother", name: "Mother", show: false },
        { key: "Brother", name: "Brother", show: false },
        { key: "Sister", name: "Sister", show: false },
        { key: "OtherRelatives", name: "Other Relatives Details", show: false },
    ]);
    const membersArray = [];
    for (let index = 1; index < 15; index++) {
        membersArray.push({ label: index, value: index });
    }

    const AppContext = useContext(appContext);

    const getFormData = async () => {
        var res: any = await getGoliveFormDetails()
        if (res?.data?.status) {
            setFormDetail(res?.data?.data)
        }
    }

    const onSubmit = async (values: any) => {
        Object.keys(values)?.forEach((i: any) => { if (values[i]?.value) { values[i] = values[i].value; } })
        var res = await signUp(values);
        if (res?.data?.status) {
            toast("Family Detail Saved Successfully");
            AppContext.updateUser();
            reset();
            AppContext.setPopups(constant.popups.isThirdForm, false);
        } else {
            toast(res?.data?.errors);
        }
    };

    const toggleRelation = (name: any) => {
        relation.forEach((i: any) => {
            if (i?.name === name) {
                i.show = !i.show;
            }
        })
        setRelation(JSON.parse(JSON.stringify(relation)));
    }
    const onReject = (modalState: Boolean) => {
        reset();
        AppContext.setPopups(constant.popups.isThirdForm, modalState);
        AppContext.setDoItLater();
    }

    const onDidDismiss = async () => {
        AppContext.setPopups(constant.popups.isThirdForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    const onWillDismiss = async () => {
        AppContext.setPopups(constant.popups.isThirdForm, false);
        AppContext?.setPopupClose(false)
        AppContext.setDoItLater();
        localStorage.setItem('isModal', 'false')

    }

    const onDidPresent = () => {
        AppContext?.setPopupClose(false)
        if (AppContext?.user?.fifth_formFamilyTypeEnglish) setValue("fifth_formFamilyTypeEnglish", AppContext.user.fifth_formFamilyTypeEnglish)
        if (AppContext?.user?.fifth_formFamilyMember) setValue("fifth_formFamilyMember", AppContext.user.fifth_formFamilyMember)
        if (AppContext?.user?.second_formMotherTongueEnglish) setValue("second_formMotherTongueEnglish", AppContext.user.second_formMotherTongueEnglish)
        getFormData()
        localStorage.setItem('isModal', 'true')

    }

    return (
        <IonContent>
            <IonModal isOpen={AppContext?.popups[constant.popups.isThirdForm]} className='GoliveBody'
                onDidDismiss={onDidDismiss}
                onWillDismiss={onWillDismiss}
                onDidPresent={onDidPresent}
                onWillPresent={() => {
                    localStorage.setItem('isModal', 'true')
                }}>
                <IonHeader>
                    <IonToolbar color={"primary"} >
                        <IonTitle className='heading text-white'>Family Details</IonTitle>
                        <div slot="end" className='backBtn'>
                            <IonButton type="submit" className="px-1" onClick={(event: any) => {
                                // event.stopPropagation(); setThirdForm(false)
                                Object.keys(touchedFields).length
                                    ? presentAlert({
                                        header: 'Would you like to save changes?',
                                        cssClass: 'actionAlert',
                                        buttons: [
                                            {
                                                text: 'Yes', role: 'confirm',
                                                handler: handleSubmit(onSubmit),
                                            },
                                            {
                                                text: 'no', role: 'cancel', handler: () => {
                                                    onReject(false);
                                                }
                                            },
                                        ],
                                    })
                                    : onReject(false)
                            }}>
                                <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonHeader>

                <IonList className='golive'>
                    <form className='my-2' onSubmit={handleSubmit(onSubmit)}>
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Family Type<span className='errorText m-0' >*</span></IonLabel>
                            <CustomSelect
                                options={[{ label: "Joint Family", value: "Joint Faimily" }, { label: "Nuclear Family", value: "Nuclear Faimily" }]}
                                control={control}
                                required={true}
                                name={"fifth_formFamilyTypeEnglish"}
                                placeholder={"Select Family Type"}
                                isSearchable={false}
                            />
                            {errors?.fifth_formFamilyTypeEnglish?.type === "required" && <p className='errorText ms-1'> Select Family Type.</p>}
                        </IonGrid>

                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Total Family Member<span className='errorText m-0' >*</span></IonLabel>
                            <CustomSelect
                                options={membersArray}
                                control={control}
                                required={true}
                                name={"fifth_formFamilyMember"}
                                placeholder={"Select Faimily Member"}
                            />
                            {errors?.fifth_formFamilyMember?.type === "required" && <p className='errorText ms-1'>Write Number of Faimily Member.</p>}
                        </IonGrid>

                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Mother Tounge<span className='errorText m-0' >*</span></IonLabel>
                            {formDetail?.motherTongueList?.english && <CustomSelect
                                options={formDetail?.motherTongueList?.english}
                                control={control}
                                required={true}
                                name={"second_formMotherTongueEnglish"}
                                placeholder={"Select Mother Tounge"}
                                isSearchable={false}

                            />}
                            {errors?.second_formMotherTongueEnglish?.type === "required" && <p className='errorText ms-1'>Select Mother Tounge.</p>}
                        </IonGrid>
                        {relation?.map((i: any) => (
                            <>
                                {i.show && <GeneralForm
                                    toggleRelation={toggleRelation}
                                    errors={errors}
                                    register={register}
                                    formDetail={formDetail}
                                    control={control}
                                    keyName={i?.key}
                                    name={i?.name}
                                    setValue={setValue}
                                />}
                            </>
                        ))}

                        <div className='pt-3' style={{ cursor: "pointer" }}>
                            {relation?.map((i: any, ind: any) => (
                                <>
                                    {!i.show &&
                                        <div onClick={() => { toggleRelation(i.name) }} style={{ display: "flex", alignItems: "center", fontWeight: 600 }}>
                                            <div className='backBtn'>
                                                <IonButton style={{ marginRight: 8 }}>
                                                    <IonIcon icon={addOutline} color="light" />
                                                </IonButton>
                                            </div>
                                            Add {i?.name}
                                        </div>
                                    }
                                </>
                            ))}
                        </div>


                        <IonCol class='btnFill  ion-margin-bottom'>
                            <IonButton type="submit" expand="block" >Save</IonButton>
                        </IonCol>
                    </form>
                </IonList>
            </IonModal>

        </IonContent>

    );
}


const GeneralForm = ({ errors, register, formDetail, control, keyName, name, toggleRelation, setValue }: any) => {
    const number = [{ label: "0", value: "0" }, { label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }, { label: "4", value: "4" }, { label: "5", value: "5" }, { label: "6", value: "6" }, { label: "7", value: "7" }, { label: "8", value: "8" }]
    const AppContext = useContext(appContext);
    if (AppContext.user[`fifth_form${keyName}NameEnglish`]) setValue(`fifth_form${keyName}NameEnglish`, AppContext.user[`fifth_form${keyName}NameEnglish`])
    if (AppContext.user[`fifth_form${keyName}ContactNumberMobile`]) setValue(`fifth_form${keyName}ContactNumberMobile`, AppContext.user[`fifth_form${keyName}ContactNumberMobile`])
    if (AppContext.user[`fifth_form${keyName}EmployedInEnglish`]) setValue(`fifth_form${keyName}EmployedInEnglish`, AppContext.user[`fifth_form${keyName}EmployedInEnglish`])
    if (AppContext.user[`fifth_form${keyName}IncomeEnglish`]) setValue(`fifth_form${keyName}IncomeEnglish`, AppContext.user[`fifth_form${keyName}IncomeEnglish`])
    if (keyName === "OtherRelatives") {
        if (AppContext.user[`fifth_formotherReletiveNameHeadingNameEnglish`]) setValue(`fifth_formotherReletiveNameHeadingNameEnglish`, AppContext.user[`fifth_formotherReletiveNameHeadingNameEnglish`])
        if (AppContext.user[`fifth_formOtherReletiveNameHeadingMobile`]) setValue(`fifth_formOtherReletiveNameHeadingMobile`, AppContext.user[`fifth_formOtherReletiveNameHeadingMobile`])
        if (AppContext.user[`fifth_formotherReletiveNameHeadingAddressEnglish`]) setValue(`fifth_formotherReletiveNameHeadingAddressEnglish`, AppContext.user[`fifth_formotherReletiveNameHeadingAddressEnglish`])
    }
    if (keyName === "Brother" || keyName === "Sister") {
        if (AppContext.user[`fifth_formMerried${keyName}`]) setValue(`fifth_formMerried${keyName}`, AppContext.user[`fifth_formMerried${keyName}`])
        if (AppContext.user[`fifth_formUnmerried${keyName}`]) setValue(`fifth_formUnmerried${keyName}`, AppContext.user[`fifth_formUnmerried${keyName}`])
    }
    return (
        <>
            {keyName === "OtherRelatives" &&
                <>
                    <div className='card-family imagePreview' >
                        <IonIcon icon={closeOutline} className='closeIcon' style={{ top: -8, right: -1, fontSize: 18, padding: 3 }} onClick={() => toggleRelation(name)} />
                        <div className='card-family-name Color-Theme'>{name}</div>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Name<span className='errorText m-0' >*</span></IonLabel>
                            <IonInput
                                {...register(`fifth_formotherReletiveNameHeadingNameEnglish`, { required: true })}
                                type="text"
                                placeholder="Enter Name"
                                className='border-bottom' >
                            </IonInput>
                            {errors?.[`fifth_formotherReletiveNameHeadingNameEnglish`]?.type === "required" && <p className='errorText ms-1'>Write Name of Relative.</p>}
                        </IonGrid>
                        <IonGrid >
                            <IonLabel position="stacked" className='fs-16 labelItem'>Mobile Number<span className='errorText m-0' >*</span></IonLabel>
                            <IonInput
                                {...register(`fifth_formOtherReletiveNameHeadingMobile`, { required: true, maxLength: 10, minLength: 10 })}
                                type="number"
                                pattern="[0-9]*"
                                maxlength={10}
                                placeholder="Enter Mobile Number"
                                className='border-bottom' >
                            </IonInput>
                            {errors?.[`fifth_formOtherReletiveNameHeadingMobile`]?.type === "required" && <p className='errorText ms-1'>Write Mobile Number.</p>}
                            {errors?.[`fifth_formOtherReletiveNameHeadingMobile`] && errors?.[`fifth_formOtherReletiveNameHeadingMobile`].type === "maxLength" && <p className='errorText ms-1'>Mobile Number must have 10 digits</p>}
                            {errors?.[`fifth_formOtherReletiveNameHeadingMobile`] && errors?.[`fifth_formOtherReletiveNameHeadingMobile`].type === "minLength" && <p className='errorText ms-1'>Mobile Number must have 10 digits</p>}
                        </IonGrid>
                        <IonGrid>
                            <IonLabel position="stacked" className='fs-16 labelItem'>Address<span className='errorText m-0' >*</span></IonLabel>
                            <IonTextarea autoGrow={true} rows={1} className='border-bottom' placeholder='Write Here...'   {...register("fifth_formotherReletiveNameHeadingAddressEnglish", { required: true })}></IonTextarea>
                            {errors.fifth_formotherReletiveNameHeadingAddressEnglish && errors.fifth_formotherReletiveNameHeadingAddressEnglish.type === "required" && <p className='errorText ms-1'>Address is required</p>}
                        </IonGrid>
                    </div>
                </>
            }
            {(keyName === "Father" || keyName === "Mother") &&
                <div className='card-family imagePreview' >
                    <IonIcon icon={closeOutline} className='closeIcon' style={{ top: -8, right: -1, fontSize: 18, padding: 3 }} onClick={() => toggleRelation(keyName)} />
                    <div className='card-family-name'>{name}</div>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Name<span className='errorText m-0' >*</span></IonLabel>
                        <IonInput
                            {...register(`fifth_form${keyName}NameEnglish`, { required: true })}
                            type="text"
                            placeholder="Enter Name"
                            className='border-bottom' >
                        </IonInput>
                        {errors?.[`fifth_form${keyName}NameEnglish`]?.type === "required" && <p className='errorText ms-1'>Write Name of {name}.</p>}
                    </IonGrid>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Mobile Number<span className='errorText m-0' >*</span></IonLabel>
                        <IonInput
                            {...register(`fifth_form${keyName}ContactNumberMobile`, { maxLength: 10, minLength: 10 })}
                            type="number"
                            pattern="[0-9]*"
                            maxlength={10}
                            placeholder="Enter Mobile Number"
                            className='border-bottom' >
                        </IonInput>
                        {errors?.[`fifth_form${keyName}ContactNumberMobile`]?.type === "required" && <p className='errorText ms-1'>Write Mobile Number.</p>}
                        {errors?.[`fifth_form${keyName}ContactNumberMobile`] && errors?.[`fifth_form${keyName}ContactNumberMobile`].type === "maxLength" && <p className='errorText ms-1'>Mobile Number must have 10 digits</p>}
                        {errors?.[`fifth_form${keyName}ContactNumberMobile`] && errors?.[`fifth_form${keyName}ContactNumberMobile`].type === "minLength" && <p className='errorText ms-1'>Mobile Number must have 10 digits</p>}
                    </IonGrid>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Employed In<span className='errorText m-0' >*</span></IonLabel>
                        {formDetail?.employedIn?.english && <CustomSelect
                            options={formDetail?.employedIn?.english}
                            control={control}
                            required={true}
                            name={`fifth_form${keyName}EmployedInEnglish`}
                            placeholder={"Select Employed In"}
                            isSearchable={true}

                        />}
                        {errors?.[`fifth_form${keyName}EmployedInEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Employed In.</p>}
                    </IonGrid>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Income<span className='errorText m-0' >*</span></IonLabel>
                        {formDetail?.incomeList?.english && <CustomSelect
                            options={formDetail?.incomeList?.english}
                            control={control}
                            required={true}
                            name={`fifth_form${keyName}IncomeEnglish`}
                            placeholder={"Select Income"}
                            isSearchable={false}

                        />}
                        {errors?.[`fifth_form${keyName}IncomeEnglish`]?.type === "required" && <p className='errorText ms-1'>Select Income.</p>}
                    </IonGrid>
                </div>
            }
            {(keyName === "Brother" || keyName === "Sister") &&
                <div className='card-family imagePreview' >
                    <IonIcon icon={closeOutline} className='closeIcon' style={{ top: -8, right: -1, fontSize: 18, padding: 3 }} onClick={() => toggleRelation(keyName)} />
                    <div className='card-family-name'>{name}</div>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Married<span className='errorText m-0' >*</span></IonLabel>
                        <CustomSelect
                            options={number}
                            control={control}
                            required={true}
                            name={`fifth_formMerried${keyName}`}
                            placeholder={`Select No of Married ${name}`}
                            isSearchable={true}

                        />
                        {errors?.[`fifth_formMerried${keyName}`]?.type === "required" && <p className='errorText ms-1'>Select No of Married {name}</p>}
                    </IonGrid>
                    <IonGrid >
                        <IonLabel position="stacked" className='fs-16 labelItem'>Unmarried<span className='errorText m-0' >*</span></IonLabel>
                        <CustomSelect
                            options={number}
                            control={control}
                            required={true}
                            name={`fifth_formUnmerried${keyName}`}
                            placeholder={`Select No of Unmarried ${name}`}
                            isSearchable={false}
                        />
                        {errors?.[`fifth_formUnmerried${keyName}`]?.type === "required" && <p className='errorText ms-1'>Select No of Unmarried {name}</p>}
                    </IonGrid>
                </div>
            }
        </>
    )
}

export default ThirdForm;