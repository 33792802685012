import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonIcon,
  IonSearchbar,
  IonCheckbox,
  IonFooter,
  IonChip,
} from "@ionic/react";
import { close, closeOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import appContext from "../../context/AppContext";
import React from "react";
import CheckboxTree from "react-checkbox-tree";

const FamilyStatusModel = ({
  serverFamilyStatus,
  setServerFamilyStatus,
  filterStates,
  isOpenModel,
  activeField,
  optionManglik,
  setIsOpenEducation,
}: any) => {
  const local_filters = localStorage.getItem("filters");
  const filters = local_filters ? JSON.parse(local_filters) : {};

  const AppContext = useContext(appContext);
  const [data, setData] = useState<any>(
    serverFamilyStatus?.length > 0 ? serverFamilyStatus : filters.education
  );

  const onSubmit = () => {
    setServerFamilyStatus(data);
    // setSelectedEducation(data)
  };

  const handleChange = (emp: any) => {
    const updatedSelectedItems = data.includes(emp?.name_english)
      ? data.filter((item: any) => item !== emp?.name_english)
      : [...data, emp?.name_english];
    setData(updatedSelectedItems);
  };

  const onDidDismiss = async () => {
    setIsOpenEducation(false);
  };

  const onWillDismiss = async () => {
    setIsOpenEducation(false);
  };

  return (
    <IonPage>
      <IonContent className="ion-padding filterBody">
        <IonModal
          isOpen={isOpenModel}
          className="filterBody"
          onDidDismiss={onDidDismiss}
          onWillDismiss={onWillDismiss}
        >
          <IonHeader>
            <IonToolbar color={"primary"}>
              <IonTitle className="heading text-white">
                Select Family Status
              </IonTitle>
              <div slot="end" className="backBtn">
                <IonButton
                  type="submit"
                  className="px-1"
                  onClick={() => {
                    setIsOpenEducation(false);
                    setData([]);
                  }}
                >
                  <IonIcon icon={closeOutline} color="light" />
                </IonButton>
              </div>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div
              className="d-flex"
              style={{ overflowX: "scroll", overflowY: "hidden" }}
            >
              {data?.map((label: any, i: any) => {
                return (
                  <IonChip key={i} color="medium">
                    <IonLabel>{label}</IonLabel>
                    <IonIcon
                      icon={close}
                      onClick={(ind: any) => {
                        const newEdu = data?.filter(
                          (item: any) => item !== label
                        );
                        setData(newEdu);
                      }}
                    ></IonIcon>
                  </IonChip>
                );
              })}
            </div>
            <div className="educationModal">
              <IonList>
                <div className="checkbox-tree-container">
                  <CheckboxTree
                    nodes={optionManglik}
                    checked={data}
                    expanded={["All"]}
                    onCheck={(checked: any) => setData(checked)}
                  />
                </div>
              </IonList>
            </div>
            <IonFooter
              style={{ display: "grid", position: "sticky", bottom: "0" }}
            >
              <IonButton
                onClick={() => {
                  setIsOpenEducation(false);
                  onSubmit();
                }}
              >
                Submit
              </IonButton>
            </IonFooter>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default FamilyStatusModel;
