import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonList, IonRadioGroup, IonIcon, IonSearchbar, IonCheckbox, IonFooter, IonChip, IonSelect, IonSelectOption, IonText, IonRadio } from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import appContext from "../../context/AppContext"
import CheckboxTree from 'react-checkbox-tree';


function AgeModal({ setServerStartAge, setServerEndAge, serverStartAge, serverEndAge, filterStates, isOpenAge, setIsOpenAge, ActiveAgeField, setStartAge, setEndAge }: any) {

    const local_filters = localStorage.getItem('filters')
    const filters = local_filters ? JSON.parse(local_filters) : {}
    const AppContext = useContext(appContext);
    const [data, setData] = useState<any>({
        start: serverStartAge ? serverStartAge : 18,
        end: serverEndAge ? serverEndAge : 80
        // start: filterStates?.age?.start ? filterStates?.age?.start : {},
        // end: filterStates?.age?.end ? filterStates?.age?.end : {}
    })
    // const [setFilterTemp]: any = useState(null);

    const onSubmit = () => {
        ActiveAgeField === 'startAge' ? setServerStartAge(data?.start?.value) : setServerEndAge(data?.end?.value)
        // ActiveAgeField === 'startAge' ? setFilterTemp({ ...AppContext?.user?.filter, startAge: data?.start.value }) : setFilterTemp({ ...AppContext?.user?.filter, endAge: data?.end.value })
    };

    const ageArray = [];
    for (let index = 18; index < 81; index++) {
        ageArray.push({ label: index, value: index });
    }

    const onDidDismiss = async () => {
        setIsOpenAge(false)
    }

    const onWillDismiss = async () => {
        setIsOpenAge(false)
    }

    return (
        <><IonPage>
            <IonContent className="ion-padding filterBody">
                <IonModal
                    isOpen={isOpenAge}
                    className="right-opening-modal"
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss}
                >
                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>{ActiveAgeField === 'startAge' ? 'Age From' : ' Age To'}</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => setIsOpenAge(false)}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <div className='ageModal'>
                            <IonList>
                                <IonRadioGroup allowEmptySelection={true}   >
                                    {ageArray?.map((item: any, i: any) => {
                                        return <IonItem key={i}>
                                            <IonLabel style={{ color: '#434343', fontSize: 15 }} >{item?.label} Yrs</IonLabel>
                                            {ActiveAgeField === 'startAge' ? <IonRadio slot="start" color='primary' value={item} onClick={() => { setData({ ...data, start: item }) }} ></IonRadio>
                                                : <IonRadio slot="start" color='primary' value={item} onClick={() => { setData({ ...data, end: item }) }} ></IonRadio>}
                                        </IonItem>
                                    })
                                    }
                                </IonRadioGroup>
                            </IonList>
                        </div>

                        <IonFooter style={{ display: "grid", position: "sticky", bottom: '0' }}>
                            <IonButton onClick={() => { setIsOpenAge(false); onSubmit() }}>Apply</IonButton>
                        </IonFooter>

                    </IonContent>
                </IonModal >
            </IonContent >
        </IonPage ></>
    );
};
export default AgeModal;