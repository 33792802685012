import { IonGrid } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import appContext from "../../context/AppContext"
import Firstform from './firstform';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';
import FourthForm from './FourthForm';
import GoLive from './GoLive';
import SixthForm from './SixthForm';
import FifthForm from './FifthForm';
import PopUp from './PopUp';
import utills from '../utils';
import profilePicture from '../../assets/images/profile-picture-1.png'
import publish from '../../assets/images/publish.png'
import RESIDENTAL from '../../assets/images/RESIDENTAL.png'
import professional from '../../assets/images/professional.png'
import family from '../../assets/images/family.png'
import birth from '../../assets/images/birth.png'
import Religious from '../../assets/images/Religious.png';
import PPF from '../../assets/images/partner-prefrence-female.png';
import PPM from '../../assets/images/partner-prefrence-male.png';
import BIODM from '../../assets/images/biodatamale.png';
import BIODF from '../../assets/images/biodatafemale.png';
import MultipleProfileUpload from './MultipleProfileUpload';
import subscribe4 from '../../assets/images/subscribe4.png'
import advance from '../../assets/images/advance_filter.png'
import constant from '../../constant';
import Packages from './Packages';

const Popups = () => {
    const AppContext = useContext(appContext);
    const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
    const [value, setValue] = useState<any>()

    // console.log("isOpenPopUp", isOpenPopUp);


    const formCalculations = async () => {
        var time = 15000;
        if (AppContext?.instantPopup) time = 10
        var calculation = await utills?.formCalculation(AppContext.user)
        if (calculation?.golive === 0) setValue("golive")
        else if (calculation?.profilePictureScore === 0) setValue("profilePicture")
        else if (calculation?.sixthForm === 0) setValue("partnerPreference")
        else if (calculation?.bioDataScore === 0) setValue("BioData")
        else if (calculation?.fistFormFillValue === 0) setValue("residentialDetail")
        else if (calculation?.secondFormFillValue === 0) setValue("professionalDetail")
        else if (calculation?.thirdFormFillValue === 0) setValue("family")
        else if (calculation?.fourthFormFillValue === 0) setValue("birth")
        else if (calculation?.fifthForm === 0) setValue("religious")
        else {
            setValue("")
            setOpenPopUp(false)
            return 0;
        }
        setTimeout(() => {
            setOpenPopUp(true)
        }, time);
    }

    useEffect(() => {
        if (AppContext?.user && !Object.values(AppContext?.popups).includes(true) && (!AppContext?.doitlater || AppContext?.noDelayPopup)) {
            if (AppContext?.user && AppContext?.noDelayPopup) {
                setValue(AppContext?.noDelayPopup);
                setOpenPopUp(true);
            } else {
                formCalculations();
            }
        }
    }, [AppContext?.user, AppContext?.popups, AppContext?.noDelayPopup]);

    useEffect(() => {
        if (AppContext?.instantPopup) formCalculations();
    }, [AppContext?.instantPopup]);

    const action = () => {
        if (value === "golive") AppContext.setPopups(constant.popups.isGoLive, true);
        else if (value === "profilePicture") { AppContext.setPopups(constant.popups.isOpenProfile, true); AppContext.setUpload({ key: "profile", name: "Profile" }) }
        else if (value === "BioData") { AppContext.setPopups(constant.popups.isOpenProfile, true); AppContext.setUpload({ key: "birth", name: "Biodata" }) }
        else if (value === "partnerPreference") AppContext.setPopups(constant.popups.isPartnerPreference, true);
        else if (value === "residentialDetail") AppContext.setPopups(constant.popups.isFirstForm, true);
        else if (value === "professionalDetail") AppContext.setPopups(constant.popups.isSecondForm, true);
        else if (value === "family") AppContext.setPopups(constant.popups.isThirdForm, true);
        else if (value === "birth") AppContext.setPopups(constant.popups.isFourthForm, true);
        else if (value === "religious") AppContext.setPopups(constant.popups.isFifthForm, true);
        else if (value === "isSubscribe") AppContext.setPopups(constant.popups.isSubscribe, true, true);
        else if (value === "isGoLive") AppContext.setPopups(constant.popups.isGoLive, true, true);
        else if (value === "filter") AppContext.setPopups(constant.popups.isSubscribe, true, true);
        setOpenPopUp(false)
    }

    const cancleAction = (data: any) => {
        setOpenPopUp(data)
    }

    return (
        <>
            <IonGrid className='dashboardScreen'>
                <div className='d-flex' style={{ overflowX: 'scroll', overflowY: "hidden" }}>
                    {value && <PopUp isOpenPopUp={isOpenPopUp} setOpenPopUp={cancleAction} img={
                        value === "golive" ? publish :
                            value === "isGoLive" ? publish :
                                value === "profilePicture" ? profilePicture :
                                    value === "BioData" && AppContext.user.first_formGenderEnglish === "Male" ? BIODM :
                                        value === "BioData" && AppContext.user.first_formGenderEnglish === "Female" ? BIODF :
                                            value === "partnerPreference" && AppContext.user.first_formGenderEnglish === "Male" ? PPM :
                                                value === "partnerPreference" && AppContext.user.first_formGenderEnglish === "Female" ? PPF :
                                                    value === "residentialDetail" ? RESIDENTAL :
                                                        value === "professionalDetail" ? professional :
                                                            value === "family" ? family :
                                                                value === "birth" ? birth :
                                                                    value === "isSubscribe" ? subscribe4 :
                                                                        value === "filter" ? advance :
                                                                            value === "religious" ? Religious : null} goTo={action} cancel={true} componentName={value} />}
                    <GoLive />
                    <MultipleProfileUpload />
                    <Firstform />
                    <SecondForm />
                    <ThirdForm />
                    <FourthForm />
                    <FifthForm />
                    <SixthForm />
                    <Packages />
                </div>
            </IonGrid>
        </>
    );
};
export default Popups;