const constant = {
  JSMUSER: "hsm_user",
  JSMUSERLOGGEDIN: "hsm_user_logged_in",
  SignIn: "SignIn",
  SignUp: "SignUp",
  English: "en",
  NewAccount: "NewAccount",
  FilterConst: {
    employedIn: [],
    dosh: [],
    maritalStatus: [],
    sect: [],
    states: [],
    physicalStatus: [],
    familyStatus: [],
    incomeOfApplicant: [],
    age: {
      start: 18,
      end: 80,
    },
  },
  routes: {
    signin: "/signin",
    otp: "/otp",
    userlist: "/user-list",
    signupform: "/signup-form",
  },
  ImageS3URl: "https://mobile.jainshadimilan.com",
  // ImageS3URl: "http://localhost:7200",
  otpRestore: "otpRestore",
  popups: {
    isOpenProfile: "isOpenProfile",
    isGoLive: "isGoLive",
    isPartnerPreference: "isPartnerPreference",
    isFirstForm: "isFirstForm",
    isSecondForm: "isSecondForm",
    isThirdForm: "isThirdForm",
    isFourthForm: "isFourthForm",
    isFifthForm: "isFifthForm",
    isSubscribe: "isSubscribe",
    filter: "filter",
  },
};

export default constant;
// Hello
