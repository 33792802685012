import {
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonSkeletonText,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import constant from "../../constant";
import { arrowBackOutline } from "ionicons/icons";
import {
  addToFavoriteList,
  dashboard,
  unlock,
  profileVisitedUserList,
  sendInterestRequest,
  sendPhotoRequestApi,
  unlockUserList,
  getCurrentPackage,
} from "../../service";
import UserCard from "./UserCard";
import GoLive from "./GoLive";
import Details from "./Details";
import { toast } from "react-toastify";
import NewUserCard from "./NewUserCard";

const UnlockedProfile: React.FC = () => {
  const [usersList, setUsersList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const AppContext = useContext(appContext);
  const [sortedData, setSortedData] = useState([]);
  const history = useHistory();
  const [present] = useIonToast();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const [presentAlert] = useIonAlert();
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [isDataAvailable, setDataAvailable] = useState<any>(true);

  useEffect(() => {
    if (location.pathname === "/app/unlocked-Profile") {
      CurrentPackageData();
      getUserlist(0);
    }
  }, [location]);

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  const getUserlist = useCallback(async (pN: any) => {
    var response = await unlockUserList(
      { order: "latest", name: "Latest Profile" },
      pN
    );
    setLoading(false);
    setPageNumber(pN + 1);
    setUsersList(response?.data?.data);
  }, []);

  useEffect(() => {
    const sorted = [...usersList].sort((a, b) => {
      const dateA: any = new Date(b.ViewedUser.created_at);
      const dateB: any = new Date(a.ViewedUser.created_at);
      return dateA - dateB;
    });
    const groupedByDate: any = {};
    sorted.forEach((item) => {
      const formattedDate = formatDate(new Date(item.ViewedUser.created_at));
      if (!groupedByDate[formattedDate]) {
        groupedByDate[formattedDate] = [];
      }
      groupedByDate[formattedDate].push(item);
    });

    setSortedData(groupedByDate);
  }, [usersList]);

  const formatDate = (date: Date) => {
    const day = date.toLocaleString("en-US", { day: "2-digit" });
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.toLocaleString("en-US", { year: "numeric" });
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    if (AppContext?.user) getUserlist(0);
  }, [AppContext?.user]);

  const getNextUserlist = async (pN: any) => {
    if (pN === 0) setUsersList([]);
    var response = await unlockUserList(
      { order: "latest", name: "Latest Profile" },
      pN
    );
    setLoading(false);
    setPageNumber(pN + 1);
    let uList = pN === 0 ? [] : usersList;
    setDataAvailable(response?.data?.data?.length >= 10 ? true : false);
    setUsersList([...uList, ...response?.data?.data]);
  };

  const addToFavorite = async (selectedUser: any, index: any) => {
    const usersListTemp = [...usersList];
    usersListTemp[index].favorite = !usersListTemp[index].favorite;
    setUsersList(usersListTemp);
    await addToFavoriteList(selectedUser);
    getUserlist(pageNumber - 1);
  };

  const slideOpts = {
    slidesPerView: "auto",
    spaceBetween: 10,
  };

  const sendInterest = async (selectedUser: any, index: any) => {
    var res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...usersList];
      usersListTemp[index].interest = true;
      setUsersList(usersListTemp);
      toast("Sent Interest Successfully");
      getUserlist(pageNumber - 1);
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  const handleClick = async (type: string, subType: string = "") => {
    switch (type) {
      case "details":
        if (!AppContext?.user?.payment_verified_at) {
          AppContext.setPopups(constant.popups.isSubscribe, true);
          // AppContext.setNoDelayPopup(constant.popups.isSubscribe)
        } else if (!AppContext?.user?.verified_at) {
          presentAlert({
            header: `Please wait for admin to approve your profile.`,
            cssClass: "actionAlert",
            buttons: [{ text: "OK", role: "confirm" }],
          });
        }
        break;
    }
  };

  const sendPhotoRequest = async (selectedUser: any, index: any) => {
    const usersListTemp = [...usersList];
    usersListTemp[index].photoRequest = true;
    setUsersList(usersListTemp);
    var res: any = await sendPhotoRequestApi(selectedUser);
    if (res?.data?.status) {
      toast("Photo request sent successfully.");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <div
            onClick={() => {
              history.goBack();
            }}
            slot="start"
            className="backBtn"
          >
            <IonButton type="submit" className="px-1">
              <IonIcon icon={arrowBackOutline} color="light" />
            </IonButton>
          </div>
          <IonTitle className="fs-18 text-white">
            <b> Unlocked Profile</b>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ marginBottom: "50px !important " }}>
        {loading ? (
          <IonList>
            {[...Array(3)].map((i) => (
              <IonCard className="box-new-profile ion-justify-content-center ion-align-items-center">
                <IonGrid
                  className="ion-margin-0"
                  style={{ maxHeight: "130px", margin: 0 }}
                >
                  <IonRow>
                    <IonCol size="4">
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "100%",
                          position: "relative",
                          background: "#ccc",
                        }}
                      ></div>
                    </IonCol>
                    <IonCol size="8">
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText
                            animated
                            style={{ width: "80%", height: "12px" }}
                          ></IonSkeletonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText
                            animated
                            style={{ width: "60%", height: "12px" }}
                          ></IonSkeletonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText
                            animated
                            style={{ width: "80%", height: "12px" }}
                          ></IonSkeletonText>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <>
            {usersList.length < 1 ? (
              <IonCard className="card ">
                {" "}
                {AppContext?.user?.payment_verified_at === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      className="fs-18 ion-text-center ion-margin-top"
                      style={{ fontWeight: "bolder" }}
                    >
                      You didn't unlocked any users yet
                    </h2>
                    <h2
                      className="fs-18 ion-text-center"
                      style={{ fontWeight: "bolder" }}
                    >
                      Pay registration fees to unlock
                    </h2>
                    <h2
                      className="fs-12 ion-text-center "
                      style={{ fontWeight: "bolder" }}
                    >
                      (आपने अभी तक किसी यूजर को अनलॉक नहीं किया है यूजर को अनलॉक
                      करने के लिए अभी रजिस्ट्रेशन फीस भरे|)
                    </h2>
                    <IonButton
                      className="w-50 ion-margin-top ion-margin-bottom"
                      style={{ height: "2em" }}
                      size="large"
                      onClick={(event) => {
                        handleClick("details");
                      }}
                    >
                      Pay Now
                    </IonButton>
                  </div>
                ) : (
                  <p className="fs-16 ion-text-center ion-margin-top">
                    You don't have any user in Unlocked Profile!
                  </p>
                )}
              </IonCard>
            ) : (
              <IonList>
                {Object.entries(sortedData).map(([date, items]: any) => (
                  <div key={date}>
                    <IonList
                      style={{
                        paddingLeft: "1.2em",
                        background: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {date}
                    </IonList>
                    {items?.map((user: any, i: any) => {
                      return (
                        <NewUserCard
                          addToFavorite={addToFavorite}
                          sendInterest={sendInterest}
                          user={user}
                          i={i}
                          history={history}
                          CurrentPackageBasic={CurrentPackageBasic}
                          CurrentPackageVip={CurrentPackageVip}
                          sendPhotoRequest={sendPhotoRequest}
                          onAction={() => {
                            setUserID(user?.id);
                          }}
                          isOpenDetailModal={() => {
                            setOpenDetail(true);
                          }}
                        />
                      );
                    })}
                  </div>
                ))}
              </IonList>
            )}
            <Details
              isOpenDetail={isOpenDetail}
              setOpenDetail={setOpenDetail}
              id={userID}
            />

            {isDataAvailable && (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  getNextUserlist(pageNumber);
                  setTimeout(() => ev.target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            )}
          </>
        )}
      </IonContent>
      {/* <GoLive /> */}
    </IonPage>
  );
};
export default UnlockedProfile;
