import { useState } from "react";
import { Controller } from "react-hook-form";
import Select from 'react-select'


const CustomMultiselect = ({ options, control, name, required, placeholder, components, isDisabled, isSearchable = true, setValue }: any) => {
    const [optionsTemp, setOptionsTemp] = useState(options);
    const handleChange = (e: any) => {
        // console.log("laltverma", e);
        // if (e?.find((u: any) => { return u?.label === 'All' })) {
        if (e[e.length - 1]?.value === "All") {
            setValue(name, [{ label: "All", value: "All" }]);
            // optionsTemp?.forEach((p: any) => { p.disabled = true; });
            // setOptionsTemp(optionsTemp);
        } else {
            var tempOption: any = [...e]
            tempOption = tempOption.filter((val: any) => val.value !== "All")
            optionsTemp?.forEach((p: any) => { p.disabled = false; });
            setValue(name, tempOption);
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => (<Select maxMenuHeight={200}
                isOptionDisabled={(option) => option.disabled}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                isSearchable={false}
                components={components}
                placeholder={<div>{placeholder || "Select"}</div>}
                className='border-bottom'
                isMulti
                theme={(theme) => ({
                    ...theme,
                    spacing: {
                        ...theme.spacing,
                        controlHeight: 30,
                    },
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: 'white',
                        primary: '#ff4d4d',
                        neutral50: 'lightgrey'
                    },
                })}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: state.isFocused ? 'rgb(238, 69, 61)' : 'white',
                        fontWeight: 600,
                        fontSize: 15,
                    }),
                }}
                options={optionsTemp}
                {...field}
                defaultValue={[optionsTemp?.filter((option: any) => option?.label === (field?.value?.value || field?.value))]}
                onChange={handleChange}
            />)}
        />
    )
}
export default CustomMultiselect;