import {
  createAnimation,
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonList,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Header from "../shared/Header";
import DashboardUserList from "./DashboardUserList";
import UserForms from "./UserForms";
import congratulations from "../../assets/images/congratulations.png";
import constant from "../../constant";
import PopUp from "./PopUp";
import { PushNotifications } from "@capacitor/push-notifications";
import { caretForwardSharp, heartCircleOutline } from "ionicons/icons";
import {
  applicationLogin,
  getAppVersion,
  getUpdateApp,
  updateApprovalPopup,
  websiteLogin,
} from "../../service";
import { CapacitorUpdater } from "capacitor-updater";
import appContext from "../../context/AppContext";
import { isPlatform } from "@ionic/react";
// import { Camera } from '@capacitor/camera';
// import { Storage } from '@capacitor/storage';
import { Capacitor } from "@capacitor/core";
import { Plugins } from "@capacitor/core";
import { toast } from "react-toastify";

const Dashbaord = () => {
  const AppContext = useContext(appContext);
  const [percent, setPercent] = useState(0);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  // const [isOpenPopUpdate, setOpenPopUpdate] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [show, setShow] = useState<any>(false);
  const [showDownload, setShowDownload] = useState<any>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const { App } = Plugins;
  const [presentAlert] = useIonAlert();

  const getUpdateAppVersion = useCallback(async () => {
    var res: any = await getUpdateApp();
    if (res?.data?.status) {
      setData(res?.data?.data);
      setShow(res?.data?.data?.show);
      // res?.data?.data?.show ? setOpenPopUpdate(res?.data?.data?.show) : setOpenPopUpdate(res?.data?.data?.show)
      setTimeout(() => {
        setShow(false);
      }, res?.data?.data?.time * 10);
    }
  }, []);

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }
    await PushNotifications.register();
  };

  useEffect(() => {
    const performLogin = async () => {
      if (isPlatform("hybrid")) {
        await applicationLogin();
      } else {
        await websiteLogin();
      }
    };

    performLogin();
  }, []);

  useEffect(() => {
    AppContext?.user?.is_approvalpopup_open &&
      presentAlert({
        header: "Now your profile is visible to all the users.",
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
            handler: async () => {
              await updateApprovalPopup({ id: AppContext?.user?.id });
            },
          },
        ],
      });
    registerNotifications();
  }, []);

  useEffect(() => {
    getUpdateAppVersion();
    if (localStorage.getItem(constant.NewAccount) === "YES") {
      setOpenPopUp(true);
    }
  }, [getUpdateAppVersion]);

  useEffect(() => {
    localStorage.removeItem("lastPage");
    // updateApp();
    App?.addListener("backButton", (res: any) => {
      if (!res.canGoBack) {
        if (localStorage.getItem("isModal") === "false") {
          presentAlert({
            header: "Are you sure want to Exit?",
            cssClass: "actionAlert",
            buttons: [
              {
                text: "Yes",
                role: "confirm",
                handler: async () => {
                  App.exitApp();
                },
              },
              {
                text: "no",
                role: "cancel",
              },
            ],
          });
        }
      }
    });
  }, []);

  var data1: any = localStorage.getItem("responseData");
  var res = JSON.parse(data1);

  // useEffect(() => {
  //     updateApp();
  //     App?.addListener('backButton', (res: any) => {
  //         if (!res.canGoBack) {
  //             {
  //                 console.log("response For new user");
  //             }
  //         }
  //     });
  // }, [])

  //   const updateApp = async () => {
  //     let res = await getAppVersion();
  //     if (
  //       AppContext.currentVersion < res?.data?.data?.newVersion &&
  //       Capacitor.getPlatform() != "web"
  //     ) {
  //       CapacitorUpdater.addListener("download", (info) => {
  //         setPercent(info.percent);
  //       });
  //       AppContext.setDoItLater();
  //       setShowDownload(true);
  //       AppContext.forceUpdate();
  //     }
  //   };

  const modal = useRef<HTMLIonModalElement>(null);
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  const goTo = async () => {
    localStorage.removeItem(constant.NewAccount);
    setOpenPopUp(false);
  };

  const handleCheckboxClick = (value: number) => {
    setRating(value);
  };

  const handleSubmit = () => {
    // Handle submission logic here using 'rating' and 'comment' states
    console.log("Rating:", rating);
    console.log("Comment:", comment);

    // Reset state after submission (optional)
    setRating(0);
    setComment("");
    setShowPopover(false);
  };

  const renderRatingCheckboxes = () => {
    const checkboxes = [];
    for (let i = 1; i <= 5; i++) {
      checkboxes.push(
        <IonCheckbox
          style={{ marginX: "5px" }}
          key={i}
          checked={rating >= i}
          onIonChange={() => handleCheckboxClick(i)}
        />
      );
    }
    return checkboxes;
  };

  const popoverContent = (
    <div>
      <h2>Please write a review</h2>
      <div>{renderRatingCheckboxes()}</div>
      <div>
        <IonInput
          placeholder="Write your comment..."
          value={comment}
          onIonChange={(e: any) => setComment(e.detail.value!)}
        />
      </div>
      <IonButton expand="block" onClick={handleSubmit}>
        Submit
      </IonButton>
    </div>
  );

  return (
    <>
      <IonPage className="home-body">
        <Header />
        <IonContent className="p-2">
          <UserForms />
          <div style={{}}>
            <IonRow style={{ alignItems: "center", margin: "8px 12px" }}>
              <IonCol size="7" style={{ padding: 0 }}>
                <p style={{ fontSize: 14, fontWeight: 600, margin: 0 }}>
                  <b>
                    <IonIcon
                      icon={heartCircleOutline}
                      color="primary"
                      size="small"
                      style={{ marginRight: 4, verticalAlign: "middle" }}
                    />
                    Our Recommendation
                  </b>
                </p>
              </IonCol>
              <IonCol size="4" style={{ padding: 0 }}>
                <div style={{ margin: "0px 0px 0px 0px", width: "auto" }}>
                  <div style={{ padding: 0 }}>
                    <div className="hr-border-red" style={{ height: 1 }}></div>
                    <div
                      className="hr-border-yellow"
                      style={{ height: 1 }}
                    ></div>
                    <div
                      className="hr-border-green"
                      style={{ height: 1 }}
                    ></div>
                    <div className="hr-border-blue" style={{ height: 1 }}></div>
                  </div>
                </div>
              </IonCol>
              {/* <IonCol size='3' style={{ textAlign: "right", padding: 0 }}>
                        <p style={{ fontSize: 12, fontWeight: 600, margin: 0, cursor: "pointer" }} className="color-primary" 
                        // onClick={() => {
                        //     history.push({
                        //         pathname: '/app/latest-profile',
                        //         state: { "order": "latest", "name": "Latest Profile" }
                        //     })
                        // }}
                        ></p>
                    </IonCol> */}
            </IonRow>
          </div>
          <DashboardUserList />
          <PopUp
            isOpenPopUp={isOpenPopUp}
            setOpenPopUp={setOpenPopUp}
            img={congratulations}
            goTo={goTo}
            cancel={false}
            componentName={"newaccount"}
          />
          <div className="updateModal">
            <IonModal
              isOpen={show}
              id="example-modal-subscribe1"
              ref={modal}
              trigger="open-custom-dialog"
              enterAnimation={enterAnimation}
              leaveAnimation={leaveAnimation}
              backdropDismiss={false}
            >
              <IonContent>
                <IonList className="mainCard">
                  <div className="ion-text-center mx-3">
                    <img loading="lazy" src={data?.image}></img>
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={() => {
                        window.location.href = data?.link;
                      }}
                    >
                      Update
                      <IonIcon
                        icon={caretForwardSharp}
                        color="light"
                        style={{ verticalAlign: "middle" }}
                        className="ms-2"
                      />
                    </button>
                  </div>
                </IonList>
              </IonContent>
            </IonModal>
          </div>
          {/* <div>
                        <IonButton onClick={(e) => setShowPopover(true)}>Open Review Popover</IonButton>

                        <IonPopover
                            isOpen={showPopover}
                            onDidDismiss={() => setShowPopover(false)}
                            style={{ padding: "2em", "--max-width": "90vw !important" }}
                        >
                            {popoverContent}
                        </IonPopover>
                    </div> */}
        </IonContent>
        {/* <GoLive /> */}
        <IonModal isOpen={showDownload}>
          <IonContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "28vh",
                position: "absolute",
                width: "100%",
                paddingLeft: "5vw",
                paddingRight: "5vw",
                textAlign: "center",
              }}
            >
              <h2>hold on!</h2>
              <h6>
                We are updating you
                <br />
                The latest app of{" "}
                <b style={{ color: "#EF423B" }}>Hindu Shadi Connect</b>
              </h6>
            </div>
            <h1 className="onloading">
              <div className="loading-container">
                <div className="loading"></div>
                <div id="loading-text">
                  UPDATING <br />
                  {percent}%
                </div>
              </div>
            </h1>
          </IonContent>
        </IonModal>
      </IonPage>
    </>
  );
};
export default Dashbaord;
