import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonIcon, IonSearchbar, IonFooter, IonChip, IonLabel, IonRadioGroup, IonList, IonItem, IonCheckbox } from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { useState, useContext } from 'react';
import appContext from "../../context/AppContext"


function EmployedInModel({ filterStates, serverEmployedIn, setServerEmployedIn, isOpenEmployed, setIsOpenEmployed, employedInList, setSelectedEmployed, }: any) {


    const local_filters = localStorage.getItem('filters')
    const filters = local_filters ? JSON.parse(local_filters) : {}

    const AppContext = useContext(appContext);
    const [data, setData] = useState<any>(serverEmployedIn.length > 0 ? serverEmployedIn : filters.employedIn)
    const [filteredResults, setFilteredResults] = useState<any>(employedInList);

    const handleChange = (emp: any) => {
        const empArray = [{ label: "All", value: "All" }].concat(employedInList)?.map((item: any) => item?.label);
        if (emp?.label === 'All') {
            data?.includes(emp?.label) ?
                setData([]) : setData(empArray)
        }
        else {
            const updatedSelectedItems = data?.includes(emp?.label)
                ? data?.filter((item: any) => item !== emp?.label)
                : [...data, emp?.label];
            setData(updatedSelectedItems)
        }
    };

    const onsubmit = () => {
        const updatedSelectedItems = data?.includes('All')
            ? data?.filter((item: any) => item !== 'All')
            : [...data];
        setServerEmployedIn(updatedSelectedItems)
        // setSelectedEmployed(updatedSelectedItems)
    };

    const set = () => {
        setFilteredResults(employedInList)
    }

    const handleSearch = (event: any) => {
        const searchInput = (event?.target?.value).trim();
        const filteredItems = employedInList?.filter((item: any) =>
            item?.label?.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredResults(filteredItems);
    };

    const onDidDismiss = async () => {
        setIsOpenEmployed(false)
    }

    const onWillDismiss = async () => {
        setIsOpenEmployed(false)
    }



    return (
        <IonPage>
            <IonContent className="ion-padding filterBody">
                <IonModal isOpen={isOpenEmployed} className='filterBody'
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss} onDidPresent={set}
                >
                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>Select Employed In</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => setIsOpenEmployed(false)}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonSearchbar debounce={1000} onIonChange={(event) => handleSearch(event)} ></IonSearchbar>
                        <div className='d-flex' style={{ overflowX: 'scroll', overflowY: "hidden" }}>{data?.map((item: any, i: any) => {
                            return <IonChip key={i} color="medium" >
                                <IonLabel style={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}>{item}</IonLabel>
                                <IonIcon icon={close} onClick={(ind: any) => {
                                    const showEmployee = data?.filter((i: any) => i !== item)
                                    setData(showEmployee)
                                }}>
                                </IonIcon>
                            </IonChip >
                        })}
                        </div>
                        <div className='educationModal'>
                            <IonList>
                                <IonRadioGroup allowEmptySelection={true}  >
                                    {[{ label: "All", value: "All" }].concat(filteredResults)?.map((emp: any, i: any) => {
                                        return <IonItem key={i}>
                                            <IonLabel style={{ fontSize: 15 }} >{emp?.label}</IonLabel>
                                            <IonCheckbox slot="start" checked={data?.includes(emp?.label)} color='primary' class='mx-2' onClick={(e) => {
                                                handleChange(emp)
                                            }} />
                                        </IonItem>
                                    })
                                    }
                                </IonRadioGroup>
                            </IonList>
                        </div>
                        <IonFooter style={{ display: "grid", position: "sticky", bottom: '0' }}>
                            <IonButton onClick={() => { setIsOpenEmployed(false); onsubmit(); }}>Submit</IonButton>
                        </IonFooter>
                    </IonContent>
                </IonModal >
            </IonContent >
        </IonPage >
    );
};
export default EmployedInModel;