import { IonAvatar, IonCard, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonSkeletonText, IonThumbnail } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import appContext from "../../context/AppContext"
import constant from '../../constant';
import { useHistory } from 'react-router-dom';
import { getAllNotification } from '../../service';
import moment from 'moment';
import subscribenow from '../../assets/images/subs.png'
import PopUp from './PopUp';
import Details from './Details';
import MultipleProfileUpload from './MultipleProfileUpload';
import PushNotification from './PushNotification';

const Notification: React.FC = () => {

    const AppContext = useContext(appContext);
    const [Notification, setNotification] = useState<any>();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
    const [isOpenDetail, setOpenDetail] = useState<any>(false);
    const [userID, setUserID] = useState<any>("");
    const [pageNumber, setPageNumber] = useState<number>(0);

    // useEffect(() => {
    //     getNotification(0)
    // }, [])

    const getNotification = useCallback(async (page: any) => {
        var res: any = await getAllNotification(page);
        if (res?.data?.status) {
            setPageNumber(page + 1);
            // setLoading(false);
            let uList = page === 0 ? [] : Notification;
            return [...uList, ...res?.data?.data] || []
            // setNotification([...uList, ...res?.data?.data]);
        }
    }, [])

    const memoizedNotificationList = useMemo(async () => {
        return getNotification(0);
    }, [getNotification])

    useEffect(() => {
        Promise.all([
            memoizedNotificationList
        ])
            .then(([Notification]) => {
                setNotification(Notification);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error in handling Promise results:', error);
            });
    }, [memoizedNotificationList]);


    

    const redirectUser = (user: any) => {
        if (user?.type === "new_visitor" || user?.type === "interest") {
            setUserID(user?.user?.id);
            setOpenDetail(true);
            // AppContext.setNotification(false);
        }
        if (user?.type === "popular") {
            if (AppContext?.user?.payment_verified_at) {
                history.push({
                    pathname: '/app/profile/second-marrige',
                    state: { "type": "new_ten_visitor", "name": "Visitor List" }
                })
                AppContext.setNotification(false)
            }
            else {
                setOpenPopUp(true);
            }
        }
        if (user?.type === "request_photo") {
            AppContext.setPopups(constant.popups.isOpenProfile, true);
            AppContext.setUpload({ key: "profile", name: "Profile" })
        }
    }

    const goTo = async () => {
        AppContext.setPopups(constant.popups.isSubscribe, true, true)
        setOpenPopUp(false);
    }

    return (
        <>

            {loading ?
                <div >
                    {[...Array(3)].map(user => (
                        <IonCard className='card ion-margin-top'>
                            <IonItem onClick={() => { }} >
                                <IonThumbnail className='ion-margin-end'>
                                    <IonSkeletonText animated></IonSkeletonText>
                                </IonThumbnail>
                                <IonLabel>
                                    <h3 >
                                        <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                                    </h3>
                                    <p>
                                        <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                                    </p>
                                </IonLabel>

                            </IonItem>
                        </IonCard>
                    ))
                    }
                </div>
                :
                (<><div >
                    <PushNotification />
                    {Notification.length < 1 ? <IonCard className='card '> <p className='fs-16 ion-text-center ion-margin-top'>No New Notification !</p></IonCard> :
                        <div>
                            {Notification.map((i: any) => {
                                return <IonCard className='card ion-margin-top'>

                                    <IonItem onClick={() => { redirectUser(i) }} >
                                        {
                                            i?.type !== "payment_done"
                                                ?
                                                i?.user ?
                                                    i?.user?.profiles?.length === 0 ?
                                                        <IonAvatar className='ion-margin-end' style={{ height: 55, width: 55 }}>
                                                            <img loading="lazy" src={"https://ionicframework.com/docs/img/demos/avatar.svg"} />
                                                        </IonAvatar>
                                                        : <IonAvatar className='ion-margin-end' style={{ height: 55, width: 55 }}>
                                                            <img loading="lazy" src={`${constant.ImageS3URl}/${i?.user?.profiles[0]?.folder}/${i?.user?.profiles[0]?.name}`} />
                                                        </IonAvatar>
                                                    : <IonAvatar className='ion-margin-end' style={{ height: 55, width: 55 }}>
                                                        <img loading="lazy" src={"https://ionicframework.com/docs/img/demos/avatar.svg"} />
                                                    </IonAvatar>
                                                :
                                                <IonAvatar className='ion-margin-end' style={{ height: 55, width: 55 }}>
                                                    <img loading="lazy" src={"https://ionicframework.com/docs/img/demos/avatar.svg"} />
                                                </IonAvatar>
                                        }

                                        <IonLabel className='notifictaionText'>
                                            <p >
                                                {i.title}
                                            </p>
                                            <h3 >
                                                <b>{i.description}</b>
                                            </h3>
                                            <small className='fs-12'><b>{moment(i.created_at).fromNow()}</b></small>
                                        </IonLabel>

                                    </IonItem>
                                </IonCard>
                            })}
                            <IonInfiniteScroll
                                onIonInfinite={(ev) => {
                                    getNotification(pageNumber);
                                    setTimeout(() => ev.target.complete(), 500);
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </div>
                    }
                </div>
                    {/* <PushNotification /> */}
                </>)
            }
            {/* <MultipleProfileUpload /> */}
            <PopUp isOpenPopUp={isOpenPopUp} setOpenPopUp={setOpenPopUp} img={subscribenow} goTo={goTo} cancel={true} componentName={'subscribenow'} />
            <Details isOpenDetail={isOpenDetail} setOpenDetail={setOpenDetail} id={userID} />
        </>

    );
};
export default Notification;
export const MemoizedNotification = React.memo(Notification);