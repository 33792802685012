import { useEffect, useState, useContext, createRef } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
  IonIcon,
  IonImg,
  useIonActionSheet,
  useIonToast,
  useIonAlert,
  IonContent,
  IonTextarea,
} from "@ionic/react";
import { getGoliveFormDetails, getProfiles, signUp } from "../../service";
import appContext from "../../context/AppContext";
import {
  cameraOutline,
  closeOutline,
  eyeOutline,
  imagesOutline,
} from "ionicons/icons";
import { useForm } from "react-hook-form";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { profileUpload } from "../../service";
import { decode } from "base64-arraybuffer";
import moment from "moment";
import EducationModal from "./EducationModal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import CustomSelect from "../shared/CustomSelect";
import constant from "../../constant";
import PopUp from "./PopUp";
import publish from "../../assets/images/publish.png";
import { toast } from "react-toastify";

function GoLive({ liveButton = true }: any) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, touchedFields },
  }: any = useForm();
  const [ImageResponse, setImageResponse] = useState<any>([]);
  const [employedInOption, setEmployedInOption] = useState<any>([]);

  const [live, setLive] = useState<any>(true);
  const [mobileError, setMobileError] = useState<any>(false);
  const [formDetail, setFormDetail] = useState<any>(null);
  const [education, setEducation] = useState<any>(null);
  const [isClosePopUp, setClosePopUp] = useState<any>(true);
  const [presentAction] = useIonActionSheet();
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const AppContext = useContext(appContext);
  const [isOpenEducation, setIsOpenEducation] = useState(false);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const contentRef = createRef<HTMLIonContentElement>();

  const pphysicalStatusArray = [
    { label: "Normal", value: "Normal" },
    { label: "Basic Physical Problem", value: "Basic Physical Problem" },
    { label: "Some Medical Problem", value: "Some Medical Problem" },
    { label: "Physically Challenged", value: "Physically Challenged" },
  ];

  useEffect(() => {
    if (AppContext?.user?.second_formMobile) {
      setLive(true);
    } else {
      setLive(false);
    }
  }, [AppContext?.user?.second_formMobile]);

  useEffect(() => {
    const checkUserInformation = () => {
      const checkSignUpPage = () => {
        if (
          AppContext.user.nameEnglish &&
          AppContext.user.first_formGenderEnglish &&
          AppContext.user.third_formSectEnglish &&
          AppContext.user.first_formMaritalStatusEnglish &&
          AppContext.user.first_formProfileEnglish &&
          AppContext.user.second_formStateEnglish &&
          AppContext.user._age
        ) {
          return true;
        } else return false;
      };
      if (
        checkSignUpPage() &&
        (!AppContext?.user?.third_formDoshEnglish ||
          !AppContext?.user?.fourth_formIncomeOfApplicantEnglish ||
          !AppContext?.user?.fourth_formEmployedEnglish ||
          !AppContext?.user?.fifth_formFamilyStatusEnglish ||
          !AppContext?.user?.second_formEatingHabitsEnglish ||
          !AppContext?.user?.second_formHeightFeet ||
          !AppContext?.user?.second_formHeightCm ||
          !AppContext?.user?.fourth_formEducationEnglish ||
          !AppContext?.user?.second_formPhysicalStatusEnglish ||
          !AppContext?.user?.fourth_formSubEductionEnglish)
      ) {
        return true;
      } else return false;
    };
    if (checkUserInformation()) {
      setTimeout(() => {
        if (AppContext.user.verified_at === 1) setOpenPopUp(true);
      }, 180000);
    } else setOpenPopUp(false);
  }, [AppContext.user]);

  const [isOpen, setIsOpen] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");
  const [isLessThan10, setIsLessThan10] = useState(false);

  const pickImage = async () => {
    await presentAction({
      cssClass: "myMenu",
      buttons: [
        {
          text: "Add From Album",
          icon: imagesOutline,
          data: {
            action: "Add From Album",
          },
          handler: () => {
            uploadProfile("Gallery");
          },
        },
        {
          text: " Use Camera",
          icon: cameraOutline,
          data: {
            action: "Use Camera",
          },
          handler: () => {
            uploadProfile("Camera");
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          icon: closeOutline,
          data: {
            action: "cancel",
          },
        },
      ],
      onDidDismiss: () => {},
    });
  };

  const getProfilesList = async () => {
    var res: any = await getProfiles({ type: "profile" }, AppContext?.user?.id);
    if (res?.data?.status) {
      setImageResponse(res?.data?.data[0]);
    }
  };
  const getFormDetais = async () => {
    setClosePopUp(false);
    var res: any = await getGoliveFormDetails();
    if (res?.data?.status) {
      setFormDetail(res?.data?.data);
    }
    var arr: any = [];
    res?.data?.data?.employedIn?.english.map((i: any) => {
      if (i?.value !== "Passed away" || i?.value !== "Retired") {
        arr.push(i);
        setEmployedInOption(arr);
      }
      return 0;
    });
  };

  useEffect(() => {
    if (errors.second_formMobile) {
      contentRef.current?.scrollToTop(500);
    }
  }, [errors, contentRef]);

  const uploadProfile = async (source: any) => {
    const image: any = await Camera.getPhoto({
      quality: 50,
      resultType: CameraResultType.Base64,
      source: source === "Gallery" ? CameraSource.Photos : CameraSource.Camera,
      correctOrientation: true,
    });
    const blob = new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,
    });
    const file = new File([blob], "Name", {
      lastModified: moment().unix(),
      type: blob.type,
    });
    var bodyFormData: any = new FormData();
    bodyFormData.append("images", file);
    AppContext.setLoading(true);
    var res = await profileUpload("profile", bodyFormData);
    if (res?.data?.status) {
      AppContext.setLoading(false);
      toast("Image Upload Successfully");
      getProfilesList();
    }
    AppContext.updateUser();
  };
  const onSubmit = async (values: any) => {
    if (parseInt(values.second_formMobile) === AppContext.user.mobile) {
      setMobileError(true);
      contentRef.current?.scrollToTop(500);
      return;
    } else setMobileError(false);
    var obj: any = {};
    obj.second_formMobile = parseInt(values.second_formMobile);
    obj.third_formDoshEnglish = values.dosh.value;
    obj.fourth_formIncomeOfApplicantEnglish = values.incomeList.value;
    obj.fourth_formEmployedEnglish = values.employedIn.value;
    obj.fifth_formFamilyStatusEnglish = values.familystatus.value;
    obj.second_formEatingHabitsEnglish = values.eatingHabits.value;
    obj.second_formHeightFeet = values.height.data[0];
    obj.second_formHeightCm = values.height.data[1];
    obj.fourth_formEducationEnglish = education.fourth_formEducationEnglish;
    obj.fourth_formSubEductionEnglish = education.fourth_formSubEductionEnglish;
    obj.second_formPhysicalStatusEnglish =
      values.second_formPhysicalStatusEnglish.value;
    await signUp(obj);
    AppContext.updateUser();
    reset();
    AppContext.setPopups(constant.popups.isGoLive, false);
  };

  const imagePreview = (img: any) => {
    setImagePreviewSrc(img);
    setIsOpen(true);
  };

  const onReject = (modalState: Boolean) => {
    reset();
    AppContext.setPopups(constant.popups.isGoLive, modalState);
    AppContext.setDoItLater();
  };
  const goTo = async () => {
    setOpenPopUp(false);
    AppContext.setPopups(constant.popups.isGoLive, false);
  };

  const onDidDismiss = async () => {
    AppContext.setPopups(constant.popups.isGoLive, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    localStorage.setItem("isModal", "false");
  };

  const onWillDismiss = async () => {
    AppContext.setPopups(constant.popups.isGoLive, false);
    AppContext?.setPopupClose(false);
    AppContext.setDoItLater();
    localStorage.setItem("isModal", "false");
  };

  const onDidPresent = () => {
    AppContext?.setPopupClose(false);
    if (education?.fourth_formSubEductionEnglish) {
      setValue(
        "education",
        education?.fourth_formEducationEnglish +
          " - " +
          education?.fourth_formSubEductionEnglish
      );
      delete errors.education;
    }
    if (AppContext?.user?.second_formMobile)
      setValue("second_formMobile", AppContext?.user?.second_formMobile);
    if (AppContext?.user?.fourth_formEmployedEnglish)
      setValue("employedIn", {
        label: AppContext?.user?.fourth_formEmployedEnglish,
        value: AppContext?.user?.fourth_formEmployedEnglish,
      });
    if (AppContext?.user?.fourth_formIncomeOfApplicantEnglish)
      setValue("incomeList", {
        label: AppContext?.user?.fourth_formIncomeOfApplicantEnglish,
        value: AppContext?.user?.fourth_formIncomeOfApplicantEnglish,
      });
    if (AppContext?.user?.third_formDoshEnglish)
      setValue("dosh", {
        label: AppContext?.user?.third_formDoshEnglish,
        value: AppContext?.user?.third_formDoshEnglish,
      });
    if (AppContext?.user?.second_formPhysicalStatusEnglish)
      setValue("second_formPhysicalStatusEnglish", {
        label: AppContext?.user?.second_formPhysicalStatusEnglish,
        value: AppContext?.user?.second_formPhysicalStatusEnglish,
      });
    if (AppContext?.user?.fifth_formFamilyStatusEnglish)
      setValue("familystatus", {
        label: AppContext?.user?.fifth_formFamilyStatusEnglish,
        value: AppContext?.user?.fifth_formFamilyStatusEnglish,
      });
    if (AppContext?.user?.second_formEatingHabitsEnglish)
      setValue("eatingHabits", {
        label: AppContext?.user?.second_formEatingHabitsEnglish,
        value: AppContext?.user?.second_formEatingHabitsEnglish,
      });
    setEducation(AppContext?.user);
    setMobileError(false);
    getProfilesList();
    getFormDetais();
    localStorage.setItem("isModal", "true");
  };

  return (
    <>
      {!live && liveButton && (
        <>
          {" "}
          <div
            className="floatBtn "
            onClick={() => {
              AppContext.setPopups(constant.popups.isGoLive, true);
            }}
          >
            <IonButton className="px-1">Publish Now</IonButton>
          </div>
          <div
            className="goLiveIcon"
            onClick={() => {
              AppContext.setPopups(constant.popups.isGoLive, true);
            }}
          >
            <IonIcon
              icon={eyeOutline}
              style={{ color: "#fff" }}
              className="fs-22 goLive"
            />
          </div>{" "}
        </>
      )}
      <IonModal
        isOpen={AppContext?.popups[constant.popups.isGoLive]}
        className="GoliveBody"
        onDidDismiss={onDidDismiss}
        onWillPresent={() => {
          localStorage.setItem("isModal", "true");
        }}
        onWillDismiss={onWillDismiss}
        onDidPresent={onDidPresent}
      >
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonTitle className="heading text-white">
              {!AppContext?.user?.verified_at
                ? "Publish Your Profile"
                : "Please fill in all details."}
            </IonTitle>
            {!AppContext?.user?.verified_at && (
              <div slot="end" className="backBtn">
                <IonButton
                  type="submit"
                  className="px-1"
                  onClick={() => {
                    Object.keys(touchedFields).length
                      ? presentAlert({
                          header: "Would you like to save changes?",
                          cssClass: "actionAlert",
                          buttons: [
                            {
                              text: "Yes",
                              role: "confirm",
                              handler: handleSubmit(onSubmit),
                            },
                            {
                              text: "no",
                              role: "cancel",
                              handler: () => {
                                onReject(false);
                              },
                            },
                          ],
                        })
                      : onReject(false);
                  }}
                >
                  <IonIcon icon={closeOutline} style={{ color: "#fff" }} />
                </IonButton>
              </div>
            )}
          </IonToolbar>
        </IonHeader>

        {formDetail && (
          <IonContent ref={contentRef}>
            <IonList className="golive">
              <form className="my-2" onSubmit={handleSubmit(onSubmit)}>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Alternate Mobile Number
                    <span className="errorText m-0">*</span>
                  </IonLabel>
                  <IonInput
                    {...register("second_formMobile", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                    })}
                    type="number"
                    pattern="[0-9]*"
                    maxlength={10}
                    onInput={(e: any) => {
                      const inputValue = e.currentTarget.value;
                      if (inputValue.length > 10) {
                        e.currentTarget.value = inputValue.slice(0, 10);
                      }

                      setIsLessThan10(inputValue.length < 10);
                    }}
                    placeholder="Enter mobile number"
                    className="border-bottom"
                  ></IonInput>
                  {isLessThan10 && (
                    <span className="errorText">
                      Mobile number must be 10 digits
                    </span>
                  )}
                  {errors?.second_formMobile?.type === "required" && (
                    <p className="errorText ms-1">Mobile number is required.</p>
                  )}
                  {errors.second_formMobile &&
                    errors.second_formMobile.type === "maxLength" && (
                      <p className="errorText ms-1">
                        Mobile Number must have 10 digits
                      </p>
                    )}
                  {errors.second_formMobile &&
                    errors.second_formMobile.type === "minLength" && (
                      <p className="errorText ms-1">
                        Mobile Number must have 10 digits
                      </p>
                    )}
                  {mobileError && (
                    <p className="errorText ms-1">
                      The mobile number should be different from the main
                      number.
                    </p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Candidate Education<span className="errorText m-0">*</span>
                  </IonLabel>
                  <IonTextarea
                    autoGrow={true}
                    rows={1}
                    readonly
                    value={
                      education?.fourth_formSubEductionEnglish
                        ? education?.fourth_formEducationEnglish +
                          " - " +
                          education?.fourth_formSubEductionEnglish
                        : ""
                    }
                    placeholder="Select Education "
                    {...register("education", { required: true })}
                    className="border-bottom"
                    onClick={() => {
                      setIsOpenEducation(true);
                    }}
                  ></IonTextarea>
                  <EducationModal
                    isOpenEducation={isOpenEducation}
                    setIsOpenEducation={setIsOpenEducation}
                    formDetail={formDetail}
                    setEducation={setEducation}
                    education={education}
                    setValue={setValue}
                    errors={errors}
                  />
                  {errors?.education?.type === "required" && (
                    <p className="errorText ms-1">Select education.</p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Candidate Employed In
                    <span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={employedInOption}
                    control={control}
                    required={true}
                    name={"employedIn"}
                    placeholder={"Select Employed In"}
                    isSearchable={true}
                  />
                  {errors?.employedIn?.type === "required" && (
                    <p className="errorText ms-1">
                      Select Candidate Employed In.
                    </p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Candidate Income<span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={formDetail?.incomeList?.english}
                    control={control}
                    required={true}
                    name={"incomeList"}
                    placeholder={"Select Income"}
                    isSearchable={false}
                  />
                  {errors?.incomeList?.type === "required" && (
                    <p className="errorText ms-1">Select Candidate Income.</p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Candidate Height<span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={formDetail?.height?.english}
                    control={control}
                    required={true}
                    name={"height"}
                    placeholder={"Select Height"}
                    isSearchable={false}
                  />
                  {errors?.height?.type === "required" && (
                    <p className="errorText ms-1">Select Candidate Height.</p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Manglik<span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={formDetail?.doshList?.english}
                    control={control}
                    required={true}
                    name={"dosh"}
                    placeholder={"Select Manglik status"}
                    isSearchable={false}
                  />
                  {errors?.dosh?.type === "required" && (
                    <p className="errorText ms-1">Select Manglik.</p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Candidate Physical Status
                    <span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={pphysicalStatusArray}
                    control={control}
                    required={true}
                    name={"second_formPhysicalStatusEnglish"}
                    placeholder={"Select Physical Status"}
                    isSearchable={false}
                  />
                  {errors?.second_formPhysicalStatusEnglish?.type ===
                    "required" && (
                    <p className="errorText ms-1">
                      Select Candidate Physical Status
                    </p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Family Status<span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={formDetail?.familyStatusList?.english}
                    control={control}
                    required={true}
                    name={"familystatus"}
                    placeholder={"Select Family Status"}
                    isSearchable={false}
                  />
                  {errors?.familystatus?.type === "required" && (
                    <p className="errorText ms-1">Select Family Status.</p>
                  )}
                </IonGrid>
                <IonGrid>
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Eating Habits<span className="errorText m-0">*</span>
                  </IonLabel>
                  <CustomSelect
                    options={formDetail?.eatingList?.english}
                    control={control}
                    required={true}
                    name={"eatingHabits"}
                    placeholder={"Select Eating Habits"}
                    isSearchable={false}
                  />
                  {errors?.eatingHabits?.type === "required" && (
                    <p className="errorText ms-1">Select Habits.</p>
                  )}
                </IonGrid>
                <IonGrid className="GoLiveImagePreview">
                  <IonLabel position="stacked" className="fs-16 labelItem">
                    Upload Candidate Picture
                  </IonLabel>
                  <IonRow style={{ margin: "10px 0px 0px 0px" }}>
                    {ImageResponse?.length > 0 && (
                      <>
                        {ImageResponse?.map((img: any) => {
                          return (
                            <IonCol size="4">
                              <div>
                                <img
                                  loading="lazy"
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    imagePreview(
                                      `${constant.ImageS3URl}/${img?.folder}/${img?.name}`
                                    );
                                  }}
                                  src={`${constant.ImageS3URl}/${img?.folder}/${img?.name}`}
                                ></img>
                                {isOpen && (
                                  <Lightbox
                                    onImageLoad={() => {
                                      window.dispatchEvent(new Event("resize"));
                                    }}
                                    imageTitle="User Profile"
                                    mainSrc={imagePreviewSrc}
                                    onCloseRequest={() => setIsOpen(false)}
                                    imageLoadErrorMessage="This image failed to load"
                                  />
                                )}
                                {/* <IonIcon icon={closeOutline} className='closeIcon' onClick={() => ActionProfile(img?.id,)} /><br /> */}
                              </div>
                            </IonCol>
                          );
                        })}
                      </>
                    )}
                    <IonCol size="4">
                      <span className="dragBox" onClick={pickImage}>
                        <IonIcon
                          icon={cameraOutline}
                          color="primary"
                          className="fs-24"
                        />
                        <br />
                        Add Photo
                        <IonInput
                          className="drop-zone__input"
                          accept="image/*"
                          readonly
                        ></IonInput>
                      </span>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonCol class="btnFill  ion-margin-bottom">
                  <IonButton type="submit" expand="block">
                    Publish Now
                  </IonButton>
                </IonCol>
              </form>
            </IonList>
          </IonContent>
        )}
      </IonModal>
      <PopUp
        isOpenPopUp={isOpenPopUp && isClosePopUp}
        setOpenPopUp={setOpenPopUp}
        img={publish}
        goTo={goTo}
        cancel={true}
        componentName={"golive"}
      />
    </>
  );
}

export default GoLive;
