import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonIcon, IonSearchbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useState } from 'react';

function EducationModal({ isOpenEducation, setIsOpenEducation, formDetail, setEducation, education, setValue, errors }: any) {
    const handleChange = (education: any, subEducation: any) => {
        setEducation({
            fourth_formEducationEnglish: education?.name_english,
            fourth_formSubEductionEnglish: subEducation?.name_english
        })
        setValue("education", `${education?.name_english} - ${subEducation?.name_english}`)
        delete errors.education
        setIsOpenEducation(false)
    }
    let [results, setResults] = useState([...formDetail?.education]);

    const handleChangeSerach = (ev: Event) => {
        let query = "";
        const target = ev.target as HTMLIonSearchbarElement;
        if (target) query = target.value!.toLowerCase();
        // setResults(formDetail?.education?.filter((d: any) => d?.name_english?.toLowerCase().indexOf(query) > -1));
        setResults([]);
        const educationsArr = JSON.stringify(formDetail?.education);
        let newArr: any = [];
        if (query) {
            JSON.parse(educationsArr).forEach((res: any) => {
                const childArray = res.sub_educations.filter((sub: any) => sub?.name_english?.toLowerCase().indexOf(query) > -1);
                if (childArray.length > 0) {
                    res['sub_educations'] = childArray;
                    newArr.push(res);
                }
            })
            setResults(newArr);
        } else {
            newArr = [...formDetail?.education];
            setResults([...newArr]);
        }
    }

    const onDidDismiss = async () => {
        setIsOpenEducation(false)
    }

    const onWillDismiss = async () => {
        setIsOpenEducation(false)
    }

    return (
        <IonPage>
            <IonContent className="ion-padding filterBody">
                <IonModal isOpen={isOpenEducation} className='filterBody'
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss}
                >
                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>Select Education</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => setIsOpenEducation(false)}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonSearchbar debounce={1000} onIonChange={(ev) => handleChangeSerach(ev)}></IonSearchbar>
                        <div className='educationModal'>
                            {results.map((e: any) => (
                                <>
                                    {e?.sub_educations.length !== 0
                                        && <IonList>
                                            <h5 className='fs-16 px-3' style={{ textDecoration: 'underline' }}><b>{e?.name_english}</b></h5>
                                            <IonRadioGroup allowEmptySelection={true} value={education?.fourth_formSubEductionEnglish} >
                                                {
                                                    e?.sub_educations?.map((subEdt: any, i: any) => {
                                                        return <IonItem >
                                                            <IonLabel style={{ fontSize: 15 }} >{subEdt?.name_english}</IonLabel>
                                                            <IonRadio slot="end" color='primary' value={subEdt?.name_english} onClick={() => { handleChange(e, subEdt) }} ></IonRadio>
                                                        </IonItem>
                                                    })
                                                }
                                            </IonRadioGroup>
                                        </IonList>
                                    }
                                </>
                            ))}
                        </div>
                    </IonContent>
                </IonModal >
            </IonContent >
        </IonPage >
    );
};
export default EducationModal;