import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonList, IonRadioGroup, IonIcon, IonSearchbar, IonCheckbox, IonFooter, IonChip } from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import appContext from "../../context/AppContext"
import CheckboxTree from 'react-checkbox-tree';


function StateModal({ serverStates, setServerStates, filterStates, isOpenState, setIsOpenState, stateList, selectedStates, setSelectedStates }: any) {
    const AppContext = useContext(appContext);

    const local_filters = localStorage.getItem('filters')
    const filters = local_filters ? JSON.parse(local_filters) : {}

    const [data, setData] = useState<any>(serverStates?.length > 0 ? serverStates : filters?.states)
    // const [data, setData] = useState<any>(filterStates?.states ? filterStates?.states : [])
    const [filteredResults, setFilteredResults] = useState<any>(stateList);
    // console.log("state", filterStates);
    const handleChange = (state: any) => {
        const statesArray = [{ english: "All", value: "All" }].concat(stateList)?.map((item: any) => item?.english);
        if (state?.english === 'All') {
            data?.includes(state?.english) ?
                setData([]) : setData(statesArray)
        }
        else {
            const updatedSelectedItems = data?.includes(state?.english)
                ? data?.filter((item: any) => item !== state?.english)
                : [...data, state?.english];
            setData(updatedSelectedItems)
        }
    };


    const onSubmit = () => {
        const updatedSelectedItems = data?.includes('All')
            ? data?.filter((item: any) => item !== 'All')
            : [...data];
        const updatedServerStates = data?.includes('All')
            ? data?.filter((item: any) => item !== 'All')
            : [...data];
        setServerStates(updatedServerStates)
        setSelectedStates(updatedSelectedItems)
    };

    const handleSearch = (event: any) => {
        const searchInput = (event?.target?.value).trim();
        const filteredItems = stateList?.filter((item: any) =>
            item?.english?.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredResults(filteredItems);

    };

    const onDidDismiss = async () => {
        setIsOpenState(false)
    }

    const onWillDismiss = async () => {
        setIsOpenState(false)
    }



    return (
        <><IonPage>
            <IonContent className="ion-padding filterBody">
                <IonModal isOpen={isOpenState} className='filterBody'
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss}
                >
                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>Select State</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => setIsOpenState(false)}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonSearchbar debounce={1000} onIonChange={(event) => handleSearch(event)} ></IonSearchbar>
                        <div className='d-flex' style={{ overflowX: 'scroll', overflowY: "hidden" }}>{data?.map((item: any, i: any) => {
                            return <IonChip key={i} color="medium" >
                                <IonLabel style={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}>{item}</IonLabel>
                                <IonIcon icon={close} onClick={(ind: any) => {
                                    const showStates = data?.filter((i: any) => i !== item)
                                    setData(showStates)
                                }}>
                                </IonIcon>
                            </IonChip >
                        })}
                        </div>
                        <div className='educationModal'>
                            <IonList>
                                <IonRadioGroup allowEmptySelection={true}  >
                                    {[{ english: "All", value: "All" }].concat(filteredResults)?.map((states: any, i: any) => {
                                        return <IonItem key={i}>
                                            <IonLabel style={{ fontSize: 15 }} >{states?.english}</IonLabel>
                                            <IonCheckbox slot="start" checked={data?.includes(states?.english)} color='primary' class='mx-2' onClick={(e) => {
                                                handleChange(states)
                                            }} />
                                        </IonItem>
                                    })
                                    }
                                </IonRadioGroup>
                            </IonList>
                        </div>
                        <IonFooter style={{ display: "grid", position: "sticky", bottom: '0' }}>
                            <IonButton onClick={() => { setIsOpenState(false); onSubmit() }}>Submit</IonButton>
                        </IonFooter>

                    </IonContent>
                </IonModal >
            </IonContent >
        </IonPage ></>
    );
};
export default StateModal;