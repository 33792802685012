import { Redirect, Route, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  IonApp,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonAlert,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import appContext from "./context/AppContext";
import { Network } from "@capacitor/network";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";
import "./theme/common.css";
import "./theme/loader.css";
import "./theme/card.css";
import "./theme/web.css";

import Otp from "./pages/Authentication/Otp";
import Signup from "./pages/Authentication/Signup";
import Auth from "./pages/Authentication/Auth";
import Dashbaord from "./pages/PrivatePage/Dashboard";

//*translation
import "./assets/i18n/config";
import Profile from "./pages/PrivatePage/Profile";
import Interest from "./pages/PrivatePage/Interest";
import Userlist from "./pages/Authentication/Userlist";
import Favourite from "./pages/PrivatePage/Favourite";
import SecondMarrige from "./pages/PrivatePage/secondMarrige";
import Details from "./pages/PrivatePage/Details";
import {
  homeOutline,
  heartOutline,
  mailOutline,
  personCircleOutline,
  globeOutline,
  searchOutline,
  lockOpenOutline,
} from "ionicons/icons";
import NotificationModal, {
  MemoizedNotificationModal,
} from "./pages/PrivatePage/NotificationModal";
import Settings from "./pages/PrivatePage/Settings";
import SearchModal from "./pages/PrivatePage/SearchModal";
import ProfileEdit from "./pages/PrivatePage/ProfileEdit";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import Popups from "./pages/PrivatePage/Popups";
import { Keyboard } from "@capacitor/keyboard";
import LatestProfile from "./pages/PrivatePage/LatestProfile";
import UnlockedProfile from "./pages/PrivatePage/UnlockedProfile";
import Test from "./pages/Authentication/test";
import ReferEarn from "./pages/PrivatePage/ReferEarn";
import AppUrlListener from "./AppUrlListener";
import LessDetails from "./pages/PrivatePage/LessDetail";
import constant from "./constant";
import { generateToken } from "./service";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import BlockedUser from "./pages/PrivatePage/BlockedUser";
import ShadiDoneUsers from "./pages/PrivatePage/ShadiDoneUsers";
import ProfileView from "./pages/PrivatePage/ProfileView";
import TermsCondition from "./pages/PublicPages/Terms&Conditions";
import PrivacyPolicy from "./pages/PublicPages/PrivacyPolicy";
import RefundPolicy from "./pages/PublicPages/RefundPolicy";

setupIonicReact();

const App = () => {
  const [present] = useIonToast();
  const [showSearch, SetShowSearch] = useState(true);
  const AppContext = useContext(appContext);

  useEffect(() => {
    const checkNetWork = async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        present({
          message: "No Internet Connection",
          duration: 0,
          position: "bottom",
          color: "secondary",
          cssClass: "NetWorkToast",
          animated: true,
          icon: globeOutline,
        });
      }
    };
    Keyboard.addListener("keyboardWillShow", (info) => {
      if (info.keyboardHeight) {
        console.log("sadsa");

        SetShowSearch(false);
      }
    });
    Keyboard.addListener("keyboardWillHide", () => {
      console.log("sadsa");
      SetShowSearch(true);
    });
    checkNetWork();
  }, [present]);

  if (AppContext.user === undefined) {
    return (
      <h1 className="onloading">
        <div className="loading-container">
          <div className="loading"></div>
          <div id="loading-text">Loading</div>
        </div>
      </h1>
    );
  }

  return (
    <>
      {AppContext.isLoggedIn ? (
        <AppRoutes showSearch={showSearch} />
      ) : (
        <AuthRoutes />
      )}
    </>
  );
};

const AuthRoutes = () => {
  const mediaQuery = window.matchMedia("(min-width: 576px)");

  useEffect(() => {
    if (window.location.pathname.includes("users")) {
      var id =
        window.location.pathname.split("/")[
          window.location.pathname?.split("/")?.length - 1
        ];
      window.open(`/users${id}`);
    }
  }, [window.location.pathname]);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonRouterOutlet>
          {mediaQuery.matches ? (
            <Route exact path="/" component={Test} />
          ) : (
            <Route exact path="/" component={Auth} />
          )}
          <Route exact path="/signin" component={Auth} />

          <Route exact path="/otp" component={Otp} />
          <Route exact path="/terms" component={TermsCondition} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/refund-policy" component={RefundPolicy} />
          <Route exact path="/app/users/:id" component={LessDetails} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/user-list" component={Userlist} />
          <Route exact path="/signup-form" component={Signup} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

const AppRoutes = ({ showSearch }: any) => {
  const history = useHistory();
  const mediaQuery = window.matchMedia("(min-width: 576px)");
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const AppContext = useContext(appContext);

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        if (parseInt(notification?.notification?.data?.user_id)) {
          setOpenDetail(true);
          setUserID(parseInt(notification.notification.data.user_id));
        }
      }
    );
  }, []);

  useEffect(() => {}, [window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname.includes("users")) {
      setUserID(
        window.location.pathname.split("/")[
          window.location.pathname?.split("/")?.length - 1
        ]
      );
      setOpenDetail(true);
    }
  }, [window.location.pathname]);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonContent>
          {/* <NotificationModal /> */}
          <MemoizedNotificationModal />
          <Popups />
          <IonTabs>
            <IonRouterOutlet>
              <Redirect exact path="/" to="/app/home" />
              <Redirect exact path="/app" to="/app/home" />
              <Route exact path="/app/home" component={Dashbaord} />
              <Route exact path="/app/favourite" component={Favourite} />
              <Route exact path="/app/interest" component={Interest} />
              <Route exact path="/app/profile" component={Profile} />
              <Route
                exact
                path="/app/unlocked-Profile"
                component={UnlockedProfile}
              />
              <Route exact path="/app/profile/settings">
                {" "}
                <Settings />
              </Route>
              <Route exact path="/app/profile/profile-edit">
                {" "}
                <ProfileEdit />
              </Route>
              <Route exact path="/app/profile/second-marrige">
                {" "}
                <SecondMarrige />
              </Route>
              <Route exact path="/app/profile/blocked-user">
                {" "}
                <BlockedUser />
              </Route>
              <Route exact path="/app/terms">
                {" "}
                <TermsCondition />
              </Route>
              <Route exact path="/app/privacy-policy">
                {" "}
                <PrivacyPolicy />
              </Route>
              <Route exact path="/app/refund-policy">
                {" "}
                <RefundPolicy />
              </Route>
              <Route exact path="/app/profile/shadi-done-users">
                {" "}
                <ShadiDoneUsers />
              </Route>
              <Route exact path="/app/profile/profile-view">
                {" "}
                <ProfileView />
              </Route>
              <Route exact path="/app/profile/refer-earn">
                {" "}
                <ReferEarn />
              </Route>
              <Route exact path="/app/latest-profile">
                {" "}
                <LatestProfile />
              </Route>
              <Route exact path="/app/search">
                {" "}
                <SearchModal />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" className="FooterPanel">
              <IonTabButton tab="home" href="/app/home">
                <IonIcon icon={homeOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="favourite" href="/app/favourite">
                <IonIcon icon={heartOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Favourite</IonLabel>
              </IonTabButton>
              <IonTabButton></IonTabButton>
              <IonTabButton tab="interest" href="/app/interest">
                <IonIcon icon={mailOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Interest</IonLabel>
              </IonTabButton>
              <IonTabButton tab="profile" href="/app/unlocked-Profile">
                <IonIcon icon={lockOpenOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Unlocked</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
          {showSearch && !mediaQuery.matches && (
            <IonFab
              vertical="bottom"
              horizontal="center"
              slot="fixed"
              className="test"
            >
              <IonFabButton routerLink="/app/search">
                <IonIcon icon={searchOutline} className="footerIcon" />
              </IonFabButton>
            </IonFab>
          )}

          <Details
            isOpenDetail={isOpenDetail}
            setOpenDetail={setOpenDetail}
            id={userID}
          />
        </IonContent>
      </IonReactRouter>
    </IonApp>
  );
};
export default App;
