import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonPage, IonRow, IonSkeletonText, IonTitle, IonToolbar, useIonAlert, useIonToast, useIonViewWillEnter } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { arrowBackOutline } from 'ionicons/icons';
import NewUserCard from './NewUserCard';
import Details from './Details';
import { addToFavoriteList, getShadiDoneUser, sendInterestRequest, sendPhotoRequestApi } from '../../service';
import ShadiDoneUserCard from './ShadiDoneUserCard';

const ShadiDoneUsers = () => {
    const [loading, setLoading] = useState(true);
    const [isOpenDetail, setOpenDetail] = useState<any>(false);
    const [userID, setUserID] = useState<any>("");
    const history = useHistory();
    const [list, setList] = useState<any>([]);
    const [present] = useIonToast();
    const [presentAlert] = useIonAlert();
    const { state }: any = useLocation();

    useEffect(() => {
        getFavourite()
    }, [])

    useIonViewWillEnter(() => {
        getFavourite()
    })

    const getFavourite = async () => {
        const res = await getShadiDoneUser();
        if (res?.data?.status) {
            setLoading(false);
            setList(res?.data?.data?.excelHistory)
        }
    }

    const addToFavorite = async (selectedUser: any, index: any) => {
        const usersListTemp = [...list];
        usersListTemp[index].favorite = !usersListTemp[index].favorite;
        await addToFavoriteList(selectedUser);
        getFavourite();
    }

    const sendInterest = async (selectedUser: any, index: any) => {
        var res: any = await sendInterestRequest(selectedUser);
        if (res?.data?.status) {
            const usersListTemp = [...list];
            usersListTemp[index].interest = true;
            present({
                message: "Sent Interest Successfully",
                duration: 1500,
                position: 'top',
                color: "success",
                cssClass: "alertToast"
            });
            getFavourite();
        } else {
            presentAlert({
                header: res?.data?.message,
                cssClass: 'actionAlert',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    }
                ],
            })
        }
    }

    const sendPhotoRequest = async (selectedUser: any, index: any) => {
        var res: any = await sendPhotoRequestApi(selectedUser);
        if (res?.data?.status) {
            const usersListTemp = [...list];
            usersListTemp[index].photoRequest = [{
                count_no: usersListTemp[index]?.photoRequest[0]?.count_no ? usersListTemp[index]?.photoRequest[0]?.count_no + 1 : 1
            }];
            setList(usersListTemp)
            present({
                message: "Photo request sent successfully.",
                duration: 1500,
                position: 'top',
                color: "success",
                cssClass: "alertToast"
            });
        }
    }

    return (
        <IonPage >
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <Link to='/app/profile' slot='start' className='backBtn'>
                        <IonButton type="submit" className="px-1" >
                            <IonIcon icon={arrowBackOutline} color="light" />
                        </IonButton>
                    </Link>
                    <IonTitle className='fs-18 text-white'>
                        <b>Shadi Done Users</b>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ marginBottom: "50px !important " }}>
                {loading ?
                    <IonList >
                        {
                            [...Array(3)].map(i => (
                                <IonCard className="box-new-profile ion-justify-content-center ion-align-items-center">
                                    <IonGrid className="ion-margin-0" style={{ maxHeight: "11em", margin: 0 }}>
                                        <IonRow>
                                            <IonCol size="4">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        paddingTop: "100%",
                                                        position: "relative",
                                                        background: "#ccc",
                                                    }}
                                                ></div>
                                            </IonCol>
                                            <IonCol size="8">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonSkeletonText animated style={{ width: "80%", height: "12px" }}></IonSkeletonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonSkeletonText animated style={{ width: "60%", height: "12px" }}></IonSkeletonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonSkeletonText animated style={{ width: "80%", height: "12px" }}></IonSkeletonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCard>))
                        }
                    </IonList> : <>
                        {list?.length < 1 ?
                            <>
                                <IonList className='p2 m-3 fw-bold text-center'>
                                    No Shadi Done User Found
                                </IonList>
                            </> :
                            <>
                                <IonList>
                                    {list?.map((user: any, i: any) => {
                                        return <ShadiDoneUserCard
                                            addToFavorite={addToFavorite}
                                            sendInterest={sendInterest}
                                            getFavourite={() => {
                                                getFavourite()
                                            }}
                                            user={user}
                                            i={i}
                                            history={history}
                                            sendPhotoRequest={sendPhotoRequest}
                                            onAction={() => {
                                                setUserID(user?.id)
                                            }}
                                            isOpenDetailModal={() => {
                                                setOpenDetail(true);
                                            }}
                                        />
                                    })}
                                </IonList>
                            </>}
                        <Details isOpenDetail={isOpenDetail} setOpenDetail={setOpenDetail} id={userID} />

                        {/* <IonInfiniteScroll
                            onIonInfinite={(ev) => {
                                getNextUserlist(pageNumber);
                                setTimeout(() => ev.target.complete(), 500);
                            }}
                        >
                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                        </IonInfiniteScroll> */}
                    </>
                }
            </IonContent>
        </IonPage>
    )
}

export default ShadiDoneUsers
