import { useCallback, useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonIcon,
  IonLabel,
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCard,
  IonAvatar,
  IonSkeletonText,
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  useIonToast,
  IonInput,
  IonChip,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonViewDidLeave,
} from "@ionic/react";
import appContext from "../../context/AppContext";
import {
  arrowBackOutline,
  close,
  funnelOutline,
  heartCircleOutline,
  searchCircleSharp,
} from "ionicons/icons";
import {
  addToFavoriteList,
  dashboard,
  search,
  sendInterestRequest,
  sendPhotoRequestApi,
  userFilters,
  getCurrentPackage,
  suggestionApi,
  getHinduEducationsApi,
} from "../../service";
import UserCard from "./UserCard";
import { useHistory } from "react-router-dom";
import vector2 from "../../assets/images/Vector(2).png";
import vector1 from "../../assets/images/Vector(1).png";
import DashboardFilter from "./DashboardFilter";
import Header from "../shared/Header";
import Details from "./Details";
import { Keyboard } from "@capacitor/keyboard";
import { toast } from "react-toastify";
import NewUserCard from "./NewUserCard";

let initialValues = {
  marritalStatus: [],
  sect: [],
  states: [],
  employedIn: [],
  education: [],
  dosh: [],
  physicalStatus: [],
  familyStatus: [],
  incomeOfApplicant: [],
  age: { start: 18, end: 80 },
  startAge: { start: 18, end: 80 },
  endAge: { start: 18, end: 80 },
  isProfile: false,
};

function SearchModal() {
  if (!localStorage.getItem("filters")) {
    localStorage.setItem("filters", JSON.stringify(initialValues));
  }
  const local_filters = localStorage.getItem("filters");
  const filters = local_filters ? JSON.parse(local_filters) : initialValues;

  const [serverStartAge, setServerStartAge] = useState<any>(
    filters?.age?.start ?? null
  );
  const [serverEndAge, setServerEndAge] = useState<any>(
    filters?.age?.end ?? null
  );
  const [serverDosh, setServerDosh] = useState<any>(filters?.dosh ?? null);
  const [serverEducation, setServerEducation] = useState<any>(
    filters?.education ?? null
  );
  const [serverPhysicalStatus, setServerPhysicalStatus] = useState<any>(
    filters?.physicalStatus ?? []
  );
  const [serverFamilyStatus, setServerFamilyStatus] = useState<any>(
    filters?.familyStatus ?? []
  );
  const [serverIncomeOfApplicant, setServerIncomeOfApplicant] = useState<any>(
    filters?.incomeOfApplicant ?? []
  );
  const [serverEmployedIn, setServerEmployedIn] = useState<any>(
    filters?.employedIn ?? null
  );
  const [serverMaritalStatus, setServerMaritalStatus] = useState<any>(
    filters?.marritalStatus ?? null
  );
  const [serverSect, setServerSect] = useState<any>(filters?.sect ?? null);
  const [serverStates, setServerStates] = useState<any>(
    filters?.states ?? null
  );

  const AppContext = useContext(appContext);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [filterStates, setFilterStates] = useState<any>(filters);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageNumber2, setPageNumber2] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const [sectOption, setsectOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [CurrentPackageSpecial, setCurrentPackageSpecial] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [SelectSearch, setSelectSearch] = useState<any>({
    type: "name",
    value: "",
  });
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [isDataAvailable, setDataAvailable] = useState<any>(true);
  const [suggestionList, setSuggestionList] = useState<any>([]);
  const [userID, setUserID] = useState<any>("");
  const [presentAlert] = useIonAlert();

  const getUserList = useCallback(async (pN: any) => {
    try {
      setLoading(true);
      const localFilters = localStorage.getItem("filters");
      const filterStatesJSON = localFilters
        ? JSON.parse(localFilters)
        : initialValues;
      const response = await dashboard(filterStatesJSON, pN);
      setPageNumber(pN + 1);
      setDataAvailable(response?.data?.data?.length >= 10 ? true : false);
      setUsersList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user list:", error);
      setLoading(false);
    }
  }, []);

  const getOurSuggestionList = async () => {
    const suggestionRespo̥nse = await suggestionApi(0);
    setPageNumber2(1);
    setSuggestionList(suggestionRespo̥nse?.data?.data);
  };

  useEffect(() => {
    CurrentPackageData();
    getOurSuggestionList();
    getHinduSect();
  }, []);

  const getHinduSect = async () => {
    const res = await getHinduEducationsApi();
    let tempArr: any = [
      {
        value: "All",
        label: "All",
        children: [],
      },
    ];
    res?.data?.data?.HinduSect?.map((i: any) => {
      // tempArr[0].children.push({
      //   value: i?.name_english,
      //   label: i?.name_english,
      //   isDisabled: true,
      //   isGroupHeding: true,
      // });
      i?.HinduSubSect?.map((u: any) => {
        tempArr[0].children.push({
          value: u?.name_english,
          label: u?.name_english,
        });
      });
    });

    setsectOption(tempArr);
  };

  const CurrentPackageData = async () => {
    let res: any = await getCurrentPackage();
    if (res?.data?.status) {
      const getPackagesByType = (type: any) =>
        res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
      const paymentVerified = AppContext?.user?.payment_verified_at === 1;
      setCurrentPackageBasic(
        (getPackagesByType("basic") ||
          getPackagesByType(null) ||
          getPackagesByType("null") ||
          getPackagesByType("Basic")) &&
          paymentVerified
          ? getPackagesByType("basic") ||
              getPackagesByType(null) ||
              getPackagesByType("Basic")
          : null
      );
      setCurrentPackageSpecial(
        getPackagesByType("special") || getPackagesByType("Special")
          ? getPackagesByType("special") || getPackagesByType("Special")
          : null
      );
      setCurrentPackageVip(
        getPackagesByType("VIP") && paymentVerified
          ? getPackagesByType("VIP")
          : null
      );
    }
  };

  useEffect(() => {
    getUserList(0);
  }, [getUserList]);

  const onSubmit = async (values: any, pN: any) => {
    setLoading(true);
    try {
      if (values?.value) {
        let res = await search(values, pN);
        if (res?.data?.status) {
          setPageNumber(pN + 1);
          let uList = pN === 0 ? [] : usersList;
          let newList = await removeDuplicates(
            [...uList, ...res?.data?.data],
            "id"
          );
          setDataAvailable(true);
          setUsersList(newList);
          setLoading(false);
        }
      } else {
        const localFilters = localStorage.getItem("filters");
        const filterStatesJSON = localFilters
          ? JSON.parse(localFilters)
          : initialValues;
        let response = await dashboard(filterStatesJSON, pN);
        setPageNumber(pN + 1);
        let uList = pN === 0 ? [] : usersList;
        let newList = await removeDuplicates(
          [...uList, ...response?.data?.data],
          "id"
        );

        setDataAvailable(response?.data?.data?.length >= 10 ? true : false);
        setUsersList(newList);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getOurSuggestionMoreData = async (pN: any) => {
    setLoading(true);
    try {
      let response = await suggestionApi(pN);
      setPageNumber2(pN + 1);
      let uList = pN === 0 ? [] : suggestionList;
      let newList = await removeDuplicates(
        [...uList, ...response?.data?.data],
        "id"
      );
      setSuggestionList(newList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function removeDuplicates(arr: any, key: any) {
    const seen = new Set();
    return arr.filter((item: any) => {
      const value = key ? item[key] : item;
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }

  const addToFavorite = async (selectedUser: any, index: any) => {
    const usersListTemp = [...usersList];
    usersListTemp[index].favorite = !usersListTemp[index].favorite;
    setUsersList(usersListTemp);
    await addToFavoriteList(selectedUser);
  };

  const sendInterest = async (selectedUser: any, index: any) => {
    let res: any = await sendInterestRequest(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...usersList];
      usersListTemp[index].interest = true;
      setUsersList(usersListTemp);
      toast("Sent Interest Successfully");
    } else {
      presentAlert({
        header: res?.data?.message,
        cssClass: "actionAlert",
        buttons: [
          {
            text: "Ok",
            role: "confirm",
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (usersList && currentIndex >= 0 && currentIndex < usersList.length) {
      setUserID(usersList[currentIndex].id);
    }
  }, [currentIndex, usersList, setUserID]);

  useEffect(() => {
    if (usersList && userID) {
      const newIndex = usersList.findIndex(
        (user: any) => user.userID === userID
      );
      if (newIndex !== -1) {
        setCurrentIndex(newIndex);
      }
    }
  }, [userID, usersList]);

  const handleProfileNavigation = (type: any) => {
    if (usersList) {
      if (type === "previous") {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
      } else if (type === "next") {
        if (currentIndex < usersList.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
      }
    }
  };

  const sendPhotoRequest = async (selectedUser: any, index: any) => {
    let res: any = await sendPhotoRequestApi(selectedUser);
    if (res?.data?.status) {
      const usersListTemp = [...usersList];
      usersListTemp[index].photoRequest = [
        {
          count_no: usersListTemp[index]?.photoRequest[0]?.count_no
            ? usersListTemp[index]?.photoRequest[0]?.count_no + 1
            : 1,
        },
      ];
      setUsersList(usersListTemp);
      toast("Photo request sent successfully.");
    }
  };

  const camel2title = (camelCase: any) =>
    camelCase
      .replace(/([A-Z])/g, (match: any) => ` ${match}`)
      .replace(/^./, (match: any) => match.toUpperCase())
      .trim();

  const removeSingleFilterStates = (type: any) => {
    switch (type) {
      case "age":
        setServerStartAge(18);
        setServerEndAge(80);
        break;
      case "state":
        setServerStates([]);
        break;
      case "sect":
        setServerSect([]);
        break;
      case "education":
        setServerEducation([]);
        break;
      case "employedIn":
        setServerEmployedIn([]);
        break;
      case "maritalStatus":
        setServerMaritalStatus([]);
        break;
      case "manglik":
        setServerDosh([]);
        break;
      case "physicalStatus":
        setServerPhysicalStatus([]);
        break;
      case "familyStatus":
        setServerFamilyStatus([]);
        break;
      case "incomeOfApplicant":
        setServerIncomeOfApplicant([]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const updatedServerFilters = {
      age: {
        start: serverStartAge,
        end: serverEndAge,
      },
      dosh: serverDosh,
      education: serverEducation,
      employedIn: serverEmployedIn,
      physicalStatus: serverPhysicalStatus,
      familyStatus: serverFamilyStatus,
      incomeOfApplicant: serverIncomeOfApplicant,
      endAge: {
        start: serverStartAge,
        end: serverEndAge,
      },
      isProfile: false,
      marritalStatus: serverMaritalStatus,
      sect: serverSect,
      startAge: {
        start: serverStartAge,
        end: serverEndAge,
      },
      states: serverStates,
    };
    const filtersJSON = JSON.stringify(updatedServerFilters);
    localStorage.setItem("filters", filtersJSON);
    getUserList(0);
  }, [
    serverStartAge,
    serverEndAge,
    serverStates,
    serverEducation,
    serverDosh,
    serverPhysicalStatus,
    serverFamilyStatus,
    serverIncomeOfApplicant,
    serverEmployedIn,
    serverMaritalStatus,
    serverSect,
  ]);

  return (
    <>
      <div className="header-common-box">
        <Header />
      </div>
      <IonPage className="search-body-part">
        <IonContent>
          <IonHeader>
            <IonToolbar color={"primary"}>
              <div
                onClick={() => {
                  history.goBack();
                }}
                slot="start"
                className="backBtn"
              >
                <IonButton className="px-1">
                  <IonIcon icon={arrowBackOutline} color="light" />
                </IonButton>
              </div>
              <IonTitle className="fs-18 text-white">
                <b>Search</b>
              </IonTitle>
              <IonGrid class="backBtn " slot="end">
                {window.location.pathname === "/app/search" && (
                  <IonButton
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    className="px-1"
                  >
                    <IonIcon icon={funnelOutline} style={{ color: "#fff" }} />
                    &nbsp; Filter&nbsp;
                  </IonButton>
                )}
              </IonGrid>
              {/* <Packages /> */}
              <DashboardFilter
                sectOption={sectOption}
                serverStartAge={serverStartAge}
                setServerStartAge={setServerStartAge}
                serverEndAge={serverEndAge}
                setServerEndAge={setServerEndAge}
                serverDosh={serverDosh}
                setServerDosh={setServerDosh}
                serverEducation={serverEducation}
                setServerEducation={setServerEducation}
                serverIncomeOfApplicant={serverIncomeOfApplicant}
                serverFamilyStatus={serverFamilyStatus}
                serverPhysicalStatus={serverPhysicalStatus}
                serverEmployedIn={serverEmployedIn}
                setServerEmployedIn={setServerEmployedIn}
                setServerIncomeOfApplicant={setServerIncomeOfApplicant}
                setServerFamilyStatus={setServerFamilyStatus}
                setServerPhysicalStatus={setServerPhysicalStatus}
                serverMaritalStatus={serverMaritalStatus}
                setServerMaritalStatus={setServerMaritalStatus}
                serverSect={serverSect}
                setServerSect={setServerSect}
                serverStates={serverStates}
                setServerStates={setServerStates}
                filterStates={filterStates}
                setFilterStates={setFilterStates}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                getUserList={getUserList}
              />
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="pt-3 px-2 pb-0">
              <div className="px-2">
                {/* <IonLabel position="stacked" className='label'>Search keyword</IonLabel> */}
                <div className="search-input">
                  <IonInput
                    enterkeyhint="search"
                    type="search"
                    value={SelectSearch?.value}
                    onKeyUp={(e) => {
                      if (e?.key === "Enter") {
                        onSubmit(SelectSearch, 0);
                      }
                    }}
                    placeholder="Enter Search Keyword"
                    onIonChange={(event: any) => {
                      if (event.target.value === "")
                        onSubmit(
                          { value: "", type: SelectSearch.type, event: event },
                          0
                        );
                      setSelectSearch({
                        ...SelectSearch,
                        value: event.target.value,
                      });
                    }}
                  ></IonInput>
                  {SelectSearch?.value && (
                    <IonButton
                      fill="clear"
                      onClick={() => {
                        setSelectSearch({ ...SelectSearch, value: "" });
                        onSubmit({ value: "", type: SelectSearch.type }, 0);
                      }}
                    >
                      <IonIcon
                        style={{ fontSize: 20 }}
                        icon={close}
                        color="secondary"
                      />
                    </IonButton>
                  )}
                  <IonButton
                    onClick={() => {
                      onSubmit(SelectSearch, 0);
                    }}
                    fill="clear"
                  >
                    <IonIcon
                      style={{ fontSize: 32 }}
                      icon={searchCircleSharp}
                      color="primary"
                    />
                  </IonButton>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="serachContainer pt-1 px-4"
              >
                <IonLabel class="filterBtn">
                  <IonButton
                    size="small"
                    fill={SelectSearch?.type === "name" ? "solid" : "outline"}
                    style={{ fontWeight: 600 }}
                    onClick={() =>
                      setSelectSearch({ ...SelectSearch, type: "name" })
                    }
                  >
                    Name
                  </IonButton>
                  <IonButton
                    size="small"
                    fill={SelectSearch?.type === "mobile" ? "solid" : "outline"}
                    style={{ fontWeight: 600 }}
                    onClick={() =>
                      setSelectSearch({ ...SelectSearch, type: "mobile" })
                    }
                  >
                    Mobile
                  </IonButton>
                  <IonButton
                    size="small"
                    fill={SelectSearch?.type === "id" ? "solid" : "outline"}
                    style={{ fontWeight: 600 }}
                    onClick={() =>
                      setSelectSearch({ ...SelectSearch, type: "id" })
                    }
                  >
                    Id
                  </IonButton>
                </IonLabel>
              </div>
            </div>
            {/* <span className='filterbtn'>
                            {window.location.pathname === "/app/search" &&
                                <IonButton onClick={() => { setIsOpen(true); }} className="px-1" >
                                    <IonIcon icon={funnelOutline} style={{ color: "#fff" }} />&nbsp; Filter&nbsp;({serverStartAge !== 18 && serverEndAge !== 80 ? 1 : 0 + serverStates?.length > 0 ? 1 : 0 + serverSect?.length > 0 ? 1 : 0 + serverEducation?.length > 0 ? 1 : 0 + serverEmployedIn?.length > 0 ? 1 : 0 + serverMaritalStatus?.length > 0 ? 1 : 0 + serverDosh?.length > 0 ? 1 : 0})
                                </IonButton>
                            }
                        </span> */}

            <div className="px-3 w-100">
              {serverStartAge !== 18 && serverEndAge !== 80 && (
                <IonChip color="medium">
                  <IonLabel>Age</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("age")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverStates?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>State</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("state")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverSect?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Sect</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("sect")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverEducation?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Education</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("education")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverEmployedIn?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>EmployedIn</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("employedIn")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverMaritalStatus?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Marital Status</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("maritalStatus")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverDosh?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Manglik</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("manglik")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverPhysicalStatus?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Physical Status</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("physicalStatus")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverFamilyStatus?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Family Status</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() => removeSingleFilterStates("familyStatus")}
                  ></IonIcon>
                </IonChip>
              )}
              {serverIncomeOfApplicant?.length > 0 && (
                <IonChip color="medium">
                  <IonLabel>Applicant's Income</IonLabel>
                  <IonIcon
                    icon={close}
                    onClick={() =>
                      removeSingleFilterStates("incomeOfApplicant")
                    }
                  ></IonIcon>
                </IonChip>
              )}
            </div>

            {loading && (
              // <IonList >
              //     {
              //         [...Array(3)].map((i, index) => (
              //             <IonCard key={index} className="box-new-profile ion-justify-content-center ion-align-items-center">
              //                 <IonGrid className="ion-margin-0" style={{ maxHeight: "11em", margin: 0 }}>
              //                     <IonRow>
              //                         <IonCol size="4">
              //                             <div
              //                                 style={{
              //                                     width: "100%",
              //                                     paddingTop: "100%",
              //                                     position: "relative",
              //                                     background: "#ccc",
              //                                 }}
              //                             ></div>
              //                         </IonCol>
              //                         <IonCol size="8">
              //                             <IonRow>
              //                                 <IonCol>
              //                                     <IonSkeletonText animated style={{ width: "80%", height: "12px" }}></IonSkeletonText>
              //                                 </IonCol>
              //                             </IonRow>
              //                             <IonRow>
              //                                 <IonCol>
              //                                     <IonSkeletonText animated style={{ width: "60%", height: "12px" }}></IonSkeletonText>
              //                                 </IonCol>
              //                             </IonRow>
              //                             <IonRow>
              //                                 <IonCol>
              //                                     <IonSkeletonText animated style={{ width: "80%", height: "12px" }}></IonSkeletonText>
              //                                 </IonCol>
              //                             </IonRow>
              //                         </IonCol>
              //                     </IonRow>
              //                 </IonGrid>
              //             </IonCard>
              //         ))
              //     }
              // </IonList >
              <div
                className="loading-container"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="loading"></div>
                <div id="loading-text">Loading</div>
              </div>
            )}
            {usersList.length < 1 ? (
              <IonCard className="card ">
                {" "}
                <p className="fs-16 ion-text-center ion-margin-top">
                  No Data Found !
                </p>
              </IonCard>
            ) : (
              <>
                <IonList>
                  {usersList?.map((user, i) => {
                    return (
                      <NewUserCard
                        key={i}
                        addToFavorite={addToFavorite}
                        sendInterest={sendInterest}
                        user={user}
                        i={i}
                        history={history}
                        CurrentPackageBasic={CurrentPackageBasic}
                        CurrentPackageVip={CurrentPackageVip}
                        CurrentPackageSpecial={CurrentPackageSpecial}
                        sendPhotoRequest={sendPhotoRequest}
                        onAction={() => {
                          setUserID(user?.id);
                        }}
                        isOpenDetailModal={() => {
                          setOpenDetail(true);
                        }}
                      />
                    );
                  })}
                </IonList>
                {/* {isDataAvailable && (
                  <div className="loading-container">
                    <div className="loading"></div>
                    <div id="loading-text">Loading</div>
                  </div>
                )} */}
                {isDataAvailable && (
                  <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                      onSubmit(SelectSearch, pageNumber);
                      setTimeout(() => ev.target.complete(), 500);
                    }}
                  >
                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                )}
                {/* loader for more data */}
                <Details
                  isOpenDetail={isOpenDetail}
                  handleProfileNavigation={handleProfileNavigation}
                  setOpenDetail={setOpenDetail}
                  id={userID}
                />
              </>
            )}
            {!isDataAvailable && suggestionList?.length !== 0 && (
              <div className="container">
                <IonRow style={{ alignItems: "center", margin: "8px 12px" }}>
                  <IonCol size="3" style={{ padding: 0 }}>
                    <div style={{ margin: "0px 0px 0px 0px", width: "auto" }}>
                      <div style={{ padding: 0 }}>
                        <div
                          className="hr-border-red"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-yellow"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-green"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-blue"
                          style={{ height: 1 }}
                        ></div>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="6" style={{ padding: 0 }}>
                    <p style={{ fontSize: 14, fontWeight: 600, margin: 0 }}>
                      <b>
                        <IonIcon
                          icon={heartCircleOutline}
                          color="primary"
                          size="small"
                          style={{ marginRight: 4, verticalAlign: "middle" }}
                        />
                        Our Suggestions
                      </b>
                    </p>
                  </IonCol>
                  <IonCol size="3" style={{ padding: 0 }}>
                    <div style={{ margin: "0px 0px 0px 0px", width: "auto" }}>
                      <div style={{ padding: 0 }}>
                        <div
                          className="hr-border-red"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-yellow"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-green"
                          style={{ height: 1 }}
                        ></div>
                        <div
                          className="hr-border-blue"
                          style={{ height: 1 }}
                        ></div>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <IonList>
                  {suggestionList?.map((user: any, i: any) => {
                    return (
                      <NewUserCard
                        key={i}
                        addToFavorite={addToFavorite}
                        sendInterest={sendInterest}
                        user={user}
                        i={i}
                        history={history}
                        CurrentPackageBasic={CurrentPackageBasic}
                        CurrentPackageVip={CurrentPackageVip}
                        CurrentPackageSpecial={CurrentPackageSpecial}
                        sendPhotoRequest={sendPhotoRequest}
                        onAction={() => {
                          setUserID(user?.id);
                        }}
                        isOpenDetailModal={() => {
                          setOpenDetail(true);
                        }}
                      />
                    );
                  })}
                </IonList>
                {suggestionList?.length !== 1 && (
                  <div className="loading-container">
                    <div className="loading"></div>
                    <div id="loading-text">Loading</div>
                  </div>
                )}
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    getOurSuggestionMoreData(pageNumber2);
                    setTimeout(() => ev.target.complete(), 500);
                  }}
                >
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </div>
            )}
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </IonContent>
        </IonContent>
        {/* <GoLive /> */}
      </IonPage>
    </>
  );
}

export default SearchModal;
