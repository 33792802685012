import { useContext, useEffect, useRef } from "react";
import { IonModal, createAnimation, IonImg } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";

function PopUp({
  isOpenPopUp,
  setOpenPopUp,
  img,
  goTo,
  cancel,
  componentName,
}: any) {
  const AppContext = useContext(appContext);
  const modal = useRef<HTMLIonModalElement>(null);
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  useEffect(() => {
    if (componentName === "newaccount")
      setTimeout(() => {
        setOpenPopUp(false);
        localStorage.removeItem(constant.NewAccount);
      }, 5000);
  }, []);

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };
  return (
    <div className="subscribeModal">
      <IonModal
        isOpen={isOpenPopUp}
        id="example-modal-subscribe"
        ref={modal}
        trigger="open-custom-dialog"
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        backdropDismiss={false}
        onClick={() => setOpenPopUp(false)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            onClick={() => {
              goTo();
            }}
            style={{ position: "relative" }}
          >
            <img loading="lazy" src={`${img}`}></img>
            {cancel && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={
                    componentName === "religious"
                      ? { bottom: "20%" }
                      : componentName === "birth"
                      ? { bottom: "18%" }
                      : componentName === "family"
                      ? { bottom: "16%" }
                      : componentName === "subscribenow"
                      ? { bottom: "1%" }
                      : componentName === "golive" ||
                        componentName === "unlock" ||
                        componentName === "isGoLive"
                      ? { bottom: "1%" }
                      : componentName === "isSubscribe"
                      ? { bottom: "1%" }
                      : componentName === "profilePicture"
                      ? { bottom: "10%" }
                      : componentName === "partnerPreference"
                      ? { bottom: "10%" }
                      : componentName === "BioData"
                      ? { bottom: "13%" }
                      : componentName === "filter"
                      ? { bottom: "9%" }
                      : { bottom: "10%" }
                  }
                  type="button"
                  className={`btn popUpBtn mt-1`}
                  onClick={(event) => {
                    AppContext.setDoItLater();
                    event.stopPropagation();
                    setOpenPopUp(false);
                  }}
                >
                  DO IT LATER
                </button>
              </div>
            )}
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default PopUp;
